'use client';

import PlayVideoIcon from 'components/icons/play-video';
import { withSuspense } from 'components/with-suspense';
import { useState } from 'react';
import './video-block.css';

const VideoSkeleton = (
  <div className="animate-pulse">

  </div>
);

export const VideoEmbed = withSuspense((
  props: {
    content: any;
    contentAutoplay: any;
    posterImage: string;
    posterImageAltText: string;
    posterImageVisibility: boolean;
    url: string;
  }
) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const posterImage = props.posterImage;
  const posterAlt = props.posterImageAltText;
  const hidePoster = props.posterImageVisibility;
 
  const handlePosterClick = () => {
    setHasBeenClicked(true);
  }

  return (
    <div className="video-block">
      {posterImage && !hidePoster && (
        <div className={`video-block-poster${hasBeenClicked ? ' video-is-active' : ''}`}>
          <img 
            src={posterImage}
            className="video-block-poster-image"
            alt={posterAlt ? posterAlt : 'Video thumbnail image'}
          />
          <button
            aria-label="Click image to play video"
            className="video-block-poster-trigger"
            onClick={handlePosterClick}
          >
            <PlayVideoIcon />
          </button>
        </div>
      )}

      {posterImage && !hidePoster && hasBeenClicked ? (
        <div
          className="video-block-builder-embed"
          dangerouslySetInnerHTML={{ __html: props.contentAutoplay }}
        />
      ) : posterImage && hidePoster ? (
        <div
          className="video-block-builder-embed no-poster"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      ) : !posterImage && (
        <div
          className="video-block-builder-embed no-poster"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      )}
    </div>
  );
}, VideoSkeleton)
