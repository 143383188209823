import {type SearchResponseModel} from '../generated/search';
import {type SuggestResponseModel} from '../generated/search/models/SuggestResponseModel';
import {
  type AutocompleteDataAdapter,
  type SuggestionBase,
  type SuggestionProduct,
  type SuggestionQuery,
  type SuggestionUIGroup,
} from 'propel-shared-utility';

const convertSearchspringSuggestions = (
  suggestionsData: SuggestResponseModel | undefined,
): SuggestionUIGroup<SuggestionQuery> | undefined => {
  const text = suggestionsData?.suggested?.text;
  if (text) {
    return {
      group: 'suggest',
      suggestions: [
        {text, isActive: false, uniqueId: `${text}__group_suggestions`},
      ],
      trending: []
    };
  }
};

const convertTrendingSuggestions = (
  trendingData: any | undefined,
): any | undefined => {
  const trendingResp = trendingData?.trending?.queries  
  if (trendingResp) {
    return {
      group: 'trending',
      suggestions: [],
      trending: trendingResp,
    };
  }
};

const convertAlternativeSuggestions = (
  suggestionsData: SuggestResponseModel | undefined,
): SuggestionUIGroup<SuggestionQuery> | undefined => {
  const alternatives = suggestionsData?.alternatives;
  if (alternatives) {
    const result: SuggestionUIGroup<SuggestionQuery> = {
      group: 'alternatives',
      suggestions: alternatives.map((a) => {
        return {
          text: a.text,
          isActive: false,
          uniqueId: `${a.text}__group_alternative`,
        };
      }),
      trending: []
    };
    return result;
  }
  return;
};

const convertProductSuggestions = (
  autocompleteData: SearchResponseModel | undefined,
): SuggestionUIGroup<SuggestionProduct> | undefined => {
  const products = autocompleteData?.results;
  if (products) {
    const result: SuggestionUIGroup<SuggestionProduct> = {
      group: 'products',
      suggestions: products.map((p) => {
        return {
          name: p.name as string,
          isActive: false,
          uniqueId: `${p.id}__group_products`,
          price: p.price as string,
          imageUrl: p.thumbnailImageUrl as string,
          pdpUrl: p.url || '',
          intellisuggestData: p.intellisuggestData || '',
          intellisuggestSignature: p.intellisuggestSignature || '',
          custom_url: p.custom_url,
          banner: p.mfield_cql_product_banner,
          burst: p.mfield_cql_product_burst,
          categories: p.ss_ctgy_name_url,
          result: p
        };
      }),
      trending: []
    };
    return result;
  }
};

const convertDidYouMeanSuggestions = (
  autocompleteData: SearchResponseModel | undefined,
): SuggestionUIGroup<SuggestionQuery> | undefined => {
  const didYouMeanQuery = autocompleteData?.didYouMean?.query;
  if (didYouMeanQuery) {
    const result: SuggestionUIGroup<SuggestionQuery> = {
      group: 'didYouMean',
      suggestions: [
        {
          text: didYouMeanQuery,
          isActive: false,
          uniqueId: `${didYouMeanQuery}__group_didYouMean`,
        },
      ],
      trending: []
    };
    return result;
  }
};

type SearchSpringUIGroup =
  | 'trending'
  | 'products'
  | 'didYouMean'
  | 'alternatives'
  | 'suggestions';

type UIOrdering = [
  SearchSpringUIGroup | undefined,
  SearchSpringUIGroup | undefined,
  SearchSpringUIGroup | undefined,
  SearchSpringUIGroup | undefined,
  SearchSpringUIGroup | undefined,
];

type SearchspringSuggestionAPIData = {
  autocomplete: SearchResponseModel | undefined;
  suggestions: SuggestResponseModel | undefined;
  trending: any | undefined;
};

export const createSearchspringAutocompleteAdapter = (
  uiOrdering: UIOrdering,
): AutocompleteDataAdapter<SuggestionBase, SearchspringSuggestionAPIData> => {
  const adapter = {
    convert(
      data: SearchspringSuggestionAPIData,
    ): SuggestionUIGroup<SuggestionBase>[] {
      const result = uiOrdering
        .map((key) => {
          switch (key) {
            case 'trending':
              return convertTrendingSuggestions(data.trending)
            case 'alternatives':
              return convertAlternativeSuggestions(data.suggestions);
            case 'didYouMean':
              return convertDidYouMeanSuggestions(data.autocomplete);
            case 'products':
              return convertProductSuggestions(data.autocomplete);
            case 'suggestions':
              return convertSearchspringSuggestions(data.suggestions);
          }
        })
        .filter((k) => k) as SuggestionUIGroup<SuggestionBase>[];
      return result;
    },
  };

  return adapter;
};
