
import {
  AddToCartComponentInterface,
  LinkComponentInterface,
  debounce,
  type CarouselDataProps,
  type Recommendation,
  type RecommendationDataProps,
} from 'propel-shared-utility';
import { useRef } from "react";
import { InView } from "react-intersection-observer";
import Slider from "react-slick";
import { ProductCardRecommendations } from "../ProductCard/ProductCard";
import * as gtm from '../analytics/gtm';
import CarouselNext from "./carousel-next";
import CarouselPrev from "./carousel-prev";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './recommendations.css';

interface TileOptions {
  banner: boolean;
  burst: boolean;
  promo: boolean;
  specs: boolean;
  tagline: boolean;
  ratings: boolean;
  addToCart: boolean;
}

interface  CarouselOptions {
  showNav: boolean,
  navTheme: string,
  desktopSettings: {
    count: number,
    per: number,
  },
  tabletSettings: {
    count: number,
    per: number,
  },
  mobileSettings: {
    count: number,
    per: number,
  },
  builderBlock: {
    id: string
  },
  speed: number, 
  autoPlay: boolean,
  dtSwipe: boolean,
  initialSlide: number,
}

export type RecommendationsProps = {
  recommendations?: Recommendation[];
  setRecommendationDataProps: (
    recommendation: Recommendation,
    active: boolean,
  ) => RecommendationDataProps;
  setCarouselDataProps: () => CarouselDataProps;
  tileOptions: TileOptions,
  carouselOptions: CarouselOptions,
  AddToCartComponent: AddToCartComponentInterface
  LinkComponent: LinkComponentInterface
};

export const RecommendationsCarousel = (props: RecommendationsProps) => {
  const slider = useRef<Slider | null>(null);
  const {
    recommendations,
    setRecommendationDataProps,
    setCarouselDataProps,
    tileOptions,
    carouselOptions,
    AddToCartComponent,
    LinkComponent
  } = props;

  const next = () => {
    slider.current?.slickNext();
  };

  const previous = () => {
    slider.current?.slickPrev();
  };

  const settings = {
    dots: carouselOptions?.showNav,
    arrows: false,
    autoplay: carouselOptions.autoPlay,
    autoplaySpeed: carouselOptions.speed,
    swipe: carouselOptions.dtSwipe,
    swipeToSlide: carouselOptions.dtSwipe,
    touchMove: carouselOptions.dtSwipe,
    touchThreshold: 5,
    infinite: (carouselOptions.autoPlay || carouselOptions.dtSwipe),        
    speed: ( carouselOptions.speed ? carouselOptions.speed : undefined ),        
    cssEase: 'linear',         
    pauseOnHover: ( carouselOptions.autoPlay ? false : undefined ),            
    centerMode: ( carouselOptions.initialSlide > 1 ),        
    initialSlide: ( carouselOptions.initialSlide ? carouselOptions.initialSlide - 1 : 0 ),
    appendDots: (dots: boolean) => (
      <div className={`cql-carousel--navigation`}>
        <ul className='dot-container'>{dots}</ul>
        <button className={`
                    glider-prev 
                    cql-carousel--arrow 
                    cql-carousel--prev 
                    slick-arrow 
                    ${carouselOptions?.initialSlide && carouselOptions?.initialSlide - 1 > 0 ? '' : 'slick-disabled'}`}   
                    onClick={previous}>
          <CarouselPrev/>
        </button>
        <button className="glider-next cql-carousel--arrow cql-carousel--next slick-arrow" onClick={next}>
          <CarouselNext/>
        </button>
      </div>
    ),
    slidesToShow: carouselOptions.desktopSettings.count ? carouselOptions.desktopSettings.count : 4,
    slidesToScroll: carouselOptions.desktopSettings.per ? carouselOptions.desktopSettings.per : 4,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: carouselOptions.tabletSettings.count ? carouselOptions.tabletSettings.count : 2,
          slidesToScroll: carouselOptions.tabletSettings.per ? carouselOptions.tabletSettings.per : 2,
          swipe: true,
          swipeToSlide: true,
          touchMove: true,
          touchThreshold: 5,
          infinite: true, 
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: carouselOptions.mobileSettings.count ? carouselOptions.mobileSettings.count : 1,
          slidesToScroll: carouselOptions.mobileSettings.per ? carouselOptions.mobileSettings.per : 1,
        },
      },
    ],
    beforeChange: () => {    
      const loadingClass = 'dot-container--loading';   
      const theSlider = document.querySelector(`.${carouselOptions.builderBlock.id}`);  
      if (!theSlider) return false;
      const dotContainer = theSlider.querySelector('.dot-container') as HTMLElement;
      if (dotContainer) dotContainer.classList.add(loadingClass);
    },  
    afterChange: () => {
      const theSlider = document.querySelector(`.${carouselOptions.builderBlock.id}`);
      if (!theSlider) return false;
      const dotContainer = theSlider.querySelector('.dot-container');
      const prevArrow = theSlider.querySelector('.glider-prev');
      const nextArrow = theSlider.querySelector('.glider-next');
      if (!dotContainer || !prevArrow || !nextArrow) return false;
      const disabledClass = 'slick-disabled';
      const pageCount = dotContainer.childElementCount;
      const activeItem = dotContainer.querySelector('.slick-active');
      const activeItems = dotContainer.querySelectorAll('.slick-active');
      let activeIndex = Array.prototype.indexOf.call(dotContainer.children, activeItem) + 1;

      // Fallback for last slide of fraction sliders
      if (activeIndex === 0) {
        const activeSlide = theSlider.querySelector('.slick-track .slick-current') as HTMLElement;

        if (activeSlide && activeSlide.dataset && activeSlide.dataset.index) {
          let slideIndex = Number(activeSlide?.dataset?.index);

          const dotChildren = dotContainer.childNodes;

          if ((slideIndex + 1) > dotChildren.length) {
            slideIndex = dotChildren.length - 1;
          }

          const activeChild = dotChildren[slideIndex] as HTMLElement;
          if (activeChild) {
            activeChild.classList.add('slick-active');
          }
          activeIndex = slideIndex + 1;
        }
      }

      // Clean up for fallback
      if (activeItems.length > 1) {
        activeItems.forEach((node) => {
          const tempIndex = Array.prototype.indexOf.call(dotContainer.children, node) + 1;
          if (tempIndex !== activeIndex) {
            node.classList.remove('slick-active');
          }
        });
      }

      if (pageCount === 1) {
        prevArrow.classList.add(disabledClass);
        prevArrow?.setAttribute('disabled', '');
        nextArrow.classList.add(disabledClass);
        nextArrow?.setAttribute('disabled', '');
      } else if (activeIndex >= pageCount) {
        prevArrow.classList.remove(disabledClass);
        prevArrow?.removeAttribute('disabled');
        nextArrow.classList.add(disabledClass);
        nextArrow?.setAttribute('disabled', '');
      } else if (activeIndex === 1) {
        prevArrow.classList.add(disabledClass);
        prevArrow?.setAttribute('disabled', '');
        nextArrow.classList.remove(disabledClass);
        nextArrow?.removeAttribute('disabled');
      } else {
        prevArrow.classList.remove(disabledClass);
        prevArrow?.removeAttribute('disabled');
        nextArrow.classList.remove(disabledClass);
        nextArrow?.removeAttribute('disabled');
      }
      dotContainer.classList.remove('dot-container--loading');
    },
  };

  const pendingItemList = useRef<Recommendation[]>([]);
  const triggerViewListEvent = debounce((pendingItemList: React.MutableRefObject<Recommendation[]>) => {
    gtm.viewRecommendationItemList({ recommendations: pendingItemList.current });
    pendingItemList.current = [];
    }, 500);

  const handleViewChange = (pendingItemList: React.MutableRefObject<Recommendation[]>, triggerEvent: (pendingItemList: React.MutableRefObject<Recommendation[]>) => void, rec: Recommendation, inView: boolean, _entry: IntersectionObserverEntry) => {
      if (inView) {
        pendingItemList.current.push(rec)
        triggerEvent(pendingItemList);
    }
  };
  const onChange = handleViewChange.bind(null, pendingItemList, triggerViewListEvent)

  return recommendations && recommendations.length ? (
    <div {...setCarouselDataProps()}>
      {
      // @ts-ignore: TypeScript doesn't like the JSX tag type
      } <Slider
        ref={slider}
        {...settings}
        className={`cql-carousel recs-carousel ${tileOptions.addToCart ? 'carousel-atc' : ''} ${carouselOptions.navTheme ? 'cql-carousel--theme-' + carouselOptions.navTheme : ''} ${carouselOptions.showNav ? 'cql-carousel--nav-active' : ''
          }`}
      >
        {recommendations.map((rec, index) => {
          return (
            <InView
              key={index}
              as="div"
              className={`${tileOptions.addToCart ? 'carousel-atc-slide' : ''} builder-slide builder-slide-${index}`}
              triggerOnce={true}
              rootMargin='-100px 0px'
              onChange={onChange.bind(null, rec)}
            >
              <ProductCardRecommendations
                product={rec}
                setRecommendationDataProps={setRecommendationDataProps}
                tileOptions={tileOptions}
                AddToCartComponent={AddToCartComponent}
                LinkComponent={LinkComponent}
              />
            </InView>
          );
        })}
      </Slider>
    </div>
  ) : (
    <div></div>
  );
};

export const RecommendationsSkeleton = (
  <div>
      <div className="cql-carousel recs-carousel carousel-atc rounded-md animate-pulse h-[26rem] bg-neutral-400 dark:bg-neutral-700"></div>
  </div>
);

export default RecommendationsCarousel;
