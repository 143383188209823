import { ContentCardRecommendations } from '../ProductCard/ProductCard';

import {
  CarouselDataProps,
  LinkComponentInterface,
  type ContentRecommendation,
  type RecommendationDataProps,
} from 'propel-shared-utility';

import { useRef } from 'react';
import Slider from 'react-slick';
import CarouselNext from '../Recommendations/carousel-next';
import CarouselPrev from '../Recommendations/carousel-prev';
import './content-recs.css';

interface ContentTileOptions {
  type: boolean;
  date: boolean;
  description: boolean;
  topics: boolean;
}

interface  CarouselOptions {
  showNav: boolean,
  navTheme: string,
  desktopSettings: {
    count: number,
    per: number,
  },
  tabletSettings: {
    count: number,
    per: number,
  },
  mobileSettings: {
    count: number,
    per: number,
  },
  builderBlock: {
    id: string
  },
  speed: number, 
  autoPlay: boolean,
  dtSwipe: boolean,
  initialSlide: number,
}

export type ContentRecommendationsProps = {
  recommendations?: ContentRecommendation[];
  setRecommendationDataProps: (
    recommendation: ContentRecommendation,
    active: boolean
  ) => RecommendationDataProps;
  maxTiles: number,
  LinkComponent: LinkComponentInterface,
  tileOptions: ContentTileOptions,
  setCarouselDataProps: () => CarouselDataProps;
  carouselOptions: CarouselOptions,
};

export const ContentRecsCarosuel = (props: ContentRecommendationsProps) => {
  const slider = useRef<Slider | null>(null);

  const {recommendations, setRecommendationDataProps, maxTiles, LinkComponent, tileOptions, setCarouselDataProps, carouselOptions} =
    props;

  const next = () => {
    slider.current?.slickNext();
  };

  const previous = () => {
    slider.current?.slickPrev();
  };

  const settings = {
    dots: carouselOptions?.showNav,
    arrows: false,
    autoplay: carouselOptions.autoPlay,
    autoplaySpeed: carouselOptions.speed,
    swipe: carouselOptions.dtSwipe,
    swipeToSlide: carouselOptions.dtSwipe,
    touchMove: carouselOptions.dtSwipe,
    touchThreshold: 5,
    infinite: (carouselOptions.autoPlay || carouselOptions.dtSwipe),        
    speed: ( carouselOptions.speed ? carouselOptions.speed : undefined ),        
    cssEase: 'linear',         
    pauseOnHover: ( carouselOptions.autoPlay ? false : undefined ),            
    centerMode: ( carouselOptions.initialSlide > 1 ),        
    initialSlide: ( carouselOptions.initialSlide ? carouselOptions.initialSlide - 1 : 0 ),
    appendDots: (dots: boolean) => (
      <div className={`cql-carousel--navigation`}>
        <ul className='dot-container'>{dots}</ul>
        <button className={`
                    glider-prev 
                    cql-carousel--arrow 
                    cql-carousel--prev 
                    slick-arrow 
                    ${carouselOptions?.initialSlide && carouselOptions?.initialSlide - 1 > 0 ? '' : 'slick-disabled'}`}   
                    onClick={previous}>
          <CarouselPrev/>
        </button>
        <button className="glider-next cql-carousel--arrow cql-carousel--next slick-arrow" onClick={next}>
          <CarouselNext/>
        </button>
      </div>
    ),
    slidesToShow: carouselOptions?.desktopSettings?.count ? carouselOptions?.desktopSettings.count : 4,
    slidesToScroll: carouselOptions?.desktopSettings?.per ? carouselOptions?.desktopSettings.per : 4,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: carouselOptions.tabletSettings.count ? carouselOptions.tabletSettings.count : 2,
          slidesToScroll: carouselOptions.tabletSettings.per ? carouselOptions.tabletSettings.per : 2,
          swipe: true,
          swipeToSlide: true,
          touchMove: true,
          touchThreshold: 5,
          infinite: true, 
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: carouselOptions.mobileSettings.count ? carouselOptions.mobileSettings.count : 1,
          slidesToScroll: carouselOptions.mobileSettings.per ? carouselOptions.mobileSettings.per : 1,
        },
      },
    ],
    beforeChange: () => {    
      const loadingClass = 'dot-container--loading';   
      const theSlider = document.querySelector(`.${carouselOptions.builderBlock.id}`);  
      if (!theSlider) return false;
      const dotContainer = theSlider.querySelector('.dot-container') as HTMLElement;
      if (dotContainer) dotContainer.classList.add(loadingClass);
    },  
    afterChange: () => {
      const theSlider = document.querySelector(`.${carouselOptions.builderBlock.id}`);
      if (!theSlider) return false;
      const dotContainer = theSlider.querySelector('.dot-container');
      const prevArrow = theSlider.querySelector('.glider-prev');
      const nextArrow = theSlider.querySelector('.glider-next');
      if (!dotContainer || !prevArrow || !nextArrow) return false;
      const disabledClass = 'slick-disabled';
      const pageCount = dotContainer.childElementCount;
      const activeItem = dotContainer.querySelector('.slick-active');
      const activeItems = dotContainer.querySelectorAll('.slick-active');
      let activeIndex = Array.prototype.indexOf.call(dotContainer.children, activeItem) + 1;

      // Fallback for last slide of fraction sliders
      if (activeIndex === 0) {
        const activeSlide = theSlider.querySelector('.slick-track .slick-current') as HTMLElement;

        if (activeSlide && activeSlide.dataset && activeSlide.dataset.index) {
          let slideIndex = Number(activeSlide?.dataset?.index);

          const dotChildren = dotContainer.childNodes;

          if ((slideIndex + 1) > dotChildren.length) {
            slideIndex = dotChildren.length - 1;
          }

          const activeChild = dotChildren[slideIndex] as HTMLElement;
          if (activeChild) {
            activeChild.classList.add('slick-active');
          }
          activeIndex = slideIndex + 1;
        }
      }

      // Clean up for fallback
      if (activeItems.length > 1) {
        activeItems.forEach((node) => {
          const tempIndex = Array.prototype.indexOf.call(dotContainer.children, node) + 1;
          if (tempIndex !== activeIndex) {
            node.classList.remove('slick-active');
          }
        });
      }

      if (pageCount === 1) {
        prevArrow.classList.add(disabledClass);
        prevArrow?.setAttribute('disabled', '');
        nextArrow.classList.add(disabledClass);
        nextArrow?.setAttribute('disabled', '');
      } else if (activeIndex >= pageCount) {
        prevArrow.classList.remove(disabledClass);
        prevArrow?.removeAttribute('disabled');
        nextArrow.classList.add(disabledClass);
        nextArrow?.setAttribute('disabled', '');
      } else if (activeIndex === 1) {
        prevArrow.classList.add(disabledClass);
        prevArrow?.setAttribute('disabled', '');
        nextArrow.classList.remove(disabledClass);
        nextArrow?.removeAttribute('disabled');
      } else {
        prevArrow.classList.remove(disabledClass);
        prevArrow?.removeAttribute('disabled');
        nextArrow.classList.remove(disabledClass);
        nextArrow?.removeAttribute('disabled');
      }
      dotContainer.classList.remove('dot-container--loading');
    },
  };

  if (!recommendations || !recommendations.length) {
    return null;
  }

  if (maxTiles < 4) {
    return (
      <div className="content__recs">
        {recommendations.slice(0, maxTiles).map((rec, index) => (
          <div
            className={`content__recs-tile content__recs-tile--${maxTiles}`}
            key={index}
          >
            <ContentCardRecommendations
              product={rec}
              setRecommendationDataProps={setRecommendationDataProps}
              LinkComponent={LinkComponent}
              tileOptions={tileOptions}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div {...setCarouselDataProps()}>
      {
      // @ts-ignore: TypeScript doesn't like the JSX tag type
      } <Slider
        ref={slider}
        {...settings}
        className={`cql-carousel recs-carousel ${
          carouselOptions.navTheme
            ? "cql-carousel--theme-" + carouselOptions.navTheme
            : ""
        } ${carouselOptions.showNav ? "cql-carousel--nav-active" : ""}`}
      >
        {recommendations.map((rec, index) => (
          <div
            key={index}
            className={`content__recs-tile builder-slide builder-slide-${index}`}
          >
            <ContentCardRecommendations
              product={rec}
              setRecommendationDataProps={setRecommendationDataProps}
              LinkComponent={LinkComponent}
              tileOptions={tileOptions}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};


