import { VercelProduct as Product } from 'lib/bigcommerce/types';
import './inventory.css';

export function ProductInventory({ product }: { product: Product }) {
  const inStock = product.inventory.isInStock;
  const available = product.inventory.aggregated.availableToSell;
  const lowStock = product.inventory.aggregated.warningLevel;

  return (
    <>
      {
        inStock && available > lowStock ? <p className={`product-stock product-in-stock`}>In Stock</p>
          : inStock && available <= lowStock ? <p className={`product-stock product-low-stock`}>Only {available} Left</p>
          : null
      }
    </>
  );
}
