'use client';

import { BuilderBlocks } from '@builder.io/react';
import { Builder } from '@builder.io/sdk';
import { useState } from 'react';
import ArrowRightIcon from '../icons/arrow-right';
import './mobile-nav.css';

export function MobileNav(props: { builderBlock: any, nav: Array<object>, homeContent: any, attributes: {style: any, className: string, 'builder-id': string, id: string} }) {
  const [activeTab, setActiveTab] = useState(-1);

  const clickHandler = (i: number) => {
        const navTabs = document.querySelector<HTMLDivElement>('[data-builder-component="header"] .builder-tabs .builder-tabs-nav');
        if(i === activeTab){    
            setActiveTab(-1);
            if(navTabs) navTabs.style.cssText = "opacity: 1; visibility: visible; height: auto; ";
        } else {
            setActiveTab(i);
            if(navTabs) navTabs.style.cssText = "opacity: 0; visibility: hidden; height: 0; ";
        }
    }

  const GenContent = ({
        id,
        index,
        name,
        path,
        content,
        color,
        click
    }: {
        id: string;
        index: number;
        name: string;
        path: string;
        content: any;
        color: string;
        click: any;
    } ) => {
    if((!content || content.length === 0)  && !Builder.isEditing) return;

    return (
        <div             
            className={
                `mobile-nav-content-container ${activeTab === index ? "mobile-nav-content-container-active" : ""}`
            }
            style={{
                backgroundColor: `${color || ''}`
            }}
        >               
            <div className="mobile-nav-content--header">
                <div className="mobile-nav-content--header-element">
                    <button type='button'
                        onClick={() => click(index)}
                        className="mobile-nav-content--header-back"
                    >
                        Back
                    </button>
                </div>
                <div className="mobile-nav-content--header-element mobile-nav-content--title">
                    <h2>
                        {name}
                    </h2>
                </div>
                <div className="mobile-nav-content--header-element">
                    <span>&nbsp;</span>
                </div>
            </div>
            <BuilderBlocks
                parentElementId={id}
                dataPath={path}
                blocks={content}
            />
        </div>
    )
  }
  return (
    <div style={props.attributes.style} className={props.attributes.className} builder-id={props.attributes['builder-id']} id={props.attributes.id}>
        <nav 
            className={
                `mobile-nav-component ${activeTab === -1 ? "mobile-nav-component-default" : ""}`                    
            }
        >
            <div className="mobile-nav-component-home">
                <ul>
                    {props.nav?.map((item: any, index: number) => (
                        <li
                            key={index}  
                        >
                            <button type="button"                     
                                onClick={() => clickHandler(index)}  
                                style={{color:(item?.textColorOverride ? item?.textColorOverride : ''), stroke: (item?.textColorOverride ? item?.textColorOverride : '')}}
                            >
                                {item.label}
                                <ArrowRightIcon />
                            </button>
                        </li>
                    ))}
                </ul>
                <BuilderBlocks
                parentElementId={props.builderBlock.id}
                dataPath={`component.options.homeContent`}
                blocks={props?.homeContent}
                />
            </div>
            
            <section 
                className={
                    `mobile-nav-content`
                }
            >   

                {props.nav?.map((item: any, index: number) => (
                    <GenContent key={index} id={props.builderBlock.id} index={index} name={item?.label} path={`component.options.nav.${index}.content`} content={item?.content} color={item?.contentBackgroundColor}  click={clickHandler}/>
                ))}
            </section>
        </nav>
    </div>
  );
}
