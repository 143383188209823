'use client';

import useUser from 'components/hooks/use-user';
import { Customer } from 'lib/bigcommerce/types/customer';
import Link from 'next/link';
import UserOutlineIcon from '../icons/user-outline';
import UserSolidIcon from '../icons/user-solid';
import './header-account.css';
import './header-icons.css';

function getLabelString(loading: boolean, loggedIn: boolean, user: Customer | undefined) {
    if (loading) {
        return "\u00A0"; // &nbsp
    }
    if (user && loggedIn) {
        if (user.firstName) {
            return 'Hi, ' + user.firstName;
        } else {
            return 'Hi, 4Patriots Customer';
        }
    }
    return 'Log In / Sign Up';
}

export function HeaderAccount( props: { showLabel: boolean, attributes: {style: any, className: string, 'builder-id': string, id: string} } ) {  
    const { user, loading, loggedIn } = useUser();

    const labelString = getLabelString(loading, loggedIn, user)
    return (
        <div style={props.attributes.style} className={props.attributes.className} builder-id={props.attributes['builder-id']} id={props.attributes.id}>
            <section className={`account-component ${props.showLabel ? '' : 'header-icon-label-hide'}`} >
                <div className="header-icon account-icon" >
                    <Link href={loggedIn ? `/account` : `/login`}>
                        <GenIcon isLoggedIn={loggedIn} labelString={labelString} />
                    </Link>
                </div>
            </section>
        </div>
    );
}

const GenIcon = ({ isLoggedIn, labelString }: { isLoggedIn: boolean; labelString: string }) => {
    if (isLoggedIn) {
        return (
            <div className="header-icon">
                <UserSolidIcon />
                <span className="header-icon-label header-icon-label--account">
                    {labelString}
                </span>
            </div>
        );
    } else {
        return (
            <div className="header-icon">
                <UserOutlineIcon />
                <span className="header-icon-label header-icon-label--account">
                    {labelString}
                </span>
            </div>
        );
    }
}
