import { VercelProduct as Product } from 'lib/bigcommerce/types';
import './banner.css';

export function ProductBanner({ product }: { product: Product }) {
  return (
    <>
      {product.productMetafields.map((field: any, i) => (
        field.key === 'banner' 
          ? <span className={`product-banner`} dangerouslySetInnerHTML={{ __html: field.value }} key={i} />
          : null
      ))}
    </>
  );
}
