'use client';

import * as blotout from 'components/analytics/blotout';
import * as gtm from 'components/analytics/gtm';
import { addItem } from 'components/cart/actions';
import { SubmitButton } from 'components/global/add-to-cart-cta';
import useCart from 'components/hooks/use-cart';
import { useStorefrontDispatch } from 'components/providers/storefront-provider';
import { FormEvent, useState, useTransition } from 'react';

export function AddToCartCTA({
  availableForSale,
  isInStock,
  selectedQuantity,
  selectedProductId,
  selectedVariantId,
  buttonColor,
  buttonText,
  buttonFontSize,
  notifyMe,
  itemListId,
  itemListName,
  productHandle
}: {
  availableForSale: boolean;
  isInStock: boolean;
  selectedQuantity: number;
  selectedProductId: string;
  selectedVariantId: string;
  buttonColor: string;
  buttonText: string;
  buttonFontSize?: number;
  notifyMe: boolean;
  itemListId?: string;
  itemListName?: string;
  productHandle: string;
}) {
  const { mutate } = useCart();
  const [message, setMessage] = useState<undefined | string>(undefined);
  const [error, setError] = useState<undefined | string>(undefined);
  const [isPending, startTransition] = useTransition();
  const dispatch = useStorefrontDispatch();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    startTransition(async () => {
      setMessage(undefined);
      setError(undefined);
      const result = await addItem(null, { selectedProductId, selectedVariantId, selectedQuantity });
      if (result.isSuccess) {
        setMessage('Success')
        if (result.value) {
          const item = result.value.lines.find(l => l.merchandise.id === selectedVariantId)
          if (item) {
            gtm.addToCart({
              cart: result.value,
              lineItem: item,
              quantity: selectedQuantity,
              itemListId: itemListId ?? 'recommended_products',
              itemListName: itemListName ?? 'Recommended Products'
            });
            blotout.addToCart({
              cart: result.value,
              lineItem: item,
              quantity: selectedQuantity
            });
          }
        }
        mutate(result);
        dispatch({ type: 'OPEN_CART_CONFIRM', currentVariant: selectedVariantId });
      }
      else {
        console.error(result.error)
        setMessage(result.error)
        setError(result.error)
      }
    });
  };

  return (
    <form onSubmit={onSubmit} className='tile__form relative'>
        { error && (
            <span className='tile__error pb-1 pl-4 pr-4'>{error}</span>
        )}
      <SubmitButton
        availableForSale={availableForSale}
        isInStock={isInStock}
        selectedVariantId={selectedVariantId}
        buttonColor={buttonColor}
        buttonText={buttonText}
        buttonFontSize={buttonFontSize}
        notifyMe={notifyMe}
        pending={isPending}
        productHandle={productHandle}
      />
      <p aria-live="polite" className="sr-only" role="status">
        {message}
      </p>
    </form>
  );
}
