
import DOMPurify from 'dompurify';
import { LinkComponentInterface } from 'propel-shared-utility';
import React from 'react';
import { SearchBlogResult } from '../../interfaces';
import './blog-card.css';

export type BlogCardProps = {
    article: SearchBlogResult;
    LinkComponent: LinkComponentInterface;
}

export const BlogCard: React.FC<BlogCardProps> = (props:BlogCardProps) => {
const { article, LinkComponent } = props;

const date = new Date(article.ss_date_published);
const formattedDate = date.toLocaleDateString('en-US', {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit'
});

return (
    <>
    <div className='tile__item-grid border lg:m-2'>
        <LinkComponent href={article.url}>
            <div className="tile__image-wrapper-grid relative">
                <img
                src={article.imageUrl}
                alt={article.name}
                className="w-full aspect-square object-cover"
                />
            </div>
        </LinkComponent>

        <div className="p-4 tile__content-wrapper h-full flex flex-col">
            <div className='tile__content-top'>
                <span className='tile__type' dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(article.type as string),
                    }}></span>
                <span className='tile__date'>{formattedDate}</span>
            </div>
            {article.name && (
                <h2 className="tile__title-content mt-2">{article.name}</h2>
            )}
            {article.content && (
                <div className='tile__description'>{article.content}</div>
            )}
            <LinkComponent className='tile__readmore' href={article.url}>Read More</LinkComponent>
            {article.topics && (
                <div className='tile__topics'>
                    {article.topics.map((topic: string) => (
                        <span key={topic} className='tile__topic' dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(topic as string),
                          }}></span>
                    ))}
                </div>
            )}
        </div>
    </div>
    </>
);
}
  