'use client';

import React, { useContext, useEffect, useState } from 'react';

const initialState: ProductOptionsContextState =
{
  optionSearchParams: new URLSearchParams(),
  setOptionSearchParams: () => {
    throw Error('You forgot to wrap this in a Provider object')
  },
}

type ProductOptionsContextState = {
  optionSearchParams: URLSearchParams;
  setOptionSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>;
}

const ProductOptionsContext = React.createContext<ProductOptionsContextState>(initialState)

export interface ProductOptionsProviderProps {
  children: React.ReactNode
}

export function ProductOptionsProvider({
  children,
}: ProductOptionsProviderProps) {
  const [optionSearchParams, setOptionSearchParams] = useState(new URLSearchParams());
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setOptionSearchParams(urlParams);
  },[]);

  return (
    <ProductOptionsContext.Provider value={{optionSearchParams, setOptionSearchParams}}>
        {children}
    </ProductOptionsContext.Provider>
  );
}

export function useProductOptions() {
  return useContext(ProductOptionsContext);
}