'use client';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { GridTileImage } from 'components/grid/tile';
import { useProductOptions } from 'components/providers/product-options-provider';
import { VercelProduct as Product } from 'lib/bigcommerce/types';
import Image from 'next/image';
import { TouchEvent, useState } from 'react';
import YouTubeIcon from '../../components/icons/youtube';
import './media-carousel.css';
import { Video } from './video';

export function Gallery({ 
  images,
  product
}: { 
  images: { src: string; altText: string }[];
  product: Product;
}) {
  // Check videos
  let media = images;
  product.productMetafields.map((field: any) => {
    if (field.key === 'videos') {
      JSON.parse(field.value.replace(/\\/g, '')).map((item: any) => {
        let videoId;
        item.video.includes('https://youtu.be/')
          ? videoId = item.video.replace('https://youtu.be/','')
          : item.video.includes('https://www.youtube.com/watch?v=')
            ? videoId = item.video.replace('https://www.youtube.com/watch?v=','')
            : null
        const image = {
          src: `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`,
          altText: 'Video thumbnail'
        };
        media.push(image);
      });
    }
  });
  // Filter duplicates from dual-rendered array push in React 18
  const mediaSources = media.map(({ src }) => src);
  media = media.filter(({ src }, index) =>
  !mediaSources.includes(src, index + 1));
  
  const {optionSearchParams, setOptionSearchParams} = useProductOptions();
  const imageSearchParam = optionSearchParams.get('image');
  const imageIndex = imageSearchParam ? parseInt(imageSearchParam) : 0;

  const [startX, setStartX] = useState<number | undefined>();
  const [endX, setEndX] = useState<number | undefined>();

  const nextSearchParams = new URLSearchParams(optionSearchParams.toString());
  const nextImageIndex = imageIndex + 1 < media.length ? imageIndex + 1 : 0;
  nextSearchParams.set('image', nextImageIndex.toString());
  const nextSearchParamsImage = nextSearchParams.get('image');
  let currentNextIndex: number | undefined;
  nextSearchParamsImage ? currentNextIndex = Number(nextSearchParamsImage.toString().replace('image=','')) : null;

  const previousSearchParams = new URLSearchParams(optionSearchParams.toString());
  const previousImageIndex = imageIndex === 0 ? media.length - 1 : imageIndex - 1;
  previousSearchParams.set('image', previousImageIndex.toString());
  const previousSearchParamsImage = previousSearchParams.get('image');
  let currentPreviousIndex: number | undefined;
  previousSearchParamsImage ? currentPreviousIndex = Number(previousSearchParamsImage.toString().replace('image=','')) : null;

  const buttonClassName = 'h-10 w-10 transition-all ease-in-out flex items-center justify-center rounded-full border border-[#1E3665]';

  // Check for burst
  let burstURL;
  product.productMetafields.map((field: any) => field.key === 'burst' ? burstURL = field.value : null);

  
  const handleTouchStart = (touch: Touch) => { 
    setStartX(touch.clientX);
  }
  const handleTouchMove = (touch: Touch) => { 
    setEndX(touch.clientX);

  }
  const handleTouchEnd = () => { 
    if(startX && endX) {
        let direction = 'nowhere';
        if(startX > endX) {
            direction = 'left';
        } else if (startX < endX) {
            direction = 'right';
        }

        switch(direction) {
            case 'left':
                if (media.length - 1 > imageIndex) setOptionSearchParams(nextSearchParams);
                break;
            case 'right':
                if (imageIndex > 0) setOptionSearchParams(previousSearchParams);
                break;
        }
    }

    // Reset
    setStartX(undefined);
    setEndX(undefined);

  }
  const handleTouchEvent = (event: TouchEvent<HTMLDivElement>) => { 
    event.persist();

    // Don't bother if there aren't multiple
    if(media.length < 2 ) return false;

    const e =  event.nativeEvent;
    const touch = e?.touches?.[0];

    switch(e.type) {
        case 'touchstart':
            if (touch) handleTouchStart(touch);
            break;
        case 'touchmove':
            if (touch) handleTouchMove(touch);
            break;
        case 'touchend':
            handleTouchEnd();
            break;
    }
  };

  return (
    <div className="media-carousel">
      <div className="flex lg:flex-row justify-center lg:align-center">
        {media.length > 1 ? (
          <ul className="hidden lg:flex flex-col items-center justify-center lg:justify-start gap-2 overflow-auto w-[92px] basis-[92px] mr-[1.25rem]">
            {media.map((image, index) => {
              const isActive = index === imageIndex;
              const imageSearchParams = new URLSearchParams(optionSearchParams.toString());
              imageSearchParams.set('image', index.toString());
              
              return (
                <li key={image.src} className="h-[92px] w-[92px]">
                  {
                    image.altText === 'Video thumbnail'
                      ? <button
                          type="button"  
                          aria-label="Enlarge product video"
                          onClick={() => {
                            setOptionSearchParams(imageSearchParams)
                          }}
                          className="h-full w-full relative"
                        >
                          <GridTileImage
                            alt={image.altText}
                            src={image.src}
                            width={92}
                            height={92}
                            active={isActive}
                          />
                          <div className="flex items-center justify-center w-full h-full absolute top-0 z-[1]">
                            <YouTubeIcon />
                          </div>
                        </button>
                      : <button
                          type="button" 
                          aria-label="Enlarge product image"
                          onClick={() => {
                            setOptionSearchParams(imageSearchParams)
                          }}
                          className="h-full w-full"
                        >
                          <GridTileImage
                            alt={image.altText}
                            src={image.src}
                            width={92}
                            height={92}
                            active={isActive}
                          />
                        </button>
                  }
                </li>
              );
            })}
          </ul>
        ) : null}
      
        <div className={`relative flex flex-col justify-center lg:justify-start w-full ${media.length > 1 ? `lg:w-[calc(100%-112px)] max-w-[650px]` : `lg:w-full max-w-[762px] max-h-[762px]`} h-full`}>
          <div className={`w-full h-full aspect-square ${media[imageIndex]?.altText === 'Video thumbnail' ? `flex flex-col justify-center` : ``} border border-[#E4E4E4] mb-[0.625rem] overflow-hidden`} onTouchStart={handleTouchEvent} onTouchMove={handleTouchEvent} onTouchEnd={handleTouchEvent}>
            {media[imageIndex] && media[imageIndex]?.altText === 'Video thumbnail'
              ? 
                <div className='h-auto aspect-video'>
                <Video                   
                  videoHost={media[imageIndex]?.src.includes('ytimg') ? `YouTube` : `Other`}
                  videoId={`${media[imageIndex]?.src.replace('https://i.ytimg.com/vi/','').replace('/hqdefault.jpg','')}`}
                />
                </div>
              : <>
                  <Image
                    className="!static h-full !w-auto object-contain"
                    fill
                    sizes="(min-width: 992px) 66vw, 100vw"
                    alt={media[imageIndex]?.altText as string}
                    src={media[imageIndex]?.src as string}
                    priority={true}
                  />
                  {
                    burstURL 
                    ? <Image
                        className="product-burst-image"
                        alt={`Product burst graphic`}
                        src={`${burstURL}`}
                        width="175"
                        height="175"
                        role="img"
                      />
                    : null
                  }
                </>
            }
          </div>
          {media.length > 1 ? (
            <div className="flex justify-center w-full">
              <div className="mx-auto flex items-center gap-[0.3125rem]">
                {
                  media.length - 1 === currentPreviousIndex
                    ? <button
                        type="button" 
                        aria-label="Previous product image"
                        aria-disabled="true"
                        onClick={() => {
                          setOptionSearchParams(previousSearchParams)
                        }}
                        className={`${buttonClassName} product-carousel-button-disabled`}
                        tab-index="-1"
                      >
                        <ChevronLeftIcon className="w-[0.875rem] h-[1.125rem]" />
                      </button>
                    : <button
                        type='button'
                        aria-label="Previous product image"
                        onClick={() => {
                          setOptionSearchParams(previousSearchParams)
                        }}                        className={`${buttonClassName}`}
                        tab-index="0"
                      >
                        <ChevronLeftIcon className="w-[0.875rem] h-[1.125rem]" />
                      </button>
                }
                {
                  currentNextIndex === 0
                    ? <button
                        type="button" 
                        aria-label="Next product image"
                        aria-disabled="true"
                        onClick={() => {
                          setOptionSearchParams(nextSearchParams)
                        }}
                        className={`${buttonClassName} product-carousel-button-disabled`}
                      >
                        <ChevronRightIcon className="w-[0.875rem] h-[1.125rem]" />
                      </button>
                    : <button
                        type="button" 
                        aria-label="Next product image"
                        onClick={() => {
                          setOptionSearchParams(nextSearchParams)
                        }}
                        className={`${buttonClassName}`}
                      >
                        <ChevronRightIcon className="w-[0.875rem] h-[1.125rem]" />
                      </button>
                }
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
