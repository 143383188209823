import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { ProofDetails } from '../../app/api/proof/types';

function getSocialProofMessage(socialProof: ProofDetails | null): string {
  if (!socialProof) return '';
  const { firstName, lastName, city, state, productName } = socialProof;
  const name = `${firstName} ${lastName.charAt(0)}`;
  return `${name} from ${city}, ${state} just purchased ${productName}`;
}

function getTimeAgo(socialProof: ProofDetails | null): string {
  if (!socialProof) return '';

  const { timestamp } = socialProof;
  const now = new Date();
  const orderedAt = new Date(timestamp * 1000);
  const seconds = Math.floor((Number(now) - Number(orderedAt)) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 }
  ];

  for (const interval of intervals) {
    const count = Math.floor(seconds / interval.seconds);
    if (count >= 1) {
      return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
    }
  }

  return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
}

export function SocialProof(props: { offerId: number }) {
  const [show, setShow] = useState<boolean>(false);
  const [proofs, setProofs] = useState<ProofDetails[]>([]);
  const [currentProof, setCurrentProof] = useState<ProofDetails | null>(null);
  const [timeAgoMessage, setTimeAgoMessage] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [userDismissed, setUserDismissed] = useState<boolean>(false);
  const [proofBottom, setProofBottom] = useState<string>('0px');

  const handleNextProof = (allProofs: ProofDetails[], nextIndex: number) => {
    if (!allProofs.length) {
      return;
    }

    setCurrentIndex(nextIndex);
    const proof = allProofs[nextIndex] ?? null;
    setTimeAgoMessage(getTimeAgo(proof));
    setCurrentProof(proof);
    setShow(true);
  };

  const handleDismiss = () => {
    setShow(false);
    setUserDismissed(true);
  };

  useEffect(() => {
    if (!props.offerId) return;

    const fetchSocialProofs = async (): Promise<void> => {
      const proofResponse = await fetch(`/api/proof/${props.offerId}`);
      const proofs = await proofResponse.json();
      setProofs(proofs);
      handleNextProof(proofs, 0);
    };
    fetchSocialProofs().catch(() => setProofs([]));
  }, [props.offerId]);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setShow(false), 8000);
      return () => clearTimeout(timer);
    } else if (!userDismissed && proofs.length > 0) {
      const nextIndex = (currentIndex + 1) % proofs.length;
      const timer = setTimeout(() => handleNextProof(proofs, nextIndex), 4000);
      return () => clearTimeout(timer);
    }
  }, [show, userDismissed, currentIndex, proofs]);

  useEffect(() => {
    // Observes if the Add To Cart sticky footer is initially visible and when its visibility changes
    // Adjusts the social proof notification position accordingly
    const stickyBar = document.querySelector('.product-sticky-bar') as HTMLElement;

    if (!stickyBar) {
      return;
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const { visibility } = (mutation.target as HTMLElement).style;
          const height = stickyBar.offsetHeight;
          setProofBottom(visibility === 'visible' ? `${height}px` : '0px');
        }
      });
    });

    observer.observe(stickyBar, { attributes: true, attributeFilter: ['style'] });

    const { visibility } = window.getComputedStyle(stickyBar);
    const height = stickyBar.offsetHeight;
    const initialVisibility = visibility !== 'hidden';
    setProofBottom(initialVisibility ? `${height}px` : '0px');

    return () => observer.disconnect();
  }, []);

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed left-0 z-50 p-4"
      style={{ bottom: proofBottom, transition: 'all 200ms ease-in-out' }}
    >
      <div className="relative flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          enter="transform ease-out duration-500 transition"
          enterFrom="translate-y-4 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transform ease-in duration-500 transition"
          leaveFrom="translate-y-0 opacity-100"
          leaveTo="translate-y-4 opacity-0"
        >
          <div className="pointer-events-auto relative w-full max-w-md overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="relative flex-shrink-0">
                  <Image
                    className="giftcard-content-image"
                    width={80}
                    height={80}
                    alt={'Social Proof Image'}
                    src={currentProof?.thumbnail ?? ''}
                  />
                </div>
                <div className="ml-3 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    {getSocialProofMessage(currentProof)}
                  </p>
                  <div className="mt-1 flex items-center text-sm text-gray-500">
                    {timeAgoMessage}
                    <CheckCircleIcon className="ml-2 h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleDismiss}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
