
import { Builder } from '@builder.io/react';
import { Fragment, useEffect, useState } from 'react';
import './back-to-top.css';

const scrollThreshold = 300;
const label = 'Back to Top';

export function BackToTop(props: {
  buttonPosition: string,
  attributes: {style: any, className: string, 'builder-id': string, id: string}
}) {
  const [show, setShow] = useState(false);

  // Get user-selected position
  const position = props.buttonPosition.toLowerCase();

  // Get height of announcement bar banner
  let bannerHeight;
  if (typeof document !== 'undefined') {
    bannerHeight = document.querySelector('.announcement-bar-content')?.getBoundingClientRect().height;
  } else {
    bannerHeight = 0;
  }

  useEffect(() => {
    // Show/hide client-side for desktop screens
    if (typeof window !== 'undefined') {
      if (!Builder.isEditing) {
        if (window.innerWidth >= 992) {
          window.addEventListener('scroll', () => {
            if (window.innerWidth < 992 || window.scrollY > scrollThreshold) {
              setShow(true);
            } else {
              setShow(false);
            }
          });
        } else {
          setShow(true);
        }
      } else {
        setShow(true);
      }
    }
  },[]);

  // Handle click event to scroll user back to the top
  const handleClick = (bannerHeight: any) => {
    window.scrollTo({
      top: bannerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Fragment>
      {
        (show) ? (
            <div style={props.attributes.style} className={props.attributes.className} builder-id={props.attributes['builder-id']} id={props.attributes.id}>
                <button 
                    className={`back-to-top back-to-top-position-${position}`}
                    aria-label={label}
                    onClick={handleClick}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23" fill="none">
                    <path d="M1.5 13L12.5 2L23.5 13" stroke="#1E3665" strokeWidth="1.5"/>
                    <line x1="12.5" y1="3" x2="12.5" y2="23" stroke="#1E3665" strokeWidth="1.5"/>
                    </svg>
                    <span>Top</span>
                </button>
            </div>
        ) : (<Fragment/>)
      }
    </Fragment>
  );
}
