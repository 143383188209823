import DOMPurify from 'dompurify';
import {
  AddToCartComponentInterface,
  ContentRecommendation,
  LinkComponentInterface,
  type Recommendation,
  type RecommendationDataProps,
} from 'propel-shared-utility';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { SearchProductResult } from '../../interfaces';
import * as gtm from '../analytics/gtm';
import './ProductCard.css';
import { EmptyStar, Star } from './StarIcon';

import { useIntelliSuggestTrackClick } from '../analytics/intellisuggest';

// Product.ts
interface TileOptions {
  banner: boolean;
  burst: boolean;
  promo: boolean;
  specs: boolean;
  tagline: boolean;
  ratings: boolean;
  addToCart: boolean;
}

interface RecsTileOptions {
  banner: boolean;
  burst: boolean;
  promo: boolean;
  specs: boolean;
  tagline: boolean;
  ratings: boolean;
  addToCart: boolean;
}

interface ContentTileOptions {
  type: boolean;
  date: boolean;
  description: boolean;
  topics: boolean;
}

export type ProductCardProps = {
  product: SearchProductResult;
  tileOptions: TileOptions;
  AddToCartComponent: AddToCartComponentInterface;
  LinkComponent: LinkComponentInterface;
  onViewChange: (inView: boolean, entry: IntersectionObserverEntry) => void;
  productCategoryTitle?: string;
}

export type ProductCardRecommendationProps = {
  product: Recommendation;
  setRecommendationDataProps: (
    recommendation: Recommendation,
    active: boolean,
  ) => RecommendationDataProps;
  tileOptions: RecsTileOptions;
  AddToCartComponent?: AddToCartComponentInterface;
  LinkComponent: LinkComponentInterface;
};

export type ContentCardRecommendationProps = {
  product: ContentRecommendation;
  setRecommendationDataProps: (
    recommendation: ContentRecommendation,
    active: boolean,
  ) => RecommendationDataProps;
  LinkComponent: LinkComponentInterface;
  tileOptions: ContentTileOptions;
};


export const ProductCard: React.FC<ProductCardProps> = ({ product, tileOptions, AddToCartComponent, LinkComponent, onViewChange, productCategoryTitle }) => {
  const productId = product?.uid;
  const variantId = product?.base_variant_id;
  const inStock = product?.ss_in_stock;
  const intelliData = product?.intellisuggestData;
  const intelliSignature = product?.intellisuggestSignature;

  const { intellisuggestTrackClick } = useIntelliSuggestTrackClick(process.env.NEXT_PUBLIC_SS_INDEX ?? '');

  const handleMouseDown = (event: any) => {
    event.preventDefault();
    intellisuggestTrackClick(event.currentTarget, intelliData ?? '', intelliSignature ?? '')
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 990px)'
  })

  return (
    <>
      <InView
        as="div"
        className='tile__item-grid border lg:m-2 rounded-md'
        triggerOnce={true}
        rootMargin='-100px 0px'
        onChange={onViewChange}
      >
        <LinkComponent href={product.custom_url} onMouseDown={handleMouseDown} onClick={() => gtm.selectSearchItem({ result: product, itemListId: productCategoryTitle?.toLowerCase()?.replaceAll(' ', '_'), itemListName: productCategoryTitle })}>
          <div className="tile__image-wrapper-grid relative">
            {!isMobile && inStock === "0" && (
              <span className='tile__oos'>Out Of Stock</span>
            )}
            {tileOptions.burst && product?.mfield_product_burst && (
              <img src={product?.mfield_product_burst} className="tile__image-burst absolute right-2 top-2" />
            )}

            {/* Using dangerouslySetInnerHTML here because there are & characters in some of the banners encoded as @amp; */}
            {tileOptions.banner && product?.mfield_product_banner && (
              <div className="tile__banner" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product?.mfield_product_banner)}}></div>
            )}

            <img
              src={product.imageUrl}
              alt={product.name}
              className="w-full aspect-square object-cover p-1"
            />
          </div>

          <div className="p-4 pb-0 tile__content-wrapper">
              <Rating tileOptions={tileOptions} product={product} />
            {/* Using dangerouslySetInnerHTML here because there are & characters in some of the product names encoded as @amp; */}
            <p className="tile__title mt-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.name ?? '')}}></p>
            {tileOptions.tagline && product?.mfield_product_tagline && (
              <p className="tile__tagline mt-2">{product?.mfield_product_tagline}</p>
            )}
            {tileOptions.specs && product?.mfield_product_specs && (
              <p className="tile__specs mt-2">{product?.mfield_product_specs}</p>
            )}
          </div>
          {product.ss_on_sale === '1' ? 
            ( 
              <div className='tile__sale-wrapper mt-2'>
                <p className="tile__price tile__sale-price pl-4">${parseFloat(product.price ?? '').toFixed(2)}</p>
                <s className='tile__strikethrough'><p className="tile__price tile__strikethrough">${parseFloat(product.ss_msrp ?? '').toFixed(2)}</p></s>
              </div>
            ) 
            : ( <p className="tile__price pb-4 pl-4 mt-2">${parseFloat(product.price ?? '').toFixed(2)}</p>)}
          
          {tileOptions.promo && product?.mfield_product_promotion_text && (
            <p className="tile__promo pb-4 pl-4 mt-2">{product?.mfield_product_promotion_text}</p>
          )}
        </LinkComponent>

        {tileOptions.addToCart && (
          productId && variantId && inStock === "1" ? (
            <AddToCartComponent
              availableForSale={true}
              isInStock={true}
              selectedProductId={productId}
              selectedVariantId={variantId.toString()}
              selectedQuantity={1}
              buttonColor={'ATC Tile'}
              buttonText={'Add to Cart'}
              buttonFontSize={18}
              notifyMe={false}
              productHandle={product.custom_url ?? '#'}
            />

          ) : (
            <LinkComponent className="tile__view-details" href={product.custom_url} onClick={() => gtm.selectSearchItem({ result: product, itemListId: productCategoryTitle?.toLowerCase()?.replaceAll(' ', '_'), itemListName: productCategoryTitle })}>View Details</LinkComponent>
          )
        )
        }
      </InView>
    </>
  );
}

export const ProductCardRecommendations: React.FC<
  ProductCardRecommendationProps
> = ({ product, tileOptions, AddToCartComponent, LinkComponent }) => {

  const productId = product?.mappings.core.uid;
  const variantId = product?.attributes?.base_variant_id;
  const inStock = product?.attributes?.ss_in_stock;

  return (
    <>
      {tileOptions.addToCart ? (
        <div className='tile__item-grid-atc border lg:m-2 rounded-md'>
          <LinkComponent className="tile__item-recs" href={product.attributes.custom_url} onClick={() => gtm.selectRecommendationItem({ recommendation: product })}>
            <div className="tile__image-wrapper-atc tile__image-wrapper relative">
              {tileOptions.burst && product?.attributes?.mfield_product_burst && (
                <img src={product?.attributes?.mfield_product_burst} className="absolute right-2 top-2" />
              )}

              {tileOptions.banner && product?.attributes?.mfield_product_banner && (
                <div className="tile__banner" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product?.attributes?.mfield_product_banner)}}></div>
              )}

              <img
                src={product.mappings.core.imageUrl}
                alt={product.mappings.core.name}
                className="tile__item-image w-full aspect-square object-cover"
              />
            </div>

            <div className='tile__content-wrapper p-4'>
              <Rating tileOptions={tileOptions} product={product.attributes} />
              <p className="tile__title mt-2">{product.mappings.core.name}</p>
              {tileOptions.tagline && product?.attributes?.mfield_product_tagline && (
                <p className="tile__tagline mt-2">{product?.attributes?.mfield_product_tagline}</p>
              )}
              {tileOptions.specs && product?.attributes?.mfield_product_specs && (
                <p className="tile__specs mt-2">{product?.attributes?.mfield_product_specs}</p>
              )}

              <div className='tile__price-wrapper'>
                {tileOptions.promo && product?.attributes?.mfield_product_promotion_text && (
                  <p className="tile__promo mt-2">{product?.attributes?.mfield_product_promotion_text}</p>
                )}

                {product.attributes.ss_on_sale === 1 ? 
                ( 
                  <div className='tile__sale-wrapper mt-2'>
                    <p className="tile__price tile__sale-price">${product.mappings.core.price.toFixed(2)}</p>
                    <s className='tile__strikethrough'><p className="tile__price tile__strikethrough">${product.attributes.ss_msrp?.toFixed(2)}</p></s>
                  </div>
                ) 
                : ( <p className="tile__price mt-2">${product.mappings.core.price.toFixed(2)}</p>)}
               
              </div>

            </div>
          </LinkComponent>

          {tileOptions.addToCart && (
            AddToCartComponent && productId && variantId && inStock === 1 ? (
              <div>
                <AddToCartComponent
                  availableForSale={true}
                  isInStock={true}
                  selectedProductId={productId}
                  selectedVariantId={variantId.toString()}
                  selectedQuantity={1}
                  buttonColor={'ATC Tile'}
                  buttonText={'Add to Cart'}
                  buttonFontSize={18}
                  notifyMe={false}
                  productHandle={product.attributes.custom_url ?? '#'}
              />
              </div>
            ) : (
              <LinkComponent className="tile__view-details" href={product.attributes.custom_url} onClick={() => gtm.selectRecommendationItem({ recommendation: product })}>View Details</LinkComponent>
            )
          )
          }
        </div>
      ) : (
        <LinkComponent className="tile__item-grid-no-atc tile__item-recs m-2" href={product.attributes.custom_url} onClick={() => gtm.selectRecommendationItem({ recommendation: product })}>
          <div className="border tile__image-wrapper relative">
            {tileOptions.burst && product?.attributes?.mfield_product_burst && (
              <img src={product?.attributes?.mfield_product_burst} className="absolute right-2 top-2" />
            )}

            {tileOptions.banner && product?.attributes?.mfield_product_banner && (
              <div className="tile__banner" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product?.attributes?.mfield_product_banner)}}></div>
            )}

            <img
              src={product.mappings.core.imageUrl}
              alt={product.mappings.core.name}
              className="w-full aspect-square object-cover"
            />
          </div>

          <div className='tile__content-wrapper'>
            <Rating tileOptions={tileOptions} product={product.attributes} />
            <p className="tile__title mt-2">{product.mappings.core.name}</p>
            {tileOptions.tagline && product?.attributes?.mfield_product_tagline && (
              <p className="tile__tagline mt-2">{product?.attributes?.mfield_product_tagline}</p>
            )}
            {tileOptions.specs && product?.attributes?.mfield_product_specs && (
              <p className="tile__specs mt-2">{product?.attributes?.mfield_product_specs}</p>
            )}
            <div className='tile__price-wrapper'>
              {tileOptions.promo && product?.attributes?.mfield_product_promotion_text && (
                <p className="tile__promo mt-2">{product?.attributes?.mfield_product_promotion_text}</p>
              )}
              {product.attributes.ss_on_sale === 1 ? 
              ( 
                <div className='tile__sale-wrapper mt-2'>
                  <p className="tile__price tile__sale-price">${product.mappings.core.price.toFixed(2)}</p>
                  <s className='tile__strikethrough'><p className="tile__price tile__strikethrough">${product.attributes.ss_msrp?.toFixed(2)}</p></s>
                </div>
              ) 
              : ( <p className="tile__price mt-2">${product.mappings.core.price.toFixed(2)}</p>)}
            </div>
          </div>
        </LinkComponent>
      )}
    </>
  );
};


export const MegaMenuCardRecommendations: React.FC<
  ProductCardRecommendationProps
> = ({ product, tileOptions, LinkComponent }) => {

  return (
    <LinkComponent className="ttile__item-recs m-2" href={product.attributes.custom_url}  onClick={() => gtm.selectRecommendationItem({ recommendation: product })}>
      <div className="border tile__image-wrapper relative">
        {tileOptions.burst && product?.attributes?.mfield_product_burst && (
          <img src={product?.attributes?.mfield_product_burst} className="absolute right-2 top-2" />
        )}

        {tileOptions.banner && product?.attributes?.mfield_product_banner && (
          <div className="tile__banner" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product?.attributes?.mfield_product_banner)}}></div>
        )}

        <img
          src={product.mappings.core.imageUrl}
          alt={product.mappings.core.name}
          className="w-full aspect-square object-cover"
        />
      </div>

      <div className='tile__content-wrapper'>
        <Rating tileOptions={tileOptions} product={product.attributes} />
        <p className="tile__title mt-2">{product.mappings.core.name}</p>
        {tileOptions.tagline && product?.attributes?.mfield_product_tagline && (
          <p className="tile__tagline mt-2">{product?.attributes?.mfield_product_tagline}</p>
        )}
        {tileOptions.specs && product?.attributes?.mfield_product_specs && (
          <p className="tile__specs mt-2">{product?.attributes?.mfield_product_specs}</p>
        )}
        {product.attributes.ss_on_sale === 1 ? 
          ( 
            <div className='tile__sale-wrapper mt-2'>
              <p className="tile__price tile__sale-price">${product.mappings.core.price.toFixed(2)}</p>
              <s className='tile__strikethrough'><p className="tile__price tile__strikethrough">${product.attributes.ss_msrp?.toFixed(2)}</p></s>
            </div>
          ) 
          : ( <p className="tile__price mt-2">${product.mappings.core.price.toFixed(2)}</p>)}

        {tileOptions.promo && product?.attributes?.mfield_product_promotion_text && (
          <p className="tile__promo mt-2">{product?.attributes?.mfield_product_promotion_text}</p>
        )}
      </div>
    </LinkComponent>
  );
};


export const ContentCardRecommendations: React.FC<
  ContentCardRecommendationProps
> = ({ product,  LinkComponent, tileOptions }) => {

  const date = new Date(product.attributes?.ss_date_published);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  });
  return (
      <>
        <div className="border tile__image-wrapper relative">
          <img
            src={product.mappings.core.imageUrl}
            alt={product.attributes.name}
            className="w-full aspect-square object-cover"
          />
        </div>

        <div className='tile__content-wrapper'>
          <div className='tile__content-top mt-2'>
            {tileOptions.type && (
              <span className='tile__type' dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(product.attributes.type as string),
              }}></span>
            )}
            {tileOptions.date && (
              <span className='tile__date'>{formattedDate}</span>
            )}
          </div>
          <div className='headline6 mt-2'>{product.attributes.name}</div>
          {tileOptions.description && (
            <div className='tile__content mt-2'>{product.attributes.content}</div>
          )}
          <LinkComponent className="tile__item-recs mt-2" href={product.attributes.url}>
            <span className='tile__readmore'>Read More</span>
          </LinkComponent>
          {tileOptions.topics && product.attributes.topics && (
            <div className='tile__topics'>
                {product.attributes.topics.map((topic: string) => (
                    <span key={topic} className='tile__topic' dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(topic as string),
                      }}></span>
                ))}
            </div>
          )}
        </div>
      </>
  );
};

interface RatingProps {
  tileOptions: TileOptions;
  product: any;
}

export const Rating: React.FC<RatingProps> = ({ tileOptions, product }) => {  if (!tileOptions.ratings || !product?.totalrating || !product?.totalreviews) {
    return null;
  }
  
  const totalRating = Math.floor(parseFloat(product.totalrating));  
  const stars = Array.from({ length: 5 }, (_, index) => {
    if (totalRating > index) {
      return <Star key={index} />;
    } else {
      return <EmptyStar key={index} />;
    }
  });

  return (
    <div className='tile__ratings'>
      {stars}
      <span className='tile__ratings-count'>({product.totalreviews})</span>
    </div>
  );
}

