'use client';

import { withSuspense } from 'components/with-suspense';
import { RefinementSidebar } from 'propel-react-components/src/components/Sidebar/RefinementSidebar';
import { withSearchspringSearch } from "../withSearchspringSearch";

const SidebarSkeleton = (
    <div className="animate-pulse">
        <label className="ss__filter-label md:mb-3 sm:mb-3">Filter by</label>
        <div className="sm:basis-full sm:w-full md:w-full h-4"> </div>
    </div>
);

export const Sidebar = withSuspense(withSearchspringSearch(RefinementSidebar), SidebarSkeleton);