/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchResponseModel } from '../models/SearchResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPIConfig } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { SuggestResponseModel } from '../models/SuggestResponseModel';
import { BeaconRequestBodyModel } from '../models/BeaconRequestBodyModel';
import { GenericAPIResult } from '../core/ApiResult';
import { BeaconDTO } from '../../../types';

export class SearchspringAPIService {
  public config: OpenAPIConfig;
  public beaconConfig: OpenAPIConfig;

  constructor(_config: OpenAPIConfig, _beaconConfig: OpenAPIConfig) {
    this.config = _config;
    this.beaconConfig = _beaconConfig;
  }

    /**
     * Search API
     * Returns search results, filters, sort options, pagination, active filters (if active), configured merchandising data (if triggered), and spell correction information to build a results page. See [Search Result Pages](https://docs.searchspring.com/reference/search-result-pages), [Category Result Pages](https://docs.searchspring.com/reference/category-result-pages), and [Content Results](https://docs.searchspring.com/reference/content-results) to see where this endpoint is used.
     * @param siteId Unique identifier to associate the Searchspring account making the request to the database. This can be found on the [My Account](https://manage.searchspring.net/management/account) page in the Searchspring Management Console under the **Account details** section.
     * @param userId If the cookie **"ssUserId"** is currently set, use the value as the **userId** value.
     *
     * If it doesn't exist yet, [generate a new ID](https://docs.searchspring.com/reference/uuid-v4) and store it in the **"ssUserId"** cookie.
     * @param sessionId If the cookie **"ssSessionIdNamespace"** is currently set, use the value as the **sessionId** value.
     *
     * If it doesn't exist yet, [generate a new ID](https://docs.searchspring.com/reference/uuid-v4) and store it in the **"ssSessionIdNamespace"** cookie.
     * @param pageLoadId Create a new ID for this parameter on every page load. **If using a headless build create a new ID for this parameter on every URL route change.**
     *
     * If it doesn't exist yet, [generate a new ID](https://docs.searchspring.com/reference/uuid-v4).
     * @param domain The full URL of the current page.
     * @param httpXForwardedFor This header is ***required*** _only_ for accounts that are making API requests within their server (server-side integration).
     *
     * In order for requests to be properly handled pass the active customer's IP address to Searchspring as the value for this header.
     * @param resultsFormat For API integrations this parameter with a value of ***json*** is required to return results data as JSON. Other results formats are deprecated and no longer available.
     * @param q Query value will search the index for terms that match the query. Will support up to 256 characters.
     * @param filter
     * @param bgfilter
     * @param sort
     * @param resultsPerPage This will allow you to override the number of results per page that are returned in the Search API response in the results array.
     * @param page The page of results to be loaded.
     * @param redirectResponse Will change how redirects behave in the API response.
     * * Direct - will return a 302 and redirect a shopper to the returned URL within **merchandising.redirect** in the response. Direct is best for API integrations as it is the most performant.
     * * Mininal - returns a partial API response without results data. Redirect URL will be returned in the merchandising object.
     * * Full - returns the full API response with results data. Redirect URL will be returned in the merchandising object.
     * @param landingPage Will allow the API to search the database for products that are a part of a configured [landing page campaign](https://searchspring.zendesk.com/hc/en-us/articles/115001067186-Landing-Pages).
     *
     * Landing page campaign example: **landing-page=black-friday**
     * @param tag Will allow our API to search the database for products that are a part of a configured [segmented merchandising campaign](https://searchspring.zendesk.com/hc/en-us/articles/360042411431-Segmented-Merchandising). To trigger a segmented merchandising campaign the value must follow this format, **tag=merch.segment/[your-tag-here]**.
     *
     * Segmented Merchandising campaign example: **tag=merch.segment/logged-in-black-friday**
     * @param includedFacets Will allow you to specify specific facets you would like returned in the API response. The specified value passed must be the name of the facet field for it to be included. If the parameter is included, but the value is blank, facets will not be returned in the response.
     * @param excludedFacets Will allow you to remove a specified facet from the facets array returned in the API response. The specified value passed must be the name of the facet field for removal to function as expected.
     * @param disableInlineBanners
     * @param lastViewed Comma seperated list of product SKUs or UIDs that the customer has recently viewed. The most recently viewed product SKU or UID being at the front of the string of product SKUs or UIDs passed. This is required to allow personalization functionality to personalize products in search results as the customer navigates the site. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @param cart Comma seperated list of product SKUs or UIDs that are in the current customers cart. This is required to allow personalization functionality to personalize products in search results as the customer navigates the site. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @param shopper ID of current logged-in shopper from your platform, this is required for personalization features. If the shopper is anonymous this should be omitted. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @returns SearchResponseModel OK
     * @throws ApiError
     */
    public getSearch(
        siteId: string,
        userId: string,
        sessionId: string,
        pageLoadId: string,
        domain: string,
        httpXForwardedFor?: string,
        resultsFormat: string = 'json',
        q?: string,
        filter?: Record<string, any>,
        bgfilter?: Record<string, any>,
        sort?: Record<string, 'asc' | 'desc'>,
        resultsPerPage: number = 24,
        page?: number,
        redirectResponse: 'direct' | 'minimal' | 'full' = 'direct',
        landingPage?: string,
        tag?: string,
        includedFacets?: string,
        excludedFacets?: string,
        disableInlineBanners?: string,
        lastViewed?: string,
        cart?: string,
        shopper?: string,
    ): CancelablePromise<GenericAPIResult<SearchResponseModel>> {
        return __request(this.config, {
            method: 'GET',
            url: '/api/search/search.json',
            headers: {
                'HTTP_X_FORWARDED_FOR': httpXForwardedFor,
            },
            query: {
                'siteId': siteId,
                'resultsFormat': resultsFormat,
                'q': q,
                'filter': filter,
                'bgfilter': bgfilter,
                'sort': sort,
                'userId': userId,
                'sessionId': sessionId,
                'pageLoadId': pageLoadId,
                'domain': domain,
                'resultsPerPage': resultsPerPage,
                'page': page,
                'redirectResponse': redirectResponse,
                'landing-page': landingPage,
                'tag': tag,
                'includedFacets': includedFacets,
                'excludedFacets': excludedFacets,
                'disableInlineBanners': disableInlineBanners,
                'lastViewed': lastViewed,
                'cart': cart,
                'shopper': shopper,
            },
            errors: {
                302: `This status code will be returned when a query value passed in the API request matches a configured redirect in the Searchspring Management Console and the query parameter ***redirectResponse=direct*** is in use.`,
                400: `Bad Request`,
                403: `403 Request generated a response that was too large`,
                404: `404 Not Found`,
                405: `405 Method Not Allowed`,
                429: `429 Too Many Requests`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }

    /**
     * Preflight API
     * If your account is configured to utilize [Personalized Search & Merchandising](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising), this endpoint is required to feed into the feature. In the integration, the moment the data points for "shopper", "cart", or "lastViewed" query string parameters are assigned a value, a Preflight request will need to be sent _before_ a request to the Autocomplete or Search API endpoint is sent. ***The GET method will support up to 2048 characters, if there are more characters use the [POST method](https://docs.searchspring.com/reference/post-preflight)***.
     * @param siteId Unique identifier to associate the Searchspring account making the request to the database. This can be found on the [My Account](https://manage.searchspring.net/management/account) page in the Searchspring Management Console under the **Account details** section.
     * @param userId If the cookie **"ssUserId"** is currently set, use the value as the **userId** value.
     *
     * If it doesn't exist yet, [generate a new ID](https://docs.searchspring.com/reference/uuid-v4) and store it in the **"ssUserId"** cookie.
     * @param shopper ID of current logged-in shopper from your platform, this is required for personalization features. If the shopper is anonymous this should be omitted. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @param cart Comma seperated list of product SKUs or UIDs that are in the current customers cart. This is required to allow personalization functionality to personalize products in search results as the customer navigates the site. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @param lastViewed Comma seperated list of product SKUs or UIDs that the customer has recently viewed. The most recently viewed product SKU or UID being at the front of the string of product SKUs or UIDs passed. This is required to allow personalization functionality to personalize products in search results as the customer navigates the site. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @returns any
     * @throws ApiError
     */
    public getPreflight(
        siteId: string,
        userId: string,
        shopper?: string,
        cart?: string,
        lastViewed?: string,
    ): CancelablePromise<GenericAPIResult<any>> {
        return __request(this.config, {
            method: 'GET',
            url: '/api/personalization/preflightCache',
            query: {
                'siteId': siteId,
                'userId': userId,
                'shopper': shopper,
                'cart': cart,
                'lastViewed': lastViewed,
            },
            errors: {
                400: `If the siteId, userId, or shopper query string parameters are not passed this error code will be returned. If siteId or shopper are not passed the message will be "ShopperContext is invalid". If userId is not passed the message will be "Missing UserID".`,
            },
        });
    }

    /**
     * Preflight API
     * If your account is configured to utilize [Personalized Search & Merchandising](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising), this endpoint is required to feed into the feature. In the integration, the moment the data points for "shopper", "cart", or "lastViewed" query string parameters are assigned a value, a Preflight request will need to be sent _before_ a request to the Autocomplete or Search API endpoint is sent. ***The POST method will support more than 2048, if there are less characters use the [GET method](https://docs.searchspring.com/reference/get-preflight)***.
     * @param siteId Unique identifier to associate the Searchspring account making the request to the database. This can be found on the [My Account](https://manage.searchspring.net/management/account) page in the Searchspring Management Console under the **Account details** section.
     * @param userId If the cookie **"ssUserId"** is currently set, use the value as the **userId** value.
     *
     * If it doesn't exist yet, [generate a new ID](https://docs.searchspring.com/reference/uuid-v4) and store it in the **"ssUserId"** cookie.
     * @param shopper ID of current logged-in shopper from your platform, this is required for personalization features. If the shopper is anonymous this should be omitted. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @param cart Comma seperated list of product SKUs or UIDs that are in the current customers cart. This is required to allow personalization functionality to personalize products in search results as the customer navigates the site. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @param lastViewed Comma seperated list of product SKUs or UIDs that the customer has recently viewed. The most recently viewed product SKU or UID being at the front of the string of product SKUs or UIDs passed. This is required to allow personalization functionality to personalize products in search results as the customer navigates the site. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @returns any
     * @throws ApiError
     */
    public postPreflight(
        siteId: string,
        userId: string,
        shopper?: string,
        cart?: string,
        lastViewed?: string,
    ): CancelablePromise<GenericAPIResult<any>> {
        return __request(this.config, {
            method: 'POST',
            url: '/api/personalization/preflightCache',
            query: {
                'siteId': siteId,
                'userId': userId,
                'shopper': shopper,
                'cart': cart,
                'lastViewed': lastViewed,
            },
            errors: {
                400: `If the siteId, userId, or shopper query string parameters are not passed this error code will be returned. If siteId or shopper are not passed the message will be "ShopperContext is invalid". If userId is not passed the message will be "Missing UserID".`,
            },
        });
    }

    /**
     * Personalized Recommendations API
     * Will return recommended products queried from a configured personalized recommendations profile. Returned results will be personalized to the shopper.
     * @param siteId Unique identifier to associate the Searchspring account making the request to the database. This can be found on the [My Account](https://manage.searchspring.net/management/account) page in the Searchspring Management Console under the **Account details** section.
     * @param tags Will be a comma separated list of profile ID's that are associated with a recommendations profile in the Searchspring Management Console.
     *
     * Examples:
     * * tags=also-viewed
     * * tags=also-viewed,also-bought,most-popular
     * @param product Will be the ID or SKU of the product being viewed. This parameter is required only for recommendations profiles that use ***cross-sell*** or ***similar*** recommendations types, and is ***recommended*** for all requests made on Product Detail Pages.
     * @param categories Will be a comma-separated list of category IDs. This is ***required*** when using Category Trending recommendation profiles.
     * @param brands Will be a comma-separated list of brand IDs. This is ***required*** when using Brands Trending recommendation profiles.
     * @param shopper ID of current logged-in shopper from your platform, this is required for personalization features. If the shopper is anonymous this should be omitted.
     * @param cart Comma seperated list of product SKUs or UIDs that are in the current customers cart. This is required to allow personalization functionality to curate recommended products as the customer navigates the site.
     * @param lastViewed Comma seperated list of product SKUs that the customer has recently viewed. The most recently viewed product SKU being at the front of the string of product SKUs passed. This field is required to allow personalization functionality to curate recommended products as the customer navigates the site.
     * @param limits Limits the number of products returned in the recommendations API response. If multiple profiles are requested use a comma-separated list. This will apply a limit for each profile in order, corresponding to each profile tag. If a limit is not defined, the default of 20 products will be returned.
     * @returns any OK
     * @throws ApiError
     */
    public getRecommendations(
        siteId: string,
        tags: string,
        product?: string,
        categories?: string,
        brands?: string,
        shopper?: string,
        cart?: string,
        lastViewed?: string,
        limits: number = 20,
    ): CancelablePromise<GenericAPIResult<{
        /**
         * Array of objects that represent the relevant results returned after the database is queried. The way to modify the result data that is returned is through the passed query, and/or filter, and/or background filter parameter value(s).
         */
        results?: any[];
        /**
         * Will return data that indicates which profile configured in the Searchspring Mangement Console is returning results data.
         */
        profile?: {
            /**
             * Will identify the unique profile tag that is returning results data.
             */
            tag?: string;
        };
    }[]>> {
        return __request(this.config, {
            method: 'GET',
            url: `/boost/${siteId}/recommend`,
            path: {
                'siteId': siteId,
            },
            query: {
                'tags': tags,
                'product': product,
                'categories': categories,
                'brands': brands,
                'shopper': shopper,
                'cart': cart,
                'lastViewed': lastViewed,
                'limits': limits,
            },
            errors: {
                400: `400 Bad Request`,
                403: `403 Forbidden`,
                404: `404 Not Found`,
                405: `405 Method Not Allowed`,
            },
        });
    }

    /**
     * Finder API
     * Used to source filters to build a [Product Finder Module](https://docs.searchspring.com/reference/product-finder-module).
     * @param siteId Unique identifier to associate the Searchspring account making the request to the database. This can be found on the [My Account](https://manage.searchspring.net/management/account) page in the Searchspring Management Console under the **Account details** section.
     * @param resultsPerPage Because we only need relevant facet data returned to build a Product Finder, we do not need results. This should always be passed and receive a value of 0.
     * @param filter
     * @param bgfilter
     * @param includedFacets Will allow you to specify specific facets you would like returned in the API response. The specified value passed must be the name of the facet field for it to be included. If the parameter is included, but the value is blank, facets will not be returned in the response.
     * @param excludedFacets Will allow you to remove a specified facet from the facets array returned in the API response. The specified value passed must be the name of the facet field for removal to function as expected.
     * @returns SearchResponseModel OK
     * @throws ApiError
     */
    public getFinder(
        siteId: string,
        resultsPerPage: number,
        filter?: Record<string, any>,
        bgfilter?: Record<string, any>,
        includedFacets?: string,
        excludedFacets?: string,
    ): CancelablePromise<GenericAPIResult<SearchResponseModel>> {
        return __request(this.config, {
            method: 'GET',
            url: '/api/search/finder.json',
            query: {
                'siteId': siteId,
                'resultsPerPage': resultsPerPage,
                'filter': filter,
                'bgfilter': bgfilter,
                'includedFacets': includedFacets,
                'excludedFacets': excludedFacets,
            },
            errors: {
                400: `Bad Request`,
                403: `403 Request generated a response that was too large`,
                404: `404 Not Found`,
                405: `405 Method Not Allowed`,
                429: `429 Too Many Requests`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
    }

    /**
     * Trending API
     * Returns the most popular search terms from the past 90 days for your site.
     * @param siteId Unique identifier to associate the Searchspring account making the request to the database. This can be found on the [My Account](https://manage.searchspring.net/management/account) page in the Searchspring Management Console under the **Account details** section.
     * @param limit Will limit the number of popular terms to be returned in the API response.
     * @returns any OK
     * @throws ApiError
     */
    public getTrending(
        siteId: string,
        limit: number = 6,
    ): CancelablePromise<GenericAPIResult<{
        /**
         * The trending object will house the most popular queries shoppers are submitting on the site in the last 90 days within the queries array. Results will be returned in descending order based on the ***popularity*** key.
         */
        trending?: {
            /**
             * Array of objects, each object will represent a popular query.
             */
            queries?: any[];
        };
    }>> {
        return __request(this.config, {
            method: 'GET',
            url: '/api/suggest/trending',
            query: {
                'siteId': siteId,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                404: `404 Not Found`,
            },
        });
    }

    /**
     * Suggest API
     * If applicable, will return a suggestion and alternate suggestions to be displayed to the shopper as alternate search queries to pass to the [Autocomplete API](https://docs.searchspring.com/reference/get-autocomplete) to source results.
     * @param siteId Unique identifier to associate the Searchspring account making the request to the database. This can be found on the [My Account](https://manage.searchspring.net/management/account) page in the Searchspring Management Console under the **Account details** section.
     * @param q Query value will search the index for terms that match the query.
     * @param language Determine the language model that is used for spell corrections.
     * @param suggestionCount Limits the number of possible suggestions that will be returned by our API. These values will be shown to the shopper on the frontend to select. If the shopper selects a suggestion it will be passed to the Autocomplete API request as the ***q*** parameter.
     * @param productCount Applying this parameter will limit the number of items the Suggest API will scan in order to find matches against the query parameter that is passed. During a scan the Suggest API will attempt to match the query parameter against the Name and SKU core fields.
     * @returns SuggestResponseModel OK
     * @throws ApiError
     */
    public getSuggest(
        siteId: string,
        q?: string,
        language: string = 'en',
        suggestionCount: number = 4,
        productCount: number = 20,
    ): CancelablePromise<GenericAPIResult<SuggestResponseModel>> {
        return __request(this.config, {
            method: 'GET',
            url: '/api/suggest/query',
            query: {
                'siteId': siteId,
                'q': q,
                'language': language,
                'suggestionCount': suggestionCount,
                'productCount': productCount,
            },
            errors: {
                400: `Bad Request`,
                404: `404 Not Found`,
            },
        });
    }

    /**
     * Autocomplete API
     * Used to receive data to build a preview of results to the shopper within the [Autocomplete Module](https://docs.searchspring.com/reference/autocomplete-module) based on the typed value in the searchbar.
     * @param siteId Unique identifier to associate the Searchspring account making the request to the database. This can be found on the [My Account](https://manage.searchspring.net/management/account) page in the Searchspring Management Console under the **Account details** section.
     * @param userId If the cookie **"ssUserId"** is currently set, use the value as the **userId** value.
     *
     * If it doesn't exist yet, [generate a new ID](https://docs.searchspring.com/reference/uuid-v4) and store it in the **"ssUserId"** cookie.
     * @param sessionId If the cookie **"ssSessionIdNamespace"** is currently set, use the value as the **sessionId** value.
     *
     * If it doesn't exist yet, [generate a new ID](https://docs.searchspring.com/reference/uuid-v4) and store it in the **"ssSessionIdNamespace"** cookie.
     * @param pageLoadId Create a new ID for this parameter on every page load. **If using a headless build create a new ID for this parameter on every URL route change.**
     *
     * If it doesn't exist yet, [generate a new ID](https://docs.searchspring.com/reference/uuid-v4).
     * @param domain The full URL of the current page.
     * @param httpXForwardedFor This header is ***required*** _only_ for accounts that are making API requests within their server (server-side integration).
     *
     * In order for requests to be properly handled pass the active customer's IP address to Searchspring as the value for this header.
     * @param resultsFormat For API integrations this parameter with a value of ***json*** is required to return results data as JSON. Other results formats are deprecated and no longer available.
     * @param q Query value will search the index for terms that match the query. Will support up to 256 characters.
     * @param filter
     * @param bgfilter
     * @param sort
     * @param resultsPerPage This will allow you to override the number of results per page that are returned in the Search API response in the results array.
     * @param page The page of results to be loaded.
     * @param redirectResponse Will change how redirects behave in the API response.
     * * Direct - will return a 302 and redirect a shopper to the returned URL within **merchandising.redirect** in the response. Direct is best for API integrations as it is the most performant.
     * * Mininal - returns a partial API response without results data. Redirect URL will be returned in the merchandising object.
     * * Full - returns the full API response with results data. Redirect URL will be returned in the merchandising object.
     * @param landingPage Will allow the API to search the database for products that are a part of a configured [landing page campaign](https://searchspring.zendesk.com/hc/en-us/articles/115001067186-Landing-Pages).
     *
     * Landing page campaign example: **landing-page=black-friday**
     * @param tag Will allow our API to search the database for products that are a part of a configured [segmented merchandising campaign](https://searchspring.zendesk.com/hc/en-us/articles/360042411431-Segmented-Merchandising). To trigger a segmented merchandising campaign the value must follow this format, **tag=merch.segment/[your-tag-here]**.
     *
     * Segmented Merchandising campaign example: **tag=merch.segment/logged-in-black-friday**
     * @param includedFacets Will allow you to specify specific facets you would like returned in the API response. The specified value passed must be the name of the facet field for it to be included. If the parameter is included, but the value is blank, facets will not be returned in the response.
     * @param excludedFacets Will allow you to remove a specified facet from the facets array returned in the API response. The specified value passed must be the name of the facet field for removal to function as expected.
     * @param disableInlineBanners
     * @param lastViewed Comma seperated list of product SKUs or UIDs that the customer has recently viewed. The most recently viewed product SKU or UID being at the front of the string of product SKUs or UIDs passed. This is required to allow personalization functionality to personalize products in search results as the customer navigates the site. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @param cart Comma seperated list of product SKUs or UIDs that are in the current customers cart. This is required to allow personalization functionality to personalize products in search results as the customer navigates the site. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @param shopper ID of current logged-in shopper from your platform, this is required for personalization features. If the shopper is anonymous this should be omitted. To learn more about personalized search, please refer to [this link](https://help.searchspring.net/hc/en-us/articles/4405042906779-Personalized-Search-Merchandising) for more information.
     * @returns SearchResponseModel OK
     * @throws ApiError
     */
    public getAutocomplete(
        siteId: string,
        userId: string,
        sessionId: string,
        pageLoadId: string,
        domain: string,
        httpXForwardedFor?: string,
        resultsFormat: string = 'json',
        q?: string,
        filter?: Record<string, any>,
        bgfilter?: Record<string, any>,
        sort?: Record<string, 'asc' | 'desc'>,
        resultsPerPage: number = 24,
        page: number = 1,
        redirectResponse: 'direct' | 'minimal' | 'full' = 'direct',
        landingPage?: string,
        tag?: string,
        includedFacets?: string,
        excludedFacets?: string,
        disableInlineBanners?: string,
        lastViewed?: string,
        cart?: string,
        shopper?: string,
    ): CancelablePromise<GenericAPIResult<SearchResponseModel>> {
        return __request(this.config, {
            method: 'GET',
            url: '/api/search/autocomplete.json',
            headers: {
                'HTTP_X_FORWARDED_FOR': httpXForwardedFor,
            },
            query: {
                'siteId': siteId,
                'resultsFormat': resultsFormat,
                'q': q,
                'filter': filter,
                'bgfilter': bgfilter,
                'sort': sort,
                'userId': userId,
                'sessionId': sessionId,
                'pageLoadId': pageLoadId,
                'domain': domain,
                'resultsPerPage': resultsPerPage,
                'page': page,
                'redirectResponse': redirectResponse,
                'landing-page': landingPage,
                'tag': tag,
                'includedFacets': includedFacets,
                'excludedFacets': excludedFacets,
                'disableInlineBanners': disableInlineBanners,
                'lastViewed': lastViewed,
                'cart': cart,
                'shopper': shopper,
            },
            errors: {
                302: `This status code will be returned when a query value passed in the API request matches a configured redirect in the Searchspring Management Console and the query parameter ***redirectResponse=direct*** is in use.`,
                400: `Bad Request`,
                403: `403 Request generated a response that was too large`,
                404: `404 Not Found`,
                405: `405 Method Not Allowed`,
                429: `429 Too Many Requests`,
                500: `Internal Server Error`,
                502: `Bad Gateway`,
            },
        });
        
    }

    /**
     * Beacon API
     * @param type Describes each use case per event type
     * @param category This parameter with the value "searchspring.recommendations.user-interactions" must always get passed.
     * @param id Random UUID generated for the event
     * @param pid If there is a "parent" event that happened at the same time as this event, pass the parent's event ID here.
     * @param event The data passed within this object will describe the details of the event.
     * @returns Success OK
     * @throws ApiError
     */
    public postBeacon<T>(body: BeaconDTO): CancelablePromise<GenericAPIResult<{
        success: boolean
    }>> {
        return __request(this.beaconConfig, {
            method: 'POST',
            url: '/beacon',
            body,
            errors: {
                400: `Bad Request`,
                502: `Bad Gateway`,
            },
        });
    }

}

