import {
    type BeaconConfig,
    type SearchspringState
  } from 'int-searchspring-api/src/types';
  import {
      ContentRecommendation,
    LinkComponentInterface,
    type Recommendation,
  } from 'propel-shared-utility';
    
    import { SearchspringService } from 'components/searchspring/searchspringService';
  import { type RecommendationsProps } from 'propel-react-components/src/components/MegaMenuRecs/MegaMenuRecs';
  import React, { useEffect, useState } from 'react';
  import {
    useBeaconEventDataLayer,
    useSearchspringClient,
  } from '../../hooks/ssClientHooks';
  import { getBeaconConfig, getSearchspringState } from '../search-spring-state';
import { ContentRecommendationsProps } from 'propel-react-components/src/components/ContentRecs/ContentRecsCarousel';
    
  
type ContentRecsStrategyParams = {
  recommendations?: ContentRecommendation[];
  beaconConfig?: BeaconConfig;
  searchspringState?: SearchspringState;
  profile: string;
  maxTiles: number;
  tileType: boolean;
  tileDate: boolean;
  tileDescription: boolean;
  tileTopics: boolean;
  showNav: boolean;
  navTheme: string;
  desktopSettings: {
    count: number,
    per: number,
  },
  tabletSettings: {
    count: number,
    per: number,
  },
  mobileSettings: {
    count: number,
    per: number,
  },
  builderBlock: {
    id: string
  },
  speed: number;
  autoPlay: boolean;
  dtSwipe: boolean;
  initialSlide: number;
};
  
export default function  getContentRecsStrategy(
  Component: React.FC<ContentRecommendationsProps>,
  builderEditorMode: boolean,
  LinkComponent: LinkComponentInterface
) {
  
  const ContentRecsPropsStrategy = (props: ContentRecsStrategyParams) => {
      const maxTiles = props.maxTiles;
      const tileOptions = {
        type: props.tileType,
        date: props.tileDate,
        description: props.tileDescription,
        topics: props.tileTopics,
      };

      const carouselOptions = {
        showNav: props.showNav,
        navTheme: props.navTheme,
        desktopSettings: props.desktopSettings,
        tabletSettings: props.tabletSettings,
        mobileSettings: props.mobileSettings,
        builderBlock: props.builderBlock,
        speed: props.speed,
        autoPlay: props.autoPlay,
        dtSwipe: props.dtSwipe,
        initialSlide: props.initialSlide
      }

      const state = getSearchspringState({ overrides: { siteId: process.env.NEXT_PUBLIC_SS_CONTENT_INDEX, profile: props.profile }})
      const searchspringClient: any = useSearchspringClient(state);
      const [recsData, setRecsData] = useState<any | null>(null);
      const [error, setError] = useState<any | null>(null);

      useEffect(() => {
        const searchService = new SearchspringService(state);
        const fetchRecsData = async () => {
          try {
            const data = await searchService.recommendationsGET(state);
            setRecsData(data);
          } catch (error) {
            setError(error);
          }
        };
        fetchRecsData();   
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [setRecsData, setError]); 
      const beaconConfig = getBeaconConfig({});

      const { setContentRecommendationDataProps, setCarouselDataProps } =
        useBeaconEventDataLayer(
          beaconConfig,
          searchspringClient,
          props.recommendations || [],
        );

      return (
        <Component
          recommendations={recsData?.body?.at(0)?.results || []}
          setRecommendationDataProps={setContentRecommendationDataProps}
          maxTiles={maxTiles}
          LinkComponent={LinkComponent}
          setCarouselDataProps={setCarouselDataProps}
          tileOptions={tileOptions}
          carouselOptions={carouselOptions}
          />    
      );

  };

  ContentRecsPropsStrategy.displayName = 'ContentRecsPropsStrategy';

  return ContentRecsPropsStrategy;
}