import './video.css';

export function Video({ 
  videoHost,
  videoId
}: { 
  videoHost?: string;
  videoId: any;
}) {
  return (
    <>
      {
        videoHost === 'YouTube' 
          ? <iframe 
              width="560" 
              height="315" 
              src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&controls=0`}
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen
            ></iframe>
          : null
      }
    </>
  );
}
