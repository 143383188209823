
export default function CartIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
        <path d="M16.9456 13.6937C17.3598 13.6937 17.6956 13.358 17.6956 12.9437C17.6956 12.5295 17.3598 12.1937 16.9456 12.1937V13.6937ZM18.6828 3.72697L17.949 3.57165L18.6828 3.72697ZM5.19713 12.1327L5.93362 11.991L5.19713 12.1327ZM17.6113 8.78892L16.8775 8.63361L17.6113 8.78892ZM-5.70076e-05 1.75H2.22908V0.25H-5.70076e-05V1.75ZM2.47457 1.95276L4.46064 12.2744L5.93362 11.991L3.94755 1.66933L2.47457 1.95276ZM6.17912 13.6937H16.9456V12.1937H6.17912V13.6937ZM3.62365 3.87343H18.1936V2.37343H3.62365V3.87343ZM17.949 3.57165L16.8775 8.63361L18.345 8.94424L19.4165 3.88229L17.949 3.57165ZM16.6329 8.83183H5.35319V10.3318H16.6329V8.83183ZM18.1936 3.87343C18.0347 3.87343 17.9161 3.72711 17.949 3.57165L19.4165 3.88229C19.581 3.10498 18.9881 2.37343 18.1936 2.37343V3.87343ZM4.46064 12.2744C4.61917 13.0983 5.34012 13.6937 6.17912 13.6937V12.1937C6.05926 12.1937 5.95627 12.1087 5.93362 11.991L4.46064 12.2744ZM16.8775 8.63361C16.8531 8.74916 16.7511 8.83183 16.6329 8.83183V10.3318C17.4598 10.3318 18.1738 9.75315 18.345 8.94424L16.8775 8.63361ZM2.22908 1.75C2.34893 1.75 2.45192 1.83506 2.47457 1.95276L3.94755 1.66933C3.78902 0.84545 3.06808 0.25 2.22908 0.25V1.75Z" fill="#1E3665"/>
        <circle cx="6.90917" cy="16.5498" r="1.64354" fill="#1E3665"/>
        <circle cx="15.292" cy="16.5498" r="1.64354" fill="#1E3665"/>
    </svg>
  );
}
