'use client'

import { Recommendation, SuggestionProduct } from 'propel-shared-utility';
import { SearchProductResult } from '../../interfaces';

const eventPrefix = '4p_';

export const viewRecommendationItemList = ({ recommendations }: { recommendations: Recommendation[] }) => {
  if (typeof window === 'undefined') return {};

  const viewItemListEvent = {
    event: `${eventPrefix}view_item_list`,
    ecommerce: {
      channel_id: 'storefront',
      item_list_id: "recommended_products",
      item_list_name: "Recommended products",
      items: recommendations.map((recommendation, index) => gtmViewRecommendationItem({ recommendation, index })),
      scrollPosition: window.scrollY,
    }
  }
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent(viewItemListEvent);
  return viewItemListEvent;
}

export const selectRecommendationItem = ({ recommendation }: { recommendation: Recommendation }) => {
  if (typeof window === 'undefined') return {};

  const selectItemList = {
    event: `${eventPrefix}select_item`,
    ecommerce: {
      channel_id: 'storefront',
      item_list_id: "recommended_products",
      item_list_name: "Recommended products",
      items: [gtmViewRecommendationItem({ recommendation, index: 0 })],
      scrollPosition: window.scrollY,
    }
  }
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent(selectItemList);
  return selectItemList;
}

type ViewSearchItemListProps = { results: (SearchProductResult)[], itemListName?: string, itemListId?: string };
export const viewSearchItemList = ({ results, itemListName, itemListId }: ViewSearchItemListProps) => {
  if (typeof window === 'undefined') return {};

  const viewItemListEvent = {
    event: `${eventPrefix}view_item_list`,
    ecommerce: {
      channel_id: 'storefront',
      item_list_id: itemListId ?? 'search_results',
      item_list_name: itemListName ?? 'Search Results',
      items: results.map((result, index) => gtmViewSearchItem({ result, index} )),
      scrollPosition: window.scrollY,
    }
  }
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent(viewItemListEvent);
  return viewItemListEvent;
}

export const selectSearchItem = ({ result, itemListName, itemListId }: { result: SearchProductResult, itemListName?: string, itemListId?: string }) => {
  if (typeof window === 'undefined') return {};

  const selectItemList = {
    event: `${eventPrefix}select_item`,
    ecommerce: {
      channel_id: 'storefront',
      item_list_id: itemListId ?? 'search_results',
      item_list_name: itemListName ?? 'Search Results',
      items: [gtmViewSearchItem({ result, index: 0 })],
      scrollPosition: window.scrollY,
    }
  }
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent(selectItemList);
  return selectItemList;
}

type ViewSuggestionItemListProps = { query: string, results: (SuggestionProduct)[], itemListName?: string, itemListId?: string };
export const viewSuggestionItemList = ({ query, results, itemListName, itemListId }: ViewSuggestionItemListProps) => {
  if (typeof window === 'undefined') return {};

  const viewItemListEvent = {
    event: `${eventPrefix}view_item_list`,
    ecommerce: {
      channel_id: 'storefront',
      item_list_id: itemListId,
      item_list_name: itemListName,
      search_term: query,
      items: results.map((result, index) => gtmViewSuggestionItem({ result, index })),
      scrollPosition: window.scrollY,
    }
  }
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent(viewItemListEvent);
  return viewItemListEvent;
}

export const selectSuggestionItem = ({ query, result, itemListName, itemListId }: { query: string, result: SuggestionProduct, itemListName?: string, itemListId?: string }) => {
  if (typeof window === 'undefined') return {};

  const selectItemList = {
    event: `${eventPrefix}select_item`,
    ecommerce: {
      channel_id: 'storefront',
      item_list_id: itemListId,
      item_list_name: itemListName,
      search_term: query,
      items: [gtmViewSuggestionItem({ result, index: 0 })],
      scrollPosition: window.scrollY,
    }
  }
  sendGTMEvent({ ecommerce: null });
  sendGTMEvent(selectItemList);
  return selectItemList;
}

type ViewRecommendationItemProps = { recommendation: Recommendation, index: number }
const gtmViewRecommendationItem = ({ recommendation, index }: ViewRecommendationItemProps) => {
  const item = recommendation as any;
  return {
    index,
    item_id: item.mappings.core.sku,
    item_name: item.mappings.core.name,
    item_variant_id: null,
    coupon: null,
    discount: null,
    item_brand: item.mappings.core.brand,
    item_category: item.attributes?.categories?.at(0),
    item_category2: item.attributes?.categories?.at(1),
    item_category3: item.attributes?.categories?.at(2),
    item_category4: item.attributes?.categories?.at(3),
    item_category5: item.attributes?.categories?.at(4),
    item_variant: null,
    price: item.mappings.core.price.toFixed(2),
    retail: item.attributes?.retail_price?.toFixed(2),
    cost: item.attributes?.cost_price?.toFixed(2),
    factor_13: item.attributes?.mfield_product_gpm,
    quantity: 1
  };
};

type ViewSearchItemProps = { result: SearchProductResult, index: number }
const gtmViewSearchItem = ({ result, index }: ViewSearchItemProps) => {
  const item = result as any;
  return {
    index,
    item_id: item.sku,
    item_name: result.name,
    item_variant_id: null,
    coupon: null,
    discount: null,
    item_brand: item.brand,
    item_category: item.categories?.at(0),
    item_category2: item.categories?.at(1),
    item_category3: item.categories?.at(2),
    item_category4: item.categories?.at(3),
    item_category5: item.categories?.at(4),
    item_variant: null,
    price: item.price,
    retail: item.retail_price,
    cost: item.cost,
    factor_13: item.mfield_product_gpm,
    quantity: 1
  };
};

type ViewSuggestionItemProps = { result: SuggestionProduct, index: number }
const gtmViewSuggestionItem = ({ result, index }: ViewSuggestionItemProps) => {
  const item = result as any;
  return {
    index,
    item_id: item.result.sku,
    item_name: item.name,
    item_variant_id: null,
    coupon: null,
    discount: null,
    item_brand: item.result.brand,
    item_category: item.categories?.at(0)?.split('::')?.at(0),
    item_category2: item.categories?.at(1)?.split('::')?.at(0),
    item_category3: item.categories?.at(2)?.split('::')?.at(0),
    item_category4: item.categories?.at(3)?.split('::')?.at(0),
    item_category5: item.categories?.at(4)?.split('::')?.at(0),
    item_variant: null,
    price: item.price,
    retail: item.result.retail_price,
    cost: item.result.cost,
    factor_13: item.result.mfield_product_gpm,
    quantity: 1
  };
};

function sendGTMEvent(payload: any) {
  if (typeof window === 'undefined') return;

  if (window['dataLayer']) {
    window['dataLayer'].push(payload)
  } else {
    console.warn('GA dataLayer does not exist')
  }
}
