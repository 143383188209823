import { Builder, BuilderBlocks } from '@builder.io/react';
import { BuilderBlock } from '@builder.io/react/dist/types/src/components/builder-block.component';
import { ReactNode, useState } from 'react';
import CollapseIcon from "../icons/collapse";
import ExpandIcon from "../icons/expand";
import MinusIcon from "../icons/minus";
import PlusIcon from "../icons/plus";
import './accordion.css';

export interface BuilderSectionProps {
  content?: ReactNode;
  model?: string;
  data?: any;
  attributes: {
    style: any,
    className: string, 
    'builder-id': string,
    id: string
  };
}

export function Accordion(props: {
  builderBlock: BuilderBlock, 
  folds: Array<BuilderSectionProps>, 
  accordionStyle: string,
  attributes: {
    style: any,
    className: string, 
    'builder-id': string,
    id: string
  }
}) {
  const [activeFold, setActiveFold] = useState(0);
  const accordionStyle = props.accordionStyle.toLowerCase();
  const isEditing = Builder.isEditing;
  const editOpen = isEditing && !['hybrid'].includes(accordionStyle);

  const clickHandler = (i: number, allClose: boolean) => {
        if(editOpen) return false;
        if(i === activeFold){ 
            //Do not allow all folds closed on desktop Hybrid
            if(!allClose){
                return;
            }   
            setActiveFold(-1);
        } else {
            setActiveFold(i);
            viewScroll(i, allClose);
        }
    }

  const viewScroll = (i: number, useScroll: boolean)  => {
    if(useScroll) {
        const linkHash = props.attributes['builder-id'] + '-' + i;
        const header = document.querySelector('.header-content');
        const target = document.getElementById(linkHash);
        if(header && target) {
            //Wait for content to open
            setTimeout(() => {
                const offset = (window.scrollY +  target.getBoundingClientRect().top) - header.scrollHeight;
                window.scrollTo(0, offset);
            }, 300);
        }
    }
  }

  const GenNav = ({
        folds,
        style
    }: {
        folds: any;
        style: string;
    }) => {
        if(!['hybrid'].includes(style)){
            return;
        }
        return (            
            <div className={`builder-accordion-nav`}>
                {folds?.map((item: any, index: number) => (
                    <div
                        key={index}
                        className={`builder-fold--title${(activeFold === index ? ' builder-fold--title-active' : '')}`}
                        onClick={() => {
                            clickHandler(index, false);
                        }}
                    >
                        <span>
                            {item.label}
                        </span>
                        <span className='builder-fold--title-icon'>
                            <GenIcon style={style} active={(activeFold === index)}/>
                        </span>
                    </div>
                ))}
            </div>
        )
    };

  const GenIcon =  ({
        style,
        active
    }: {
        style: string;
        active: boolean;
    }) => {
        switch(style) {
            case 'hybrid': 
                if(active) {
                    return <CollapseIcon />;
                } else {
                    return <ExpandIcon />;
                }
            default:
                if(active) {
                    return <MinusIcon />;
                } else {
                    return <PlusIcon />;
                }
        }
    }


  return (
    <div style={props.attributes.style} className={props.attributes.className} builder-id={props.attributes['builder-id']} id={props.attributes.id}>
        <div className={`builder-accordion builder-accordion-${accordionStyle}`}>
            <GenNav folds={props.folds} style={accordionStyle} />
            {props.folds?.map((item: any, index: number) => (
                <div
                    id={props.attributes['builder-id'] + '-' + index}
                    key={index}
                    className={`builder-fold${(activeFold === index || editOpen ? ' builder-fold-active' : '')}`}
                >
                    <div
                        className={`builder-fold--title`}
                        onClick={() => {
                            clickHandler(index, true);
                        }}
                    >
                        <span>
                            {item.label}
                        </span>
                        <span className='builder-fold--title-icon'>
                            <GenIcon style={accordionStyle} active={(activeFold === index)}/>
                        </span>
                    </div>
                    <div className="builder-accordion-content">
                        <BuilderBlocks
                        parentElementId={props.builderBlock.id}
                        dataPath={`component.options.folds.${index}.content`}
                        blocks={props?.folds[index]?.content}
                        />
                    </div>
                </div>
            ))}
        </div>
      </div>
  );
}
