import { ContentCardRecommendations } from '../ProductCard/ProductCard';

import {
  LinkComponentInterface,
  type ContentRecommendation,
  type RecommendationDataProps,
} from 'propel-shared-utility';

import './content-recs.css';

interface ContentTileOptions {
  type: boolean;
  date: boolean;
  description: boolean;
  topics: boolean;
}

export type ContentRecommendationsProps = {
  recommendations?: ContentRecommendation[];
  setRecommendationDataProps: (
    recommendation: ContentRecommendation,
    active: boolean
  ) => RecommendationDataProps;
  LinkComponent: LinkComponentInterface,
  tileOptions: ContentTileOptions,
};

export const ContentRecs = (props: ContentRecommendationsProps) => {
  const {recommendations, setRecommendationDataProps, LinkComponent, tileOptions} =
    props;

  return recommendations && recommendations.length && (
    <div className='content__recs'>
        {recommendations.slice(0, 3).map((rec, index) => {
          return (
            <div className={`content__recs-tile content__recs-tile--${index}`} key={index} >
                 <ContentCardRecommendations
                  product={rec}
                  setRecommendationDataProps={setRecommendationDataProps}
                  LinkComponent={LinkComponent}
                  tileOptions={tileOptions}
                />
            </div>
          );
        })}
    </div>
  ) 
};


