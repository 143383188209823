'use client';

import {
    type AutocompleteDataAdapter,
    type AutocompleteDataFetcher,
    type AutocompleteLinkComponentInterface,
    type SearchBarFormComponentInterface,
    type SuggestionBase,
} from 'propel-shared-utility';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useCreateAutocomplete } from '../hooks/useCreateAutocomplete';
import { WrappedComponentContextProvider } from '../hooks/useWrappedComponentContext';
import { Autocomplete } from './Autocomplete';
import SearchIcon from './icons/search';
import './search.css';

interface AutocompleteData {
  autocomplete: {
    merchandising: {
      redirect: string;
    };
  };
}

export type SearchBarProps<K extends AutocompleteData, T extends SuggestionBase> = {
  fetcher: AutocompleteDataFetcher<K>;
  autoCompleteAdapter: AutocompleteDataAdapter<T, K>;
  FormComponent: SearchBarFormComponentInterface;
  LinkComponent: AutocompleteLinkComponentInterface;
};

export const SearchBar = <K extends AutocompleteData, T extends SuggestionBase>({
  fetcher,
  autoCompleteAdapter,
  FormComponent,
  LinkComponent,
}: SearchBarProps<K, T>) => {
  const formRef = useRef<HTMLDivElement>(null);
  const [redirect, setRedirect] = useState<string | undefined>();

  const { data } = fetcher;

  const {
    handleKeyboardInput,
    updateActiveSuggestion,
    groups,
    query,
    setInputQuery,
    showAutocomplete,
    toggleShowAutocomplete,
  } = useCreateAutocomplete(data, autoCompleteAdapter);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!formRef.current?.contains(e.target as Node)) {
        toggleShowAutocomplete(false);
        return;
      }
    };

    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [toggleShowAutocomplete]);

  useEffect(() => {
    if (data && data?.autocomplete && data.autocomplete.merchandising.redirect) {
      const redirectUrl = data.autocomplete.merchandising.redirect;
      setRedirect(redirectUrl);
    } else {
        setRedirect(undefined);
    }
  }, [data])

  const handleKeyboardInputQuery = (key: string) => {
    if (key === 'Enter') {
      toggleShowAutocomplete(false);
    }
  };

  return (
    <WrappedComponentContextProvider LinkComponent={LinkComponent}>
      <div ref={formRef} className="search-component">
        <Suspense fallback={searchBarLoadingSkeleton}>
          <FormComponent
            onKeyDown={(e: { key: any }) => {
              handleKeyboardInputQuery(e.key);
            }}
          >
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative">
              <input
                type="text"
                id="default-search"
                className=""
                placeholder="Search"
                name="q"
                autoComplete="off"
                value={query}
                data-redirect={redirect}
                onChange={(e) => {
                  setInputQuery(e.target.value);
                  fetcher.load(`${e.target.value}`);
                }}
                onFocus={() => {
                  toggleShowAutocomplete(true);
                }}
                onClick={(e) => {
                  if (e.target instanceof HTMLInputElement) {
                    toggleShowAutocomplete(true);
                    fetcher.load(e.target.value);
                  }
                }}
              />
              <button
                type="submit"
                className="search-icon"
              >
                <SearchIcon />
              </button>
            </div>
            <Autocomplete
              query={query}
              groups={groups}
              toggleShowAutocomplete={toggleShowAutocomplete}
              showAutocomplete={showAutocomplete}
              handleClick={() => { }}
              handleMouseOver={(e, suggestion) =>
                updateActiveSuggestion(suggestion)
              }
            />
          </FormComponent>
        </Suspense>
      </div>
    </WrappedComponentContextProvider>
  );
};

const searchBarLoadingSkeleton =
  <form className="w-full">
    <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div className="relative">
      <input type="text" placeholder="Search" />
      <button className="search-icon">
        <SearchIcon />
      </button>
    </div>
  </form>
