import { Component as ComponentOptions } from '@builder.io/sdk';
import { timerSettingsInput } from '../hooks/timer-settings';

export const cqlSectionOptions: any = {
  name: 'Core:Section',
  friendlyName: 'Section',
  override: true,
  static: true,
  image: 'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F682efef23ace49afac61748dd305c70a',
  inputs: [
    {
      name: 'maxWidth',
      type: 'number',
      defaultValue: 1440,
    },
    {
      name: 'lazyLoad',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      description: 'Only render this section when in view',
    },
    ...timerSettingsInput
  ],
  defaults: {
    responsiveStyles: {
        large: {
            paddingLeft: '60px',
            paddingRight: '60px',
            paddingTop: '80px',
            paddingBottom: '80px',
            marginTop: '0px',
            width: '100vw',
            marginLeft: 'calc(50% - 50vw)',
        },
        medium: {
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingTop: '60px',
            paddingBottom: '60px',
        },
        small: {
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '40px',
            paddingBottom: '40px',
        },
    },
  },
  canHaveChildren: true,
} as ComponentOptions;
