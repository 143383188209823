'use client';

import { Builder, BuilderBlocks, useIsPreviewing } from '@builder.io/react';
import Link from 'next/link';
import { memo, useState } from 'react';
import { useHoverIntent } from 'react-use-hoverintent';
import './mega-nav.css';

const genContent = ({
    id,
    path,
    content,
    height,
    color,
    isEditing
}: {
    id: string;
    path: string;
    content: any;
    height: number;
    color: string;
    isEditing: boolean;
}) => {
    if ((!content || content.length === 0) && isEditing) return;
    return (
        <section
            className={
                `mega-nav-content`
            }
        >
            <div
                className={
                    `mega-nav-content-container`
                }
                style={{
                    minHeight: `${height}px`,
                    backgroundColor: `${color || ''}`
                }}
            >
                <BuilderBlocks
                    parentElementId={id}
                    dataPath={path}
                    blocks={content}
                />
            </div>
        </section>
    )
};
const GenContent = memo(genContent, (prevProps: any, nextProps: any) => {
    return prevProps.id === nextProps.id &&
        prevProps.path === nextProps.path &&
        prevProps.height === nextProps.height &&
        prevProps.color === nextProps.color &&
        !nextProps.isEditing;
});

const GenNavItem = ({
    item,
    index,
    click,
    isPreviewing
}: {
    item: any;
    index: number;
    click: any;
    isPreviewing: boolean;
}) => {
    if (!isPreviewing && item.link) {
        return <Link href={item.link} style={{ color: (item?.textColorOverride ? item?.textColorOverride : '') }}>{item.label}</Link>
    } else {
        return (
            <span
                onClick={() => click(index)}
                style={{ color: (item?.textColorOverride ? item?.textColorOverride : '') }}
            >
                {item.label}
            </span>
        )
    }
}

export function MegaNav(props: { builderBlock: any, nav: Array<object>, sensitivity: number, interval: number, timeout: number, attributes: { style: any, className: string, 'builder-id': string, id: string } }) {
    const hoverSettings = {
        sensitivity: props.sensitivity,
        interval: props.interval,
        timeout: props.timeout
    }

    const [activeTab, setActiveTab] = useState(-1);
    const [isActive, intentRef, setIsActive] = useHoverIntent(hoverSettings);
    const [popoverHeight, setPopoverHeight] = useState<number>(0);
    const isPreviewing = useIsPreviewing();
    const isEditing = Builder.isEditing;

    const mouseOverHandler = (i: number) => {
        if (!isPreviewing) {
            const tempItem = document.querySelector(`.${props.builderBlock.id} .main-nav-component li.active`);
            const tempContent = tempItem?.querySelector('.mega-nav-content-container');
            if (activeTab > -1) {
                setPopoverHeight(tempContent?.clientHeight || 0);
                tempItem?.classList.add("active-previous");
            }
            setIsActive(true);
            setActiveTab(i);
            setPopoverHeight(0);
            tempItem?.classList.remove("active-previous");
        }
    };

    const mouseOutHandler = () => {
        if (!isPreviewing) {
            setIsActive(false);
            setActiveTab(-1);
            //setPopoverHeight(0);
        }
    }

    const clickHandler = (i: number) => {
        if (i === activeTab) {
            setActiveTab(-1);
            setIsActive(false);
        } else {
            setActiveTab(i);
            setIsActive(true);
        }
    }

    return (
        <div style={props.attributes.style} className={props.attributes.className} builder-id={props.attributes['builder-id']} id={props.attributes.id}>
            <nav
                className={
                    `main-nav-component ${isActive ? "main-nav-component-active" : ""}`
                }
                onMouseOut={mouseOutHandler}
                ref={intentRef}
            >
                <ul>
                    {props.nav?.map((item: any, index: number) => (
                        <li
                            key={index}
                            onMouseOver={() => mouseOverHandler(index)}
                            className={
                                `${activeTab === index ? "active" : ""}`
                            }
                        >
                            <GenNavItem item={item} index={index} click={clickHandler} isPreviewing={isPreviewing} />
                            <GenContent id={props.builderBlock.id} path={`component.options.nav.${index}.content`} content={item?.content} height={popoverHeight} color={item?.contentBackgroundColor} isEditing={isEditing} />
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}
