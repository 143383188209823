'use client';

import { Dialog, Transition } from '@headlessui/react';
import * as gtm from 'components/analytics/gtm';
import useCart from 'components/hooks/use-cart';
import CartIcon from 'components/icons/cart';
import CloseIcon from 'components/icons/close';
import Price from 'components/price';
import { createMapping } from 'components/product/mulberry-actions';
import { useStorefront, useStorefrontDispatch } from 'components/providers/storefront-provider';
// PATB-870 import { Recommendations } from 'components/searchspring/Recs/Recs';
import { Mapping } from 'lib/bigcommerce/types/mulberry';
import { NAVIDIUM_SKU } from 'lib/constants';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { Fragment, useEffect, useState } from 'react';
import { removeItem } from './actions';
import { CustomCartItems, GcCartItems, ProductCartItems } from './cart-items';
import { ShippingBanner } from './shipping-banner';

import './cart-modal.css';
import './mini-cart.css';

export default function MiniCart() {
  const { cart, mutate } = useCart();
  const { miniCartOpen } = useStorefront();
  const router = useRouter();
  const dispatch = useStorefrontDispatch();
  const [mapping, setMapping] = useState<Mapping[]>([]);

  // Clear Navidium
  useEffect(() => {
    const updateCart = async () => {
      if (cart && cart.lines) {
        const navidiumLine = cart.lines.find((line) => line.sku === NAVIDIUM_SKU);

        if (navidiumLine) {
          cart.lines = cart.lines.filter((line) => line.sku !== NAVIDIUM_SKU);
          cart.totalQuantity -= 1;
          const updatedCart = await removeItem(cart.id, navidiumLine.id);
          if (updatedCart) {
            mutate(updatedCart, false);
          }
        }
      }
    };
    updateCart();
  }, [cart, mutate]);

  useEffect(() => {
    if (miniCartOpen && cart) {
      gtm.viewCart({ cart });
    }
  }, [miniCartOpen, cart]);

  useEffect(() => {
    if (cart) {
      const newMapping = createMapping(cart);
      setMapping(newMapping);
    }
  }, [cart]);

  // Mini cart open state
  const openCart = () => {
    if (cart) {
      dispatch({ type: 'OPEN_MINI_CART' });
    } else {
      router.push('/cart');
    }
  };
  const closeCart = () => {
    dispatch({ type: 'CLOSE_MINI_CART' });
  };

  return (
    <>
      <button aria-label="Open cart" onClick={openCart}>
        <div
          className={`mini-cart-count ${
            cart?.totalQuantity === 0 || cart?.totalQuantity === undefined ? '!hidden' : ''
          }`}
        >
          <span>{cart?.totalQuantity}</span>
        </div>
        <CartIcon />
      </button>
      <Transition show={miniCartOpen}>
        <Dialog onClose={closeCart} className="mini-cart">
          <Transition.Child
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="opacity-0 backdrop-blur-none"
            enterTo="opacity-100 backdrop-blur-[.5px]"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="opacity-100 backdrop-blur-[.5px]"
            leaveTo="opacity-0 backdrop-blur-none"
          >
            <div
              className="mini-cart-backdrop fixed inset-0 z-[100] bg-black/60"
              aria-hidden="true"
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="mini-cart-drawer backdrop-blur-xl">
              <div className="cart-modal-header">
                <h2 className="cart-modal-header-heading">Cart</h2>

                <button
                  aria-label="Close cart"
                  className="cart-modal-close-button"
                  onClick={closeCart}
                >
                  <CloseIcon />
                </button>
              </div>

              {cart && cart.lines.length > 0 && <ShippingBanner />}

              {!cart || cart.lines.length === 0 ? (
                <div className="mini-cart-empty">
                  <p>
                    Your Cart is currently empty... but don&rsquo;t worry, we have plenty of
                    products to help you achieve ultimate preparedness &amp; peace of mind.
                  </p>
                </div>
              ) : (
                <div className="mini-cart-line-items-container">
                  <div className="mini-cart-line-items">
                    <ul>
                      {cart?.lines?.map((item, i) => {
                        if (item?.type === 'giftCertificate') {
                          return <GcCartItems key={i} item={item} />;
                        } else if (item?.type === 'custom') {
                          return <CustomCartItems key={i} item={item} />;
                        } else {
                          const mappingEntry = mapping.find((map) => map.productId === item.id);
                          const customItem = mappingEntry
                            ? cart?.lines.find((line) => line.id === mappingEntry.customItemId)
                            : undefined;
                          return (
                            <ProductCartItems
                              cartId={cart.id}
                              key={i}
                              item={item}
                              fromMiniCart={true}
                              index={i}
                              customItem={customItem}
                              context={'minicart'}
                            />
                          );
                        }
                      })}
                    </ul>
                    {/* --- PATB-870: Comment out until this returns something
                    <div className="mini-cart-recommended">
                      <h2 className="mini-cart-recommended-title">Recommended For You</h2>
                      <Recommendations
                        tilePromo={false}
                        tileBurst={false}
                        tileBanner={false}
                        tileTagline={false}
                        tileSpecs={false}
                        tileRatings={false}
                        tileATC={true}
                        profile={'mini-cart-profile'}
                        showNav={true}
                        dtSwipe={false}
                        navTheme={'dark'}
                        desktopSettings={{
                          count: 1.2,
                          per: 1
                        }}
                        tabletSettings={{
                          count: 1.2,
                          per: 1
                        }}
                        mobileSettings={{
                          count: 1.2,
                          per: 1
                        }}
                        builderBlock={{
                          id: 'mini-cart-recommended'
                        }}
                        speed={500}
                        autoPlay={false}
                        initialSlide={1}
                      />
                    </div>
                     --- */}
                  </div>

                  <div className="mini-cart-totals">
                    <div className="mini-cart-totals-subtotal">
                      <strong>Subtotal</strong>
                      <div>
                        <span>{cart.totalQuantity} Items</span>
                        &nbsp;|&nbsp;
                        <span>
                          {
                            <Price
                              amount={cart.cost.subtotalAmount.amount}
                              currencyCode={cart.cost.subtotalAmount.currencyCode}
                            />
                          }
                        </span>
                      </div>
                    </div>
                    <div className="mini-cart-totals-actions">
                      <a href={cart.checkoutUrl} onClick={closeCart} className="cta-button">
                        Check Out Now
                      </a>
                      <Link
                        href="/cart"
                        onClick={closeCart}
                        className="cta-button cta-button-secondary-inverted"
                      >
                        View Cart
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}
