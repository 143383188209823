import {
  type SearchResponseModel,
} from 'int-searchspring-api';

import { SearchspringAPIService } from 'int-searchspring-api/src/generated/search';
import { GenericAPIResult } from 'int-searchspring-api/src/generated/search/core/ApiResult';
import { toSearchState } from 'int-searchspring-api/src/lib/clientState';
import { createOpenAPIConfigurations } from 'int-searchspring-api/src/lib/stateMutations';
import { RecommendationsResult, SearchspringState } from 'int-searchspring-api/src/types';

export class SearchspringService {
  private searchspringClient: SearchspringAPIService;
  #state: SearchspringState;

  constructor(state: SearchspringState) {
    const { openAPIConfig, beaconConfig } = createOpenAPIConfigurations(state.siteId);
    this.#state = state;
    this.searchspringClient = new SearchspringAPIService(openAPIConfig, beaconConfig);

  }

  public async searchGET(
    state: SearchspringState
  ): Promise<GenericAPIResult<SearchResponseModel | undefined>> {
        const toState = toSearchState(this.#state, '', this.#state.resultsPerPage, this.#state.domain)
        const {
      userId,
      sessionId,
      pageLoadId,
      lastViewed,
      cart,
      shopper,
      httpXForwardedFor,
      domain,
      q,
      filter,
      bgfilter,
      sort,
      resultsPerPage,
      page,
      landingPage,
      includedFacets,
      excludedFacets,
      disableInlineBanners,
      siteId,
      tag,
    } = toState;


    let result: GenericAPIResult<SearchResponseModel | undefined> = {
      body: undefined,
      ok: false,
    };

    try {
      result = await this.searchspringClient.getSearch(
        siteId,
        userId,// userId as string,
        sessionId, // sessionId as string,
        pageLoadId.identifier,
        domain,
        httpXForwardedFor,
        'json',
        q || '',
        filter,
        bgfilter,
        sort,
        resultsPerPage,
        page,
        'minimal',
        landingPage,
        tag,
        includedFacets,
        excludedFacets,
        disableInlineBanners,
        lastViewed,
        cart,
        shopper
      );
    
      
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  public async recommendationsGET(
    state: SearchspringState,
  ): Promise<GenericAPIResult<RecommendationsResult[] | undefined>> {
    const {
      siteId,
      profile,
      userId,
      productId_PDP,
      categories,
      brands,
      shopper,
      cart,
      lastViewed,
      limits,
    } = state;

      const charCount =
        (shopper?.length || 0) +
        (cart?.length || 0) +
        (lastViewed?.length || 0) +
        siteId.length;

      if (charCount > 2048) {
        const result = await this.searchspringClient.postPreflight(
          siteId,
          userId,
          shopper,
          cart,
          lastViewed,
        );
  
      } else {
        const result = await this.searchspringClient.getPreflight(
          siteId,
          userId,
          shopper,
          cart,
          lastViewed,
        );
      }
    
    let result: GenericAPIResult<RecommendationsResult[] | undefined> = {
      body: undefined,
      ok: false,
    };
    try {
      result = await this.searchspringClient.getRecommendations(
        siteId,
        profile,
        productId_PDP,
        categories,
        brands,
        shopper,
        cart,
        lastViewed,
        limits,
      );
    } catch (e) {
      console.error(e);
    }

    return result;
  }
}


