'use client';

import { Wishlist } from 'lib/bigcommerce/types/wishlist';
import { ResultJSON } from 'lib/result';
import { useEffect, useState } from 'react';
import useSWR, { Fetcher } from 'swr';

const fetcher: Fetcher<ResultJSON<Wishlist>, string> = (path) => fetch(path).then((res) => res.json());
export default function useUser() {
  const { data, mutate, error, isLoading } = useSWR("/api/user/wishlist", fetcher);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

  const loading = initialized ? isLoading : true;

  if (data?.isFailure) {
    console.log(data);
  }
  if (error) {
    console.log(error);
  }

  let wishlist;
  if (!loading) {
    wishlist = data?.value;
  }

  return {
    loading,
    wishlist,
    mutate
  };
}
