import { Builder } from '@builder.io/react';
import { Fragment, useEffect, useState } from 'react';
import './snap-chat.css';

const scrollThreshold = 300;
const label = 'SnapEngage Chat';

export function Chat(props: {
    buttonPosition: string,
    attributes: {style: any, className: string, 'builder-id': string, id: string}
  }) {
    const [show, setShow] = useState(false);
    const position = props.buttonPosition.toLowerCase();

    useEffect(() => {
      if (typeof window !== 'undefined') {
        if (!Builder.isEditing) {
          if (window.innerWidth >= 992) {
            window.addEventListener('scroll', () => {
              if (window.innerWidth < 992 || window.scrollY > scrollThreshold) {
                setShow(true);
              } else {
                setShow(false);
              }
            });
          } else {
            setShow(true);
          }
        } else {
          setShow(true);
        }
      }
    },[]);
  
    // TODO Setup opening of chat when we get script from the 4pats
    // const handleClick = () => {
      
    // };
  
    return (
      <Fragment>
        {
          (show) ? (
              <div style={props.attributes.style} className={props.attributes.className} builder-id={props.attributes['builder-id']} id={props.attributes.id}>
                  <button 
                      className={`snap-chat snap-chat-position-${position}`}
                      aria-label={label}
                      //onClick={handleClick}
                  >
                     <svg width="22" height="auto" viewBox="16 9 25 24.0907" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="Group 513">
                              <path id="Vector" d="M27.9657 26.8352H19.7995C17.9848 26.8352 16.5 25.341 16.5 23.5147V13.3206C16.5 11.4943 17.9848 10 19.7995 10H36.7259C38.5406 10 40.0254 11.4943 40.0254 13.3206V23.5147C40.0254 25.341 38.5406 26.8352 36.7259 26.8352H33.2119L28.6091 34.0907" stroke="#1E3665" strokeWidth="1.5" strokeMiterlimit="10"/>
                              <path id="Vector_2" d="M21.8618 15.7446H34.6638" stroke="#1E3665" strokeWidth="1.5" strokeMiterlimit="10"/>
                              <path id="Vector_3" d="M21.8618 20.5596H32.0737" stroke="#1E3665" strokeWidth="1.5" strokeMiterlimit="10"/>
                          </g>
                      </svg>
                      <span>CHAT</span>
                  </button>
              </div>
          ) : (<Fragment/>)
        }
      </Fragment>
    );
  }
