import { init, tag } from '@blotoutio/edgetag-sdk-js';
import { SearchProductResult } from '../../interfaces';

type ViewSearchItemListProps = { results: (SearchProductResult)[], searchTerm?: string };
export const viewSearchItemList = ({ results, searchTerm }: ViewSearchItemListProps) => {
    if (typeof window === 'undefined') return {};
    const edgeUrl = process.env.NEXT_PUBLIC_BLOTOUT_URL;

    init({
      edgeURL: edgeUrl ??  '',
      disableConsentCheck: true
    });
    tag('Search', {
        search: searchTerm,
        contents: results.map((result) => blotoutViewSearchItem({ result } )),
        currency: 'USD',
        value: 0
    });
}

type ViewSearchItemProps = { result: SearchProductResult }
const blotoutViewSearchItem = ({ result }: ViewSearchItemProps) => {
  const item = result;
  return {
    id: item.id,
    title: result.name,
    item_price: item.price,
    quantity: 1,
    image: item.imageUrl,
    url: item.url
  };
};