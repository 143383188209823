'use client';

import Price from 'components/price';
import { useProductOptions } from 'components/providers/product-options-provider';
import { withSuspense } from 'components/with-suspense';
import { Product, ProductVariant } from 'lib/bigcommerce/types';
import { getSelectedVariant } from './helpers';

import dynamic from 'next/dynamic';
import './price.css';

const KlarnaMessaging = (dynamic(() => import('./klarna-product').then((m) => m.KlarnaMessaging), {
  ssr: false
}));

function PriceComponent({
  hidePromo,
  product
}: {
  hidePromo?: boolean;
  product: Product
}) {
  const {optionSearchParams} = useProductOptions();
  const variant = getSelectedVariant(product, optionSearchParams);
  if (variant) {
    return (
      <VariantPriceComponent hidePromo={hidePromo} product={product} variant={variant} />
    );
  }
  return <ProductPriceComponent hidePromo={hidePromo} product={product} />
}

const ProductPriceSkeleton = (
  <div className="product-price-container">
    <span className="product-price product-selected-price animate-pulse">&nbsp;</span>
  </div>
);

export const ProductPrice = withSuspense(PriceComponent, ProductPriceSkeleton);


function ProductPriceComponent({
  hidePromo,
  product
}: {
  hidePromo?: boolean;
  product: Product
}) {
  const isOnSale = product.salePrice && Number(product.salePrice.amount) > 0;
  const promotionText = !hidePromo && product.productMetafields.find((m) => m.key === 'promotion-text');
  return (
    <div className="product-price-container">
      {isOnSale &&
        <div className="product-promotion">
          <div className="product-promotion-prices">
            <Price
              amount={product.price.amount}
              currencyCode={product.price.currencyCode}
              className="product-price product-sale-price product-selected-price"
            />
            <span className="product-price product-base-price">
              was&nbsp;
              <s>
                <Price
                  amount={product.basePrice.amount}
                  currencyCode={product.basePrice.currencyCode}
                />
              </s>
            </span>
          </div>
          {promotionText &&
            <div
              className="product-promotion-message"
              dangerouslySetInnerHTML={{ __html: promotionText.value }}
            />
          }
        </div>
      }

      {!isOnSale &&
        <>
          <Price
            amount={product.priceRange.maxVariantPrice.amount}
            currencyCode={product.priceRange.maxVariantPrice.currencyCode}
            className="product-price product-selected-price"
          />
          {promotionText &&
            <div
              className="product-promotion-message"
              dangerouslySetInnerHTML={{ __html: promotionText.value }}
            />
          }
        </>
      }
    </div>
  );
}

function VariantPriceComponent({
  hidePromo,
  product,
  variant
}: {
  hidePromo?: boolean;
  product: Product;
  variant: ProductVariant;
}) {
  const isOnSale = variant.salePrice && Number(variant.salePrice.amount) > 0;
  const disableKlarna = variant.paymentMetafields.some((m) => m.key === 'disable-klarna' && m.value === 'true');
  const hasPaymentPlanOrSubscription = variant.paymentMetafields.some((m) => m.key === 'payment-type' && (m.value === 'payment-plan' || m.value === 'subscription'));
  const showKlarna = !hidePromo && !disableKlarna && !hasPaymentPlanOrSubscription;
  const promotionText = !hidePromo && product.productMetafields.find((m) => m.key === 'promotion-text');
  return (
    <div className="product-price-container">
      {isOnSale &&
        <div className="product-promotion">
          <div className="product-promotion-prices">
            <Price
              amount={variant.price.amount}
              currencyCode={variant.price.currencyCode}
              className="product-price product-sale-price product-selected-price"
            />
            <span className="product-price product-base-price">
              was&nbsp;
              <s>
                <Price
                  amount={variant.basePrice.amount}
                  currencyCode={variant.basePrice.currencyCode}
                />
              </s>
            </span>
          </div>
          {promotionText &&
            <div
              className="product-promotion-message"
              dangerouslySetInnerHTML={{ __html: promotionText.value }}
            />
          }
        </div>
      }

      {!isOnSale &&
        <>
          <Price
            amount={variant.price.amount}
            currencyCode={variant.price.currencyCode}
            className="product-price product-selected-price"
          />
          {promotionText &&
            <div
              className="product-promotion-message"
              dangerouslySetInnerHTML={{ __html: promotionText.value }}
            />
          }
        </>
      }
      {showKlarna && <KlarnaMessaging variant={variant} />}
    </div>
  );
}