export default function MulberryLogo() {
    return (
        <svg width="35" height="43" viewBox="0 0 35 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35 17.8061C35 34.0773 20.4151 40.8947 17.5 42.1094C14.5849 40.8947 0 34.0585 0 17.8061V7.75897C3.07294 6.86443 6.09019 5.75332 9.01459 4.43504C11.939 3.15444 14.7706 1.66667 17.5 0C20.2294 1.67609 23.061 3.15444 25.9854 4.43504C28.9098 5.75332 31.9178 6.86443 35 7.75897V17.8061Z" fill="url(#paint0_linear_8552_126637)"/>
            <path d="M17.5 42.1094C17.5 42.1094 0 35.6498 0 17.8061V7.75897C3.07294 6.86443 6.09019 5.75332 9.01459 4.43504C11.939 3.15444 17.5 0 17.5 0V42.1094Z" fill="#3A306B"/>
            <path d="M32.8594 18.0592C32.8594 32.3899 20.5179 38.6076 17.5 39.9244C14.4825 38.607 2.14062 32.3731 2.14062 18.0592V9.1672C4.82358 8.35492 7.45862 7.35854 10.0161 6.18569C12.5929 5.03745 15.0898 3.71055 17.4993 2.22886C19.9103 3.71876 22.4077 5.03771 24.9839 6.18569C27.5413 7.35852 30.169 8.35505 32.8594 9.1675V18.0592Z" stroke="white" strokeOpacity="0.4"/>
            <path d="M25.1592 12.8801H20.7772C20.7215 12.8801 20.6658 12.899 20.6194 12.9366L17.4165 15.592L14.2043 12.9366C14.1579 12.899 14.1022 12.8801 14.0465 12.8801H9.66449C9.5995 12.8801 9.53451 12.9084 9.47881 12.9555C9.43239 13.0025 9.40454 13.0685 9.40454 13.1438V26.7785C9.40454 26.8444 9.43239 26.9197 9.47881 26.9668C9.52523 27.0139 9.59022 27.0421 9.66449 27.0421H13.8701C14.0093 27.0421 14.1207 26.9197 14.1207 26.7785V18.3039L17.4072 21.2794L20.6844 18.3133V26.7785C20.6844 26.9291 20.7958 27.0421 20.935 27.0421H25.1499C25.2892 27.0421 25.4006 26.9197 25.4006 26.7785V13.1438C25.4006 13.0025 25.2892 12.8895 25.1499 12.8801H25.1592ZM20.6937 18.3039L20.6751 18.285L20.7029 18.3039H20.6937Z" fill="#F6F4F3"/>
            <defs>
            <linearGradient id="paint0_linear_8552_126637" x1="-30.0053" y1="45.9135" x2="35.7296" y2="11.4498" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CC499E"/>
                <stop offset="1" stopColor="#483B85"/>
            </linearGradient>
            </defs>
      </svg>
    );
  }


