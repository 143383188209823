'use client';

import { withSuspense } from 'components/with-suspense';
import { createUrl } from 'lib/utils';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ProductGrid } from 'propel-react-components';
import { ProductGridProps } from 'propel-react-components/src/components/ProductGrid/ProductGrid';
import { AddToCartComponentInterface, LinkComponentInterface } from 'propel-shared-utility';
import { AddToCartCTA } from '../add-to-cart-cta';
import { withSearchspringSearch } from '../withSearchspringSearch';

const AddToCartComponent: AddToCartComponentInterface = (props) => {
    return (
        <AddToCartCTA itemListId={"search_results"} itemListName={"Search Results"} {...props} />
    );
};

const LinkComponent: LinkComponentInterface = ({ href, className, children, onMouseDown }) => {
    const pathname = usePathname();
    const url = href ? createUrl(href, new URLSearchParams({
        from: pathname || ''
    })) : '#';
    return (
        <Link href={url} passHref legacyBehavior>
            <a className={className} onMouseDown={onMouseDown}>
            {children}
            </a>
        </Link>
    );
  };

const ProductGridComponent: React.FC<ProductGridProps> = (props) => {
    const productGridProps = {
        ...props,
        AddToCartComponent: AddToCartComponent,
        LinkComponent: LinkComponent
    }
    return (
        <ProductGrid {...productGridProps} />
    );
};

const ProductGridSkeleton = (
    <div>
        <div className="flex">
            <div className="grid gap-y-2.5 lg:gap-y-6 sm:basis-full sm:w-full sm:w-full sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
                <div className="tile__item-grid border lg:m-2 rounded-md h-[26rem] animate-pulse bg-neutral-400 dark:bg-neutral-700"></div>
                <div className="tile__item-grid border lg:m-2 rounded-md h-[26rem] animate-pulse bg-neutral-400 dark:bg-neutral-700"></div>
                <div className="tile__item-grid border lg:m-2 rounded-md h-[26rem] animate-pulse bg-neutral-400 dark:bg-neutral-700"></div>
            </div>
        </div>
    </div>
);
export const ProductGridWrap = withSuspense(withSearchspringSearch<ProductGridProps>(ProductGridComponent), ProductGridSkeleton);
