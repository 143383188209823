'use client'

import { BuilderStore } from '@builder.io/react';
import ArrowLeft from 'components/icons/arrow-left';
import { Category, Product } from 'lib/bigcommerce/types';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { withSuspense } from 'components/with-suspense';
import { useSearchParams } from 'next/navigation';
import './breadcrumbs.css';

function BreadcrumbsComponent(props: { product?: Product, builderState: BuilderStore }) {
  const product = props.product ?? props.builderState.state.product as Product;

  const mobile = useMediaQuery({ query: '(max-width: 990px)' });
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(mobile);
  }, [mobile]);

  const categories = product?.categories;

  const searchParams = useSearchParams();
  const referrer = searchParams?.get('from');
  const path = referrer ?? '';
  const foundCat = categories.find((cat: Category) => pathsMatchExactly(cat.path, path));
  const notFoundCat = categories.at(0);

  const renderLastBreadcrumb = (breadcrumbs: any[]) => {
    if (breadcrumbs.length === 0) return null;
    const lastItem = breadcrumbs[breadcrumbs.length - 1];
    return (
      <li className="breadcrumb">
        <Link href={lastItem.path ?? '#'}>
          <ArrowLeft />
          {lastItem.name}
        </Link>
      </li>
    );
  };

  const createPdpBreadcrumbsJsonLd = (foundCat?: Category) => {
    const startingJson =  {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
          {
              "@type": "ListItem",
              position: 1,
              item: {
                  "@id": process.env.NEXT_PUBLIC_DOMAIN_NAME,
                  name: "home",
              }
          }
      ]
    }
    const pdpBreadcrumbsJsonLd = startingJson;
    if (foundCat) {
      pdpBreadcrumbsJsonLd.itemListElement = startingJson.itemListElement.concat(foundCat.breadcrumbs.map((item: any, index: number) => {
        return {
          "@type": "ListItem",
          position: index + 2,
          item: {
            "@id": process.env.NEXT_PUBLIC_DOMAIN_NAME + item.path,
            name: item.name
          }
        };
      }));
    }

    return pdpBreadcrumbsJsonLd;
  }

  return (
    <>
      <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
          __html: JSON.stringify(createPdpBreadcrumbsJsonLd(foundCat))
          }}
      />
      <div className="breadcrumbs">
        <ul className="breadcrumbs-list">
          {
            foundCat ? (
              !isMobile ? (
                <>
                  <li key="home" className="breadcrumb">
                    <Link href={'/'}>
                      Home /
                    </Link>
                  </li>
                  {foundCat.breadcrumbs.map((item: any, index: number) => (
                    <li key={index} className="breadcrumb">
                      <Link href={item.path ?? '#'}>
                        {item.name}
                        {index !== foundCat.breadcrumbs.length - 1 ? " / " : ""}
                      </Link>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  {renderLastBreadcrumb(foundCat.breadcrumbs)}
                </>
              )
            ) : notFoundCat ? (
              !isMobile ? (
                <>
                  <li key="home" className="breadcrumb">
                    <Link href={'/'}>
                      Home /
                    </Link>
                  </li>
                  {notFoundCat.breadcrumbs.map((item: any, index: number) => (
                    <li key={index} className="breadcrumb">
                      <Link href={item.path ?? '#'}>
                        {item.name}
                        {index !== notFoundCat.breadcrumbs.length - 1 ? " / " : ""}
                      </Link>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  {renderLastBreadcrumb(notFoundCat.breadcrumbs)}
                </>
              )
            ) : null
          }
        </ul>
      </div>
    </>
  );
}

function pathsMatchExactly(path1?: string, path2?: string) {
  const normalizedPath1 = path1?.endsWith('/') ? path1.slice(0, -1) : path1;
  const normalizedPath2 = path2?.endsWith('/') ? path2.slice(0, -1) : path2;

  return normalizedPath1 === normalizedPath2;
}

const BreadcrumbLoadingSkeleton = (
  <div className="breadcrumbs">
    <ul className="breadcrumbs-list">
      <li key="home" className="breadcrumb animate-pulse">
        <Link href={'/'}>
          Home
        </Link>
      </li>
    </ul>
  </div>
);

export const Breadcrumbs = withSuspense(BreadcrumbsComponent, BreadcrumbLoadingSkeleton);
