'use client';

import { builder } from '@builder.io/react';
import { withSuspense } from 'components/with-suspense';
import Link from 'next/link';
import { MegaMenuRecs } from 'propel-react-components';
import { LinkComponentInterface } from 'propel-shared-utility';
import getMegamenuRecsStrategy from './megaMenuRecsStrategy';

const LinkComponent: LinkComponentInterface = ({ href, className, children }) => {
    return (
        <Link href={href ?? '#'} className={className}>
            {children}
        </Link>
    );
};

const MegaMenuRecsSkeleton = (
    <div className="megamenu__recs">
        <div className="megamenu__recs-tile megamenu__recs-tile--4 animate-pulse"></div>
        <div className="megamenu__recs-tile megamenu__recs-tile--4 animate-pulse"></div>
        <div className="megamenu__recs-tile megamenu__recs-tile--4 animate-pulse"></div>
        <div className="megamenu__recs-tile megamenu__recs-tile--4 animate-pulse"></div>
    </div>
);

export const MegaMenuRecsWrap = withSuspense(getMegamenuRecsStrategy(MegaMenuRecs, builder.editingMode, LinkComponent), MegaMenuRecsSkeleton)
