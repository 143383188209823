'use client';

import { withSuspense } from 'components/with-suspense';
import { Image, VercelProduct as Product } from 'lib/bigcommerce/types';
import { Gallery } from './gallery';

export function MediaCarouselComponent({ product }: { product: Product }) {
  return (
    <Gallery
      images={product.images.map((image: Image) => ({
        src: image.url,
        altText: image.altText
      }))}
      product={product}
    />
  );
}

const MediaCarouselSkeleton = (
  <div className="media-carousel">
  <div className="flex lg:flex-row justify-center lg:align-center">
  
    <div className={'relative flex flex-col justify-center lg:justify-start w-full lg:w-full max-w-[762px] max-h-[762px] h-full'}>
      <div className={'w-full h-full aspect-square border border-[#E4E4E4] mb-[0.625rem] overflow-hidden'}>
      </div>

    </div>
  </div>
</div>
);

export const MediaCarousel = withSuspense(MediaCarouselComponent, MediaCarouselSkeleton);