import { VercelProduct as Product } from 'lib/bigcommerce/types';
import './title.css';

export function ProductTitle({ product }: { product: Product }) {
  return (
    <>
      <h1 className="product-title">{product.title}</h1>
    </>
  );
}
