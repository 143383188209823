// simplified/modified code from searchspring snap
// src: https://github.com/searchspring/snap/blob/main/packages/snap-url-manager/src/Translators/QueryString/QueryStringTranslator.ts

import deepmerge from 'deepmerge';
import Immutable from 'seamless-immutable';

export enum RangeValueProperties {
	LOW = 'low',
	HIGH = 'high',
}


export type UrlStateRangeValue = {
low?: any;
high?: any;
};

type RangeType = keyof UrlStateRangeValue;
  

import type { ImmutableObject } from 'seamless-immutable';

import type { UrlState, TranslatorConfig, UrlStateFilterType  } from './types';

export interface Translator {
	deserialize(url: string): any; // fix me later
  }

type QueryParameter = {
	key: Array<string>;
	value: string;
};

export type QueryStringTranslatorConfig = Partial<QueryStringTranslatorConfigFull>;

type QueryStringTranslatorConfigFull = TranslatorConfig & {
	urlRoot: string;
	queryParameter: string;
};

const defaultConfig: QueryStringTranslatorConfigFull = {
	urlRoot: '',
	queryParameter: 'q',
	settings: {
		serializeUrlRoot: true,
	},
};

export class QueryStringTranslator {
	protected config: ImmutableObject<QueryStringTranslatorConfigFull>;

	constructor(config: TranslatorConfig = {}) {
		this.config = Immutable(deepmerge(defaultConfig, config) as QueryStringTranslatorConfigFull);
	}

	getCurrentUrl(): string {
		return location.search || '';
	}

	getConfig(): QueryStringTranslatorConfigFull {
		return this.config.asMutable();
	}

	protected parseQueryString(queryString: string): Array<QueryParameter> {
		const justQueryString = queryString.split('?').pop() || '';
	
		return justQueryString
			.split('&')
			.filter((v) => v)
			.map((kvPair) => {
				const [rawKey, value] = kvPair.split('=').map((v) => decodeURIComponent(v.replace(/\+/g, ' ')));
				const key = rawKey ? rawKey.split('.') : [];
				return { key, value: value || '' };
			});
	}
	
	protected parsePage(queryParams: Array<QueryParameter>): UrlState {
		const pageParam = queryParams.find((param) => param.key.length == 1 && param.key[0] == 'page');

		if (!pageParam) {
			return {};
		}

		const page = Number(pageParam.value);

		return !isNaN(page) && page > 1 ? { page } : {};
	}

	protected parseSort(queryParams: Array<QueryParameter>): UrlState {
		const sortParams = queryParams.filter((param) => param.key.length === 2 && param.key[0] === 'sort');
	
		if (!sortParams.length) {
			return {};
		}
	
		const result: UrlState = {
			sort: sortParams.reduce((acc, param) => {
				const key = param.key[1];
				if (key) {
					acc[key] = [param.value];
				}
				return acc;
			}, {} as Record<string, string[]>),
		};
	
		return result;
	}
	
	protected parseOther(queryParams: Array<QueryParameter>, except: Array<string> = []): UrlState {
		const state: UrlState = {};
	
		for (const param of queryParams) {
			const key0: string | undefined = param.key[0];
			if (key0 !== undefined && param.value !== undefined && except.indexOf(key0) === -1) {
				const path = param.key;
				const value = param.value ?? ''; // Use empty string as the default value
	
				let node: Record<string, any> = state;
	
				for (let i = 0; i < path.length; i++) {
					const key = path[i]!;
					const isLast = i === path.length - 1;
	
					if (isLast) {
						node[key] = node[key] || [];
						(node[key] as any[]).push(value);
					} else {
						node[key] = node[key] || {};
						node = node[key] as Record<string, any>;
					}
				}
			}
		}
	
		return state;
	}
	
	protected parseQuery(queryParams: Array<QueryParameter>): UrlState {
		const qParamKey: string = this.getConfig().queryParameter;

		const qParam = queryParams.find((param) => param.key.length == 1 && param.key[0] == qParamKey);

		return qParam ? { q: qParam.value } : {};
	}

	protected parseFilter(queryParams: Array<QueryParameter>): UrlState {
			const valueFilterParams = queryParams.filter((p) => p.key.length == 2 && p.key[0] == 'filter');
			const rangeFilterParams = queryParams.filter((p) => p.key.length == 3 && p.key[0] == 'filter');
			
			const valueFilters = valueFilterParams.reduce((state: UrlState, param: QueryParameter): UrlState => {
				const key1: string | undefined = param.key[1];
			
				if (key1 !== undefined) {
					const currentValue = ((state.filter || {}) as Record<string, string[]>)[key1] ?? [];
					const updatedValues = [...currentValue, ...param.value.split(',')];
			
					return {
						filter: {
							...state.filter,
							[key1]: updatedValues,
						},
					};
				}
			
				return state;
			}, {});

			const rangeFilters = rangeFilterParams.reduce<UrlState>((state, param) => {
				const newState = { ...state };
				const filterKey = param.key[1] as string;
				const rangeType = param.key[2] as RangeType;
				
				const value = param.value === '*' ? '*' : Number(param.value);
			
				newState.filter = newState.filter ?? {};
			
				let ranges = newState.filter[filterKey] as UrlStateRangeValue[] || undefined;
				if (!Array.isArray(ranges)) {
					ranges = [];
				}
			
				let range = ranges.find(r => r[rangeType] === undefined);
				if (!range) {
					range = {}; // Initialize an empty object
					ranges.push(range);
				}
			
				// Assign the value, supporting both "*" and numbers
				range[rangeType] = value;
			
				// Direct manipulation and assertion to structure is maintained
				newState.filter[filterKey] = ranges as unknown as UrlStateFilterType;
			
				return newState;
			}, {});
		
		return {
			...(valueFilters.filter || rangeFilters.filter
				? {
						filter: {
							...valueFilters.filter,
							...rangeFilters.filter,
						},
				  }
				: {}),
		};
	}

	protected queryParamsToState(queryParams: Array<QueryParameter>): UrlState {
		// Todo: Special stage storage for sorts
		return {
			...this.parseQuery(queryParams),
			...this.parsePage(queryParams),
			...this.parseFilter(queryParams),
			...this.parseSort(queryParams),
			...this.parseOther(queryParams, ['page', this.getConfig().queryParameter, 'filter', 'sort']),
		};
	}

	deserialize(url: string): UrlState {
		const queryString = url.includes('?') ? (url.split('?').pop() || '').split('#').shift() || '' : '';
		const queryParams = this.parseQueryString(queryString);
		return this.queryParamsToState(queryParams);
	}
}
