import { Product, ProductOption } from "lib/bigcommerce/types";

export const getSelectedValues = (options: ProductOption[], searchParams: URLSearchParams): Record<string, string> => (
    options.reduce((accumulator, option) => {
        const name = option.name.toLowerCase();
        return {
            ...accumulator,
            [name]: searchParams.get(option.name.toLowerCase()) ?? option.defaultOption,
        }
    }, {})
);

export const getSelectedVariant = (product: Product, searchParams: URLSearchParams) => {
    const defaultVariant = product.variants.at(0);
    const selectedValues = getSelectedValues(product.options, searchParams);
    const selectedVariant = product.variants.find((variant) => variant.selectedOptions.every(option => option.value === selectedValues[option.name.toLowerCase()]));
    return selectedVariant ?? defaultVariant;
};