/** @jsxRuntime classic */
/** @jsx jsx */

import { BuilderElement, BuilderStoreContext } from "@builder.io/react";
import { Builder } from '@builder.io/sdk';
import { jsx } from '@emotion/core';
import React from 'react';
import { TimerResult } from "react-timer-hook";
import { tryEval } from '../functions/try-eval';
import { TimerProps } from "../hooks/with-timer";

import './cql-text.css';

jsx;

export interface TextProps extends TimerProps {
  text: string;
  html: string;
  builderBlock?: BuilderElement;
}

export class CQLTextComponent extends React.Component<TextProps> {
  textRef: HTMLSpanElement | null = null;

  componentDidMount() {
    // test if there are any expressions in text before assigning innerHTML
    if (this.textRef) {
      const textVal = this.text;
      if (!/{{([^}]+)}}/.test(textVal)) {
        this.textRef.innerHTML = textVal;
      }
    }
  }

  evalExpression(expression: string, state: any, timer?: TimerResult) {
    // Don't interpolate when inline editing
    if (this.allowTextEdit) {
      return String(expression);
    }
    const preProcessedString = this.preprocessExpression(expression, timer);
    return preProcessedString.replace(/{{([^}]+)}}/g, (match, group) => tryEval(group, state));
  }

  preprocessExpression(expression: string, timer?: TimerResult) {
    const totalSeconds = timer?.totalSeconds ?? 0;
    const seconds = timer?.seconds ?? 0;
    const minutes = timer?.minutes ?? 0;
    const hours = timer?.hours ?? 0;
    const days = timer?.days ?? 0;
    return String(expression)
      .replace(/{{[dD]{2}}}/g, days.toString())
      .replace(/{{[dD]}}/g, days.toString().padStart(2, '0'))
      .replace(/{{[hH]{2}}}/g, hours.toString().padStart(2, '0'))
      .replace(/{{[hH]}}/g, hours.toString())
      .replace(/{{[mM]{2}}}/g, minutes.toString().padStart(2, '0'))
      .replace(/{{[mM]}}/g, minutes.toString())
      .replace(/{{[sS]{2}}}/g, seconds.toString().padStart(2, '0'))
      .replace(/{{[sS]}}/g, seconds.toString())
      .replace(/{{[sS]{3}}}/g, totalSeconds.toString())
      .replace(/{{today}}/g, new Date().toLocaleDateString())
  }

  isContentEmpty(html?: string) {
    return !!(html && html !== '<p><br></p>');
  }

  get isRTEText() {
    return this.isContentEmpty(this.props.html)
  }


  get text() {
    return (this.isRTEText ? this.props.html : this.props.text) || (this.props as any).content || '';  //eslint-disable-line
  }

  get cssClass() {
    /* NOTE: This class name must be "builder-text" for inline editing to work in the Builder editor */
    return `builder-text${this.isRTEText ? ' cql-formatted-text' : ''}`;
  }

  get allowTextEdit() {
    return (
      Builder.isBrowser &&
      Builder.isEditing &&
      location.search.includes('builder.allowTextEdit=true') &&
      !(
        this.props.builderBlock &&
        this.props.builderBlock.bindings &&
        (this.props.builderBlock.bindings['component.options.text'] ||
          this.props.builderBlock.bindings['options.text'] ||
          this.props.builderBlock.bindings['text'])
      )
    );
  }

  get timer() {
    return (
      this.props.timer
    );
  }

  get timerSettings() {
    return (
      this.props.timerSettings
    );
  }

  render() {

    return (
      <BuilderStoreContext.Consumer>
        {state => {
          return (
            <React.Fragment>
              {/* TODO: <BuilderEditableText component that wraps this for other components with text */}
              <span
                ref={ref => {
                  this.textRef = ref;
                }}
                className={
                  this.cssClass
                }
                {...{
                  dangerouslySetInnerHTML: {
                    __html: this.evalExpression(this.text, state.state, this.timer),
                  },
                }}
              />
            </React.Fragment>
          );
        }}
      </BuilderStoreContext.Consumer>
    );
  }
}

export const CQLText = CQLTextComponent;
