'use client';

//Note Code Mirrored for Checkout: apps\checkout-js\packages\core\src\app\builder\components\carousel.tsx
import { Component as ComponentObj } from '@builder.io/sdk';
import { Component } from "react";
import Slider from "react-slick";

import CarouselNext from "../icons/carousel-next";
import CarouselPrev from "../icons/carousel-prev";

import { BuilderBlocks } from '@builder.io/react';

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import './carousel.css';

export class Carousel extends Component<{
    desktopSettings: { count: number, per: number },
    tabletSettings: { count: number, per: number },
    mobileSettings: { count: number, per: number },
    builderBlock: any,
    slide: any,
    navTheme: string,
    autoPlay: boolean,
    dtSwipe: boolean,
    speed: number,
    showNav: boolean,
    initialSlide: number,
    attributes: {
      style: any, 
      className: string,
      'builder-id': string | undefined, 
      id: string

    },
}, object> {
    private slider: any;
    
    constructor(props: {
        desktopSettings: { count: number, per: number },
        tabletSettings: { count: number, per: number },
        mobileSettings: { count: number, per: number },
        builderBlock: any,
        slide: any,
        navTheme: string,
        autoPlay: boolean,
        dtSwipe: boolean,
        speed: number,
        showNav: boolean,
        initialSlide: number,
        attributes: {
          style: any, 
          className: string,
          'builder-id': string, 
          id: string

        },
    }) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    render() {
      const settings = {
        dots: this.props?.showNav,
        arrows: false,       
        autoplay: this.props?.autoPlay,   
        autoplaySpeed: 0,          
        speed: ( this.props?.speed ? this.props?.speed : undefined ),        
        cssEase: 'linear',         
        pauseOnHover: ( this.props?.autoPlay ? false : undefined ),            
        centerMode: ( this.props?.initialSlide > 1 ),        
        initialSlide: ( this.props?.initialSlide ? this.props?.initialSlide - 1 : 0 ),  
        swipe: this.props?.dtSwipe,
        swipeToSlide: this.props?.dtSwipe,
        touchMove: this.props?.dtSwipe,
        touchThreshold: 5,
        infinite: (this.props?.autoPlay || this.props?.dtSwipe),   
        appendDots: (dots: string) => (
            <div className={`cql-carousel--navigation`}>
                <ul className='dot-container'>{dots}</ul>
                <button className={`
                    glider-prev 
                    cql-carousel--arrow 
                    cql-carousel--prev 
                    slick-arrow 
                    ${this.props?.initialSlide && this.props?.initialSlide - 1 > 0 ? '' : 'slick-disabled'}`}  onClick={this.previous}>
                    <CarouselPrev />
                </button>
                <button className="glider-next cql-carousel--arrow cql-carousel--next slick-arrow"  onClick={this.next} >                    
                    <CarouselNext />
                </button>
            </div>
        ),
        slidesToShow: (this.props?.desktopSettings?.count ? this.props.desktopSettings.count : 4),
        slidesToScroll: (this.props?.desktopSettings?.per ? this.props.desktopSettings.per : 4), 
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: (this.props?.tabletSettings?.count ? this.props.tabletSettings.count : 2),
                slidesToScroll: (this.props?.tabletSettings?.per ? this.props.tabletSettings.per : 2),
                swipe: true,
                swipeToSlide: true,
                touchMove: true,
                touchThreshold: 5,
                infinite: true, 
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: (this.props?.mobileSettings?.count ? this.props.mobileSettings.count : 1),
                slidesToScroll: (this.props?.mobileSettings?.per ? this.props.mobileSettings.per : 1)
              }
            }
        ],   
        beforeChange: () => {    
          const loadingClass = 'dot-container--loading';   
          const theSlider = document.querySelector(`.${this.props.builderBlock.id}`);    
          if (!theSlider) return false;
          const dotContainer = theSlider.querySelector('.dot-container') as HTMLElement;
          if (dotContainer) dotContainer.classList.add(loadingClass);
        },    
        afterChange: () => {                
            const theSlider = document.querySelector(`.${this.props.builderBlock.id}`);    
            if (!theSlider) return false;
            const dotContainer = theSlider.querySelector('.dot-container') as HTMLElement;
            const prevArrow = theSlider.querySelector('.glider-prev') as HTMLElement;
            const nextArrow = theSlider.querySelector('.glider-next') as HTMLElement;
            if (!dotContainer || !prevArrow || !nextArrow) return false;
            const disabledClass = 'slick-disabled';
            const pageCount = dotContainer.childElementCount;
            const activeItem = dotContainer.querySelector('.slick-active') as HTMLElement;
            const activeItems = dotContainer.querySelectorAll('.slick-active') as NodeListOf<Element>;
            let activeIndex = Array.prototype.indexOf.call(dotContainer.children, activeItem) + 1;

            //Fallback for last slide of fraction sliders
            if(activeIndex === 0) {
                const activeSlide = theSlider.querySelector('.slick-track .slick-current') as HTMLElement;

                if(activeSlide && activeSlide.dataset && activeSlide.dataset.index) {
                    let slideIndex = Number(activeSlide?.dataset?.index);
                    
                    const dotChildren = dotContainer.childNodes as NodeListOf<ChildNode>;

                    if((slideIndex + 1) > dotChildren.length) {
                        slideIndex = dotChildren.length - 1;
                    }

                    const activeChild = dotChildren[slideIndex] as HTMLElement;
                    if(activeChild) {
                        activeChild.classList.add('slick-active');
                    }
                    activeIndex = slideIndex + 1;
                }
            }
            //Clean up for fallback
            if(activeItems.length > 1){
                activeItems.forEach((node) => {
                    const tempIndex = Array.prototype.indexOf.call(dotContainer.children, node) + 1;
                    if(tempIndex !== activeIndex) {
                        node.classList.remove('slick-active');
                    }
                })
            }
            
            if(pageCount === 1){
                prevArrow.classList.add(disabledClass);
                prevArrow?.setAttribute('disabled', '');
                nextArrow.classList.add(disabledClass);
                nextArrow?.setAttribute('disabled', '');
            } else if(activeIndex >= pageCount){
                prevArrow.classList.remove(disabledClass);
                prevArrow?.removeAttribute('disabled');
                nextArrow.classList.add(disabledClass);
                nextArrow?.setAttribute('disabled', '');
            } else if (activeIndex === 1) {
                prevArrow.classList.add(disabledClass);
                prevArrow?.setAttribute('disabled', '');
                nextArrow.classList.remove(disabledClass);
                nextArrow?.removeAttribute('disabled');
            } else {
                prevArrow.classList.remove(disabledClass);
                prevArrow?.removeAttribute('disabled');
                nextArrow.classList.remove(disabledClass);
                nextArrow?.removeAttribute('disabled');
            }
            dotContainer.classList.remove('dot-container--loading');
        }
      };
      
      return (
        <div style={this.props.attributes.style} className={this.props.attributes.className} builder-id={this.props.attributes['builder-id']} id={this.props.attributes.id}>
        {
        // getting type errors due to `@types/react` version mismatches. Can safely ignore.
        // @ts-ignore: TypeScript doesn't like the JSX tag type
        } <Slider 
            ref={c => (this.slider = c)} {...settings} 
            className={`cql-carousel ${this.props.navTheme ? 'cql-carousel--theme-' + this.props.navTheme : ''} ${this.props.showNav ? 'cql-carousel--nav-active' : ''}`}
          >
            {this.props?.slide.map((item: any, index: number) => (
            <div key={index} className={`builder-slide builder-slide-${index}`}>            
                {
                // @ts-ignore: TypeScript doesn't like the JSX tag type
                } <BuilderBlocks
                    parentElementId={this.props.builderBlock.id}
                    dataPath={`component.options.slide.${index}.content`}
                    blocks={item.content}
                />
            </div>
            ))}
          </Slider>
        </div>
      );
    }
  }


  export const carouselOptions: ComponentObj = {
    name: 'Carousel',
    noWrap: true,
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/carousel.svg',
    description: 'Carousel Container',
    inputs: [
      {
        name: 'slide',
        type: 'list',
        subFields: [
          {
            name: 'label',
            type: 'text',
            defaultValue: 'New Slide',
          },
          {
            name: 'content',
            type: 'uiBlocks',
            defaultValue: [],
          },
        ],
        defaultValue: [
          {
            label: 'Slide 1',
            content: [],
          },
        ],
      },
      {
        name: 'desktopSettings',
        type: 'object',
        defaultValue: {
          count: 4,
          per: 4,
        },
        subFields: [
          {
            name: 'count',
            friendlyName: 'Slides to Show',
            type: 'number',
            defaultValue: 4
          },
          {
            name: 'per',
            friendlyName: 'Slides to Scroll',
            type: 'number',
            defaultValue: 4
          },
        ]
      },
      {
        name: 'tabletSettings',
        type: 'object',
        defaultValue: {
          count: 2,
          per: 2,
        },
        subFields: [
          {
            name: 'count',
            friendlyName: 'Slides to Show',
            type: 'number',
            defaultValue: 4
          },
          {
            name: 'per',
            friendlyName: 'Slides to Scroll',
            type: 'number',
            defaultValue: 4
          },
        ]
      },
      {
        name: 'mobileSettings',
        type: 'object',
        defaultValue: {
          count: 1,
          per: 1,
        },
        subFields: [
          {
            name: 'count',
            friendlyName: 'Slides to Show',
            type: 'number',
            defaultValue: 4
          },
          {
            name: 'per',
            friendlyName: 'Slides to Scroll',
            type: 'number',
            defaultValue: 4
          },
        ]
      },
      {
        name: 'navTheme',
        friendlyName: 'Nav Theme',
        type: 'text',
        enum: [
          'light',
          'dark'
        ],
        defaultValue: 'dark'
      },
      {
        name: 'showNav',
        friendlyName: 'Show Navigation',
        type: 'boolean',
        defaultValue: true
      },
      {
        name: 'dtSwipe',
        friendlyName: 'Desktop Swipe',
        type: 'boolean',
        defaultValue: false
      },
      {
        name: 'autoPlay',
        friendlyName: 'AutoPlay',
        type: 'boolean',
        defaultValue: false
      },
      {
        name: 'speed',
        friendlyName: 'Speed (ms)',
        type: 'number',
        defaultValue: 500
      },
      {
        name: 'initialSlide',
        friendlyName: 'Index of first slide',
        type: 'number',
        defaultValue: 1
      },
    ],
  }
