import { Input as InputOptions } from '@builder.io/sdk';
import { tryParseDate } from 'shared-react-components';

export interface TimerSettings {
  enabled: boolean;
  timerType: string;
  minutes: number;
  seconds: number;
  date?: Date;
  permanent: boolean;
  countdownParentTimer: boolean;
  actions: ActionSettings;
}

export interface ActionSettings {
  enabled: boolean;
  action: string;
  redirectUrl?: string;
  sku?: string;
  message?: string;
  modalHeader?: string;
}

export const defaultTimerSettings: TimerSettings = {
  enabled: false,
  timerType: 'seconds',
  minutes: 10,
  seconds: 0,
  date: undefined,
  permanent: false,
  countdownParentTimer: false,
  actions: {
    enabled: false,
    action: '',
    redirectUrl: undefined,
    sku: undefined,
    message: undefined,
    modalHeader: ''
  }
}

export const getTimerSettings = (timersettings?: TimerSettings) => {
  return {
    ...defaultTimerSettings,
    ...timersettings,
    date: tryParseDate(timersettings?.date) ?? undefined,
    actions: {
      ...defaultTimerSettings.actions,
      ...timersettings?.actions,
    }
  };
}

export const timerSettingsInput: InputOptions[] = [
  {
    name: 'timerSettings',
    friendlyName: 'Timer Settings',
    type: 'object',
    advanced: true,
    defaultValue: {
      enabled: false,
      timerType: 'seconds',
      minutes: 10,
      seconds: 0,
      permanent: false,
      countdownParentTimer: false,
      actions: {
        enabled: false,
      }
    },
    subFields: [
      {
        name: 'enabled',
        type: 'boolean',
      },
      {
        name: 'timerType',
        type: 'string',
        enum: [
          { label: 'Seconds', value: 'seconds' },
          { label: 'Date', value: 'date' },
        ],
      },
      {
        name: 'minutes',
        type: 'number',
      },
      {
        name: 'seconds',
        type: 'number',
      },
      {
        name: 'date',
        type: 'date',
      },
      {
        name: 'permanent',
        type: 'boolean',
      },
      {
        name: 'countdownParentTimer',
        type: 'boolean',
      },
      {
        name: 'actions',
        friendlyName: 'Timer Actions',
        type: 'object',
        subFields: [
          {
            name: 'enabled',
            type: 'boolean',
          },
          {
            name: 'action',
            friendlyName: 'Timer Actions',
            type: 'string',
            enum: [
              { label: 'None', value: '' },
              { label: 'Swap Cart Product', value: 'swapProduct' },
              { label: 'Dialog', value: 'dialog' },
              { label: 'Alert', value: 'alert' },
              { label: 'Redirect', value: 'redirect' },
              { label: 'Show', value: 'show' },
              { label: 'Hide', value: 'hide' },
              { label: 'Modal', value: 'modal' },
            ],
          },
          {
            name: 'redirectUrl',
            type: 'string',
          },
          {
            name: 'sku',
            type: 'string',
          },
          {
            name: 'message',
            type: 'string',
          },
          {
            name: 'modalHeader',
            type: 'string',
          },
        ]
      },
    ]
  }
];