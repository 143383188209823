import {QueryStringTranslator} from '..';
import {
  getQueryFromUrl,
  updateSearchStateQueryParameters,
} from './stateMutations';
import {type SearchspringState} from '../types';

export const toSearchState = (
  stateIn: SearchspringState,
  buyerIP: string,
  resultsPerPage = 24,
  url: string,
): SearchspringState => {
  const stateOut = stateIn;
  stateOut.httpXForwardedFor = buyerIP;
  stateOut.resultsFormat = 'json';
  stateOut.q = '';
  stateOut.redirectResponse = 'minimal';
  stateOut.resultsPerPage = resultsPerPage;
  return updateSearchStateQueryParameters(
    stateIn,
    new QueryStringTranslator(),
    url,
  );
};

export const toSuggestionsState = (
  stateIn: SearchspringState,
  urlIn: string,
  languageModel: string,
  suggestionsCount = 4,
  productCount = 20,
): SearchspringState => {
  const stateOut = stateIn as SearchspringState;
  stateOut.language = languageModel;
  stateOut.suggestionCount = suggestionsCount;
  stateOut.productCount = productCount;
  stateOut.q = getQueryFromUrl(urlIn) || '';
  return stateOut;
};

export const toSuggestionsStateByQuery = (
  stateIn: SearchspringState,
  query: string,
  languageModel: string,
  suggestionsCount = 4,
  productCount = 20,
): SearchspringState => {
  const stateOut = stateIn;
  stateOut.language = languageModel;
  stateOut.suggestionCount = suggestionsCount;
  stateOut.productCount = productCount;
  stateOut.q = query;
  return stateOut;
};

export const toAutocompleteState = (
  state: SearchspringState,
  buyerIP: string,
  resultsPerPage: number | undefined,
  url: string,
) => toSearchState(state, buyerIP, resultsPerPage, url);

export const toRecommendationsState = <T extends SearchspringState>(
  stateIn: T,
  options: {
    profile: string;
    categories?: string;
    limits?: number;
    brands?: string;
  },
): SearchspringState => {
  const {profile, categories, limits, brands} = options;
  const stateOut = stateIn;
  stateOut.profile = profile;
  stateOut.categories = categories;
  stateOut.limits = limits;
  stateOut.brands = brands;
  return stateOut;
};
