export function Star() {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.74995 5.74295L7.89558 0L6.04122 5.74295L1.52588e-05 5.72968L4.89573 9.26534L3.01647 15L7.89558 11.4428L12.7747 15L10.8954 9.26534L15.7895 5.72968L9.74995 5.74295Z" fill="#F4BF0B"/>
        </svg> 
    );
}

export function EmptyStar() {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5386 6.24295L8.68422 0.5L6.82985 6.24295L0.788651 6.22968L5.68437 9.76534L3.8051 15.5L8.68422 11.9428L13.5633 15.5L11.6841 9.76534L16.5781 6.22968L10.5386 6.24295Z" fill="#CCCCCC"/>
        </svg>
    );
}