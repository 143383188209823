'use client';

import * as blotout from 'components/analytics/blotout';
import * as gtm from 'components/analytics/gtm';
import Modal from 'components/global/modal';
import useCart from 'components/hooks/use-cart';
import LoadingDots from 'components/loading-dots';
import { getSelectedVariant } from 'components/product/helpers';
import { useProductOptions } from 'components/providers/product-options-provider';
import { useStorefrontDispatch } from 'components/providers/storefront-provider';
import { withSuspense } from 'components/with-suspense';
import { Cart, Product, ProductVariant } from 'lib/bigcommerce/types';
import { Result, ResultJSON } from 'lib/result';
import { FormEvent, useState, useTransition } from 'react';
import { formatDuration } from './mulberry-actions';

const addItem = async (
  prevState: any,
  payload: {
    productId: string | undefined;
    variantId: string | undefined;
    quantity: number | 1;
  },
  associatedItem?: {
    name: string;
    list_price: number;
    quantity: number;
  }): Promise<ResultJSON<Cart>> => {
  try {
    const response = await fetch(`/api/cart/item`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        payload,
        associatedItem
      }),
    });

    return await response.json();
  } catch (error: any) {
    return Result.fail<Cart>(error.message).toJSON();
  }
}

function SubmitButton({
  availableForSale,
  isInStock,
  disabled,
  selectedVariantId,
  notifyMe,
  pending
}: {
  availableForSale: boolean;
  isInStock: boolean;
  disabled: boolean;
  selectedVariantId: string | undefined;
  notifyMe: boolean;
  pending: boolean;
}) {
  if (!selectedVariantId || disabled === true) {
    return (
      <button
        aria-label="Please select an option"
        aria-disabled
        className="cta-button cta-button-atc"
        disabled
      >
        Add To Cart
      </button>
    );
  }

  const isAvailable = availableForSale && isInStock;
  const showNotifyMe = availableForSale && notifyMe === true && !isInStock;
  const showOutOfStock = !availableForSale || (notifyMe === false && !isInStock)

  return (
    <>
      {showNotifyMe && <NotifyMeModal />}
      {showOutOfStock && <OutOfStockButton />}
      {isAvailable && <AddToCartButton
        selectedVariantId={selectedVariantId}
        notifyMe={notifyMe}
        pending={pending}
      />}
    </>
  );
}

function AddToCartButton({
  selectedVariantId,
  notifyMe,
  pending
}: {
  selectedVariantId: string | undefined;
  notifyMe: boolean;
  pending: boolean;
}) {
  return (
    <button
      aria-label="Add to cart"
      className="cta-button cta-button-atc"
      data-variant-id={selectedVariantId}
      data-notify={notifyMe}
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
    >
      Add To Cart
      {pending && <LoadingDots className="loading-dots-dark" />}
    </button>
  );
}

function OutOfStockButton() {
  return (
    <button
      aria-disabled
      className="cta-button cta-button-atc"
      disabled
    >
      Out Of Stock
    </button>
  );
}

function NotifyMeModal() {
  return (
    <Modal
      buttonClasses="cta-button cta-button-atc"
      buttonText="Notify Me"
      preventDefault={true}
    >
      Placement for Klaviyo content
    </Modal>
  );
}

function AddToCartComponent({
  product,
  variants,
  availableForSale,
  quantity,
  disabled,
  isInStock
}: {
  product?: Product;
  variants: ProductVariant[];
  availableForSale: boolean;
  quantity: number;
  disabled: boolean;
  isInStock: boolean;
}) {
  const { mutate } = useCart();
  const dispatch = useStorefrontDispatch();
  const [message, setMessage] = useState<undefined | string>(undefined);
  const [isPending, startTransition] = useTransition();
  const { optionSearchParams } = useProductOptions();
  const variant = product && getSelectedVariant(product, optionSearchParams);
  const selectedVariantId = variant?.id || product?.variants?.at(0)?.id;
  const selectedProductId = variant?.parentId || product?.id;
  const selectedQuantity = quantity;
  const parentSku = product?.sku ? product.sku : undefined;
  const variantSku = variant?.sku ? variant.sku : undefined;


  // Set product-level notifyMe boolean
  let notifyMe = false;
  product && product.productMetafields.forEach((field: any) => {
    field.key === 'notify-me' && field.value === 'true' ? notifyMe = true : null;
    return;
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    startTransition(async () => {
      setMessage(undefined);
      const selectedOffer = structuredClone(window.selectedOffer);
      const currentQuantity = selectedQuantity;

      let associatedItem = undefined;
      if (selectedOffer) {
        const sku = variantSku ?? parentSku ?? '';
        const lineItemName = `${formatDuration(selectedOffer.duration_months)} Mulberry Protection ${sku}`
        associatedItem = {
          name: lineItemName,
          list_price: selectedOffer.customer_cost,
          quantity: currentQuantity
        }
      }
      const result = await addItem(null, { productId: selectedProductId, variantId: selectedVariantId, quantity: selectedQuantity }, associatedItem);

      if (result.isSuccess) {
        setMessage('Success')
        if (result.value) {
          const item = result.value.lines.find(l => l.merchandise.id === selectedVariantId)
          if (item) {
            gtm.addToCart({ cart: result.value, lineItem: item, quantity: selectedQuantity });
            blotout.addToCart({ cart: result.value, lineItem: item, quantity: selectedQuantity });
          }
        }
        mutate(result);
        dispatch({ type: 'OPEN_CART_CONFIRM', currentVariant: selectedVariantId });
      }
      else {
        console.log(result.error)
        setMessage(result.error)
      }
    });
  };

  return (
    <form
      id="add-to-cart-form"
      onSubmit={onSubmit}
    >
      <SubmitButton
        availableForSale={availableForSale}
        isInStock={isInStock}
        selectedVariantId={selectedVariantId}
        disabled={disabled}
        notifyMe={notifyMe}
        pending={isPending}
      />
      <p aria-live="polite" className="sr-only" role="status">
        {message}
      </p>
    </form>
  );
}

const AddToCartSkeleton = (
  <form id="add-to-cart-form" className="animate-pulse">
    <button aria-label="Please select an option" aria-disabled className="cta-button cta-button-atc" disabled>
      Add To Cart
    </button>
  </form>
);

export const AddToCart = withSuspense(AddToCartComponent, AddToCartSkeleton);
