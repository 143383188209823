'use client'
import { BuilderStoreContext } from '@builder.io/react';
import { SearchspringService } from 'components/searchspring/searchspringService';
import { SearchReponseModelResult, type SearchResponseModel } from 'int-searchspring-api';
import { createUrl } from 'lib/utils';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import {
  FacetProps,
  SearchProductResult,
  SortFunction,
  type RefinementFunction
} from 'propel-react-components';
import { LinkComponentInterface } from 'propel-shared-utility';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getSearchspringState } from './search-spring-state';

type SearchspringSearchProps<T> = T & {
  refinementFunction?: RefinementFunction;
  sortFunction?: SortFunction;
  searchResult?: {
    facets: FacetProps[];
    results: SearchProductResult[];
  } | SearchResponseModel; 
  tilePromo?: boolean;
  tileBurst?: boolean;
  tileBanner?: boolean;
  tileTagline?: boolean;
  tileSpecs?: boolean;
  tileRatings?: boolean;
  tileATC? : boolean;
  mobile?: boolean;
  LinkComponent?: LinkComponentInterface;
};

/**
 * Hook returns a function that performs a search refinement
 */
export const useRefinementFunction = (): RefinementFunction => {
  const router = useRouter();
  const pathname = usePathname() || '';
  const searchParams = useSearchParams();

  const refinementFunction: RefinementFunction = useCallback(
   async ({ type, field, value, low, high, multiple, checked, clear_all, view_all }) => {
      // Create a function callback to update newParams
      const updateNewParams = (callback: (params: URLSearchParams) => void) => {
        const newParamsCopy = new URLSearchParams(searchParams?.toString());
        callback(newParamsCopy);
        
        router.push(createUrl(pathname, newParamsCopy), { scroll: false });
      };

      if (clear_all) {
        updateNewParams((params) => {
          const filterParamsToDelete: string[] = [];
            // Identify filter parameters to delete
            params.forEach((value, key) => {
              if (key.startsWith('filter.')) {
                filterParamsToDelete.push(key);
              }
            });

            // Delete filter parameters
            filterParamsToDelete.forEach((key) => {
              params.delete(key);
            });
        })
      }

        if (view_all) {
          updateNewParams((params) => {
            const categoryHierarchyParamsToDelete: string[] = [];
            
            // Identify category_hierarchy parameters to delete
            params.forEach((value, key) => {
              if (key.startsWith('filter.')) {
                categoryHierarchyParamsToDelete.push(key);
              }
            });
    
            // Delete category_hierarchy parameters
            categoryHierarchyParamsToDelete.forEach((key) => {
              params.delete(key);
            });
          });
        }
  
      switch (type) {
        // TODO: currently missing handling for palette and hierarchy options.
        case 'slider':
          updateNewParams((params) => {
            if (low || low === 0) {
              params.set(`filter.${field}.low`, String(low));
            }
            if (high) {
              params.set(`filter.${field}.high`, String(high));
          }});
          break;
        default:
          if (value || low || high) {
            switch (multiple) {
              case 'single':
                updateNewParams((params) => {
                  if (checked) {
                    params.set(`filter.${field}`, String(value));
                  } else {
                    params.delete(`filter.${field}`, String(value));
                  }
                });
                break;
              default:
                updateNewParams((params) => {       
                  if (value !== undefined) {
                    const existingValues = params.getAll(`filter.${field}`);
                    if (checked) {
                        // Add the value if checked
                            if (!existingValues.includes(String(value))) {
                              params.append(`filter.${field}`, String(value));
                          }
                    } else {
                        // Remove the value if unchecked
                        params.delete(`filter.${field}`);
                        existingValues.forEach((val) => {
                            if (val !== String(value)) {
                                params.append(`filter.${field}`, val);
                            }
                        });
                    }
                  }
          
                  // Remove applied filter range handle
                  if (field === 'calculated_price'  && typeof value === 'string') {
                    let parsedValues: string | any[] = [];
                    let isLowOpenEnded = false;
                    let isHighOpenEnded = false;
                    if (value.includes('to')) {
                      parsedValues = value.match(/\d+/g)?.map(Number) || [];
                    } else if (value.includes('More than')) {
                      const match = value.match(/\d+/);
                      if (match) {
                        parsedValues = [parseInt(match[0]), Infinity];
                        isHighOpenEnded = true;
                      }
                    } else if (value.includes('Less than')) {
                      const match = value.match(/\d+/);
                      if (match) {
                        parsedValues = [-Infinity, parseInt(match[0])];
                        isLowOpenEnded = true;
                      }
                    }

                    if (parsedValues.length > 0) {
                      const [parsedLow, parsedHigh] = parsedValues;
                      ['low', 'high'].forEach((suffix) => {
                        params.getAll(`filter.${field}.${suffix}`).forEach((paramValue, index) => {
                          if ((suffix === 'low' && (isLowOpenEnded ? paramValue === '*' : parseInt(paramValue) === parsedLow)) ||
                              (suffix === 'high' && (isHighOpenEnded ? paramValue === '*' : parseInt(paramValue) === parsedHigh))) {
                            const values = params.getAll(`filter.${field}.${suffix}`);
                            values.splice(index, 1);
                            params.delete(`filter.${field}.${suffix}`);
                            values.forEach(v => params.append(`filter.${field}.${suffix}`, v));
                          }
                        });
                      });
                    }
                    return;
                  } 

                  if (low !== undefined && high !== undefined) {
                      const entries = Array.from(params.entries());
                      let lowFound = false, highFound = false;
                      let lowKeyToRemove: number | null = null, highKeyToRemove: number | null = null;
                    
                      entries.forEach(([key, value], index) => {
                        if (key === `filter.${field}.low` && value === String(low)) {
                          lowFound = true;
                          lowKeyToRemove = index;
                        }
                        if (key === `filter.${field}.high` && value === String(high)) {
                          highFound = true;
                          highKeyToRemove = index;
                        }
                      });
                    
                      if (lowFound && highFound && lowKeyToRemove !== null && highKeyToRemove !== null) {
                        const newParams = new URLSearchParams();
                        entries.forEach(([key, value], index) => {
                          if (index !== lowKeyToRemove && index !== highKeyToRemove) {
                            newParams.append(key, value);
                          }
                        });
                        params.delete(`filter.${field}.low`);
                        params.delete(`filter.${field}.high`);
                        newParams.forEach((value, key) => {
                          params.append(key, value);
                        });
                        params.delete('view');
                      } else {
                        params.append(`filter.${field}.low`, String(low));
                        params.append(`filter.${field}.high`, String(high));
                      }
                    }
                  }  
                );
            }
          }
      }
    },
    [searchParams, pathname, router],
  );

  (refinementFunction as any).displayName = 'RefinementFunction';

  return refinementFunction;
};

export const withSearchspringSearch = <T,>(
  Component: React.FC<SearchspringSearchProps<T>>,
): React.FC<SearchspringSearchProps<T>> => {
  const WrappedComponent = (props: SearchspringSearchProps<T>) => {
    const searchParams = useSearchParams()
    const [searchData, setSearchData] = useState<SearchResponseModel | undefined>();
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState<any>(null);
    const sortFunction: SortFunction = useSortFunction();
    const refinementFunction: RefinementFunction = useRefinementFunction();
    const searchValue: { [key: string]: string } = {};    
    const filterParams: { [key: string]: string[] } = {};
    const sortParams: { [key: string]: string } = {};
    const filterParamsProducts: Record<string, string[]> = {};
    const filterParamsArticles: Record<string, string[]> = {};
    const sortParamsProducts: Record<string, string> = {};
    const sortParamsArticles: Record<string, string> = {};
    
    const [allResults, setAllResults] = useState<Array<SearchReponseModelResult>>([])
    const builderState = useContext(BuilderStoreContext);
    const [contentType, setContentType] = useState('products');
    const contentCategoryHandle = builderState?.content?.data?.handle;
    const productCategoryHandle = builderState?.state?.collection?.handle
    const productCategoryTitle = builderState?.state?.collection?.title;
    const view = searchParams?.get('view');
    const pathname = usePathname();
    const [productDataCount, setProductDataCount] = useState<number | undefined>(0);
    const [articleDataCount, setArticleDataCount] = useState<number | undefined>(0);

    const newParams = new URLSearchParams(searchParams?.toString());
    const urlPathBase = process.env.NEXT_PUBLIC_DOMAIN_NAME;


    const tileOptions = {
      banner: props.tileBanner,
      burst: props.tileBurst,
      promo: props.tilePromo,
      specs: props.tileSpecs,
      ratings: props.tileRatings,
      tagline: props.tileTagline,
      addToCart: props.tileATC,
    };

    if(searchParams) {
      for (const [key, value] of searchParams.entries()) {
        if (key === 'q') {
          searchValue[key] = value;
        } else if (key.startsWith('filter.')) {
          const filterKey = key.replace('filter.', '');
          if (view === 'products') {
            if (!filterParamsProducts[`filter.${filterKey}`]) {
              filterParamsProducts[`filter.${filterKey}`] = [];
            }
            filterParamsProducts[`filter.${filterKey}`]?.push(value);
          } else if (view === 'articles') {
            if (!filterParamsArticles[`filter.${filterKey}`]) {
              filterParamsArticles[`filter.${filterKey}`] = [];
            }
            filterParamsArticles[`filter.${filterKey}`]?.push(value);
          } else {
            if (!filterParams[key]) {
              filterParams[key] = [];
            }
            filterParams[key]?.push(value);
          }
        } else if (key.startsWith('sort.')) {
          const sortKey = key.replace('sort.', '');
          if (view === 'products') {
            sortParamsProducts[`sort.${sortKey}`] = value;
          } else if (view === 'articles') {
            sortParamsArticles[`sort.${sortKey}`] = value;
          } else {
            sortParams[key] = value;
          }
        }
      }
    }
    
    const articlesState = getSearchspringState({ contentType: 'articles', overrides: { bgfilter: contentCategoryHandle ? {"category_handle": [contentCategoryHandle]} : undefined, domain: `${urlPathBase}/?${searchValue.q ? `q=${searchValue.q}&` : ''}&page=${currentPage}` ?? '' }});
    const productsState = getSearchspringState({ contentType: 'product', overrides: { bgfilter: productCategoryHandle ? {"category_ids": [productCategoryHandle]} : undefined }});

    const prevRefinementFunction = useRef(refinementFunction);

    useEffect(() => {
      if (pathname === '/search') {
        async function fetchInitialData() {
          try {
            const searchService = new SearchspringService(productsState);
            const productsData = await searchService.searchGET(productsState);
            const productsResults = productsData.body?.results || [];
            setProductDataCount(productsResults.length);
            const searchServiceContent = new SearchspringService(articlesState);
            const articlesData = await searchServiceContent.searchGET(articlesState);
            const articlesResults = articlesData.body?.results || [];
            setArticleDataCount(articlesResults.length);
  
          } catch (error) {
            console.error("Failed to fetch data:", error);
            setError(error);
          }
        }
        fetchInitialData();
       }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setContentType(view === 'articles' || contentCategoryHandle ? 'articles' : 'products');
    }, [view, contentCategoryHandle]);

    useEffect(() => {

      const fetchSearchData = async () => {
        const isArticlesView = contentType === 'articles' ||  view === 'articles';
        const state = (isArticlesView || contentCategoryHandle) ? articlesState : productsState;
        try { 
          const searchService = new SearchspringService(state);
          const data = await searchService.searchGET(state);
          setSearchData(data.body);

          if (pathname === '/search') {
            const secondTabState = { ...state, domain: `${urlPathBase}/?${searchValue.q ? `q=${searchValue.q}&` : ''}&page=${currentPage}` ?? '', siteId: !isArticlesView ? process.env.NEXT_PUBLIC_SS_CONTENT_INDEX ?? '' : process.env.NEXT_PUBLIC_SS_INDEX ?? '' };
            const secondService = new SearchspringService(secondTabState);
            const secondData = await secondService.searchGET(secondTabState);
            const tempProdCount = isArticlesView ? secondData.body?.results?.length : data.body?.results?.length;
            const tempArticle = isArticlesView ? data.body?.results?.length : secondData.body?.results?.length
            setProductDataCount(tempProdCount);
            setArticleDataCount(tempArticle);
          }
        
          const newResults = data.body?.results || [];

          if (currentPage > 1 && prevRefinementFunction.current === refinementFunction ) {
            setAllResults((prevResults) => [...prevResults, ...newResults]);
            }  else if (currentPage > 1 && prevRefinementFunction.current !== refinementFunction) {
              setCurrentPage(1)
              setAllResults([...newResults]);
            }
            else {
            setAllResults([...newResults]);
          }

          prevRefinementFunction.current = refinementFunction;

        } catch (error) {
          setError(error);
        }
      };

    
      fetchSearchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, refinementFunction, contentType]);

  const constructQueryString = (params: Record<string, string | string[]>) => {
    const queryStringFormat = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        value.forEach((item) => queryStringFormat.append(key, item));
      } else {
        queryStringFormat.append(key, value);
      }
    }

    return queryStringFormat.toString();
  };

  const queryStringProducts = constructQueryString({ ...filterParamsProducts, ...sortParamsProducts });
  const queryStringArticles = constructQueryString({ ...filterParamsArticles, ...sortParamsArticles });
  const queryStringSingleUrl = constructQueryString({ ...filterParams, ...sortParams})

  const urlPathProducts = `${urlPathBase}/?${searchValue.q ? `q=${searchValue.q}&` : ''}${queryStringProducts}&page=${currentPage}`;
  const urlPathArticles = `${urlPathBase}/?${searchValue.q ? `q=${searchValue.q}&` : ''}${queryStringArticles}&page=${currentPage}`;
  const urlPath = `${urlPathBase}/?${searchValue.q ? `q=${searchValue.q}&` : ''}${queryStringSingleUrl}&page=${currentPage}`;

  if (view === 'products') {
    productsState.domain = urlPathProducts;
  } else if (view === 'articles') {
    articlesState.domain = urlPathArticles;
  } else {
    productsState.domain = urlPath;
    articlesState.domain = urlPath;
  }

    const handleLoadMore = useCallback(() => {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
    }, [currentPage, setCurrentPage]);
  
    return (
      <Component  {...props}  contentCategoryHandle={contentCategoryHandle} productCategoryHandle={productCategoryHandle} articleDataCount={articleDataCount}  productDataCount={productDataCount} setContentType={setContentType} sorting={searchData} sortFunction={sortFunction} onLoadMore={handleLoadMore} refinementFunction={refinementFunction} searchData={searchData} allResults={allResults} tileOptions={tileOptions} contentType={contentType} productCategoryTitle={productCategoryTitle} searchTerm={searchValue.q}></Component>
    );
  };

  WrappedComponent.displayName = 'WithSearchspringSearch';

  return WrappedComponent;
};


/**
 * Hook returns a function that performs a search sort
 */
export const useSortFunction = (): SortFunction => {
  const router = useRouter();
  const pathname = usePathname() || '';
  const searchParams = useSearchParams();
  const sortFunction: SortFunction = useCallback(
    (selectedOption) => {
      // Create a function callback to update newParams
      const updateNewParams = (callback: (params: URLSearchParams) => void) => {
        const newParamsCopy = new URLSearchParams(searchParams?.toString());
        callback(newParamsCopy);
        
        router.push(createUrl(pathname, newParamsCopy));
      };
    if (selectedOption) {
      updateNewParams((params) => {
        for (const key of params.keys()) {
          if (key.startsWith('sort.')) {
            params.delete(key);
          }
        }
    
        params.set(`sort.${selectedOption.field}`, selectedOption.direction);
      });
    }
  },
  [searchParams, pathname, router],
);

(sortFunction as any).displayName = 'SortFunction';

return sortFunction;
};
