import { getCookie, setCookie } from 'cookies-next';
import { BeaconConfig, SearchspringState, getNewPageLoadId } from 'int-searchspring-api';
import { getStandardCookieValues } from 'int-searchspring-api/src/lib/stateMutations';
import { tryParseJSON } from 'shared-react-components';

export const getSearchspringCookies = ({ allowCookies = true }: { allowCookies: boolean }) => {
  if (!allowCookies) {
    return {
      ssUserId: '',
      ss_isuid: '',
      ssSessionIdNamespace: '',
      ssShopperId: '',
      ssCartProducts: '',
      ssViewedProducts: '',
      ssPageLoadId: getNewPageLoadId(''),
    }
  }

  const currentSessionId = getCookie('ssSessionIdNamespace')?.toString();
  const currentuserId = getCookie('ssUserId')?.toString();
  const currentisuid = getCookie('_isuid')?.toString();
  const { sessionId, userId, isuid } = getStandardCookieValues(
    currentSessionId,
    currentuserId,
    currentisuid,
  );

  if(!currentSessionId)
    setCookie('ssSessionIdNamespace', sessionId);
  if(!currentuserId)
    setCookie('ssUserId', userId, { path: '/', maxAge: 60 * 60 * 24 * 365 });
  if(!currentisuid)
    setCookie('_isuid', isuid, { path: '/', maxAge: 60 * 60 * 24 * 365 });

  const currentPageLoadIdCookie = getCookie('ssPageLoadId');
  const currentPageLoadIdCookieValue = currentPageLoadIdCookie?.toString();
  let currentPageLoadId: { path: string; identifier: string; } | undefined;
  if (currentPageLoadIdCookieValue) {
    const json = tryParseJSON<any>(currentPageLoadIdCookieValue.toString());
    if (json && json.path && json.identifier) {
      currentPageLoadId = {
        path: json.path,
        identifier: json.identifier
      }
    }
  }
  let pathname = '';
  if(typeof window !== 'undefined'){
    pathname = window.location.pathname;
  }
  const updatePageLoadId = getNewPageLoadId(pathname, currentPageLoadId);
  const updatePageLoadIdJSON = JSON.stringify(updatePageLoadId);
  if (updatePageLoadIdJSON !== currentPageLoadIdCookieValue)
    setCookie('ssPageLoadId', updatePageLoadIdJSON);

  const cookieState = {
    ssUserId: userId,
    ss_isuid: isuid,
    ssSessionIdNamespace: sessionId,
    ssShopperId: getCookie('ssShopperId')?.toString() ?? '',
    ssCartProducts: getCookie('ssCartProducts')?.toString() ?? '',
    ssViewedProducts: getCookie('ssViewedProducts')?.toString() ?? '',
    ssPageLoadId: updatePageLoadId,
  }
  return cookieState;
}

type SSOverrides = Partial<SearchspringState>;
export const getSearchspringState = ({ allowCookies = true, contentType = 'product', overrides }: { allowCookies?: boolean, contentType?: string, overrides?: SSOverrides}) => {
  const cookieState = getSearchspringCookies({ allowCookies });
  const state: SearchspringState = {
    siteId: contentType === 'articles' ? process.env.NEXT_PUBLIC_SS_CONTENT_INDEX! : process.env.NEXT_PUBLIC_SS_INDEX!,
    domain: process.env.NEXT_PUBLIC_DOMAIN_NAME ?? '',
    userId: cookieState.ssUserId,
    sessionId: cookieState.ssSessionIdNamespace,
    pageLoadId: cookieState.ssPageLoadId,
    isuid: cookieState.ss_isuid,
    shopper: cookieState.ssShopperId,
    lastViewed: cookieState.ssViewedProducts,
    cart: cookieState.ssCartProducts,
    language: '',
    suggestionCount: 0,
    productCount: 0,
    resultsFormat: 'json',
    resultsPerPage: 24,
    page: 0,
    redirectResponse: 'direct',
    profile: '',
  };
  return {...state, ...overrides};
}

export const getDefaultSearchspringState = ({ contentType = 'product', overrides }: { contentType?: string, overrides?: SSOverrides }): SearchspringState => {
  const state: SearchspringState = {
    siteId: contentType === 'articles' ? process.env.NEXT_PUBLIC_SS_CONTENT_INDEX! : process.env.NEXT_PUBLIC_SS_INDEX!,
    domain: process.env.NEXT_PUBLIC_DOMAIN_NAME ?? '',
    userId: '',
    sessionId: '',
    pageLoadId: {
      path: '',
      identifier: ''
    },
    isuid: '',
    shopper: '',
    lastViewed: '',
    cart: '',
    language: '',
    suggestionCount: 0,
    productCount: 0,
    resultsFormat: 'json',
    resultsPerPage: 24,
    page: 0,
    redirectResponse: 'direct',
    profile: '',
  };
  return {...state, ...overrides};
}

type BeaconConfigOverrides = Partial<BeaconConfig>;

export const getBeaconConfig = ({ allowCookies = true, contentType = 'product', overrides }: { allowCookies?: boolean, contentType?: string, overrides?: BeaconConfigOverrides}) => {
  const cookieState = getSearchspringCookies({ allowCookies });
  const beaconConfig: BeaconConfig = {
    profile: '',
    context: {
      userId: cookieState.ssUserId,
      sessionId: cookieState.ssSessionIdNamespace,
      pageLoadId: cookieState.ssPageLoadId.identifier,
      website: {
        trackingCode: contentType === 'articles' ? process.env.NEXT_PUBLIC_SS_CONTENT_INDEX! : process.env.NEXT_PUBLIC_SS_INDEX!,
      },
    },
    placement: 'home-page', //TODO create pages model and input this from builder
    seed: [{sku: ''}]
  };
  return {...beaconConfig, ...overrides}
}