interface ResultCountProps {
    count: any[];
    contentType: string
  }
  
const ResultCount: React.FC<ResultCountProps> = ({ count, contentType }) => {
    return (
        <div className="ss__count">
            { count.length > 0 && (
                <p>
                    {count.length} {contentType === 'articles' ? 'Article' : 'Product'}
                    {count.length > 1 ? 's' : ''}
                </p>
            )}
        </div>
    );
  };
  
  export default ResultCount;
