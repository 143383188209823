import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SortFunction, SortSubmissionOptions } from '../../interfaces';
import { ArrowDown } from "../Arrows/ArrowDown";
import { ArrowUp } from "../Arrows/ArrowUp";

import './sortby.css';

interface SortingProps {
  sorting?: {
    sorting: {
      options: SortSubmissionOptions[];
    };
  };
  sortFunction?: SortFunction;
  mobile?: boolean
}

export const SortByComponent: React.FC<SortingProps> = ({ sorting, sortFunction, mobile }) => {
  const [selectedOption, setSelectedOption] = useState<SortSubmissionOptions | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const activeOption = sorting?.sorting.options?.find(option => option.active === 1);
    const defaultOption = sorting?.sorting.options?.[1] || null; 
    setSelectedOption(activeOption || defaultOption);
  }, [sorting]);

  const handleSortChange = (field: string, direction: string) => {
    const selectedValue = sorting?.sorting.options?.find((option) => option.field === field && option.direction === direction);
    
    setSelectedOption(selectedValue || null);
    sortFunction && sortFunction(selectedValue || null);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 990px)'
  });

  const renderSortOptions = sorting?.sorting.options 
    .map((option, index) => (
      <li key={index} onClick={() => handleSortChange(option.field, option.direction)}>
        {option.label}
      </li>
    ));

  return (
    <div className={`ss__sortby-component ${isMobile ? 'ss__sortby-component-sm-md' : ''}`}>
      {isMobile && (
        <div className={`ss__select-wrapper ${!mobile ? 'ss__select-wrapper-sm' : ''}`}>
          <div className={`ss__custom-select ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
            <div className="ss__custom-select-label">
            <span className='ss__selected-option'>{selectedOption ? selectedOption.label : 'SELECT...'}</span> {isDropdownOpen ? <ArrowUp /> : <ArrowDown />}
            </div>
            <ul className="ss__custom-select-options">
              {renderSortOptions}
            </ul>
          </div>
        </div>
        ) 
      }

        <div className="ss__select-wrapper-md-lg">
          <div className={`ss__custom-select ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
            <div className="ss__custom-select-label-lg">
            {!isMobile ? <span>Sort By:</span> : null}<span className='ss__selected-option'>{selectedOption ? selectedOption.label : 'SELECT...'}</span> {isDropdownOpen ? <ArrowUp /> : <ArrowDown />}
            </div>
            <ul className="ss__custom-select-options">
              {renderSortOptions}
            </ul>
          </div>
        </div>    
    </div>
  );
};