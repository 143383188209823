'use client';

import { DeleteItemButton } from 'components/cart/delete-item-button';
import { EditItemQuantity } from 'components/cart/edit-item-quantity';
import GCImage from 'components/giftcard/image';
import useCart from 'components/hooks/use-cart';
import { AddWishlist } from 'components/login/add-to-wishlist';
import Price from 'components/price';
import type { CartLineItem as Item } from 'lib/bigcommerce/types';
import { Mapping, Offer } from 'lib/bigcommerce/types/mulberry';
import { DEFAULT_OPTION } from 'lib/constants';
import { createUrl } from 'lib/utils';
import Image from 'next/image';
import Link from "next/link";
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import * as MulberryService from '../product/mulberry-actions';
import { createMapping, mulberryAddWarrantyToCart } from '../product/mulberry-actions';
import { removeItem } from './actions';
import { MulberryItem, MulberryOffers } from './mulberry-item';

import './cart-items.css';

type MerchandiseSearchParams = {
  [key: string]: string;
};

const isUniqueOffer = (offer:any, seenOffers:any) => {
  return !seenOffers.has(offer.product_meta.id);
};

const imgDim = 98;

export const ProductCartItems = ({
    item,
    isMobile,
    showWishlist,
    fromMiniCart,
    cartId,
    index,
    customItem,
    context
}: {
    item: Item;
    isMobile?: boolean;
    showWishlist?: boolean
    fromMiniCart?: boolean
    cartId: string;
    index: number,
    customItem?: Item,
    context: 'cart' | 'mobile' | 'minicart'
}) => {
  
    const merchandiseSearchParams = {} as MerchandiseSearchParams;
    let subTitleWithSelectedOptions = '';

    item.merchandise.selectedOptions.forEach(({ name, value }) => {
      subTitleWithSelectedOptions += `${name}: ${value} `;
      if (value !== DEFAULT_OPTION) {
        merchandiseSearchParams[name.toLowerCase()] = value;
      }
    });

    const merchandiseUrl = createUrl(
      item.merchandise.product.handle,
      new URLSearchParams(merchandiseSearchParams)
    );

    const { mutate } = useCart();
    const [offers, setOffers] = useState<Offer[]>([]);
    const [refreshOffers, setRefreshOffers] = useState(false);
    const isMulberryInitializedRef = useRef(false);
    const seenOffersRef = useRef<Set<string>>(new Set());
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
      const initializeMulberry = async () => {
        if (item && !isMulberryInitializedRef.current) {
          try {
            const offerCall = await MulberryService.getWarrantyOffersCartItems(
              item.merchandise.title,
              item.merchandise.product.sku,
              item.merchandise.product.price.amount
            );
      
            const newUniqueOffers = offerCall.filter((offer: Offer) => isUniqueOffer(offer, seenOffersRef.current));
      
            if (newUniqueOffers.length > 0) {
              setOffers((prevOffers) => [...prevOffers, ...newUniqueOffers]);
              newUniqueOffers.forEach((offer:Offer) => seenOffersRef.current.add(offer.product_meta.id));
            }
      
            isMulberryInitializedRef.current = true;
          } catch (error) {
            console.error('Error initializing Mulberry services:', error);
          }
        }
      };
        initializeMulberry();
    }, [item]);

    useEffect(() => {
      if (offers.length > 0) {
        MulberryService.renderOffers(offers, 'radio', index, context);
      }
    }, [offers, refreshOffers, index, context]);

    const handleAddProtectionClick = async () => {
        if (window.selectedOffer) {
          setIsButtonDisabled(true);
            try {
              const storeHash = process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_HASH ?? '';
                if (cartId) {
                    const sku = (item.merchandise.product?.variants ? item?.sku : item.merchandise.product.sku) ?? '';
                    const response = await mulberryAddWarrantyToCart(storeHash, cartId, window.selectedOffer, item.quantity, sku );
                    if (response.ok) {
                        await mutate();
                    } else {
                        console.error('Error adding warranty to cart:', response);
                    }
                } else {
                    console.error('Cart ID is missing');
                }
            } catch (error) {
                console.error('Error adding warranty to cart:', error);
            }
        }
    };

    const handleRemove = async (itemId: string) => {
        try {
            const result = await removeItem(null, itemId);
            if (result.isSuccess) {
                await mutate(result);
                setRefreshOffers(!refreshOffers); 
            }
        } catch (error) {
            console.error('Error removing item from cart:', error);
        } finally {
          setIsButtonDisabled(false);
        }
    };

    return (
      <li
        className="mini-cart-line-item"
      >
        <div className="mini-cart-line-item-inner">
          <div className="mini-cart-line-item-image-link">
            <Link
              href={merchandiseUrl}

            >
              <div className="mini-cart-line-item-image">
                <Image
                  width={imgDim}
                  height={imgDim}
                  alt={
                    item.merchandise.product.featuredImage.altText ||
                    item.merchandise.product.title
                  }
                  src={item.merchandise.product.featuredImage.url}
                />
              </div>
            </Link>
          </div>
          <div className={fromMiniCart ? 'mini-cart-line-item-info' : 'cart-line-item-info'}>
            <div className='cart-line-item-info-subwrapper'>
              <Link
                href={merchandiseUrl}
                
                className="cart-info-wrapper-link"
              >
                <div className="mini-cart-line-item-info-stack">
                  <span className="cart-item-title">
                    {item.merchandise.product.title}
                  </span>
                  {item.merchandise.selectedOptions !== null && (
                    <div className="cart-item-options">
                      {item.merchandise.selectedOptions.map((option, i) => (
                        <p className="cart-item-option" key={i}>
                          {
                            option.name.includes('Payment') || option.name.includes('payment') ? null : `${option.name}: `
                          }
                          {option.value}
                        </p>
                      ))}
                    </div>
                  )}

                  {!isMobile && (
                    <>
                      {item.merchandise.product.inventory.aggregated.warningLevel > item.merchandise.product.inventory.aggregated.availableToSell
                        ? 
                          <span className="cart-item-stock cart-item-stock-warning">Only {item.merchandise.product.inventory.aggregated.availableToSell} Left</span> 
                        : <span className="cart-item-stock">In Stock</span>
                      }

                      {item.merchandise.product.productMetafields.map((field, i) => (
                        field.key === 'cart-sale-messaging' && <div className="cart-line-item-sale-message" key={i} dangerouslySetInnerHTML={{ __html: field.value as string }} />
                      ))}
                    </>
                  )}
                </div>
              </Link>

              {customItem ? (
                  !isMobile && !fromMiniCart && (<MulberryItem key={customItem.id} customItem={customItem} handleRemove={handleRemove} />)
                ) : (
                  offers.length > 0 && !fromMiniCart && (
                    <div className='mulberry-cart-offers-large'>
                      <MulberryOffers index={index} handleAddProtectionClick={handleAddProtectionClick} isMobile={false} fromMiniCart={false} isButtonDisabled={isButtonDisabled} />
                    </div>
                  )
                )
              }
            </div>
            
            <div className="cart-item-right">
              <div className="cart-item-pricing">
                  {item.merchandise.product.variants.map((variant) => (
                    item.sku === variant.sku && Number(variant.salePrice.amount) > 0
                      && <>
                          <div className="cart-item-sale-pricing">
                            <Price
                              className="cart-item-price cart-item-sale-price"
                              amount={variant.salePrice.amount}
                              currencyCode={item.cost.totalAmount.currencyCode}
                            />
                            <span className="cart-item-original-price">
                              <s>
                                <Price
                                  className="cart-item-price"
                                  amount={variant.basePrice.amount}
                                  currencyCode={item.cost.totalAmount.currencyCode}
                                />
                              </s>
                            </span>
                          </div>
                          {item.merchandise.product.productMetafields.map((field, i) => (
                            field.key === 'promotion-text' && <div className="cart-line-item-promotion-text" key={i} dangerouslySetInnerHTML={{ __html: field.value as string}} />
                          ))}
                        </>
                  ))}

                  {item.merchandise.product.variants.map((variant) => (
                    item.sku === variant.sku && Number(variant.salePrice.amount) === 0
                      && <Price
                          className="cart-item-price"
                          amount={item.cost.listPrice.amount}
                          currencyCode={item.cost.totalAmount.currencyCode}
                          key={item.sku}
                        />
                  ))}

                {isMobile && (
                  <>
                    {item.merchandise.product.inventory.aggregated.warningLevel > item.merchandise.product.inventory.aggregated.availableToSell
                      ? 
                        <span className="cart-item-stock cart-item-stock-warning">Only {item.merchandise.product.inventory.aggregated.availableToSell} Left</span> 
                      : <span className="cart-item-stock">In Stock</span>
                    }

                    {item.merchandise.product.productMetafields.map((field, i) => (
                      field.key === 'cart-sale-messaging' && <div className="cart-line-item-sale-message" key={i} dangerouslySetInnerHTML={{ __html: field.value as string }} />
                    ))}
                  </>
                )}  
                </div>
                
              <div className="cart-line-item-actions">
                <div className="cart-line-item-quantity-box">
                  <EditItemQuantity item={item} customItem={customItem} />
                </div>
                {isMobile ? (
                    <div className="cart-line-item-delete-sm cart-line-item-delete">
                      <DeleteItemButton item={item} />
                      {showWishlist ? ( <AddWishlist product={item.merchandise.product} text={'Move to Wishlist'}/>): null }
                    </div>
                ): (
                  <div className="cart-line-item-delete">
                    <DeleteItemButton item={item} customItem={customItem} />
                </div>
                )}
                
              </div>
              
              {customItem ? (
                <>
                  {isMobile && !fromMiniCart && <MulberryItem customItem={customItem} handleRemove={handleRemove} />}
                  {fromMiniCart && <MulberryItem customItem={customItem} handleRemove={handleRemove} />}
                </>
                ) : (
                  offers.length > 0 && (
                    <>
                      {fromMiniCart ? (
                        <div className='mulberry-mini-cart'>
                          <MulberryOffers index={index} handleAddProtectionClick={handleAddProtectionClick} isMobile={true} fromMiniCart={true} isButtonDisabled={isButtonDisabled}/>
                        </div>
                      ) : (
                        <div className='mulberry-cart-offers-small-medium'>
                          <MulberryOffers index={index} handleAddProtectionClick={handleAddProtectionClick} isMobile={true} fromMiniCart={false} isButtonDisabled={isButtonDisabled}/>
                        </div>
                      )}
                    </>
                  )
                )}
                {!isMobile && (
                   showWishlist ? ( <AddWishlist product={item.merchandise.product} text={'Move to Wishlist'}/>): null 
                )}
            </div> 
          </div> 
        </div>
      </li>
    );
}

export const GcCartItems = ({
    item,
    isMobile
}: {
    item: Item;
    isMobile?: boolean;
}) => {

    const merchandiseUrl = createUrl('/giftcard',new URLSearchParams());
    
    return (
      <li
        className="mini-cart-line-item mini-cart-line-item--gc"
      >
        <div className="mini-cart-line-item-inner">
          <div className="mini-cart-line-item-image-link">
            <Link
              href={merchandiseUrl}

            >
              <div className="mini-cart-line-item-image">
                <GCImage dim={imgDim} />
              </div>
            </Link>
          </div>
          <div className="cart-line-item-info">
            <Link
              href={merchandiseUrl}
              
              className="cart-info-wrapper-link"
            >
              <div className="mini-cart-line-item-info-stack">
                <span className="cart-item-title">
                  {item.merchandise.title}
                </span>
              </div>
            </Link>
            <div className="cart-item-right cart-item-right-gc">
                <div className="cart-item-pricing">
                    <Price
                        className="cart-item-price"
                        amount={item.cost.listPrice.amount}
                        currencyCode={item.cost.totalAmount.currencyCode}
                        key={item.id}
                    />
                </div>
                
              <div className="cart-line-item-actions">
                {isMobile ? (
                    <div className="cart-line-item-delete-sm cart-line-item-delete">
                      <DeleteItemButton item={item} />
                    </div>
                ): (
                  <div className="cart-line-item-delete">
                    <DeleteItemButton item={item} />
                </div>
                )}
                
              </div>
            </div> 
          </div> 
        </div>
      </li>
    );
}

export const CustomCartItems = ({
    item,
    isMobile
}: {
    item: Item;
    isMobile?: boolean;
}) => {
    //Custom Items will all have custom display
    return false
}




export default function CartItems() {
    const isMobile = useMediaQuery({
      query: '(max-width: 990px)'
    });

    const { cart } = useCart();
    const activeCart = cart;

    const [mapping, setMapping] = useState<Mapping[]>([]);

    useEffect(() => {
      if (activeCart) {
        const newMapping = createMapping(activeCart);
        setMapping(newMapping);
      }
    }, [activeCart]);

    return (
        activeCart ? (
          <>
            <div className="cart-line-items-container">
              <div className="cart-line-items">
                <ul>
                  {activeCart?.lines?.map((item, i) => {
                    if (item?.type === 'giftCertificate') {
                        return <GcCartItems key={i} item={item} isMobile={isMobile} />;
                    } else if (item?.type === 'custom') {
                        return <CustomCartItems key={i} item={item} isMobile={isMobile} />;
                    } else {
                      const mappingEntry = mapping.find(map => map.productId === item.id);
                      const customItem = mappingEntry ? cart?.lines.find(line => line.id === mappingEntry.customItemId) : undefined;
                      return (
                        <ProductCartItems 
                          cartId={activeCart.id} 
                          index={i} 
                          key={i} 
                          item={item} 
                          isMobile={isMobile} 
                          showWishlist={true}
                          customItem={customItem} 
                          context={'cart'}
                        />
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </>
        ) : (
          null
        )
    );
}
