import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { RefinementFunction, SortFunction } from '../../interfaces';
import FacetDropdown, { FacetProps } from '../Facet';
import { EmptyStar, Star } from '../ProductCard/StarIcon';
import { SortByComponent } from '../Sort/SortByComponent';
import { Close } from '../icons/icon-close';
import { CloseMobile } from '../icons/icon-close-mobile';
import './sidebar.css';

export type RefinementSidebarProps = {
  data: any;
  searchData: any;
  facets: FacetProps[];
  refinementFunction: RefinementFunction;
  sortFunction: SortFunction;
}

export const RefinementSidebar: React.FC<RefinementSidebarProps> = (props) => {
  const { refinementFunction, sortFunction } = props;
  const facets = props.searchData?.facets || props.data?.facets || [];
  const filters = props.searchData?.filterSummary || props.data?.filterSummary || [];
  const results = props.searchData?.results || props.data?.results || [];
  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    if (showMobileModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [showMobileModal]);
  
  const handleRemoveFilter = (filterField: string, filterLabel: string, filterValue?: any) => {
    if (!filterField && !filterLabel) {
      refinementFunction({
        clear_all: true,
        type: undefined,
        field: '',
        multiple: undefined
      });
    } else {
    
    const [field, value] = filterLabel.split(':');

    refinementFunction({
      field: filterField, value: value?.trim(),
      type: undefined,
      multiple: undefined, 
      low: filterValue.rangeLow,
      high: filterValue.rangeHigh
    })
  }
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 990px)'
  })

  const handleToggleMobileModal = () => {
    setShowMobileModal(!showMobileModal);
  };

  return (
    <>
      {isMobile && <button className='mobile-modal-btn' onClick={handleToggleMobileModal}>Sorts & Filters</button>}
        {isMobile && showMobileModal && (
        <div className={`mobile-modal`}>         
          <div className='ss__close-sm'>
            <span>SORT & FILTER</span>
            <span className='ss__close-btn-sm'onClick={handleToggleMobileModal}><CloseMobile/></span>
          </div>
          <div className='ss__sortby-label-container'>
            <span className='ss__sortby-label'>Sort By</span>
          </div>
           <SortByComponent sorting={props.searchData} sortFunction={sortFunction} mobile={true}/>
          {/* Your mobile modal content */}
          <span className='ss__filter-label md:mb-3 sm:mb-2'>Filter by</span>
          {filters.length > 0 && (
            <div className="ss__filters-wrapper">
              <ul className="ss__filters">
                {filters.map((filter:any) => (
                  <li className="ss__filters__filter" key={filter.label} onClick={() => handleRemoveFilter(filter.field, filter.label, filter.value)}>
            
                    <span className='ss__filter-applied' title={`Remove filter ${filter.label}`}>
                      <>
                        {filter.field === 'totalrating' ? (
                          <span className="facet-ratings">
                            {Array.from({ length: 5 }, (_, i) => i + 1).map((rating) => (
                              filter.value?.rangeLow >= rating 
                                ? <Star key={`star-${rating}`} /> 
                                : filter.value.rangeLow >= (rating - 0.5) 
                                  ? <Star key={`star-half-${rating}`} /> 
                                  : <EmptyStar key={`empty-star-${rating}`} />
                            ))}
                            <span className='rating-filter-suffix'>{filter.value.rangeLow < 5 ? '& up' : ''}  <Close /></span>
                          </span>
                        ) : (
                          <span>
                            {filter.label.split(':')[1]}
                            <Close />
                          </span>
                        )}
                      </>
                    </span> 
                  </li>
                ))}
              </ul>
              <span className="ss__filters__clear-all" onClick={() => handleRemoveFilter('', '')}>
                Clear All
              </span>
            </div>
          )}

          <div className={`ss__facets-wrapper sm:basis-full sm:w-full md:w-full`}>
            {facets.map((facet:any, index: number) => (
              <FacetDropdown key={facet.field} {...facet} facet_active={index === 0} refinementFunction={refinementFunction} />
            ))}
          </div>
 
          <div className='ss__view-results'><button  onClick={handleToggleMobileModal}>View Results ({results.length}) </button></div>
        </div>
      )}

      {!isMobile && (
        <div className='ss__filter-wrapper'>
          <label className='ss__filter-label md:mb-3 sm:mb-3'>Filter by</label>
          {filters.length > 0 && (
            <div className="ss__filters-wrapper">
              <ul className="ss__filters">
                {filters.map((filter:any) => (
                  <li className="ss__filters__filter" key={filter.label} onClick={() => handleRemoveFilter(filter.field, filter.label, filter.value)}>
                    <span className='ss__filter-applied' title={`Remove filter ${filter.label}`}>
                      <>
                          {filter.field === 'totalrating' ? (
                            <span className="facet-ratings">
                              {Array.from({ length: 5 }, (_, i) => i + 1).map((rating) => (
                                filter.value?.rangeLow >= rating 
                                  ? <Star key={`star-${rating}`} /> 
                                  : filter.value.rangeLow >= (rating - 0.5) 
                                    ? <Star key={`star-half-${rating}`} /> 
                                    : <EmptyStar key={`empty-star-${rating}`} />
                              ))}
                              <span className='rating-filter-suffix'>{filter.value.rangeLow < 5 ? '& up' : ''}  <Close /></span>
                            </span>
                          ) : (
                            <span>
                              {filter.label.split(':')[1]}
                              <Close />
                            </span>
                          )}
                        </>
                    </span>
                  </li>
                ))}
              </ul>
              <span className="ss__filters__clear-all" onClick={() => handleRemoveFilter('', '')}>
                Clear All
              </span>
            </div>
          )}
        <div className={`sm:basis-full sm:w-full md:w-full`}>
          {facets.map((facet:any, index: number) => (
            <FacetDropdown key={facet.field} {...facet} facet_active={index === 0} refinementFunction={refinementFunction} />
          ))}
        </div>
        </div>
       )}
      
    </>
  );
};
