'use client'

import { data, tag, user } from '@blotoutio/edgetag-sdk-js';
import { Cart, CartLineItem, Product, ProductVariant } from 'lib/bigcommerce/types';
import { absoluteUrl, getSalePrice } from 'lib/utils';

type AddToCartProps = { cart: Cart, lineItem: CartLineItem, quantity: number };
export const addToCart = ({ cart, lineItem, quantity }: AddToCartProps) => {
  if (typeof window === 'undefined') return {};

  const salePrice = tryParseMoney((lineItem.cost.salePrice ?? lineItem.cost.listPrice).amount);
  const value = salePrice ? (salePrice * quantity) : undefined;

    tag('AddToCart', {
        currency: lineItem.cost.listPrice.currencyCode ?? 'USD',
        value: value?.toFixed(2),
        contents: [blotoutItemContents({ lineItem, quantity})],
        checkoutUrl: cart.checkoutUrl
    });
}

export const lead = (location: string) => {
    if (typeof window === 'undefined') return {};
    tag('Lead', {name: location});
}

export const userData = (email: string, city?: string) => {
  if (typeof window === 'undefined') return {};

  if (!city) {
    user('email', email);
  } else {
    data({
      email: email,
      city: city
    });
  }
}

export const viewItem = ({ product, sku }: { product: Product, sku?: string }) => {
  if (typeof window === 'undefined') return {};

  const variant = product.variants.find(v => v.sku === sku) ?? product.variants.at(0);
  const salePrice = getSellingPrice(product, variant);

  tag('ViewContent', {
    currency: salePrice.currencyCode ?? 'USD',
    value: stringToFixed(salePrice.amount),
    contents: [blotoutViewItem({product, variant})]
  })
}

// Utility Functions

const stringToFixed = (money?: string) => {
  return tryParseMoney(money)?.toFixed(2)
};

const tryParseMoney = (money?: string) => {
  try {
    return money ? parseFloat(money) : undefined;
  }
  catch { }
};

const getSellingPrice = (product: Product, variant?: ProductVariant) => {
  const variantPrice = variant && getSalePrice(variant);
  const productPrice = getSalePrice(product);
  return variantPrice ?? productPrice;
}

  // Mapper Functions
type CartLineItemProps = { lineItem: CartLineItem, quantity?: number};
const blotoutItemContents = ({ lineItem, quantity }: CartLineItemProps) => {
  const product = lineItem.merchandise.product;
  const variant = lineItem.merchandise.product.variants.find(v => lineItem.sku === v.sku);
  const salePrice = lineItem.cost.salePrice ?? lineItem.cost.listPrice;
  const categories = product.categories.map((category) => category.name).toString();

    return {
        id: lineItem.sku || lineItem.id,
        quantity: quantity ?? lineItem.quantity,
        item_price: stringToFixed(salePrice.amount),
        variantId: variant?.sku,
        title: lineItem.merchandise.title,
        description: product.description,
        brand: product.brand,
        category: categories,
        image: product.images[0]?.url,
        url: absoluteUrl(product.handle)
    };
};

type ViewItemProps = { product: Product, variant?: ProductVariant }
const blotoutViewItem = ({ product, variant }: ViewItemProps) => {
  const salePrice = getSellingPrice(product, variant);

  const categories = product.categories.map((category) => category.name).toString();

  return {
    id: product.sku,
    quantity: 1,
    item_price: stringToFixed(salePrice.amount),
    variantId: variant?.sku,
    title: product.title,
    description: product.description,
    brand: product.brand,
    category: categories,
    image: product.images[0]?.url,
    url: absoluteUrl(product.handle)
};
};