import { Product } from 'lib/bigcommerce/types';
import { useEffect, useRef, useState } from 'react';
import * as MulberryService from './mulberry-actions';
import './mulberry.css';

export function Mulberry({ product, attributes }: { product: Product, 
    attributes: {
        style: any, 
        className: string, 
        'builder-id': string | undefined,
        id: string
    } }) {
    const [offer, setOffer] = useState<Array<any>>([]);
    const isMulberryInitializedRef = useRef(false);

    useEffect(() => {
        const initializeMulberry = async () => {
            if (product && !isMulberryInitializedRef.current) {
                try {
                    MulberryService.Init();
                    const offer = await MulberryService.getWarrantyOffer(product.title, product.sku, product.price.amount);
                    setOffer(offer);
                    isMulberryInitializedRef.current = true;
                } catch (error) {
                    console.error('Error initializing Mulberry services:', error);
                }
            }
        };
        initializeMulberry();
    }, [product]);

    useEffect(() => {
        if (offer && offer.length > 0) {
            document.querySelector('.mulberry__wrapper')?.classList.remove('hide-container');
            MulberryService.renderOffers(offer, 'buttons', 0, 'pdp');
        }
    }, [offer]);

    return (
        <div 
        id={attributes.id} 
        style={attributes.style} 
        className={`mulberry__wrapper buystack-container hide-container ${attributes.className}`}  
        builder-id={attributes['builder-id']}>
            <div className="mulberry-inline-container-0"></div>
        </div>
    );
}
