'use client';

import Price from 'components/price';
import { useProductOptions } from 'components/providers/product-options-provider';
import {
  Money,
  VercelProduct as Product,
  VercelProductOption as ProductOption,
  VercelProductVariant as ProductVariant
} from 'lib/bigcommerce/types';
import { useEffect } from 'react';
import { getSelectedValues } from './helpers';
import './variant-selector.css';

type Combination = {
  id: string;
  availableForSale: boolean;
  price: Money;
  options: Record<string, string>; // ie. { color: 'Red', size: 'Large', ... }
};

export function VariantSelector({
  options,
  product,
  variants,
  attributes
}: {
  options: ProductOption[];
  product: Product;
  variants: ProductVariant[];
    attributes: {
        style: any, 
        className: string, 
        'builder-id': string | undefined,
        id: string
    }
}) {
  const {optionSearchParams, setOptionSearchParams} = useProductOptions();
  const selectedValues = getSelectedValues(options, optionSearchParams);

  useEffect(() => {
    if (variants.length > 0) {
        document.querySelector('.variant-selection-wrapper')?.classList.remove('hide-container');
    }
  }, [variants]);
  
  const hasNoOptionsOrJustOneOption =
    !options.length || (options.length === 1 && options[0]?.values.length === 1);

  if (hasNoOptionsOrJustOneOption) {
    return null;
  }

  const combinations: Combination[] = variants.map((variant) => ({
    id: variant.id,
    availableForSale: variant.availableForSale,
    price: variant.price,
    // Adds key / value pairs for each variant (ie. "color": "Black" and "size": 'M").
    options: variant.selectedOptions.reduce(
      (accumulator, option) => ({ ...accumulator, [option.name.toLowerCase()]: option.value }),
      {}
    )
  }));

  return (
    <div 
        id={attributes.id} 
        style={attributes.style} 
        className={`variant-selection-wrapper buystack-container hide-container ${attributes.className}`}  
        builder-id={attributes['builder-id']}
    >
      {options.map((option) => (
        <dl 
          className={
            `variant-options-container ${option.displayStyle === 'RadioButtons' ? `variant-options-radios` : option.displayStyle === 'RectangleBoxes' ? `variant-options-boxes` : ``}`
          }
          key={option.id}
        >
          <dt className="variant-options-title">{option.name}</dt>
          <dd className="variant-options">
            {option.values.map((value) => {
              const optionNameLowerCase = option.name.toLowerCase();

              // Base option params on current params so we can preserve any other param state in the url.
              const optionSearchParams = new URLSearchParams(selectedValues);

              // Update the option params using the current option to reflect how the url *would* change,
              // if the option was clicked.
              optionSearchParams.set(optionNameLowerCase, value);

              // In order to determine if an option is available for sale, we need to:
              //
              // 1. Filter out all other param state
              // 2. Filter out invalid options
              // 3. Check if the option combination is available for sale
              //
              // This is the "magic" that will cross check possible variant combinations and preemptively
              // disable combinations that are not available. For example, if the color gray is only available in size medium,
              // then all other sizes should be disabled.
              const filtered = Array.from(optionSearchParams.entries()).filter(([key, value]) =>
                options.find(
                  (option) => option.name.toLowerCase() === key && option.values.includes(value)
                )
              );
              const combination = combinations.find((combination) =>
                filtered.every(
                  ([key, value]) => combination.options[key] === value
                )
              );
              const isAvailableForSale = combination?.availableForSale;
              const price = combination?.price;

              // The option is active if it's in the url params.
              const isActive = selectedValues[optionNameLowerCase] === value;

              const isSubscription = option.name.toLowerCase().includes('subscription') || option.name.toLowerCase().includes('subscribe');
              const isPaymentPlan = option.name.toLowerCase().includes('payment');

              return (
                option.displayStyle === 'RadioButtons' && isPaymentPlan ? (
                  <button
                    key={value}
                    aria-disabled={!isAvailableForSale}
                    disabled={!isAvailableForSale}
                    onClick={() => {
                      setOptionSearchParams(optionSearchParams);
                    }}
                    title={`${option.name} ${value}${!isAvailableForSale ? ' (Out of Stock)' : ''}`}
                    className={`variant-option${isActive ? ' variant-option-selected' : ''}`}
                  >
                    <span className="variant-option-circle" aria-hidden="true"></span>

                    <div className="variant-option-content">
                      <div className="variant-option-text">
                        <div className={`variant-option-cost`}>
                          <div className="variant-option-label">
                            {value}
                          </div>

                          {price && (
                            <div className="variant-option-price">
                              {variants.map((variant) => (
                                variant.selectedOptions.map((selectedOption, i) => (
                                  selectedOption.name === option.name && selectedOption.value === value && price.amount === variant.price.amount && (
                                    <div key={i}>
                                      {(variant.salePrice.amount && Number(variant.salePrice.amount) > 0) && (Number(variant.salePrice.amount) < Number(variant.basePrice.amount)) ? (
                                        <span className="variant-option-sale-pricing">
                                          <span className="variant-option-sale-price">
                                            <Price
                                              amount={variant.salePrice.amount}
                                              currencyCode={variant.salePrice.currencyCode}
                                            /> Today
                                          </span>
                                          &nbsp;
                                          <span className="variant-option-base-price">
                                            <Price
                                              amount={variant.basePrice.amount}
                                              currencyCode={variant.basePrice.currencyCode}
                                            />
                                          </span>
                                        </span>
                                      ) : (
                                        price && (
                                          <>
                                            <Price
                                              amount={price.amount}
                                              currencyCode={price.currencyCode}
                                            /> Today
                                          </>
                                        )
                                      )}
                                    </div>
                                  )
                                ))
                              ))}
                            </div>
                          )}
                        </div>

                        {variants.map((variant) => (
                          variant.selectedOptions.map((selectedOption) => (
                            selectedOption.name === option.name && selectedOption.value === value && (
                              variant.paymentMetafields.map((field: any, i) => (
                                field.key === 'num-remaining-payments' && (
                                  <span 
                                    className="variant-option-message"  
                                    key={i}
                                  >
                                    + {field.value} more monthly {Number(field.value) > 1 ? 'payments' : 'payment'} of&nbsp;
                                  </span>
                                )
                              ))
                            )
                          ))
                        ))}
                        {variants.map((variant) => (
                          variant.selectedOptions.map((selectedOption) => (
                            selectedOption.name === option.name && selectedOption.value === value && (
                              variant.paymentMetafields.map((field: any, i) => (
                                field.key === 'additional-payments-amount' && (
                                  <span 
                                    className="variant-option-message"  
                                    key={i}
                                  >
                                    <Price
                                      amount={field.value}
                                      currencyCode={variant.price.currencyCode}
                                    />
                                  </span>
                                )
                              ))
                            )
                          ))
                        ))}
                      </div>
                    </div>
                  </button>
                ) : option.displayStyle === 'RadioButtons' && isSubscription ? (
                  <button
                    key={value}
                    aria-disabled={!isAvailableForSale}
                    disabled={!isAvailableForSale}
                    onClick={() => {
                      setOptionSearchParams(optionSearchParams)
                    }}
                    title={`${option.name} ${value}${!isAvailableForSale ? ' (Out of Stock)' : ''}`}
                    className={`variant-option subscription-option${isActive ? ' variant-option-selected' : ''}`}
                  >
                    <span className="variant-option-circle" aria-hidden="true"></span>

                    <div className="variant-option-content">
                      <div className="variant-option-text">
                        <div className="variant-option-cost">
                          {price && (
                            <div className="variant-option-price">
                              {variants.map((variant) => (
                                variant.selectedOptions.map((selectedOption, i) => (
                                  selectedOption.name === option.name && selectedOption.value === value && price.amount === variant.price.amount && (
                                    <div key={i}>
                                      {(variant.salePrice.amount && Number(variant.salePrice.amount) > 0) && (Number(variant.salePrice.amount) < Number(variant.basePrice.amount)) ? (
                                        <span className="variant-option-sale-pricing">
                                          <span className="variant-option-sale-price">
                                            <Price
                                              amount={variant.salePrice.amount}
                                              currencyCode={variant.salePrice.currencyCode}
                                            />
                                          </span>
                                          &nbsp;
                                          <span className="variant-option-base-price">
                                            <Price
                                              amount={variant.basePrice.amount}
                                              currencyCode={variant.basePrice.currencyCode}
                                            />
                                          </span>
                                        </span>
                                      ) : (
                                        price && (
                                          <Price
                                            amount={price.amount}
                                            currencyCode={price.currencyCode}
                                          />
                                        )
                                      )}
                                    </div>
                                  )
                                ))
                              ))}
                            </div>
                          )}

                          &nbsp;

                          <div className="variant-option-label">
                            <span className="subscription-option-units">
                              ({price && variants.map((variant) => (
                                variant.selectedOptions.map((selectedOption) => (
                                  selectedOption.name === option.name && selectedOption.value === value && price.amount === variant.price.amount && (
                                    variant.paymentMetafields.map((field: any, i) => (
                                      field.key === 'subscription-num-units' && (
                                        <Price
                                          amount={(Number(variant.price.amount) / Number(field.value)).toString()}
                                          currencyCode={variant.price.currencyCode}
                                          key={i}
                                        />
                                      )
                                    ))
                                  )
                                ))
                              ))}

                              &nbsp;/&nbsp;

                              {price && variants.map((variant) => (
                                variant.selectedOptions.map((selectedOption) => (
                                  selectedOption.name === option.name && selectedOption.value === value && price.amount === variant.price.amount && (
                                    variant.paymentMetafields.map((field: any, i) => (
                                      field.key === 'subscription-unit' && <span key={i}>{field.value}</span>
                                    ))
                                  )
                                ))
                              ))})

                              &nbsp;

                              <span className="subscription-option-value">{value}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                ) : option.displayStyle === 'RectangleBoxes' && (
                  <button
                    key={value}
                    aria-disabled={!isAvailableForSale}
                    disabled={!isAvailableForSale}
                    onClick={() => {
                      setOptionSearchParams(optionSearchParams)
                    }}
                    title={`${option.name} ${value}${!isAvailableForSale ? ' (Out of Stock)' : ''}`}
                    className={`variant-option${isActive ? ' variant-option-selected' : ''}`}
                  >
                    {value}
                  </button>
                )
              );
            })}
          </dd>
        </dl>
      ))}
    </div>
  );
}
