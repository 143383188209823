import { FacetTypes, MultipleValuesOptions, RefinementFunction } from "propel-shared-utility";
import React, { useEffect, useState } from "react";
import { ArrowDown } from "./Arrows/ArrowDown";
import { ArrowUp } from "./Arrows/ArrowUp";
import { EmptyStar, Star } from "./ProductCard/StarIcon";


export type FacetProps = {
    label?: string;
    field?: string;
    values: {
        label?: string;
        active?: boolean;
        value?: string | number;
        count?: number;
        type?: string;
        low?: number;
        high?: number;
    }[];
    range?: [number, number];
    active? : [number, number];
    collapse?: number;
    step?: number;
    type?: FacetTypes;
    multiple?: MultipleValuesOptions;
    facet_active?: number;
};


type FacetPropsWithRefinementFunc = FacetProps & {
    refinementFunction: RefinementFunction;
};

function FacetLoader({ className }: { className: string }) {
    const dots = 'ss__facet-loading-dot mx-[1px] inline-block h-1 w-1 animate-blink rounded-md';
    return (
      <span className={`ss__facet-loading-dots loading-dots mx-2 inline-flex items-center justify-center ${className}`}>
        <span className={`${dots}`} />
        <span className={`${dots} animation-delay-[200ms]`} />
        <span className={`${dots} animation-delay-[400ms]`} />
      </span>
    );
}

function handleize(input: string): string {
	if (typeof input != 'string') {
		return input;
	}

	let handleized = input.toLowerCase();
	handleized = handleized.replace(/[^\w\s]/g, '').trim();
	handleized = handleized.replace(/\s/g, '-');
	return handleized;
}

export const FacetDropdown: React.FC<FacetPropsWithRefinementFunc> = (props: FacetPropsWithRefinementFunc) => {
    const { label, type, collapse} = props;
    const [isOpen, setIsOpen] = useState(collapse == 0);

    return (
        <div className={`ss__facet-container group ${isOpen ? 'is-open' : ''}`}>
            <button className="flex flex-nowrap p-3 items-center w-full" onClick={() => {
                setIsOpen(!isOpen);
            }}>
                <div className="ss__facet-label mr-auto">{label}</div>
                {isOpen ? 
                    <ArrowUp/>
                    : <ArrowDown/>
                }
            </button>
            <div className="ss__facet-options overflow-hidden ml-4 max-h-0 duration-500 group-[.is-open]:max-h-full">
                {(()=> {
                    switch(type) {
                        case 'hierarchy':
                            return <CategoryHierarchyFacet {...props} />;
                        case 'slider':
                            return <h1>Slider component placeholder</h1>;
                        case 'palette':
                            return <FacetPallete {...props} />
                        default:
                            return <FacetList {...props} />
                    }
                })()}
            </div>
        </div>
    );
};

const FacetList: React.FC<FacetPropsWithRefinementFunc> = ({refinementFunction, values, field, type, multiple}: FacetPropsWithRefinementFunc) => {
    const [showAll, setShowAll] = useState(false);
    const limit = 10;

    const visibleValues = showAll ? values : values?.slice(0, limit);

    const toggleShowAll = () => {
        setShowAll((prevShowAll) => !prevShowAll);
  };
    return (
        <>
            {visibleValues?.map((value) => (
                    <div className={values.length < limit ? 'ss__option-nonlimited': 'ss__option'} key={value.type === "range" ? `${field}-${value.low}${value.high}` : `${field}-${value.value}`} >
                    <input id={value.type === "range" ? `${field}-${value.low}${value.high}` : `${field}-${value.value}`} style={{ display: 'none' }} type="checkbox" name={value.type === "range" ? `${field}-${value.low}${value.high}` : `${field}-${value.value}`}   
                        onChange={(e) => refinementFunction({field: (field as string), value: value.value, type, multiple, checked: !value.active, low:value?.low, high: value?.high })}
                        checked={value.active}
                    />
                    
                    <label
                        className={`ss__facet-option-label ml-2`}
                        htmlFor={value.type === "range" ? `${field}-${value.low}${value.high}` : `${field}-${value.value}`}
                        style={{ cursor: 'pointer' }}
                    >
                        <span className="ss__checkbox ss__facet-rating-options__checkbox" aria-label={`${value.active ? 'checked' : 'unchecked'} checkbox`} role="checkbox">
                            {value.active ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="23" height="23" rx="2.5" fill="white" stroke="#BBBBBB"/><path d="M5 11L10.5 16.5L20 7" stroke="#1E3665" strokeWidth="3"/></svg>  :  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="23" height="23" rx="2.5" fill="white" stroke="#BBBBBB"/></svg> }                           
                        </span>
                        {field === "totalrating"  ? (
                        <span className="facet-ratings">
                            {Array.from({ length: 5 }, (_, i) => i + 1).map((rating) => {
                            const lowValue = value?.low ?? 0;
                            return (
                                lowValue >= rating 
                                ? <Star key={`star-${rating}`} /> 
                                : lowValue >= (rating - 0.5) 
                                    ? <Star key={`star-half-${rating}`} /> 
                                    : <EmptyStar key={`empty-star-${rating}`} />
                            );
                            })}
                             <span className="facet-ratings-suffix">{value?.low ?? 0 < 5 ? '& up' : ''} ({value.count})</span>
                        </span>
                        ) : (
                        <>
                            {value.label}
                            <span>({value.count})</span>
                        </>
                        )}
                    </label>
                    </div>
                )
            )}
            {values.length > limit && (
            <div className="ss__facets-view-more" onClick={toggleShowAll}>
               {showAll ? `Show Less` : `Show ${values?.length - limit} more`} {showAll ? <ArrowUp /> : <ArrowDown />}
            </div>
      )}
        </>
    )
    }

const FacetPallete: React.FC<FacetPropsWithRefinementFunc> = ({refinementFunction, values, field, type, multiple}: FacetPropsWithRefinementFunc) => {

    // only handles a really basic color pallete right now
    return (
        <div className="w-full flex gap-2 flex-wrap">
            {values?.map((val) => (
                <div key={`${field}-${val.value}`} className={`basis-1/4 p-1 flex flex-wrap justify-center cursor-pointer ${val.active ? 'border-4 border-slate-300' : ''}`} onClick={() => refinementFunction({ field: (field as string), type, multiple, value: val.value, checked: !val.active})}>
                    <div className="w-12 h-12" style={{background: handleize(String(val.value))}}></div>
                    <div className="w-full">{val.label}</div>
                </div>
            )
            )}
        </div>
    )
}

const CategoryHierarchyFacet: React.FC<FacetPropsWithRefinementFunc> = ({ refinementFunction, field, type, multiple, label, values }) => {
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState<string[]>([]);
    const [currentValues, setCurrentValues] = useState(values);
    const limit = 10;

    useEffect(() => {
        setIsLoading(false);
        setCurrentValues(showAll ? values : values.slice(0, limit));
    }, [showAll, values]);

    const initializeFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const categoryPath = urlParams.get(`filter.${field}`);
        if (categoryPath) {
            const categories = categoryPath.split('>');
            setBreadcrumb(categories);
            refinementFunction({
                field: field as string,
                value: categoryPath,
                type,
                multiple,
                checked: true,
            });
        }
    };

    useEffect(() => {
        initializeFromUrl();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleShowAll = () => {
        setShowAll(prevShowAll => !prevShowAll);
    };

    const handleViewAllClick = () => {
        setIsLoading(true);
        setBreadcrumb([]);
        setCurrentValues([]);
        refinementFunction({
            field: field as string,
            value: '',
            type,
            multiple,
            checked: false,
            view_all: true,
        });
    };

    const handleParentClick = (index: number, isLast: boolean) => {
        if(!isLast) setIsLoading(true);
        const newBreadcrumb = breadcrumb.slice(0, index + 1);
        setBreadcrumb(newBreadcrumb);
        refinementFunction({
            field: field as string,
            value: newBreadcrumb.join('>'),
            type,
            multiple,
            checked: true,
        });
    };

    const handleCategoryClick = (value: string | undefined) => {
        if (value) {
            setIsLoading(true);
            const newBreadcrumb = [...breadcrumb, value];
           setBreadcrumb(newBreadcrumb);
           setCurrentValues([])
            refinementFunction({
                field: field as string,
                value: newBreadcrumb.join('>'),
                type,
                multiple,
                checked: true,
            });
        }
    };

    if(isLoading) return <FacetLoader className="ss__facet-category-hierarchy-loading" />

    return (
        <>
            {breadcrumb.length > 0 && (
                <>
                    <div className="ss__facet-view-all" onClick={handleViewAllClick}>
                        View All
                    </div>
                    <div className="ss__facet-category-hierarchy">
                        {breadcrumb.map((part, index) => {
                            const isLast = index === breadcrumb.length - 1;
                            const marginLeft = `${index * 5}px`;
                            
                            return (
                                <span
                                    key={index}
                                    className={`ss__facet-category-hierarchy-part ${isLast ? 'ss__facet-category-hierarchy-last' : ''}`}
                                    style={{ marginLeft }}
                                >
                                    <span
                                        onClick={() => handleParentClick(index, isLast)}
                                        className={`ss__facet-option-label ${isLast ? 'ss__facet-option-label-last' : ''}`}
                                    >
                                        {part}
                                    </span>
                                </span>
                            );
                        })}
                    </div>
                </>
            )}

            {currentValues.map((value) => {
                const currentLabelParts = String(value.value).split('>') || [];
                const currentLabel = currentLabelParts[currentLabelParts.length - 1];
                const paddingLevel = currentLabelParts.length - 1;

                return (
                    <div
                        className={values.length < limit ? 'ss__option-nonlimited ss__option-nonlimited-ctg' : 'ss__option'}
                        key={value.type === 'range' ? `${field}-${value.low}${value.high}` : `${field}-${value.value}`}
                        style={{ paddingLeft: `${paddingLevel * 10}px` }}
                    >
                        <input
                            id={value.type === 'range' ? `${field}-${value.low}${value.high}` : `${field}-${value.value}`}
                            style={{ display: 'none' }}
                            type="checkbox"
                            name={value.type === 'range' ? `${field}-${value.low}${value.high}` : `${field}-${value.value}`}
                            onChange={() => handleCategoryClick(value.label)}
                            checked={value.active}
                        />

                        <label
                            className={`ss__facet-option-label ml-2`}
                            htmlFor={value.type === 'range' ? `${field}-${value.low}${value.high}` : `${field}-${value.value}`}
                            style={{ cursor: 'pointer' }}
                        >
                            {currentLabel}
                            <span>({value.count})</span>
                        </label>
                    </div>
                );
            })}
            {values.length > limit && (
                <div className="ss__facets-view-more" onClick={toggleShowAll}>
                    {showAll ? 'Show Less' : `Show ${values.length - limit} more`} {showAll ? <ArrowUp /> : <ArrowDown />}
                </div>
            )}
        </>
    );
};

export default FacetDropdown;
