'use client';

import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from 'components/icons/close';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import './modal.css';

interface ModalType {
  buttonClasses?: string;
  buttonFontSize?: number | undefined;
  buttonText: string;
  buttonType?: string;
  classes?: string;
  children?: ReactNode;
  headerText?: string;
  preventDefault?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
}

export default function Modal(props: ModalType) {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };
    
  useEffect(() => {
    if (props.isOpen !== undefined) {
      setIsOpen(props.isOpen);
    }
  }, [props.isOpen]);

  let buttonStyle = {};
  if (props.buttonFontSize && props.buttonFontSize > 0) {
    buttonStyle = { 
      'fontSize': `${props.buttonFontSize}px` 
    }
  }

  return (
    <>
      <button
        aria-label="Open modal" 
        className={props.buttonClasses !== null ? props.buttonClasses : ''}
        onClick={(event: any) => {
          props.preventDefault === true && event.preventDefault();
          openModal();
        }}
        style={buttonStyle}
      >
        {props.buttonText}
      </button>
      <Transition show={isOpen}>
        <Dialog onClose={closeModal} className="modal">
          <Transition.Child
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="opacity-0 backdrop-blur-none"
            enterTo="opacity-100 backdrop-blur-[.5px]"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="opacity-100 backdrop-blur-[.5px]"
            leaveTo="opacity-0 backdrop-blur-none"
          >
            <div className="modal-backdrop" aria-hidden="true" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className={`modal-dialog${` ${props.classes}`}`}>
              <div className="modal-container">
                <header className="modal-header">
                  {
                    props.headerText 
                      && <p className="modal-header-text">{props.headerText}</p>
                  }
                  <button aria-label="Close modal" onClick={closeModal}>
                    <CloseIcon />
                  </button>
                </header>
                <main className="modal-content">
                  {props.children}
                </main>
              </div>

            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}
