'use client';

import { VariantSelector } from 'components/product/variant-selector';
import { withSuspense } from 'components/with-suspense';
import { VercelProduct as Product } from 'lib/bigcommerce/types';

export function OptionSelectorComponent({ product, attributes }: { 
    product: Product, 
    attributes: {
        style: any, 
        className: string, 
        'builder-id': string | undefined,
        id: string
    } }) {
  return (
    <>
      <VariantSelector product={product} options={product.options} variants={product.variants} attributes={attributes} />
    </>
  );
}

const OptionSelectorSkeleton = (
  <></>
);

export const OptionSelector = withSuspense(OptionSelectorComponent, OptionSelectorSkeleton);
