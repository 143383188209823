import { BuilderStore } from '@builder.io/react';
import { Builder, BuilderElement } from '@builder.io/sdk';
import CloseIcon from 'components/icons/close';
import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { TimerResult } from 'react-timer-hook';
import { TimerProvider, useTimer } from '../providers/timer-provider';
import './timer-modal.css';
import { TimerSettings, getTimerSettings } from './timer-settings';
import { usePersistentTimer } from './use-persistent-timer';

const isBrowser = () => Builder.isBrowser && typeof window !== 'undefined';
const isEditing = () => isBrowser() && Builder.isEditing;
const showDebugSummary = (timerSettings: TimerSettings) => timerSettings.enabled && isEditing();

function getBuilderContextAction<T>(action: string, builderState: any) {
  if (builderState && builderState.context && action in builderState.context) {
    const contextAction = builderState.context[action];
    if (contextAction && typeof contextAction === 'function') {
      return builderState.context[action] as T;
    }
  }
}

function getTimerAction(timerSettings: TimerSettings, setShouldRender: React.Dispatch<React.SetStateAction<boolean>>, setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>, builderState?: BuilderStore) {
  const actionEnum = getAction(timerSettings);
  switch (actionEnum) {

    case 'redirect': {
      const url = timerSettings.actions.redirectUrl;
      if (!url) {
        console.log('missing required url setting for redirect action');
        break;
      }
      const contextAction = getBuilderContextAction<(url: string) => void>(actionEnum, builderState);
      const redirectFunc = (url: string, contextAction?: (url: string) => void) => {
        window.location.replace(url);
        if (contextAction)
          contextAction(url)
      }
      return redirectFunc.bind(null, url, contextAction) as () => void;
    }

    case 'alert': {
      const message = timerSettings.actions.message;
      if (!message) {
        console.log('missing required message setting for alert action');
        break;
      }
      const contextAction = getBuilderContextAction<(message: string) => void>(actionEnum, builderState);
      const alertFunc = (message: string, contextAction?: (message: string) => void) => {
        alert(message);
        if (contextAction)
          contextAction(message);
      }
      return alertFunc.bind(null, message, contextAction) as () => void;
    }

    case 'show': {
      const contextAction = getBuilderContextAction<(visible: boolean) => void>(actionEnum, builderState);
      const showFunc = (visible: boolean, contextAction?: (visible: boolean) => void) => {
        setShouldRender(visible);
        if (contextAction)
          contextAction(visible);
      }
      return showFunc.bind(null, true, contextAction) as () => void;
    }

    case 'hide': {
      const contextAction = getBuilderContextAction<(visible: boolean) => void>(actionEnum, builderState);
      const hideFunc = (visible: boolean, contextAction?: (visible: boolean) => void) => {
        setShouldRender(visible);
        if (contextAction)
          contextAction(visible);
      }
      return hideFunc.bind(null, false, contextAction) as () => void;
    }

    case 'swapProduct': {
      const sku = timerSettings.actions.sku;
      if (!sku) {
        console.log('missing required sku setting for alert action');
        break;
      }
      const quantity = 1;
      const contextAction = getBuilderContextAction<(sku: string, quantity: number) => void>(actionEnum, builderState);
      const swapProductFunc = (sku: string, quantity: number, contextAction?: (sku: string, quantity: number) => void) => {
        if (contextAction) {
          contextAction(sku, quantity);
        }
        else {
          console.log('swapProduct action is missing context');
        }
      }
      return swapProductFunc.bind(null, sku, quantity, contextAction) as () => void;
    }

    case 'modal': {
      const modalHeader = timerSettings.actions.modalHeader;
      const contextAction = getBuilderContextAction<(modalHeader: string | undefined) => void>(actionEnum, builderState);
      const modalFunc = (modalHeader: string | undefined, contextAction?: (modalHeader: string | undefined) => void) => {
        setIsModalOpen(true);
        if (contextAction)
          contextAction(modalHeader);
      }
      return modalFunc.bind(null, modalHeader, contextAction) as () => void;
    }

  }

  return () => { };
}

interface TimerConfigProps {
  timerSettings?: TimerSettings;
  builderState?: BuilderStore;
  builderBlock?: BuilderElement;
}

export interface TimerProps {
  timerSettings: TimerSettings;
  timer?: TimerResult;
}

const getNamespace = (builderState?: BuilderStore): string | undefined => {
  return builderState?.content?.testVariationId || builderState?.content?.variationId || builderState?.content?.id;
}

const getAction = (timerSettings: TimerSettings) => (
  timerSettings.enabled &&
  timerSettings.actions.enabled &&
  timerSettings.actions.action
) ? timerSettings.actions.action : undefined;

const timerSummaryString = (timerSettings: TimerSettings, name: string): string =>
  `DEBUG ${name} Timer ${timerSettings.permanent ? '- permanent' : ''} - 
  Expires: ${(timerSettings.timerType === 'date' ? timerSettings.date?.toLocaleString() : `${timerSettings.minutes}:${(timerSettings.seconds ?? 0).toString().padStart(2, '0')}`)} - 
  Action: ${getAction(timerSettings) ?? 'none'}
  `;

export const withTimer = <P extends TimerConfigProps>(Component: React.ComponentType<P>) => {
  const HOC = React.forwardRef<any, React.PropsWithChildren<P>>(
    (props, ref) => {
      const { builderState, builderBlock, timerSettings } = props;
      const settings = getTimerSettings(timerSettings)
      const [shouldRender, setShouldRender] = useState(getAction(settings) !== 'show');
      const [isModalOpen, setIsModalOpen] = useState(false);
      useEffect(() => {
        if (isEditing() && getAction(settings) === 'show') {
          setShouldRender(true);
        }
      }, [settings]);

      const action = useCallback(() => {
        const timerAction = getTimerAction(settings, setShouldRender, setIsModalOpen, builderState);
        if (timerAction)
          timerAction();
      }, [settings, builderState]);
      const namespace = getNamespace(builderState) ?? builderBlock?.id ?? '';

      const parentTimer = useTimer();
      const timer = usePersistentTimer({
        enabled: settings.enabled,
        namespace: namespace,
        timerType: settings.timerType,
        timerMs: (settings.minutes * 60 + settings.seconds) * 1000,
        expireDate: settings.date,
        persist: settings.permanent,
        onExpire: action,
      });
      const timerResult = settings.countdownParentTimer ? (parentTimer ?? timer) : (timer ?? parentTimer);
      if (getAction(settings) === 'modal' && !isEditing()) {
        return (
          <ReactModal
            isOpen={isModalOpen}
            ariaHideApp={process.env.NODE_ENV !== 'test'}
            bodyOpenClassName="has-activeModal"
            onRequestClose={() => setIsModalOpen(false)}
            overlayClassName="modal-backdrop"
            className="modal-dialog"
          >
            <header className="modal-header">
              {settings.actions.modalHeader && 
                <span className="modal-header-text">{settings.actions.modalHeader}</span>
              }

              <button
                className="modal-close"
                data-test="modal-close-button"
                onClick={() => setIsModalOpen(false)}
              >
                <CloseIcon />
              </button>
            </header>

            <div className="modal-content" data-test="modal-content">
              <TimerProvider value={timerResult}>
                {showDebugSummary(settings) && (
                  <span style={{ color: '#999', fontSize: '0.875rem', fontStyle: 'italic' }}>
                    {timerSummaryString(settings, 'Section')}
                  </span>
                )}
                {shouldRender &&
                  <Component {...props} ref={ref}>
                    {props.children}
                  </Component>
                }
              </TimerProvider>
            </div>
          </ReactModal>
        );
      }
      return (
        <>
          {showDebugSummary(settings) && (
            <span style={{ color: '#999', fontSize: '0.875rem', fontStyle: 'italic' }}>
              {timerSummaryString(settings, builderBlock?.component?.name ?? 'text')}
            </span>
          )}
          {shouldRender &&
            <Component {...props} ref={ref} timerSettings={settings} timer={timerResult}>
              {props.children}
            </Component>
          }
        </>
      );
    }
  );
  HOC.displayName = "WithTimer";
  return HOC;
};

export const withTimerContext = <P extends TimerConfigProps>(Component: React.ComponentType<P>) => {
  const HOC = React.forwardRef<any, React.PropsWithChildren<P>>(
    (props, ref) => {
      const { builderState, builderBlock, timerSettings } = props;
      const settings = getTimerSettings(timerSettings)
      const [shouldRender, setShouldRender] = useState(getAction(settings) !== 'show');
      const [isModalOpen, setIsModalOpen] = useState(false);
      useEffect(() => {
        if (isEditing() && getAction(settings) === 'show') {
          setShouldRender(true);
        }
      }, [settings]);

      const action = useCallback(() => {
        const timerAction = getTimerAction(settings, setShouldRender, setIsModalOpen, builderState);
        if (timerAction)
          timerAction();
      }, [settings, builderState]);
      const namespace = getNamespace(builderState) ?? builderBlock?.id ?? '';

      const timerResult = usePersistentTimer({
        enabled: settings.enabled,
        namespace: namespace,
        timerType: settings.timerType,
        timerMs: (settings.minutes * 60 + settings.seconds) * 1000,
        expireDate: settings.date,
        persist: settings.permanent,
        onExpire: action,
      });
      if (getAction(settings) === 'modal' && !isEditing()) {
        return (
          <ReactModal
            isOpen={isModalOpen}
            ariaHideApp={process.env.NODE_ENV !== 'test'}
            bodyOpenClassName="has-activeModal"
            onRequestClose={() => setIsModalOpen(false)}
            overlayClassName="modal-backdrop"
            className="modal-dialog modal--timer"
          >
            <header className="modal-header">
              {settings.actions.modalHeader && 
                <span className="modal-header-text">{settings.actions.modalHeader}</span>
              }

              <button
                className="modal-close"
                data-test="modal-close-button"
                onClick={() => setIsModalOpen(false)}
              >
                <CloseIcon />
              </button>
            </header>

            <div className="modal-content" data-test="modal-content">
              <TimerProvider value={timerResult}>
                {showDebugSummary(settings) && (
                  <span style={{ color: '#999', fontSize: '0.875rem', fontStyle: 'italic' }}>
                    {timerSummaryString(settings, 'Section')}
                  </span>
                )}
                {shouldRender &&
                  <Component {...props} ref={ref}>
                    {props.children}
                  </Component>
                }
              </TimerProvider>
            </div>
          </ReactModal>
        );
      }
      return (
        <TimerProvider value={timerResult}>
          {showDebugSummary(settings) && (
            <span style={{ color: '#999', fontSize: '0.875rem', fontStyle: 'italic' }}>
              {timerSummaryString(settings, 'Section')}
            </span>
          )}
          {shouldRender &&
            <Component {...props} ref={ref}>
              {props.children}
            </Component>
          }
        </TimerProvider>
      );
    }
  );
  HOC.displayName = "WithTimerContext";
  return HOC;
};
