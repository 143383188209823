import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';

export type TabsGridProps = {
  contentType: string,
  onViewChange: any;
  productCount: number,
  articleCount: number,
};

export const TabsGrid: React.FC<TabsGridProps> = (props:TabsGridProps) => {

  const {contentType, onViewChange, productCount, articleCount } = props

  const [activeTab, setActiveTab] = useState(contentType === 'articles' ? 1 : 0);
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = useSearchParams() || '';
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (!productCount && !articleCount) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [productCount, articleCount]);

  const handleTabClick = (index:number) => {
    if (!isLoading) {
      setActiveTab(index);
      const newView = index === 0 ? 'products' : 'articles';
      onViewChange(newView);

      const newParams = new URLSearchParams(searchParams);

      const keysToDelete = [];
      for (const key of newParams.keys()) {
        if (key.startsWith('filter')) {
          keysToDelete.push(key);
        }
      }

      keysToDelete.forEach(key => newParams.delete(key));
      newParams.set('view', newView);
      router.push(`${pathname}?${newParams.toString()}`, undefined);
    }
  };

  return (
    <div className="builder-tabs  builder-tabs-pill">
      <div className="builder-tabs-nav">
        <button
          className={`builder-tab${activeTab === 0 ? ' builder-tab-active' : ''}`}
          onClick={() => handleTabClick(0)}
          disabled={productCount === 0 || productCount === undefined || isLoading}
        >
          {isLoading ? (
            <FacetLoader className="ss__facet-tab-grid-loading"/>
          ) : (
            <>Products {productCount > 0 ? `(${productCount})` : ''}</>
          )}
        </button>
        <button
          className={`builder-tab${activeTab === 1 ? ' builder-tab-active' : ''}`}
          onClick={() => handleTabClick(1)}
          disabled={articleCount === 0 || articleCount === undefined || isLoading}
        >
          
          {isLoading ? (
            <FacetLoader className="ss__facet-tab-grid-loading"/>
          ) : (
            <>Articles {articleCount > 0 ? `(${articleCount})` : ''}</>
          )}
        </button>
      </div>
    </div>
  );
};

function FacetLoader({ className }: { className: string }) {
  const dots = 'ss__facet-loading-dot mx-[1px] inline-block h-1 w-1 animate-blink rounded-md';
  return (
    <span className={`loading-dots mx-2 inline-flex items-center justify-center ${className}`}>
      <span className={`${dots}`} />
      <span className={`${dots} animation-delay-[200ms]`} />
      <span className={`${dots} animation-delay-[400ms]`} />
    </span>
  );
}
