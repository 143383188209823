import {
  type CarouselDataProps,
  type Recommendation,
  type RecommendationDataProps,
  debounce,
  ContentRecommendation,
} from 'propel-shared-utility';
import {type GenericAPIResult} from '../generated/search/core/ApiResult';
import {
  type BeaconConfig,
  type BeaconDTO,
  type BeaconProductDTO,
  type BeaconProductTypeUnion,
  type BeaconProfileDTO,
  type BeaconProfileTypeUnion,
  type SearchspringAPIContract,
} from '../types';
import {v4 as uuid4} from 'uuid';

enum BeaconEventStatus {
  PENDING = 1,
  SUCCESS = 2,
  FAIL = 3,
  NULL_VALUE = 4,
}

type CreateKeyInterface = {
  type: BeaconProductTypeUnion;
  event: {
    product: Recommendation | ContentRecommendation;
  };
};

type EventMapping = {
  status: BeaconEventStatus;
  profile: string;
};

export class BeaconEventDataLayer {
  static mappedEvents: Map<string, EventMapping> = new Map();
  static instances: Map<string, BeaconEventDataLayer> = new Map();
  public initialized = false;
  private profileRendered = false;

  static purgeUnusedProfiles() {
    BeaconEventDataLayer.instances.forEach((instance) => {
      const instanceProfile = instance.beaconConfig.profile;
      const instanceIsUsed =
        document.querySelectorAll(`[data-ss_profile="${instanceProfile}"]`)
          .length > 0;

      if (!instanceIsUsed) {
        BeaconEventDataLayer.mappedEvents.forEach((eventMapping, key) => {
          if (eventMapping.profile === instanceProfile) {
            BeaconEventDataLayer.mappedEvents.delete(key);
          }
        });
        instance.cleanUp();
        BeaconEventDataLayer.instances.delete(instanceProfile);
      }
    });
  }

  public setRecommendationDataProps(
    recommendation: Recommendation,
    active: boolean,
  ): RecommendationDataProps {
    const result = {
      'data-ss_recommendation': JSON.stringify(recommendation),
      'data-ss_profile': this.beaconConfig.profile,
      'data-is_ss_recommendation': 'true',
    };
    return result;
  }

  public setContentRecommendationDataProps(
    recommendation: ContentRecommendation,
    active: boolean,
  ): RecommendationDataProps {
    const result = {
      'data-ss_recommendation': JSON.stringify(recommendation),
      'data-ss_profile': this.beaconConfig.profile,
      'data-is_ss_recommendation': 'true',
    };
    return result;
  }

  public setCarouselDataProps(): CarouselDataProps {
    const result = {
      'data-is_ss_carousel': 'true',
      'data-ss_profile': this.beaconConfig.profile,
    };
    return result;
  }

  private createActiveRecommendationSelector() {
    return `[data-ss_profile="${this.beaconConfig.profile}"][data-is_ss_recommendation="true"]`;
  }

  private createCarouselSelector() {
    return `[data-ss_profile="${this.beaconConfig.profile}"][data-is_ss_carousel="true"]`;
  }

  public beaconConfig: BeaconConfig;

  #apiWrapper: Pick<SearchspringAPIContract, 'beaconPOST'>;

  private constructor(
    _beaconConfig: BeaconConfig,
    apiWrapper: Pick<SearchspringAPIContract, 'beaconPOST'>,
  ) {
    this.beaconConfig = _beaconConfig;
    this.#apiWrapper = apiWrapper;

    // Bind handler functions to instance
    this.createRemoveDuplicateCallback =
      this.createRemoveDuplicateCallback.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScroll = debounce(this.handleScroll, 200);
    this.handleClickCarousel = this.handleClickCarousel.bind(this);
    this.handleClickCarousel = debounce(this.handleClickCarousel, 100);
    this.getVisibleRecommendationsForProfile =
      this.getVisibleRecommendationsForProfile.bind(this);
    this.setRecommendationDataProps =
      this.setRecommendationDataProps.bind(this);
    this.setCarouselDataProps = this.setCarouselDataProps.bind(this);
    this.createActiveRecommendationSelector =
      this.createActiveRecommendationSelector.bind(this);
    this.createCarouselSelector = this.createCarouselSelector.bind(this);
  }

  public bindEventListeners() {
    // Initialize event listeners
    window.addEventListener('scroll', this.handleScroll);
    document.querySelectorAll(this.createCarouselSelector()).forEach((e) => {
      (e as HTMLElement).addEventListener('click', this.handleClickCarousel);
    });
  }

  public initialize() {
    BeaconEventDataLayer.instances.set(this.beaconConfig.profile, this);
    BeaconEventDataLayer.purgeUnusedProfiles();
    this.bindEventListeners();
    this.initialized = true;
  }

  public static create(
    _beaconConfig: BeaconConfig,
    apiWrapper: Pick<SearchspringAPIContract, 'beaconPOST'>,
  ): BeaconEventDataLayer {
    const instanceMatch = this.instances.get(_beaconConfig.profile);
    if (instanceMatch) {
      instanceMatch.updateOptions(_beaconConfig, apiWrapper);
      return instanceMatch;
    }
    return new BeaconEventDataLayer(_beaconConfig, apiWrapper);
  }

  public cleanUp() {
    window.removeEventListener('scroll', this.handleScroll);
    document.querySelectorAll(this.createCarouselSelector()).forEach((e) => {
      (e as HTMLElement).removeEventListener('click', this.handleClickCarousel);
    });
  }

  public destroy() {
    this.cleanUp();
    BeaconEventDataLayer.instances.delete(this.beaconConfig.profile);
  }

  public createRenderEvent(recs: Recommendation[] | ContentRecommendation[]) {
    if (recs.length > 0 && !this.profileRendered) {
      const event = this.createEvent('profile.render', this.beaconConfig, recs);
      this.postEvent(event, this.#apiWrapper.beaconPOST);
      this.profileRendered = true;
    }
  }

  public updateOptions(
    config: Partial<BeaconConfig>,
    apiWrapper: Pick<SearchspringAPIContract, 'beaconPOST'>,
  ) {
    this.beaconConfig = {
      ...this.beaconConfig,
      ...config,
    };
    this.#apiWrapper = apiWrapper;
    this.cleanUp();
    window.addEventListener('scroll', this.handleScroll);
    document.querySelectorAll(this.createCarouselSelector()).forEach((e) => {
      (e as HTMLElement).addEventListener('click', this.handleClickCarousel);
    });
  }

  private postEvent(
    dto: BeaconDTO,
    eventCallback: (
      dto: BeaconDTO,
    ) => Promise<GenericAPIResult<{success: boolean} | undefined>>,
  ) {
    const filterEvents = dto.filter((e) => {
      if ((e as BeaconProductDTO).pid) {
        const key = this.createKey(e as BeaconProductDTO);
        const status = this.getStatus(key);
        if (status === BeaconEventStatus.NULL_VALUE || BeaconEventStatus.FAIL) {
          this.setStatus(key, BeaconEventStatus.PENDING);
          return true;
        }
        return false;
      }
      return true;
    });
    eventCallback(filterEvents)
      .then((result) => {
        if (result.ok) {
          filterEvents.forEach((e) => {
            const key = this.createKey(e as BeaconProductDTO);
            this.setStatus(key, BeaconEventStatus.SUCCESS);
          });
          return;
        }
        filterEvents.forEach((e) => {
          const key = this.createKey(e as BeaconProductDTO);
          this.setStatus(key, BeaconEventStatus.FAIL);
        });
      })
      .catch(() => {
        filterEvents.forEach((e) => {
          const key = this.createKey(e as BeaconProductDTO);
          this.setStatus(key, BeaconEventStatus.FAIL);
        });
      });
  }

  private setStatus(key: string, status: BeaconEventStatus) {
    BeaconEventDataLayer.mappedEvents.set(key, {
      profile: this.beaconConfig.profile,
      status,
    });
  }

  private getStatus(key: string): BeaconEventStatus {
    const result = BeaconEventDataLayer.mappedEvents.get(key);
    if (result) return result.status;
    return BeaconEventStatus.NULL_VALUE;
  }

  private getVisibleRecommendationsForProfile(): Recommendation[] {
    const recs = Array.from(
      document.querySelectorAll(this.createActiveRecommendationSelector()),
    )
      .map((element) => {
        const recommendation = (element as HTMLElement).dataset
          .ss_recommendation;
        if (recommendation) {
          return {
            recommendation: JSON.parse(recommendation),
            element,
          };
        }
        return false;
      })
      .filter((v) => !!v)
      .filter((v: any) => {
        const {element} = v;
        const rect = element.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
          window.innerWidth || document.documentElement.clientWidth;

        // Check if any part of the element is visible in the viewport
        const vertInView =
          rect.top <= windowHeight && rect.top + rect.height >= 0;
        const horizInView =
          rect.left <= windowWidth && rect.left + rect.width >= 0;

        return vertInView && horizInView;
      })
      .map((v: any) => {
        return v.recommendation;
      }) as Recommendation[];

    return recs;
  }

  private createRemoveDuplicateCallback(type: BeaconProductTypeUnion) {
    return (rec: Recommendation) => {
      const key = this.createKey({
        type,
        event: {
          product: rec,
        },
      });
      const status = this.getStatus(key);
      if (
        status === BeaconEventStatus.PENDING ||
        status === BeaconEventStatus.SUCCESS
      ) {
        return false;
      }
      return true;
    };
  }

  private getClickedRecommendation(e: MouseEvent) {
    const checkIsRecommendationElement = (e?: HTMLElement) => {
      return !!e?.dataset.is_ss_recommendation;
    };

    const checkElementIsCarousel = (e?: HTMLElement) => {
      return e?.dataset.is_ss_carousel;
    };

    let currentElement = e.target as HTMLElement | null;

    while (currentElement && !checkElementIsCarousel(currentElement)) {
      const isRecommendation = checkIsRecommendationElement(currentElement);
      if (isRecommendation)
        return JSON.parse(
          currentElement.dataset.ss_recommendation as string,
        ) as Recommendation;
      currentElement = currentElement.parentElement;
    }
    return undefined;
  }

  private handleClickCarousel(e: MouseEvent) {
    const removeDuplicateCallbackClick = this.createRemoveDuplicateCallback(
      'profile.product.click',
    );
    const removeDuplicateCallbackImpression =
      this.createRemoveDuplicateCallback('profile.product.impression');
    const activeRecommendations =
      this.getVisibleRecommendationsForProfile().filter(
        removeDuplicateCallbackImpression,
      );

    let clickedRecommendation = this.getClickedRecommendation(e);
    if (clickedRecommendation)
      clickedRecommendation = [clickedRecommendation].filter(
        removeDuplicateCallbackClick,
      )[0];

    let eventBatch: BeaconDTO = [];

    if (activeRecommendations && activeRecommendations.length) {
      const event = this.createEvent(
        'profile.impression',
        this.beaconConfig,
        activeRecommendations,
      );
      eventBatch = [...eventBatch, ...event];
    }

    if (clickedRecommendation) {
      const event = this.createEvent('profile.click', this.beaconConfig, [
        clickedRecommendation,
      ]);
      eventBatch = [...eventBatch, ...event];
    } else {
      const event = this.createEvent('profile.click', this.beaconConfig, []);
      eventBatch = [...eventBatch, ...event];
    }

    this.postEvent(eventBatch, this.#apiWrapper.beaconPOST);
  }

  private handleScroll() {
    const removeDuplicateCallback = this.createRemoveDuplicateCallback(
      'profile.product.impression',
    );
    const visibleRecommendations =
      this.getVisibleRecommendationsForProfile().filter(
        removeDuplicateCallback,
      );
    if (visibleRecommendations.length) {
      const event = this.createEvent(
        'profile.impression',
        this.beaconConfig,
        visibleRecommendations,
      );
      this.postEvent(event, this.#apiWrapper.beaconPOST);
    }
  }

  private createKey(dto: CreateKeyInterface) {
    const mapKey = JSON.stringify({
      type: dto.type,
      context: this.beaconConfig.context,
      event: {
        context: {
          placement: this.beaconConfig.placement,
          tag: this.beaconConfig.profile,
        },
        product: dto.event.product,
      },
    });
    return mapKey;
  }

  public createEvent(
    type: BeaconProfileTypeUnion,
    config: BeaconConfig,
    recommendations: Recommendation[] | ContentRecommendation[],
  ) {
    const parentEvent = this.createParentEvent(type, config);

    const childEventType: BeaconProductTypeUnion = (() => {
      switch (type) {
        case 'profile.click':
          return 'profile.product.click';
        case 'profile.impression':
          return 'profile.product.impression';
        case 'profile.render':
          return 'profile.product.render';
        default:
          return 'profile.product.render';
      }
    })();

    const childEvents: BeaconProductDTO[] = recommendations
      .map((r) => {
        return this.createProductEvent(childEventType, config, parentEvent, r);
      })
      .filter((r) => !!r);

    const result = [parentEvent, ...childEvents];
    return result;
  }

  private createParentEvent(
    type: BeaconProfileTypeUnion,
    config: BeaconConfig,
  ): BeaconProfileDTO {
    const result: BeaconProfileDTO = {
      id: uuid4(),
      type,
      category: 'searchspring.recommendations.user-interactions',
      context: config.context,
      event: {
        context: {
          placement: config.placement,
          tag: config.profile,
          type: 'product-recommendation',
        },
        profile: {
          tag: config.profile,
          placement: config.placement,
          seed: config.seed,
        },
      },
    };
    return result;
  }

  private createProductEvent(
    type: BeaconProductTypeUnion,
    config: BeaconConfig,
    parentEvent: BeaconProfileDTO,
    recommendation: Recommendation | ContentRecommendation,
  ) {
    const result: BeaconProductDTO = {
      id: uuid4(),
      pid: parentEvent.id,
      type,
      category: 'searchspring.recommendations.user-interactions',
      context: config.context,
      event: {
        context: {
          placement: config.placement,
          tag: config.profile,
          type: 'product-recommendation',
        },
        product: recommendation,
      },
    };

    return result;
  }
}
