'use client'

export const useIntelliSuggestTrackClick = (siteId: string) => {
  const intellisuggestTrackClick = (element: HTMLAnchorElement, data: string, signature: string): boolean => {
    const escapeFn = encodeURIComponent || escape;
    if (document.images) {
      const apiUrl = location.protocol === 'https:' 
        ? `https://${siteId}.a.searchspring.io/api/` 
        : `http://${siteId}.a.searchspring.io/api/`;

      const imgTag = new Image();
      imgTag.src = `${apiUrl}track/track.json?d=${data}&s=${signature}&u=${escapeFn(element.href)}&r=${escapeFn(document.referrer)}`;
    }

    return true;
  };

  return { intellisuggestTrackClick };
};
  