import { Component as ComponentOptions } from '@builder.io/sdk';
import { timerSettingsInput } from '../hooks/timer-settings';

export const cqlTextOptions: any = {
  name: 'CQL Text',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/transcript.svg',
  description: 'Custom WYSIWYG for CQL comps',
  inputs: [
    {
      name: 'html',
      friendlyName: 'Custom HTML',
      type: 'CQLText',
      required: true,
      autoFocus: true,
      bubble: true,
      defaultValue: 'Enter some text...',
    },
    ...timerSettingsInput
  ]
} as ComponentOptions;


export const builderTextOptions: any = {
  name: 'Text',
  // Signify that this is an override
  override: true,
  static: true,
  image: 'https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-text_fields-24px%20(1).svg?alt=media&token=12177b73-0ee3-42ca-98c6-0dd003de1929',
  inputs: [
    {
      name: 'html',
      friendlyName: 'Custom HTML',
      type: 'CQLText',
      autoFocus: true,
      bubble: true,
      defaultValue: 'Enter some text...',
    },
    {
      name: 'text',
      type: 'html',
      helperText: 'Legacy Text will only be shown if the HTML field is empty',
      required: false,
      autoFocus: true,
      bubble: true,
      // showIf: (options) => (options.get('text') && options.get('text') !== '<p><br></p>'),
    },
    ...timerSettingsInput
  ],
  // Maybe optionally a function that takes in some params like block vs absolute, etc
  defaultStyles: {
    lineHeight: 'normal',
    height: 'auto',
    textAlign: 'center',
  },
} as ComponentOptions;
