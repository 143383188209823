import Link from "next/link";
import { parse } from 'url';

const siteHostname = parse(process.env.NEXT_PUBLIC_DOMAIN_NAME ?? '')?.hostname ?? undefined;

export function renderLink(props: React.AnchorHTMLAttributes<any>) {
    if (typeof props.href !== 'string') {
        return <a {...props} />;
    }
    const parsed = parse(props.href);
    if (parsed.hostname && !parsed.hostname.includes(siteHostname ?? 'localhost')) {
        return <a {...props} />;
    }
    const relativePath = parsed.path || '';
    const { children, ...rest } = props;
    const linkProps = { prefetch: false, ...rest }
    const href = relativePath + (parsed.hash || '');
    return (
        <Link href={href} {...linkProps}>
            {children}
        </Link>
    );
}

export const RenderLink = renderLink;