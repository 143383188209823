'use client';

import CartConfirm from 'components/cart/cart-confirm';
import MiniCart from 'components/cart/mini-cart';
import './header-cart.css';

export function HeaderCart(props: { attributes: {style: any, className: string, 'builder-id': string, id: string} }) {
  return (
    <>
      <section
        className={`mini-cart-component ${props.attributes.className}`}
        style={props.attributes.style}
        builder-id={props.attributes['builder-id']} 
        id={props.attributes.id}
      >
        <div className="header-icon mini-cart-icon">
          <MiniCart />
        </div>
      </section>

      <section className="cart-confirm">
        <CartConfirm />
      </section>
    </>
  );
}
