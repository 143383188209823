'use client';

import { type SearchResponseModel } from 'int-searchspring-api';
import { AddToCartComponentInterface, LinkComponentInterface, debounce } from 'propel-shared-utility';
import React, { useRef } from 'react';
import { RefinementFunction, SearchBlogResult, SearchProductResult } from '../../interfaces';
import { BlogCard } from '../BlogCard/BlogCard';
import { FacetProps } from '../Facet';
import { ProductCard } from '../ProductCard/ProductCard';
import * as blotout from '../analytics/blotout';
import * as gtm from '../analytics/gtm';

import './product-grid.css';

interface TileOptions {
  banner: boolean;
  burst: boolean;
  promo: boolean;
  specs: boolean;
  tagline: boolean;
  ratings: boolean;
  addToCart: boolean;
}

export type ProductGridProps = {
  searchData: any;
  data: any;
  allResults: any;
  refinementFunction: RefinementFunction;
  searchResult: {
    facets: FacetProps[];
    results: SearchProductResult[];
  } | SearchResponseModel; 
  tileOptions: TileOptions,
  contentType: string,
  onLoadMore: () => void;
  AddToCartComponent: AddToCartComponentInterface;
  LinkComponent: LinkComponentInterface;
  productCategoryTitle?: string;
  searchTerm?: string;
};

export const ProductGrid: React.FC<ProductGridProps> = (props: ProductGridProps) => {
  const results = props?.allResults || props.data?.results || [];
  const merch = props.searchData?.merchandising?.content || props.data?.merchandising?.content || [];
  const contentType = props.contentType;

  const { tileOptions, AddToCartComponent, LinkComponent, productCategoryTitle } = props;

  const pendingItemList = useRef<SearchProductResult[]>([]);
  const triggerViewListEvent = debounce((pendingItemList: React.MutableRefObject<SearchProductResult[]>) => {
    gtm.viewSearchItemList({ results: pendingItemList.current, itemListId: productCategoryTitle?.toLowerCase()?.replaceAll(' ', '_'), itemListName: productCategoryTitle });
    blotout.viewSearchItemList({ results: pendingItemList.current, searchTerm: props?.searchTerm });
    pendingItemList.current = [];
  }, 500);

  const handleViewChange = (pendingItemList: React.MutableRefObject<SearchProductResult[]>, triggerEvent: (pendingItemList: React.MutableRefObject<SearchProductResult[]>) => void, rec: SearchProductResult, inView: boolean, _entry: IntersectionObserverEntry) => {
      if (inView) {
        pendingItemList.current.push(rec)
        triggerEvent(pendingItemList);
    }
  };
  const onChange = handleViewChange.bind(null, pendingItemList, triggerViewListEvent)

  return (
    <div className='ss__grid-wrapper'>
        { results && (
            <>
            <div className="flex">
                <div className="grid gap-y-2.5 lg:gap-y-6 sm:basis-full sm:w-full sm:w-full sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
                {results.flatMap((result: SearchProductResult | SearchBlogResult, index: number) => {
                    const merchItems = merch?.inline ? merch.inline.filter((item: { config: { position: { index: number; }; }; }) => item.config.position.index === index) : [];
                    const components: any[] = [];

                    merchItems.forEach((merchItem: { config: { position: { index: any; }; }; value: any; }, merchIndex: number) => {
                        components.push(
                        <div key={`merch-${merchItem.config.position.index}-${merchIndex}`} className='tile__item-grid tile__merch-item p-2' dangerouslySetInnerHTML={{__html: merchItem.value}}></div>
                        );
                    });

                    if (contentType === 'products') {
                        components.push(
                        <ProductCard key={`product-${result.id}`} product={result} tileOptions={tileOptions} AddToCartComponent={AddToCartComponent} LinkComponent={LinkComponent} onViewChange={onChange.bind(null, result)} productCategoryTitle={productCategoryTitle}/>
                        );
                    } else if (contentType === 'articles' && 'type' in result) {
                        components.push(
                        <BlogCard key={`content-${result.id}`} article={result} LinkComponent={LinkComponent} />
                        );
                    }
                    return components;
                    }).concat(
                    merch?.inline?.filter((item: { config: { position: { index: number; }; }; }) => item.config.position.index >= results.length).map((merchItem: { value: any; }, index: number) => (
                        <div key={`merch-last-${index}`} className='tile__item-grid tile__merch-item p-2' dangerouslySetInnerHTML={{__html: merchItem.value}}></div>
                    )) || []
                    )}
                </div>
            </div>
            <div className="load-more-container">
                {props?.searchData?.pagination && props?.searchData?.pagination.currentPage < props?.searchData?.pagination.totalPages && (
                <>
                    <button className="load-more-btn" onClick={props?.onLoadMore}>Load More</button>
                    <div className='load-more-btn__label'>Viewing {results.length} of {props?.searchData?.pagination.totalResults}</div>
                </>
                )}
            </div>
            </>
        )}
    </div>
  )
};
