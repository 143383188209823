'use client'

import { type BuilderStore } from '@builder.io/react';
import { Dialog, Transition } from '@headlessui/react';
import useUser from 'components/hooks/use-user';
import useWishlist from 'components/hooks/use-wishlist';
import AddedWishlist from 'components/icons/added-wishlist';
import CloseIcon from 'components/icons/close';
import RemovedWishlist from 'components/icons/removed-wishlist';
import { withSuspense } from 'components/with-suspense';
import { Product } from 'lib/bigcommerce/types';
import { Result, ResultJSON } from 'lib/result';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Fragment, useEffect, useState } from 'react';
import * as gtm from "../analytics/gtm";

import './add-to-wishlist.css';

const removeWishlistProduct = async (productId: number): Promise<ResultJSON<unknown>> => {
    try {
        const response = await fetch(`/api/user/wishlist/item/${productId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return await response.json();
    } catch (error: any) {
        return Result.fail(error.message).toJSON();
    }
}

const addWishlistProduct = async (productId: number): Promise<ResultJSON<unknown>> => {
    try {
        const response = await fetch(`/api/user/wishlist/item/${productId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return await response.json();
    } catch (error: any) {
        return Result.fail(error.message).toJSON();
    }
}

function AddWishlistComponent(props: { product: Product, text: string, builderState?: BuilderStore }) {
    const product = props.product ?? props.builderState?.state?.product;
    const productId = parseInt(product.id, 10) ?? 0;
    const text = props?.text;

    const [inWishlist, setInWishlist] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { user, loggedIn, loading: userLoading } = useUser();
    const path = usePathname() || '';
    const currentPath = encodeURIComponent(path);
    const { loading: wishlistLoading, wishlist, mutate } = useWishlist();

    const loading = wishlistLoading || userLoading;

    useEffect(() => {
        if (!loading) {
            if (wishlist?.items?.find(item => item.productEntityId === productId)) {
                setInWishlist(true);
            }
        }
    }, [productId, loading, wishlist]);

    const handleClick = async () => {
        if (loading) {
            return;
        }

        if (!loggedIn) {
            setIsOpen(true);
            return;
        }

        const item = wishlist?.items?.find(item => item.productEntityId === productId);
        if (!item) {
            const response = await addWishlistProduct(productId);
            if (response.isSuccess) {
                setInWishlist(true)
                mutate();
                gtm.addToWishlist({ product, customer: user });
            } else {
                console.error('Failed to update wishlist', response.error);
            }
        } else {
            const response = await removeWishlistProduct(productId);
            if (response.isSuccess) {
                setInWishlist(false);
                mutate();
            } else {
                console.error('Failed to update wishlist', response.error);
            }
        }
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <button className={text ? 'cart-wishlist' : ''} disabled={loading} onClick={handleClick}>
                {inWishlist ?
                  <><AddedWishlist />{text ? 'Remove from Wishlist' : ''}</> : <><RemovedWishlist />{text}</>}
            </button>

            {!loading && !loggedIn && (
                <Transition show={isOpen}>
                <Dialog onClose={closeModal}  className="modal">
                  <Transition.Child
                    as={Fragment}
                    enter="transition-all ease-in-out duration-300"
                    enterFrom="opacity-0 backdrop-blur-none"
                    enterTo="opacity-100 backdrop-blur-[.5px]"
                    leave="transition-all ease-in-out duration-200"
                    leaveFrom="opacity-100 backdrop-blur-[.5px]"
                    leaveTo="opacity-0 backdrop-blur-none"
                  >
                    <div className="modal-backdrop" aria-hidden="true" />
                  </Transition.Child>
                  <Transition.Child
                    as={Fragment}
                    enter="transition-all ease-in-out duration-300"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition-all ease-in-out duration-200"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className={`modal-dialog`}>
                        <>
                      <div className="modal-container">
                        <header className="modal-header">
                           <p className="modal-header-text">Please Sign in</p>
                           <button aria-label="Close modal" onClick={closeModal}>
                                <CloseIcon />
                            </button>
                        </header>
                        <main className="wishlist-modal modal-content">
                            <div className="wishlist-modal-content">You must be logged in to add items to your Wishlist.</div>
                            <div className='wishlist-btn-container'>
                                <Link href={`/login?redirect=${currentPath}`}><div className='wishlist-btn cta-button cta-button-atc'>Log Into My Account</div></Link>
                                <Link href={`/account?redirect=${currentPath}`}><div className='wishlist-btn cta-button cta-button-atc'>Create An Account</div></Link>
                            </div>     
                        </main>
                      </div>
                      </>
                    </Dialog.Panel>
                  </Transition.Child>
                </Dialog>
              </Transition>
            )}
        </>
    );   
}

const AddWishlistSkeleton = (
  <></>
);

export const AddWishlist = withSuspense(AddWishlistComponent, AddWishlistSkeleton);
