import { builder } from '@builder.io/react';
import { createUrl } from 'lib/utils';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { PropelSearchBar } from "propel-react-components";
import { AutocompleteLinkComponentInterface, SearchBarFormComponentInterface } from 'propel-shared-utility';
import getSearchSuggestionPropsStrategy from "./autocompleteBuilderStrategy";

const WrappedSearchForm: SearchBarFormComponentInterface = ({
  onKeyDown,
  children,
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formElement = e.target as HTMLFormElement;
    const val = formElement.querySelector<HTMLInputElement>('[name="q"]');
    const search = val?.value || '';
    const redirect = val?.dataset?.redirect || '';
    const newParams = new URLSearchParams(searchParams?.toString());
    
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    if (redirect) {
      router.push(redirect);
      return true;
    } else if (search) {
      newParams.set('q', search);
    } else {
      newParams.delete('q');
    }

    setTimeout(() => {
      router.push(createUrl('/search', newParams)); 
    }, 10);
  }

  return (
    <form
      method="GET"
      action="/search"
      onKeyDown={onKeyDown}
      onSubmit={onSubmit}
      className="w-full"
    >
      {children}
    </form>
  );
};


const LinkComponent: AutocompleteLinkComponentInterface = ({ to, href, className, children, onMouseDown, onClick }) => {
  const pathname = usePathname();
  const url = href ? createUrl(href, new URLSearchParams({
      from: pathname
  })) : '#';
  return (
    <Link href={url} passHref legacyBehavior>
      <a className={className} onMouseDown={onMouseDown} onClick={onClick}>
        {children}
      </a>
    </Link>
  );
};

export default LinkComponent;

export const Autocomplete = getSearchSuggestionPropsStrategy(
  PropelSearchBar,
  builder.editingMode,
  LinkComponent,
  WrappedSearchForm
)
