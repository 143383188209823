import { VercelProduct as Product } from 'lib/bigcommerce/types';
import './specs.css';

export function ProductSpecs({ product }: { product: Product }) {
  return (
    <>
      {product.productMetafields.map((field: any, i) => (
        field.key === 'specs' 
          ? <span className={`product-specs`} dangerouslySetInnerHTML={{ __html: field.value }} key={i} />
          : null
      ))}
    </>
  );
}
