'use client';

import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { AddToCart } from 'components/product/add-to-cart';
import { withSuspense } from 'components/with-suspense';
import { VercelProduct as Product } from 'lib/bigcommerce/types';
import { useEffect, useState } from 'react';
import { ProductPrice } from './price';

import './buy-box.css';

const iconSize = '16';

function BuyBoxComponent({ 
  product,
  showQuantitySelector,
  showStickyBar 
}: { 
  product: Product;
  showQuantitySelector: boolean;
  showStickyBar: boolean;
}) {  
  // Get max quantity available
  const maxQuantity = product.inventory?.aggregated.availableToSell;
  
  // Update text input value
  const [inputValue, setValue] = useState(1);

  // Handle value & max quantity
  const handleValue = () => {
    if (inputValue <= 0) {
      setValue(1);
    }
  
    if (inputValue > maxQuantity) {
      setValue(maxQuantity);
      return;
    }
  }

  // Handle text input
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputValue <= 0 ? setValue(1) : setValue(Number(event.target.value));
  }

  // Increment input value
  const increment = () => {
    setValue(inputValue + 1);
  }

  // Decrement input value
  const decrement = () => { 
    setValue(inputValue - 1);
    handleValue();
  }

  let notifyMe = false;
  product && product.productMetafields.map((field: any) => {
    field.key === 'notify-me' && field.value === 'true'
      ? notifyMe = true
      : notifyMe = false
  });

  useEffect(() => {
    const stickyBar = document.querySelector('.product-sticky-bar');

    // Observe when buy box is in viewport
    const buyBox = document.querySelector('.product-buy-box');
    const observer = new IntersectionObserver(entries => {
    // Set sticky bar position as height of utility menu
    let utilityMenuHeight: number;
    const utilityMenu = document.getElementById('sticky-utility-menu');
    utilityMenu !== null
      ? utilityMenuHeight = utilityMenu.getBoundingClientRect().height
      : utilityMenuHeight = 0
      entries.forEach(entry => {
        entry.isIntersecting === true
          ? stickyBar?.setAttribute('style', `opacity: 0; visibility: hidden; bottom: ${utilityMenuHeight}px;`)
          : stickyBar?.setAttribute('style', `opacity: 1; visibility: visible; bottom: ${utilityMenuHeight}px;`)
      });
    });
    buyBox !== null ? observer.observe(buyBox) : null;
  });

  const isInStock = product.inventory.isInStock;

  return (
    <>
      <div className="product-buy-box">
        {
          product.inventory.isInStock === true 
          && product.inventory.aggregated.availableToSell > 1 
          && showQuantitySelector === true
          && !notifyMe
            ? <div className="product-quantity-box">
                <div className="product-quantity-selector">
                  <button 
                    className={`product-quantity-button product-quantity-decrease`}
                    aria-label={`Decrease Quantity`}
                    onClick={decrement}
                  >
                    <MinusIcon width={iconSize} height={iconSize} />
                  </button>
                  <input 
                    type="text" 
                    id="product-quantity"
                    value={`${inputValue}`}
                    onChange={handleInput}
                  />
                  <button 
                    className={`product-quantity-button product-quantity-increase`}
                    aria-disabled={inputValue > maxQuantity ? true : false}
                    aria-label={`Increase Quantity`}
                    disabled={inputValue > maxQuantity ? true : false}
                    onClick={increment}
                  >
                    <PlusIcon width={iconSize} height={iconSize} />
                  </button>
                </div>
                {
                  inputValue > maxQuantity
                    ? <div className="product-quantity-error">
                        Only {maxQuantity} available
                      </div>
                    : null
                }
              </div>
            : null
        }
        <AddToCart 
          product={product}
          variants={product.variants} 
          availableForSale={product.availableForSale} 
          quantity={inputValue} 
          isInStock={isInStock} 
          disabled={
            !notifyMe && !isInStock ? false
              : !notifyMe && inputValue > maxQuantity 
                ? true 
                : false
          }
        />
      </div>
      {
        showStickyBar === true 
          ? <section className="product-sticky-bar">
              <div className="product-sticky-bar-content">
                <h2 className="product-sticky-bar-heading">{product.title}</h2>
                <ProductPrice 
                  hidePromo={true}
                  product={product} 
                />
              </div>
              <AddToCart 
                product={product}
                variants={product.variants} 
                availableForSale={product.availableForSale} 
                quantity={inputValue} 
                isInStock={product.inventory.isInStock} 
                disabled={
                  !notifyMe && !isInStock ? false
                    : !notifyMe && inputValue > maxQuantity 
                      ? true 
                      : false
                }
              />
            </section>
          : null
      }
    </>
  );
}

const BuyBoxSkeleton = (
  <></>
);

export const BuyBox = withSuspense(BuyBoxComponent, BuyBoxSkeleton);
