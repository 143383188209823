import { useState, useEffect } from "react";

interface ProductCountProps {
  initialCount: number;
  contentType: string;
}


const ProductCount: React.FC<ProductCountProps> = ({ initialCount, contentType }) => {
  const [resultsCount, setResultsCount] = useState<number>(initialCount);
  const [type, setType] = useState<string>(contentType)
  
  useEffect(() => {
    const handleResultsUpdate = (event: CustomEvent<{ newCount: number, contentType: string }>) => {
      setResultsCount(event.detail.newCount);
      setType(event.detail.contentType)
    };

    window.addEventListener('resultsCountUpdated', handleResultsUpdate as any);

    return () => {
      window.removeEventListener('resultsCountUpdated', handleResultsUpdate as any);
    };
  }, []);

  return (
    <div>
       {resultsCount} {resultsCount === 1 ? type.slice(0, -1) : type}
    </div>
  );
};

export default ProductCount;