import React, { useContext } from 'react';
import { TimerResult } from 'react-timer-hook';

const TimerContext = React.createContext<TimerResult | undefined>(undefined)

export interface TimerProviderProps {
  children: React.ReactNode,
  value?: TimerResult
}

export function TimerProvider({
  children,
  value
}: TimerProviderProps) {
  return (
    <TimerContext.Provider value={value}>
      {children}
    </TimerContext.Provider>
  );
}

export function useTimer() {
  return useContext(TimerContext);
}