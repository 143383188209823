import {
    type BeaconConfig,
    type SearchspringState
  } from 'int-searchspring-api/src/types';
  import {
      ContentRecommendation,
    LinkComponentInterface,
    type Recommendation,
  } from 'propel-shared-utility';
    
    import { SearchspringService } from 'components/searchspring/searchspringService';
  import { type RecommendationsProps } from 'propel-react-components/src/components/MegaMenuRecs/MegaMenuRecs';
  import React, { useEffect, useState } from 'react';
  import {
    useBeaconEventDataLayer,
    useSearchspringClient,
  } from '../../hooks/ssClientHooks';
  import { getBeaconConfig, getSearchspringState } from '../search-spring-state';
import { ContentRecommendationsProps } from 'propel-react-components/src/components/ContentRecs/ContentRecs';
    
  
type ContentRecsStackedStrategyParams = {
  recommendations?: ContentRecommendation[];
  beaconConfig?: BeaconConfig;
  searchspringState?: SearchspringState;
  profile: string;
  tileType: boolean;
  tileDate: boolean;
  tileDescription: boolean;
  tileTopics: boolean;
};
  
export default function  getContentRecsStackedStrategy(
  Component: React.FC<ContentRecommendationsProps>,
  builderEditorMode: boolean,
  LinkComponent: LinkComponentInterface
) {
  
  const ContentRecsStackedPropsStrategy = (props: ContentRecsStackedStrategyParams) => {
      const tileOptions = {
        type: props.tileType,
        date: props.tileDate,
        description: props.tileDescription,
        topics: props.tileTopics,
      };

      const state = getSearchspringState({ overrides: { siteId: process.env.NEXT_PUBLIC_SS_CONTENT_INDEX, profile: props.profile }})
      const searchspringClient: any = useSearchspringClient(state);
      const [recsData, setRecsData] = useState<any | null>(null);
      const [error, setError] = useState<any | null>(null);

      useEffect(() => {
        const searchService = new SearchspringService(state);
        const fetchRecsData = async () => {
          try {
            const data = await searchService.recommendationsGET(state);
            setRecsData(data);
          } catch (error) {
            setError(error);
          }
        };
        fetchRecsData();   
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [setRecsData, setError]); 
      const beaconConfig = getBeaconConfig({});

      const { setContentRecommendationDataProps } =
        useBeaconEventDataLayer(
          beaconConfig,
          searchspringClient,
          props.recommendations || [],
        );

      return (
        <Component
          recommendations={recsData?.body?.at(0)?.results || []}
          setRecommendationDataProps={setContentRecommendationDataProps}
          LinkComponent={LinkComponent}
          tileOptions={tileOptions}
          />    
      );

  };

  ContentRecsStackedPropsStrategy.displayName = 'ContentRecsStackedPropsStrategy';

  return ContentRecsStackedPropsStrategy;
}