import MulberryLogo from 'components/icons/mulberry-icon';
import React from 'react';
import './mulberry-item.css'

type MulberryItemProps = {
    customItem: {
      id: string;
      merchandise: {
        title: string;
      };
      cost: {
        totalAmount: {
          amount: string;
        };
      };
    };
    handleRemove: (id: string) => void;
  };

type MulberryProtectionProps = {
  index: number;
  handleAddProtectionClick: () => void;
  isMobile: boolean;
  fromMiniCart: boolean;
  isButtonDisabled?: boolean;
};

const removeLastItemFromTitle = (title: string): string => {
  const parts = title.split(' ');
  parts.pop();
  return parts.join(' ').trim();
};

export const MulberryItem: React.FC<MulberryItemProps> = ({ customItem, handleRemove }) => {
    const titleWithoutSku = removeLastItemFromTitle(customItem.merchandise.title);
    return (
        <div className='mulberry-item'>
            <div className='mulberry-logo'><MulberryLogo/></div>
            <div className='mulberry-item-content'>
                <div className='mulberry-item-title'>{titleWithoutSku}</div>
                <div className='mulberry-item-price'>${customItem.cost.totalAmount.amount}</div>
                <button className="mulberry-remove-cta" onClick={() => handleRemove(customItem.id)}>Remove Protection</button>
            </div>
        </div>
    );
};


export const MulberryOffers: React.FC<MulberryProtectionProps> = ({ index, handleAddProtectionClick, isMobile, fromMiniCart, isButtonDisabled }) => {
  let containerClass = `mulberry-inline-container-${index}`;

  if (isMobile && !fromMiniCart) {
    containerClass = `mulberry-mobile-inline-container-${index}`;
  } else if (fromMiniCart) {
    containerClass = `mulberry-minicart-inline-container-${index}`;
  }

  return (
    <div className='mulberry-offers'>
      <div className={`mulberry-container ${containerClass}`}></div>
      <button className="mulberry-protection-cta" onClick={handleAddProtectionClick} disabled={isButtonDisabled}>Add Protection</button>
    </div>
  );
};

