import { Builder } from "@builder.io/sdk";
import { MutableRefObject, useRef } from "react";
import { useTimer } from 'react-timer-hook';
import { StorageProvider, tryParseDate } from 'shared-react-components';

const isBrowser = () => Builder.isBrowser && typeof window !== 'undefined';
const allowTimer = () => isBrowser() && !Builder.isEditing;
const allowPersistTimer = () => isBrowser() && !Builder.isEditing && !Builder.isPreviewing

const getExpiryTimestamp = (timerType: string, timerMs: number, referenceTime: Date, expireDate?: Date) => {
  if (timerType === 'date' && expireDate) {
    return expireDate;
  }

  return new Date(referenceTime.getTime() + timerMs);
}

interface PersistentTimerProps {
  enabled: boolean;
  namespace: string;
  timerType: string;
  timerMs: number;
  expireDate?: Date;
  persist: boolean;
  onExpire?: (() => void);
}

export function usePersistentTimer({ enabled, namespace, timerType, timerMs, persist, onExpire, expireDate }: PersistentTimerProps) {
  const referenceTime = useRef(new Date());
  const initialized = useRef(false);
  const expiryTimestamp = getExpiryTimestamp(timerType, timerMs, referenceTime.current, expireDate);
  const timer = useTimer({ expiryTimestamp: expiryTimestamp, autoStart: false, onExpire });

  if (enabled) {
    if (allowTimer() && !initialized.current) {
      if (persist && allowPersistTimer()) {
        const storedReferenceTime = StorageProvider.getItem<MutableRefObject<string>>(namespace);
        const newReferenceTime = tryParseDate(storedReferenceTime?.current);
        if (storedReferenceTime && newReferenceTime) {
          referenceTime.current = newReferenceTime;
          const expiryTimestamp = getExpiryTimestamp(timerType, timerMs, newReferenceTime, expireDate);
          timer.restart(expiryTimestamp, true);
        } else {
          StorageProvider.setItem(namespace, referenceTime)
          timer.start();
        }
      }
      else {
        timer.start();
      }
      initialized.current = true;
    }
  }
  else {
    return undefined;
  }

  return timer;
}
