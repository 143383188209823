export type ResultJSON<T> = {
    isSuccess: boolean;
    isFailure: boolean;
    error?: string;
    value?: T;
}

export class Result<T> implements ResultJSON<T> {
    public isSuccess: boolean;
    public isFailure: boolean;
    public error?: string;
    public value?: T;

    private constructor(params: {
        isSuccess: boolean,
        error?: string,
        value?: T
    }) {
        const { isSuccess, error, value } = params;
        if (isSuccess && error) {
            throw new Error(`InvalidOperation: A result cannot be successful and contain an error`);
        }
        if (!isSuccess && !error) {
            throw new Error(`InvalidOperation: A failing result needs to contain an error message`);
        }

        this.isSuccess = isSuccess;
        this.isFailure = !isSuccess;
        this.error = error;
        this.value = value;

        Object.freeze(this);
    }

    public getValue(): T {
        if (!this.isSuccess) {
            throw new Error(`Cant retrieve the value from a failed result.`)
        }

        return this.value!;
    }

    public static ok<U>(value?: U): Result<U> {
        return new Result<U>({ isSuccess: true, value });
    }

    public static fail<U>(error: string): Result<U> {
        return new Result<U>({ isSuccess: false, error });
    }

    public static combine(results: Result<any>[]): Result<any> {
        for (const result of results) {
            if (result.isFailure) return result;
        }
        return Result.ok<any>();
    }

    public ensure(condition: boolean, errorMessage?: string): Result<T> {
        if (condition) {
            return this;
        }
        return Result.fail<T>(errorMessage ?? 'Condition was not satisfied');
    }

    public toJSON(): ResultJSON<T> {
        return {
            ...this
        };
    }

    public static toResult<U>(result: {
        isSuccess: boolean,
        error?: string,
        value?: U
    }): Result<U> {
        return new Result<U>(result);
    }
}