import DOMPurify from 'dompurify';
import { SuggestionBase, SuggestionProduct, SuggestionQuery, SuggestionUIGroup, debounce } from "propel-shared-utility";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useIntelliSuggestTrackClick } from '../components/analytics/intellisuggest';
import { useWrappedComponentContext } from "../hooks/useWrappedComponentContext";
import * as gtm from './analytics/gtm';
import { Close } from './icons/icon-close';
import SearchIcon from './icons/search';

type AutocompleteProps = {
  groups: SuggestionUIGroup<SuggestionBase>[];
  showAutocomplete: boolean;
  toggleShowAutocomplete: (open: boolean) => void;
  handleMouseOver: (e: React.MouseEvent, suggestion: SuggestionBase) => void;
  handleClick: (e: React.MouseEvent, suggestion: SuggestionBase) => void;
  query: string;
};

export const Autocomplete = ({
  query,
  showAutocomplete,
  toggleShowAutocomplete,
  groups,
  handleClick,
  handleMouseOver,
}: AutocompleteProps) => {

  const mobile = useMediaQuery({ query: '(max-width: 990px)' });
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(mobile);
  }, [mobile]);


  const handleCloseClick = () => {
    toggleShowAutocomplete(false);
  };

  const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);

  useEffect(() => {
    const categoriesSet = new Set<string>();
  
    groups.filter(g => g.group === 'products').forEach((g) => {
      g.suggestions.forEach(tile => {
        if (tile?.categories) {
          tile.categories.forEach(category => {
            categoriesSet.add(category);
          });
        }
      });
    });
  
    setUniqueCategories([...categoriesSet]);
  
  }, [groups]); 

  const categories = uniqueCategories.map(item => {
    const [label, url] = item.split("::");
    return { label, url };
  });

  useEffect(() => {
    const body = document.body;
    const originalStyle = body.style.overflow;
    
    if (showAutocomplete) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = originalStyle; 
    }

    return () => {
      body.style.overflow = originalStyle;
    };
  }, [showAutocomplete]);

  return (   
    <div
      className={`z-10 ${
        showAutocomplete ? '' : 'hidden'
      } search-component__dropdown bg-white absolute w-full flex`}
    >
      {!isMobile && (
        <>
        <div className="ss__autocomplete__left-col flex-1">
          {groups.filter(g => g.group === 'products').map((g, index) => (
            g.suggestions?.length > 0 ? (
              <div className="basis-full max-w-[100%]" key={g.group}>
                <div className="ss__autocomplete__heading-wrapper">
                  <h6 className="ss__autocomplete-heading ss__autocomplete-products-heading">{ query ? 'Related Products' : 'Featured Products'} </h6>
                  { query && (
                    <a className='ss__autocomplete-view-all' href={`/search?q=${query}`}>View All Results</a>
                  )}
                </div>

                <AutocompleteProducts
                  itemListName={query ? 'Search Suggestions' : 'Featured Products'}
                  handleClick={handleClick}
                  toggleAutocomplete={toggleShowAutocomplete}
                  handleMouseOver={handleMouseOver}
                  group={g as SuggestionUIGroup<SuggestionProduct>}
                  query={query}
                />
              </div>
            ) : (
              <div className="basis-full max-w-[100%] ss__autocomplete-empty" key={g.group}>
                <div className="ss__autocomplete__heading-wrapper">
                  <h6 className="ss__autocomplete-heading ss__autocomplete-products-heading">Related products</h6>
                </div>
                
                <div className="ss__autocomplete-empty-content">
                  <h1>No Product Matches</h1>
                  <div>Continue typing or change search term for better results</div>
                </div>
              </div>
            )
          ))}
        </div>
        
        <div className="ss__autocomplete__right-col">
          <Close onClick={handleCloseClick} />
          {groups.filter(g => g.group !== 'products' && g.group !== 'trending').map((g, index) => {
            let title;
            switch (g.group) {
              case 'alternatives':
                title = "Alternatives";
                break;
              case 'suggest':
                title = "Top Suggestion";
                break;
              default:
                return null;
            }

            return (
              g.suggestions?.length > 0 && (
                <div className={`ss__${g.group}-wrapper
                  ${groups.some(g => (g.group === 'alternatives') && g.suggestions.length === 0 && query !== '') ? 'ss__suggest-extra-bottom-space' : ''}
                  `} 
                  key={g.group}
                >
                  <AutocompleteSuggestions
                    handleClick={handleClick}
                    toggleAutocomplete={toggleShowAutocomplete}
                    handleMouseOver={handleMouseOver}
                    group={g as SuggestionUIGroup<SuggestionQuery>}
                    query={query}
                  />
                </div>
              )
            );
          })}
 
          {query !== '' && categories.length > 0 && groups.filter(g => g.group === 'products').map((g, index) => (
            g.suggestions?.length > 0 && (
              <div className="ss__related-categories" key={g.group}>
                <h6 className="ss__autocomplete-heading">Related Categories</h6>
                <div className="ss__trending-container">
                {categories.slice(0, 3).map((category, index) => {
                  return (
                    <a href={category.url} key={index} dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(category.label as string),
                    }}/>
                  );
                })}
                 </div>
              </div>
            )
          ))}
        
          {groups.filter(g => g.group === 'trending').map((g, index) => (
            g.trending?.length > 0 && (
              <div  className={`ss__${g.group}-wrapper`} key={g.group}>
                <h6 className="ss__autocomplete-heading">Popular Searches</h6>
                <div className="ss__trending-container">
                  {g.trending.slice(0, 3).map(t => (
                    <a href={`/search?q=${t.searchQuery}`} key={t.popularity}>{t.searchQuery}</a>
                  ))}
                </div> 
              </div>
            )
          ))}
        </div>
        </>
      )}

      {isMobile && (
        <>
        <div className="ss__autocomplete__mobile">
        <Close onClick={handleCloseClick} />
          {groups.filter(g => g.group !== 'products' && g.group !== 'trending').map((g, index) => {
            let title;
            switch (g.group) {
              case 'alternatives':
                title = "Alternatives";
                break;
              case 'suggest':
                title = "Top Suggestion";
                break;
              default:
                return null;
            }

            return (
              g.suggestions?.length > 0 && (
                <div className={`ss__${g.group}-wrapper
                  ${groups.some(g => (g.group === 'alternatives') && g.suggestions.length === 0 && query !== '') ? 'ss__suggest-extra-bottom-space' : ''}`} 
                  key={g.group}
                >
                  <AutocompleteSuggestions
                    handleClick={handleClick}
                    handleMouseOver={handleMouseOver}
                    toggleAutocomplete={toggleShowAutocomplete}
                    group={g as SuggestionUIGroup<SuggestionQuery>}
                    query={query}
                  />
                </div>
              )
            );
          })}

          {query == '' && groups.filter(g => g.group === 'trending').map((g, index) => (
            g.trending?.length > 0 && (
              <div  className={`ss__${g.group}-wrapper`} key={g.group}>
                <h6 className="ss__autocomplete-heading">Popular Searches</h6>
                <div className="ss__trending-container">
                  {g.trending.slice(0, 3).map(t => (
                    <a href={`/search?q=${t.searchQuery}`} key={t.popularity}>{t.searchQuery}</a>
                  ))}
                </div> 
              </div>
            )
          ))}

          {groups.filter(g => g.group === 'products').map((g, index) => (
            g.suggestions?.length > 0 && (
              <div className="basis-full max-w-[100%]" key={g.group}>
                <div className={`ss__autocomplete__heading-wrapper 
                   ${groups.some(g => (g.group === 'alternatives' || g.group === 'suggest') && g.suggestions.length === 0 && query !== '') ? 'ss__autocomplete__heading-extra-space' : ''}`}>
                  <h6 className="ss__autocomplete-heading ss__autocomplete-products-heading">{ query ? 'Related Products' : 'Featured Products'} </h6>
                  { query && (
                    <a className='ss__autocomplete-view-all' href={`/search?q=${query}`}>View All Results</a>
                  )}
                </div>
                
                <AutocompleteProducts
                  itemListName={query ? 'Search Suggestions' : 'Featured Products'}
                  handleClick={handleClick}
                  toggleAutocomplete={toggleShowAutocomplete}
                  handleMouseOver={handleMouseOver}
                  group={g as SuggestionUIGroup<SuggestionProduct>}
                  query={query}
                />
              </div>
            )
          ))}

          {query !== '' && categories.length > 0 && groups.filter(g => g.group === 'products').map((g, index) => (
            g.suggestions?.length > 0 && (
              <div className="ss__related-categories" key={g.group}>
                <h6 className="ss__autocomplete-heading">Related Categories</h6>
                <div className="ss__trending-container">
                  {categories.slice(0, 3).map((category, index) => {
                    return (
                      <a href={category.url} key={index} dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(category.label as string),
                      }}/>
                    );
                  })}
                </div>
              </div>
            )
          ))}
          
          {query !== '' && groups.filter(g => g.group === 'trending').map((g, index) => (
            g.trending?.length > 0 && (
              <div className={`ss__trending-wrapper-w-results`} key={g.group}>
                <h6 className="ss__autocomplete-heading">Popular Searches</h6>
                <div className="ss__trending-container">
                {g.trending.slice(0, 3).map(t => (
                  <a href={`/search?q=${t.searchQuery}`} key={t.popularity}>{t.searchQuery}</a>
                ))}
                </div> 
              </div>
            )
          ))}
        </div>
        </>
      )} 
      </div>
  );
};

type AutocompleteSuggestionsProps = {
  group: SuggestionUIGroup<SuggestionQuery>;
  handleMouseOver: (
    e: React.MouseEvent,
    suggestion: SuggestionQuery,
  ) => void;
  handleClick?: (
    e: React.MouseEvent,
    suggestion: SuggestionQuery,
  ) => void;
  query: string;
  toggleAutocomplete: (open: boolean) => void;
};

const AutocompleteSuggestions = (props: AutocompleteSuggestionsProps) => {
  const suggestions = props.group.suggestions;
  const handleMouseOver = props.handleMouseOver;

  const handleSuggestionClick = () => {
    props.toggleAutocomplete(false);
    document.body.focus();
  };
  
  return (
    suggestions && (
      <div
        className={`ss__alternatives ${props.group.group === "suggest" ? 'ss__suggest' : ''  }`}
        aria-labelledby="dropdown-button"
      >
        {suggestions.map((s) => (
          <span className="ss__alternatives-option" key={s.uniqueId}>
            <SearchIcon/>
            <QuerySuggestionOption
              handleClick={handleSuggestionClick}
              handleMouseOver={handleMouseOver}
              suggestion={s}
              query={props.query}
            />
          </span>
        ))}
      </div>
    )
  );
};

type AutocompleteProductsProps = {
  itemListName: string;
  group: SuggestionUIGroup<SuggestionProduct>;
  handleMouseOver: (
    e: React.MouseEvent,
    suggestion: SuggestionProduct,
  ) => void;
  handleClick?: (
    e: React.MouseEvent,
    suggestion: SuggestionProduct,
  ) => void;
  toggleAutocomplete: (open: boolean) => void;
  query: string;
};

const triggerViewListEvent = debounce((pendingItemList: React.MutableRefObject<SuggestionProduct[]>, itemListName: string, itemListId: string, query: string) => {
  gtm.viewSuggestionItemList({ query, results: Array.from(pendingItemList.current), itemListName, itemListId });
  pendingItemList.current = [];
  }, 500);

const AutocompleteProducts = (props: AutocompleteProductsProps) => {
  const suggestions = props.group.suggestions;
  const {handleMouseOver, handleClick} = props;
  const { LinkComponent } = useWrappedComponentContext();

  const { intellisuggestTrackClick } = useIntelliSuggestTrackClick('j7gjxx');

  const handleMouseDown = (s: any) => (event: any) => {
    event.preventDefault();
    intellisuggestTrackClick(event.currentTarget, s.intellisuggestData ?? '', s.intellisuggestSignature ?? '')
  };

  const handleClickLink = (event: any) => {
    props.toggleAutocomplete(false);
  };


  
  const handleSuggestionClick = (s: SuggestionProduct) => {
    gtm.selectSuggestionItem({ query: props.query, result: s, itemListName: props.itemListName, itemListId: props.itemListName.toLowerCase().replaceAll(' ', '_') });
    props.toggleAutocomplete(false);
    document.body.focus();
  };
  const pendingItemList = useRef<SuggestionProduct[]>([]);

  if(suggestions && suggestions.length) {
    pendingItemList.current = suggestions;
    triggerViewListEvent(pendingItemList, props.itemListName, props.itemListName.toLowerCase().replaceAll(' ', '_'), props.query);
    return (
      <div className="megamenu__recs py-2 text-sm text-gray-700 dark:text-gray-200">
        {suggestions.map((s) => (
        
            <LinkComponent href={s.custom_url} className="tile__item-recs" to={s.custom_url} key={s.uniqueId} onMouseDown={handleMouseDown(s)} onClick={handleSuggestionClick.bind(this, s)}>
              <ProductSuggestionOption
                handleMouseOver={handleMouseOver}
                handleClick={handleClick}
                suggestion={s}
              />
            </LinkComponent>
        
        ))}
      </div>
    )
  }
  return null;
};

type QuerySuggestionOptionProps = Pick<AutocompleteSuggestionsProps, 'handleClick' | 'handleMouseOver'> & {
    suggestion: SuggestionQuery;
    query: string;
}


const QuerySuggestionOption = (
  props: QuerySuggestionOptionProps,
) => {
  const {handleMouseOver, handleClick, suggestion, query} = props;
  const {text, isActive} = suggestion;

  const renderTextWithHighlight = (text: string, query: string) => {
    if (!query) return text;

    const cleanQuery = escapeRegExp(query);
    const parts = text.split(new RegExp(`(${cleanQuery})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part
    );
  };

  
  return (
    <button
    type="submit"
    className=""
    onMouseEnter={(e) => handleMouseOver(e, suggestion)}
    tabIndex={-1}
    onClick={(e) => handleClick && handleClick(e, suggestion)}
    >
      {renderTextWithHighlight(text, query)}
    </button>
  );
};

type ProductSuggestionOptionProps = Pick<AutocompleteProductsProps, 'handleMouseOver' | 'handleClick'> & {
    suggestion: SuggestionProduct;
}
    

const ProductSuggestionOption = (
  props: ProductSuggestionOptionProps,
) => {
  const {suggestion, handleClick, handleMouseOver} = props;
  return (
    <>
    <div className="border tile__image-wrapper relative">
      {props.suggestion.burst && (
        <img src={props.suggestion.burst} className="absolute right-2 top-2"/>
      )}

      {props.suggestion.banner && (
        <div className="tile__banner">{props.suggestion.banner}</div> 
      )}
      
      <img
      src={props.suggestion.imageUrl}
      alt={props.suggestion.name}
      className="w-full aspect-square object-cover ss__autocomplete-tile-image"
    />
    </div>

    <div className='tile__content-wrapper'>
      {/* Using dangerouslySetInnerHTML here because there are & characters in some of the product names encoded as @amp; */}
      <p className="tile__title mt-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.suggestion.name)}}></p>
      {props.suggestion.mfield_cql_product_tagline && (
        <p className="tile__tagline mt-2">{props.suggestion.mfield_cql_product_tagline}</p>
      )}
      {props.suggestion.mfield_cql_product_specs && (
        <p className="tile__specs mt-2">{props.suggestion.mfield_cql_product_specs}</p>
      )}

      {props.suggestion.result.ss_on_sale === '1' ? 
          ( 
            <div className='tile__sale-wrapper mt-2 mb-2'>
              <p className="tile__price tile__sale-price">${parseFloat(props.suggestion.result.price).toFixed(2)}</p>
              <s className='tile__strikethrough'><p className="tile__price tile__strikethrough">${parseFloat(props.suggestion.result.ss_msrp).toFixed(2)}</p></s>
            </div>
          ) 
          : ( <p className="tile__price pb-4 mt-2">${parseFloat(props.suggestion.result.price).toFixed(2)}</p>)}


      {props.suggestion.mfield_cql_product_promotion_text && (
        <p className="tile__promo mt-2">{props.suggestion.mfield_cql_product_promotion_text}</p>
      )}   
    </div>
    </>
  );
};

function escapeRegExp(unsafeString: string | undefined) {
  const str = unsafeString?.toString() ?? '';
  return str.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&');
}
