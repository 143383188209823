'use client';

import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import * as blotout from 'components/analytics/blotout';
import * as gtm from 'components/analytics/gtm';
import useCart from 'components/hooks/use-cart';
import LoadingDots from 'components/loading-dots';
import { Cart, CartLineItem as CartItem } from 'lib/bigcommerce/types';
import { Result, ResultJSON } from 'lib/result';
import { ChangeEvent, FormEvent, useEffect, useRef, useState, useTransition } from 'react';
import { useFormStatus } from 'react-dom';

const updateItemQuantity = async (
  _prevState: any,
  payload: {
    lineId: string;
    productId: string;
    variantId: string;
    quantity: number;
  },
  associatedItem?: {
    lineId: string;
    quantity: number;
  }): Promise<ResultJSON<Cart>> => {
  try {
    const response = await fetch(`/api/cart/item/${payload.lineId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        payload,
        associatedItem
      }),
    });

    return await response.json();
  } catch (error: any) {
    return Result.fail<Cart>(error.message).toJSON();
  }
}

function SubmitButton({
  className,
  type,
  disabled,
  pending
}: {
  className?: string;
  type: 'plus' | 'minus';
  disabled?: boolean;
  pending: boolean;
}) {
  return (
    <button
      type="submit"
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
      aria-label={type === 'plus' ? 'Increase item quantity' : 'Reduce item quantity'}
      aria-disabled={pending}
      className={className}
      disabled={pending || disabled}
    >
      {pending
        ? <LoadingDots className="loading-dots-dark" />
        : type === 'plus' ? (
          <PlusIcon className="h-4 w-4" />
        ) : (
          <MinusIcon className="h-4 w-4" />
        )}
    </button>
  )
}

function TextInput({
  onChange,
  value,
  max
}: {
  onChange: any;
  value: string;
  max: number;
}) {
  const { pending } = useFormStatus();

  return (
    <div className="cart-line-item-quantity-container">
      <input
        type="text"
        className="cart-line-item-quantity"
        value={value}
        onChange={onChange}
        max={max}
      />
      {
        pending
        && <div className="cart-line-item-quantity-loading-dots">
          <LoadingDots className="loading-dots-dark" />
        </div>
      }
    </div>
  );
}

export function EditItemQuantity({ item, customItem }: { item: CartItem, customItem?: CartItem }) {
  // Update text input value
  const [inputValue, setValue] = useState(item.quantity.toString());
  const prevInputValue = useRef(item.quantity);
  const handleInput = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const { mutate } = useCart();

  useEffect(() => {
    setValue(item.quantity.toString());
  }, [item]);

  // Decrease quantity
  const [decreaseMessage, setDecreaseMessage] = useState<undefined | string>(undefined);
  const [decreasePending, startDecreaseTransition] = useTransition();
  const onSubmitDecrease = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    startDecreaseTransition(async () => {
      setDecreaseMessage(undefined);
      const quantity = item.quantity - 1;
      const associatedItem = (customItem?.id ? {
        lineId: customItem?.id,
        quantity,
      } : undefined)
      const result = await updateItemQuantity(null, {
        lineId: item.id,
        productId: item.merchandise.product.id,
        variantId: item.merchandise.id,
        quantity,
      }, associatedItem);
      if (result.isSuccess) {
        setDecreaseMessage('Success');
        if (result.value) {
          gtm.removeFromCart({ cart: result.value, lineItem: item, quantity: 1 });
        }
        prevInputValue.current = quantity;
        mutate(result);
      }
      else {
        console.log(result.error)
        setDecreaseMessage(result.error)
      }
    });
  };

  // Increase quantity
  const [increaseMessage, setIncreaseMessage] = useState<undefined | string>(undefined);
  const [increasePending, startIncreaseTransition] = useTransition();
  const onSubmitIncrease = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    startIncreaseTransition(async () => {
      setIncreaseMessage(undefined);
      const quantity = item.quantity + 1;
      const associatedItem = (customItem?.id ? {
        lineId: customItem?.id,
        quantity,
      } : undefined)
      const result = await updateItemQuantity(null, {
        lineId: item.id,
        productId: item.merchandise.product.id,
        variantId: item.merchandise.id,
        quantity,
      }, associatedItem);
      if (result.isSuccess) {
        setIncreaseMessage('Success');  
        if (result.value) {
          gtm.addToCart({ cart: result.value, lineItem: item, quantity: 1 });
          blotout.addToCart({ cart: result.value, lineItem: item, quantity: 1 });
        }
        prevInputValue.current = quantity;
        mutate(result)
      }
      else {
        console.log(result.error)
        setIncreaseMessage(result.error)
      }
    });
  };

  // Enter quantity
  const [enterMessage, setEnterMessage] = useState<undefined | string>(undefined);
  const [_enterPending, startEnterTransition] = useTransition();
  const onSubmitEnter = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const quantity = parseInt(inputValue, 10);
    if (isNaN(quantity)) {
      console.log("Invalid Quantity")
      setEnterMessage("Invalid Quantity")
      return;
    }

    startEnterTransition(async () => {
      setEnterMessage(undefined);
      const associatedItem = (customItem?.id ? {
        lineId: customItem?.id,
        quantity,
      } : undefined)
      const result = await updateItemQuantity(null, {
        lineId: item.id,
        productId: item.merchandise.product.id,
        variantId: item.merchandise.id,
        quantity,
      }, associatedItem);
      if (result.isSuccess) {
        setEnterMessage('Success')
        if (result.value) {
          if (prevInputValue.current < quantity) {
            gtm.addToCart({ cart: result.value, lineItem: item, quantity: quantity - prevInputValue.current });
            blotout.addToCart({ cart: result.value, lineItem: item, quantity: quantity - prevInputValue.current });
          } else {
            gtm.removeFromCart({ cart: result.value, lineItem: item, quantity: prevInputValue.current - quantity });
          }
        }
        prevInputValue.current = item.quantity;
        mutate(result);
      }
      else {
        console.log(result.error)
        setEnterMessage(result.error)
      }
    });
  };

  // Get item max quantity available
  const maxQuantity = item.merchandise.product.inventory.aggregated.availableToSell;
  const maxPurchase = item.merchandise.product?.maxPurchaseQuantity;

  const effectiveMax = maxPurchase || maxQuantity;
  const quantity = parseInt(inputValue, 10)
  const isPlusDisabled = quantity >= effectiveMax;

  return (
    <>
      {
        (enterMessage || decreaseMessage || increaseMessage)
        && quantity > maxQuantity
        && <p aria-live="polite" className="cart-line-item-quantity-error" role="status">
          Only {maxQuantity} available
        </p>
      }
      {
        maxPurchase && (
          (enterMessage || decreaseMessage || increaseMessage)
          && quantity > maxPurchase
          && <p aria-live="polite" className="cart-line-item-quantity-error" role="status">
            Only {maxPurchase} per order
          </p>
        )
      }
      <div className="cart-line-item-quantity-adjustments">
        <form onSubmit={onSubmitDecrease}>
          <SubmitButton
            className="cart-line-item-quantity-adjust cart-line-item-quantity-decrease"
            type="minus"
            pending={decreasePending}
          />
          <p aria-live="polite" className="sr-only" role="status">
            {decreaseMessage}
          </p>
        </form>

        <form onSubmit={onSubmitEnter}>
          <TextInput
            value={inputValue}
            onChange={handleInput}
            max={effectiveMax}
          />
          <p aria-live="polite" className="sr-only" role="status">
            {enterMessage}
          </p>
        </form>

        <form
          onSubmit={onSubmitIncrease}
        >
          <SubmitButton
            className="cart-line-item-quantity-adjust cart-line-item-quantity-increase"
            type="plus"
            disabled={isPlusDisabled}
            pending={increasePending}
          />
          <p aria-live="polite" className="sr-only" role="status">
            {increaseMessage}
          </p>
        </form>
      </div>
    </>
  );
}

