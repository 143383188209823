import React, { ReactNode, Suspense } from "react";

export const withSuspense = <P extends object>(Component: React.ComponentType<P>, fallback: ReactNode) => {
  const HOC = React.forwardRef<any, React.PropsWithChildren<P>>(
    (props, ref) => {
      return (
        <Suspense fallback={fallback}>
          <Component {...props} ref={ref}>
            {props.children}
          </Component>
        </Suspense>
      );
    }
  );
  HOC.displayName = "WithSuspense";
  return HOC;
};
