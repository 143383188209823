'use client';

import { withSearchspringSearch } from "components/searchspring/withSearchspringSearch";
import { withSuspense } from "components/with-suspense";
import { ArrowDown } from "propel-react-components/src/components/Arrows/ArrowDown";
import { SortByComponent } from "propel-react-components/src/components/Sort/SortByComponent";

const SortBySkeleton = (
  <div className="ss__sortby-component">
    <div className="ss__select-wrapper">
      <div className="ss__custom-select">
        <div className="ss__custom-select-label animate-pulse">
          <span>Sort By:</span> <ArrowDown />
        </div>
      </div>
    </div>
  </div>
);

export const SortBy = withSuspense(withSearchspringSearch(({ mobile, ...props }) => (
  <SortByComponent {...props} mobile={mobile} />
)), SortBySkeleton);
