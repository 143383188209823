'use client'
import ArrowLeft from 'components/icons/arrow-left';
import Link from 'next/link';
import { useMediaQuery } from 'react-responsive';
import './breadcrumbs.css';

export function PLPBreadcrumbs(props:any) {
    const {matchedBreadcrumbs} = props.builderState.state;
    const isMobile = useMediaQuery({
        query: '(max-width: 990px)'
    });

    const createPlpBreadcrumbsJsonLd = (matchedBreadcrumbs?: any[]) => {
        const startingJson = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
                {
                    "@type": "ListItem",
                    position: 1,
                    item: {
                        "@id": process.env.NEXT_PUBLIC_DOMAIN_NAME,
                        name: "home",
                    }
                },
            ]
        }
        const plpBreadcrumbsJsonLd = startingJson
        if (matchedBreadcrumbs && matchedBreadcrumbs.length >= 1) {
            plpBreadcrumbsJsonLd.itemListElement = startingJson.itemListElement.concat(matchedBreadcrumbs.map((item: any, index: number) => {
                return {
                    "@type": "ListItem",
                    position: index + 2,
                    item: {
                        "@id": process.env.NEXT_PUBLIC_DOMAIN_NAME + item.collection.path,
                        name: item.collection.name,
                    }
                };
            }));
        }

        return plpBreadcrumbsJsonLd;
    }

    return (
        <>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
            __html: JSON.stringify(createPlpBreadcrumbsJsonLd(matchedBreadcrumbs))
            }}
        />
        {matchedBreadcrumbs.length > 1 && (
                <div className="breadcrumbs">
                    <ul className="breadcrumbs-list">
                    {
                        !isMobile ? (
                            <>
                            <li key="home" className="breadcrumb">
                                <Link href={'/'}>
                                    Home /
                                </Link>
                            </li>
                            
                            <li key={'breadcrumb'} className="breadcrumb">
                                <Link href={matchedBreadcrumbs[0].collection.path ?? '#'}>
                                {matchedBreadcrumbs[0].collection.name}
                                </Link>
                            </li>
                            </>
                        ) : (
                            <>
                            <li key={'mobile-breadcrumb'} className="breadcrumb">
                                <Link href={matchedBreadcrumbs[0].collection.path ?? '#'}>
                                <ArrowLeft/>
                                {matchedBreadcrumbs[0].collection.name}
                                </Link>
                            </li>
                            </>
                        )     
                    }
                    </ul>
                </div>
            )}
        </>
    )
}