import {type ReactNode} from 'react';

export enum KeyCodes {
  TAB = 'Tab',
  SHIFT = 'Shift',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ENTER = 'Enter',
  DELETE = 'Backspace',
  ESCAPE = 'Escape',
}

export enum PageType {
  PDP,
  PLP,
  HOME,
  OTHER,
  CART,
  NOT_FOUND,
  ACCOUNT,
  NO_RESULTS,
}

export type ReactComponent<T> = (
  props: T & JSX.IntrinsicAttributes,
) => ReactNode;

export * from './types/autocomplete';
export * from './types/recommendations';
export * from './src/util';
export * from './types/search';
