'use client';

import { Dialog, Transition } from '@headlessui/react';
import GCImage from 'components/giftcard/image';
import useCart from 'components/hooks/use-cart';
import CloseIcon from 'components/icons/close';
import Price from 'components/price';
import { useStorefront, useStorefrontDispatch } from 'components/providers/storefront-provider';
import { DEFAULT_OPTION } from 'lib/constants';
import { createUrl } from 'lib/utils';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import { clearOffers } from '../product/mulberry-actions';
import { ShippingBanner } from './shipping-banner';

import './cart-confirm.css';
import './cart-items.css';
import './cart-modal.css';

type MerchandiseSearchParams = {
  [key: string]: string;
};

export default function CartConfirm() {
  const { cart } = useCart();
  const { cartConfirmOpen, currentVariant } = useStorefront();
  const dispatch = useStorefrontDispatch();

  const closeCartConfirm = () => dispatch({ type: 'CLOSE_CART_CONFIRM' });
  const imgDim = 98;

  // Clear Selected Mulberry Offers, so they don't persist.
  clearOffers();

  return (
    <>
      <Transition show={cartConfirmOpen}>
        <Dialog onClose={closeCartConfirm} className="cart-confirm">
          <Transition.Child
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="opacity-0 backdrop-blur-none"
            enterTo="opacity-100 backdrop-blur-[.5px]"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="opacity-100 backdrop-blur-[.5px]"
            leaveTo="opacity-0 backdrop-blur-none"
          >
            <div className="cart-modal-backdrop fixed inset-0 bg-black/60 z-[100]" aria-hidden="true" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition-all ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition-all ease-in-out duration-200"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="cart-modal backdrop-blur-xl">
              <div className="cart-modal-header">
                <h2 className="cart-modal-header-heading">Added to Cart</h2>

                <button
                  aria-label="Close cart"
                  className="cart-modal-close-button"
                  onClick={closeCartConfirm}
                >
                  <CloseIcon />
                </button>
              </div>

              {cart && cart.lines.length > 0 && <ShippingBanner />}

              {cart && cart.lines.length > 0
                ? <div className="cart-confirm-container">
                  <div className="cart-confirm-item">

                    {cart.lines.map((item, i) => {
                      const merchandiseSearchParams = {} as MerchandiseSearchParams;
                      let subTitleWithSelectedOptions = '';

                      item.merchandise.selectedOptions.forEach(({ name, value }) => {
                        subTitleWithSelectedOptions += `${name}: ${value} `;
                        if (value !== DEFAULT_OPTION) {
                          merchandiseSearchParams[name.toLowerCase()] = value;
                        }
                      });

                      const merchandiseUrl = createUrl(
                        item.merchandise.product.handle,
                        new URLSearchParams(merchandiseSearchParams)
                      );

                      return (
                        item?.type === 'giftCertificate' ? (
                          currentVariant === item.id ? (
                            <div className="mini-cart-line-item-inner mini-cart-line-item--gc">
                              <div className="mini-cart-line-item-image-link">
                                <Link
                                  href={merchandiseUrl}

                                >
                                  <div className="mini-cart-line-item-image">
                                    <GCImage dim={imgDim} />
                                  </div>
                                </Link>
                              </div>
                              <div className="cart-line-item-info">
                                <Link
                                  href={merchandiseUrl}
                                  
                                  className="cart-info-wrapper-link"
                                >
                                  <div className="mini-cart-line-item-info-stack">
                                    <span className="cart-item-title">
                                      {item.merchandise.title}
                                    </span>
                                  </div>
                                </Link>
                                <div className="cart-item-right cart-item-right-gc">
                                    <div className="cart-item-pricing">
                                        <Price
                                            className="cart-item-price"
                                            amount={item.cost.totalAmount.amount}
                                            currencyCode={item.cost.totalAmount.currencyCode}
                                            key={item.id}
                                        />
                                    </div>
                                </div> 
                              </div> 
                            </div>
                          ) : null
                        ) : (
                          item.merchandise.id === currentVariant &&
                        <div className="cart-confirm-item-inner" key={i}>
                          <div className="cart-confirm-item-image-link">
                            <Link
                              href={merchandiseUrl}
                              onClick={closeCartConfirm}
                            >
                              <div className="cart-confirm-item-image">
                                <Image
                                  width={98}
                                  height={98}
                                  alt={
                                    item.merchandise.product.featuredImage.altText ||
                                    item.merchandise.product.title
                                  }
                                  src={item.merchandise.product.featuredImage.url}
                                />
                              </div>
                            </Link>
                          </div>
                          <div className="cart-confirm-item-info">
                            <Link
                              href={merchandiseUrl}
                              onClick={closeCartConfirm}
                              className="cart-confirm-wrapper-link"
                            >
                              <div className="cart-confirm-item-info-stack">
                                <span className="cart-item-title">
                                  {item.merchandise.product.title}
                                </span>
                                {item.merchandise.selectedOptions !== null && (
                                  <div className="cart-item-options">
                                    {item.merchandise.selectedOptions.map((option, i) => (
                                      <p className="cart-item-option" key={i}>
                                        {
                                          option.name.includes('Payment') || option.name.includes('payment') ? null : `${option.name}: `
                                        }
                                        {option.value}
                                      </p>
                                    ))}
                                  </div>
                                )}
                                <div className="cart-item-pricing">
                                  {item.merchandise.product.variants.map((variant) => (
                                    item.sku === variant.sku && Number(variant.salePrice.amount) > 0
                                    && <div key={item.sku}>
                                      <div className="cart-item-sale-pricing">
                                        <Price
                                          className="cart-item-price cart-item-sale-price"
                                          amount={variant.salePrice.amount}
                                          currencyCode={item.cost.totalAmount.currencyCode}
                                        />
                                        <span className="cart-item-original-price">
                                          was&nbsp;
                                          <s>
                                            <Price
                                              className="cart-item-price"
                                              amount={variant.basePrice.amount}
                                              currencyCode={item.cost.totalAmount.currencyCode}
                                            />
                                          </s>
                                        </span>
                                      </div>
                                      {item.merchandise.product.productMetafields.map((field: any, i) => (
                                        field.key === 'promotion-text' && <div className="cart-item-promotion-text" key={i} dangerouslySetInnerHTML={{ __html: field.value as string }} />
                                      ))}
                                    </div>
                                  ))}

                                  {item.merchandise.product.variants.map((variant) => (
                                    item.sku === variant.sku && Number(variant.salePrice.amount) === 0
                                    && <Price
                                      className="cart-item-price"
                                      amount={variant.basePrice.amount}
                                      currencyCode={item.cost.totalAmount.currencyCode}
                                      key={item.sku}
                                    />
                                  ))}
                                </div>
                                {item.merchandise.product.productMetafields.map((field: any, i) => (
                                  field.key === 'cart-sale-messaging' && <div className="cart-item-sale-message" key={i} dangerouslySetInnerHTML={{ __html: field.value as string }} />
                                ))}
                              </div>
                            </Link>
                          </div>
                        </div>
                        )
                      );
                    })}
                  </div>

                  <div className="cart-confirm-totals">
                    <div className="cart-confirm-totals-subtotal">
                      <strong>Subtotal</strong>
                      <div>
                        <span>{cart.totalQuantity} Items</span>
                        &nbsp;|&nbsp;
                        <span>{
                          <Price
                            amount={cart.cost.subtotalAmount.amount}
                            currencyCode={cart.cost.subtotalAmount.currencyCode}
                          />
                        }</span>
                      </div>
                    </div>
                    <div className="cart-confirm-totals-actions">
                      <a href={cart.checkoutUrl} onClick={closeCartConfirm} className="cta-button">
                        Check Out Now
                      </a>
                      <Link href="/cart" onClick={closeCartConfirm} className="cta-button cta-button-secondary">
                        View Cart
                      </Link>
                    </div>
                  </div>
                </div>
                : null
              }
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}
