import { AutocompleteDataAdapter, KeyCodes, SuggestionBase, SuggestionQuery, SuggestionUIGroup } from 'propel-shared-utility';
import { useCallback, useEffect, useState } from "react";


type AutocompleteOutput = {
  groups: SuggestionUIGroup<SuggestionBase>[];
  updateActiveSuggestion: (suggestion?: SuggestionBase) => void;
  handleKeyboardInput: (key: KeyCodes) => void;
  query: string;
  setInputQuery: (q: string) => void;
  toggleShowAutocomplete: (open: boolean) => void;
  showAutocomplete: boolean;
};

export const useCreateAutocomplete = <T extends SuggestionBase, K>(
    data: K | undefined,
    adapter: AutocompleteDataAdapter<T, K>
): AutocompleteOutput => {
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  // create handler function to set active suggestion
  const [textInputQuery, setTextInputQuery] = useState('');
  const setInputQuery = (q: string) => {
    setCurrentIndex(-1);
    setTextInputQuery(q);
  };

  const [currentIndex, setCurrentIndex] = useState(-1);
  const resetSuggestions = useCallback(() => {
    setCurrentIndex(-1);
  }, []);
  useEffect(() => {
    resetSuggestions();
  }, [textInputQuery, resetSuggestions]);


  const inputs = data ? adapter.convert(data) : [];

  // flatten the generic suggestions into an array, map uniqueIds to the array index
  const suggestionIndexMap = new Map<string, number>();
  const flattenedOrderedSuggestions: T[] = [];

  const checkActive = (keyString: string): boolean => {
    const index = suggestionIndexMap.get(keyString);
    if (index === currentIndex) {
      return true;
    }
    return false;
  };

  if (inputs) {
    inputs.forEach((input) => {
      input?.suggestions.forEach((s) => {
        flattenedOrderedSuggestions.push(s);
      });
    });
  }
  

  flattenedOrderedSuggestions.forEach((s, index) => {
    suggestionIndexMap.set(s.uniqueId, index);
  });

  let activeQuery = textInputQuery;

  const groups: SuggestionUIGroup<T>[] = inputs.map((input) => {
    return {
      ...input,
      suggestions: input.suggestions.map((s) => {
        const isActive = checkActive(s.uniqueId);
        // cast to SuggestionQuery
        const querySuggestion = (s as unknown as SuggestionQuery);
        if (isActive && querySuggestion.text) {
          activeQuery = (s as any).text;
        }
        return {
          ...s,
          isActive,
        };
      }),
    };
  });

  const updateActiveSuggestion = (suggestion?: SuggestionBase) => {
    if (suggestion?.uniqueId) {
      const indexOfSuggestion = suggestionIndexMap.get(suggestion?.uniqueId);
      if (typeof indexOfSuggestion == 'number') {
        setCurrentIndex(indexOfSuggestion);
        return;
      }
      resetSuggestions();
      return;
    }
    resetSuggestions();
  };

  const handleKeyboardInput = (key: KeyCodes) => {
    switch (key) {
      case KeyCodes.ARROW_DOWN:
        if (currentIndex < flattenedOrderedSuggestions.length - 1) {
          setCurrentIndex(currentIndex + 1);
        }
        break;
      case KeyCodes.ARROW_UP:
        if (currentIndex > -1) {
          setCurrentIndex(currentIndex - 1);
        }
        break;
      case KeyCodes.ENTER:
        toggleShowAutocomplete(false);
        break;
      case KeyCodes.ESCAPE:
        toggleShowAutocomplete(false);
        break;
      default:
        if (currentIndex !== -1) {
          setInputQuery(activeQuery);
        }
        setCurrentIndex(-1);
    }
  };

  const toggleShowAutocomplete = (open: boolean) => {
    setShowAutocomplete(open);
  };

  return {
    groups,
    handleKeyboardInput,
    updateActiveSuggestion,
    query: activeQuery,
    setInputQuery,
    toggleShowAutocomplete,
    showAutocomplete,
  };
};