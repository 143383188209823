'use client';

import { TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import * as gtm from 'components/analytics/gtm';
import useCart from 'components/hooks/use-cart';
import LoadingDots from 'components/loading-dots';
import type { Cart, CartLineItem } from 'lib/bigcommerce/types';
import { Result, ResultJSON } from 'lib/result';
import { FormEvent, useState, useTransition } from 'react';

function SubmitButton({ pending }: { pending: boolean }) {
  return (
    <button
      type="submit"
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
      aria-label="Remove cart item"
      aria-disabled={pending}
      className={clsx(
        'cart-item-delete h-[1.5rem] w-[1.5rem]',
        {
          'cursor-not-allowed': pending
        }
      )}
    >
      {pending ? <LoadingDots className="loading-dots-dark" /> : <TrashIcon />}
    </button>
  );
}

const removeItem = async (itemId: string, associatedItemId?: string): Promise<ResultJSON<Cart>> => {
  try {
    const response = await fetch(`/api/cart/item/${itemId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        associatedItem: {
          lineId: associatedItemId
        }
      }),
    });

    return await response.json();
  } catch (error: any) {
    return Result.fail<Cart>(error.message).toJSON();
  }
}

export function DeleteItemButton({ item, customItem }: { item: CartLineItem, customItem?: CartLineItem }) {
  const { mutate } = useCart();
  const [message, setMessage] = useState<undefined | string>(undefined);
  const [isPending, startTransition] = useTransition();
  const itemId = item.id;

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    startTransition(async () => {
      setMessage(undefined);
      const result = await removeItem(itemId, customItem?.id);
      if (result.isSuccess) {
        setMessage('Success')
        if (result.value) {
          gtm.removeFromCart({ cart: result.value, lineItem: item, quantity: item.quantity });
        }
        mutate(result);
      }
      else {
        console.log(result.error)
        setMessage(result.error)
      }
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <SubmitButton pending={isPending} />
      <p aria-live="polite" className="sr-only" role="status">
        {message}
      </p>
    </form>
  );
}
