import {
  type SearchResponseModel,
  type SearchspringAPIService,
} from '../generated/search';
import {type GenericAPIResult} from '../generated/search/core/ApiResult';
import {type SuggestResponseModel} from '../generated/search/models/SuggestResponseModel';
import {
  type BeaconDTO,
  type RecommendationsResult,
  type SearchspringState,
} from '../types';

export class SearchspringAPIWrapper {
  #searchspringAPIClient: SearchspringAPIService;

  constructor(searchspringAPIClient: SearchspringAPIService) {
    this.#searchspringAPIClient = searchspringAPIClient;
  }

  public async searchGET(
    state: SearchspringState,
  ): Promise<GenericAPIResult<SearchResponseModel | undefined>> {
    const {
      userId,
      sessionId,
      pageLoadId,
      lastViewed,
      cart,
      shopper,
      httpXForwardedFor,
      domain,
      q,
      filter,
      bgfilter,
      sort,
      resultsPerPage,
      page,
      landingPage,
      includedFacets,
      excludedFacets,
      disableInlineBanners,
      siteId,
      tag,
    } = state;

    let result: GenericAPIResult<SearchResponseModel | undefined> = {
      body: undefined,
      ok: false,
    };
    try {
      result = await this.#searchspringAPIClient.getSearch(
        siteId,
        userId as string,
        sessionId as string,
        pageLoadId.identifier,
        domain,
        httpXForwardedFor,
        'json',
        q || '',
        filter,
        bgfilter, //probably need to pull this from a collections ID somehow
        sort,
        resultsPerPage, //resultsPerPage, not sure how to source this configuration-wise
        page,
        'minimal',
        landingPage,
        tag,
        includedFacets, //includedFacets,
        excludedFacets, //excludedFacets,
        disableInlineBanners, //disableInlineBanners,
        lastViewed, //lastViewed, this is a csv of product IDs that I think needs to be sourced from a cookie
        cart, //cart, //csv of product IDs that are in users cart
        shopper, //shopper, ID of the shopper from the platform (logged in only)
      );
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  public async trendingGET(
    state: SearchspringState,
  ): Promise<GenericAPIResult<any | undefined>> {
    let result: GenericAPIResult<any | undefined> = {
      body: undefined,
      ok: false,
    };
    try {
      result = await this.#searchspringAPIClient.getTrending(
        state.siteId,
        5
      );
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  public async suggestionsGET(
    state: SearchspringState,
  ): Promise<GenericAPIResult<SuggestResponseModel | undefined>> {
    let result: GenericAPIResult<SuggestResponseModel | undefined> = {
      body: undefined,
      ok: false,
    };
    try {
      result = await this.#searchspringAPIClient.getSuggest(
        state.siteId,
        state.q,
        state.language,
        state.suggestionCount,
        state.productCount,
      );
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  public async autocompleteGET(
    state: SearchspringState,
  ): Promise<GenericAPIResult<SearchResponseModel | undefined>> {
    const {
      userId,
      sessionId,
      pageLoadId,
      lastViewed,
      cart,
      shopper,
      httpXForwardedFor,
      domain,
      q,
      filter,
      bgfilter,
      sort,
      resultsPerPage,
      page,
      landingPage,
      includedFacets,
      excludedFacets,
      disableInlineBanners,
      siteId,
      tag,
    } = state;

    let result: GenericAPIResult<SearchResponseModel | undefined> = {
      body: undefined,
      ok: false,
    };

    try {
      result = await this.#searchspringAPIClient.getAutocomplete(
        siteId,
        userId as string,
        sessionId as string,
        '', //page parameter
        domain,
        httpXForwardedFor,
        'json',
        q || '',
        filter,
        bgfilter, //probably need to pull this from a collections ID somehow
        sort,
        resultsPerPage, //resultsPerPage, not sure how to source this configuration-wise
        page,
        'minimal',
        landingPage,
        tag,
        includedFacets, //includedFacets,
        excludedFacets, //excludedFacets,
        disableInlineBanners, //disableInlineBanners,
        lastViewed, //lastViewed, this is a csv of product IDs that I think needs to be sourced from a cookie
        cart, //cart, //csv of product IDs that are in users cart
        shopper, //shopper, ID of the shopper from the platform (logged in only)
      );
    } catch (e) {
      console.error(e);
    }

    return result;
  }

  public async recommendationsGET(
    state: SearchspringState,
  ): Promise<GenericAPIResult<RecommendationsResult[] | undefined>> {
    const {
      siteId,
      profile,
      productId_PDP,
      categories,
      brands,
      shopper,
      cart,
      lastViewed,
      limits,
    } = state;

    let result: GenericAPIResult<RecommendationsResult[] | undefined> = {
      body: undefined,
      ok: false,
    };
    try {
      result = await this.#searchspringAPIClient.getRecommendations(
        siteId,
        profile,
        productId_PDP,
        categories,
        brands,
        shopper,
        cart,
        lastViewed,
        limits,
      );
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  public async beaconPOST(
    beaconDTO: BeaconDTO,
  ): Promise<GenericAPIResult<{success: boolean} | undefined>> {
    let result: GenericAPIResult<{success: boolean} | undefined> = {
      body: undefined,
      ok: false,
    };
    try {
      result = await this.#searchspringAPIClient.postBeacon(beaconDTO);
    } catch (e) {
      console.error(e);
    }

    return result;
  }

  public async preflightGET(
    siteId: string,
    userId: string,
    shopper: string | undefined,
    cart: string | undefined,
    lastViewed: string | undefined,
  ) {
    let result: GenericAPIResult<any> = {
      ok: false,
      body: undefined,
    };

    try {
      result = await this.#searchspringAPIClient.getPreflight(
        siteId,
        userId,
        shopper,
        cart,
        lastViewed,
      );
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  public async preflightPOST(
    siteId: string,
    userId: string,
    shopper: string | undefined,
    cart: string | undefined,
    lastViewed: string | undefined,
  ) {
    let result: GenericAPIResult<any> = {
      ok: false,
      body: undefined,
    };

    try {
      result = await this.#searchspringAPIClient.postPreflight(
        siteId,
        userId,
        shopper,
        cart,
        lastViewed,
      );
    } catch (e) {
      console.error(e);
    }
    return result;
  }
}
