import { ProductGrid } from "propel-react-components";
import ResultCount from "propel-react-components/src/components/ProductGrid/results-count";
import { RefinementSidebar } from "propel-react-components/src/components/Sidebar/RefinementSidebar";
import { SortByComponent } from "propel-react-components/src/components/Sort/SortByComponent";
import { TabsGrid } from "./Grid/TabsGrid";

import "./SearchspringWrapper.css";

export const SearchspringWrapper = (props: any) => {
  const { contentType, setContentType, productDataCount, articleDataCount, productCategoryHandle, contentCategoryHandle } = props; 
 
  const handleViewChange = (newView:string) => {
    setContentType(newView); 
    const newParams = new URLSearchParams(window.location.search);
    newParams.set('view', newView);
    window.history.pushState({}, '', `${window.location.pathname}?${newParams.toString()}`);
  };

  return (
    <div className="ss__wrapper">
        {(!productCategoryHandle && !contentCategoryHandle) && (
            <TabsGrid 
                contentType={contentType} 
                onViewChange={handleViewChange}
                productCount={productDataCount}
                articleCount={articleDataCount}
            />
        )}
      <div className="ss__eyebrow">
        <ResultCount 
            count={props.allResults} 
            contentType={contentType} 
        />
        <SortByComponent 
            sortFunction={props.sortFunction} 
            sorting={props.sorting} 
        />
      </div>
      <div className="ss__main">
        <RefinementSidebar 
            data={props.data} 
            searchData={props.searchData} 
            facets={props.facets} 
            refinementFunction={props.refinementFunction} 
            sortFunction={props.sortFunction} 
        />
        <ProductGrid 
            searchData={props.searchData} 
            allResults={props.allResults} 
            refinementFunction={props.refinementFunction} 
            tileOptions={props.tileOptions} 
            onLoadMore={props.onLoadMore} 
            AddToCartComponent={props.AddToCartComponent}
            LinkComponent={props.LinkComponent}
            searchResult={props.searchResult}
            contentType={contentType}
            data={props.data}  
        />
      </div>
    </div>
  );
};
