import {
  type AutocompleteLinkComponentInterface,
  type SearchBarFormComponentInterface,
  type SuggestionBase,
} from 'propel-shared-utility';

import {
  type SearchspringState
} from 'int-searchspring-api/src/types';

import {
  useSearchspringAutocompleteAdapter,
  useSearchspringClient,
  useSearchspringSuggestionsFetcher
} from '../../hooks/ssClientHooks';

import { type SearchBarProps } from 'propel-react-components/src/components/SearchBar';
import React from 'react';
import { getSearchspringState } from '../search-spring-state';

type SuggestionsStrategyParams = {
  searchspringState?: SearchspringState;
  builderEditorMode: boolean;
};


export default function getSearchSuggestionPropsStrategy(
  Component: React.FC<SearchBarProps<any, SuggestionBase>>,
  builderEditorMode: boolean,
  LinkWrapper: AutocompleteLinkComponentInterface,
  FormWrapper: SearchBarFormComponentInterface,
) {
  const SearchSuggestionPropsStrategy = (props: SuggestionsStrategyParams) => {
      const state = getSearchspringState({ overrides: { suggestionCount: 4, productCount: 4, resultsPerPage: 4 }})
      const searchspringClient = useSearchspringClient(state);
      const fetcher = useSearchspringSuggestionsFetcher(searchspringClient);
      const adapter = useSearchspringAutocompleteAdapter().current;

      return (
        <Component
          fetcher={fetcher}
          autoCompleteAdapter={adapter}
          LinkComponent={LinkWrapper}
          FormComponent={FormWrapper}
        />
      );
  };
  
  SearchSuggestionPropsStrategy.displayName = 'SearchSuggestionPropsStrategy';

  return SearchSuggestionPropsStrategy;
}

