import { ProductCardRecommendations } from '../ProductCard/ProductCard';

import {
  LinkComponentInterface,
  type Recommendation,
  type RecommendationDataProps,
} from 'propel-shared-utility';

import './megamenu-recs.css';

interface TileOptions {
  banner: boolean;
  burst: boolean;
  promo: boolean;
  specs: boolean;
  tagline: boolean;
  ratings: boolean;
  addToCart: boolean;
}

export type RecommendationsProps = {
  recommendations?: Recommendation[];
  setRecommendationDataProps: (
    recommendation: Recommendation,
    active: boolean
  ) => RecommendationDataProps;
  tileOptions: TileOptions,
  maxTiles: number,
  LinkComponent: LinkComponentInterface,
};

export const MegaMenuRecs = (props: RecommendationsProps) => {
  const {recommendations, setRecommendationDataProps, tileOptions, maxTiles, LinkComponent} =
    props;

  return recommendations && recommendations.length ? (
    <div className='megamenu__recs'>
        {recommendations.slice(0, maxTiles).map((rec, index) => {
          return (
            <div className={`megamenu__recs-tile megamenu__recs-tile--${maxTiles}`} key={index} >
                 <ProductCardRecommendations
                  product={rec}
                  setRecommendationDataProps={setRecommendationDataProps}
                  tileOptions={tileOptions}
                  LinkComponent={LinkComponent}
                />
            </div>
          );
        })}
    </div>
  ) : null;
};


