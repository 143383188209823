'use client';

import Link from 'next/link';
import HeartOutlineIcon from '../icons/heart-outline';
import HeartSolidIcon from '../icons/heart-solid';
import './header-icons.css';
import './header-wishlist.css';

function checkWishlist() {
    const hasWishlist = false;

    return hasWishlist;
}

export function HeaderWishlist(  props: { showLabel: boolean, attributes: {style: any, className: string, 'builder-id': string, id: string} } ) {
    
  const hasWishlist = checkWishlist();

  const GenIcon = ( { hasWishlist } : {hasWishlist: boolean;}  ) => {
    if(hasWishlist) {
        return (
            <div className="header-icon">
                <HeartSolidIcon />
                <span className="header-icon-label header-icon-label--wishlist">Wishlist</span>
            </div>
        );
    } else {
        return (
            <div className="header-icon">
                <HeartOutlineIcon />
                <span className="header-icon-label header-icon-label--wishlist">Wishlist</span>
            </div>
        );
    }
  }

  return (
    <section 
        className={`wishlist-component ${props.showLabel ? '' : 'header-icon-label-hide'} ${props.attributes.className}`}
        style={props.attributes.style}  
        builder-id={props.attributes['builder-id']} 
        id={props.attributes.id}
    >
        <div 
            className="wishlist-icon"
        >
            <Link
                href={hasWishlist ? `/wishlist` : `#`}
            >
                
                <GenIcon hasWishlist={ hasWishlist }/>
            </Link>
        </div>
    </section>
  );
}
