import Image from 'next/image';

export default function GCImage ( {dim}: {dim: number} ) {
    const src = 'https://cdn.builder.io/api/v1/image/assets%2F1adbd5971dac4273891f273603729777%2F71f5da19356d4c4c936c5389e527dadb';
    const alt = 'Digital Gift Card';

    return (
        <Image
            width={dim}
            height={dim}
        alt={alt}
        src={src}
        />
    )
}
