import {
  type BeaconConfig,
  type SearchspringState
} from 'int-searchspring-api/src/types';
import {
  LinkComponentInterface,
  type Recommendation,
} from 'propel-shared-utility';
  
  import { SearchspringService } from 'components/searchspring/searchspringService';
import { type RecommendationsProps } from 'propel-react-components/src/components/MegaMenuRecs/MegaMenuRecs';
import React, { useEffect, useState } from 'react';
import {
  useBeaconEventDataLayer,
  useSearchspringClient,
} from '../../hooks/ssClientHooks';
import { getBeaconConfig, getSearchspringState } from '../search-spring-state';
  

type MegaMenuRecsStrategyParams = {
    tilePromo: boolean;
    tileBurst: boolean;
    tileBanner: boolean;
    tileTagline: boolean;
    tileSpecs: boolean;
    tileRatings: boolean;
    recommendations?: Recommendation[];
    beaconConfig?: BeaconConfig;
    searchspringState?: SearchspringState;
    profile: string;
    maxTiles: number;
};

  export default function  getMegamenuRecsStrategy(
    Component: React.FC<RecommendationsProps>,
    builderEditorMode: boolean,
    LinkComponent: LinkComponentInterface
  ) {
    
    const MegamenuRecsPropsStrategy = (props: MegaMenuRecsStrategyParams) => {
        const tileOptions = {
          banner: props.tileBanner,
          burst: props.tileBurst,
          promo: props.tilePromo,
          specs: props.tileSpecs,
          ratings: props.tileRatings,
          tagline: props.tileTagline,
          addToCart: false
        };

        const maxTiles = props.maxTiles;
  
        const state = getSearchspringState({ overrides: { profile: props.profile }})
        const searchspringClient: any = useSearchspringClient(state);
        const [recsData, setRecsData] = useState<any | null>(null);
        const [error, setError] = useState<any | null>(null);
  
        useEffect(() => {
          const searchService = new SearchspringService(state);
          const fetchRecsData = async () => {
            try {
              const data = await searchService.recommendationsGET(state);
              setRecsData(data);
            } catch (error) {
              setError(error);
            }
          };
          fetchRecsData();   
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [setRecsData, setError]); 
        const beaconConfig = getBeaconConfig({});

        const { setRecommendationDataProps } =
          useBeaconEventDataLayer(
            beaconConfig,
            searchspringClient,
            props.recommendations || [],
          );
  
        return (
          <Component
            recommendations={recsData?.body?.at(0)?.results || []}
            setRecommendationDataProps={setRecommendationDataProps}
            tileOptions={tileOptions} 
            maxTiles={maxTiles}
            LinkComponent={LinkComponent}
            />    
        );
  
    };
  
    MegamenuRecsPropsStrategy.displayName = 'MegaMenuRecsPropsStrategy';
  
    return MegamenuRecsPropsStrategy;
  }