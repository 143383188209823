import Prose from 'components/prose';
import { VercelProduct as Product } from 'lib/bigcommerce/types';
import './description.css';

export function ProductDescription({ product }: { product: Product }) {
  return (
    <>
      {product.descriptionHtml ? (
        <Prose html={product.descriptionHtml} />
      ) : null}
    </>
  );
}
