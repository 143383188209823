'use client';

import { TruckIcon } from '@heroicons/react/24/outline';
import useCart from 'components/hooks/use-cart';
import { tryParseMoney } from 'lib/utils';
import { useEffect, useState } from 'react';

import './shipping-banner.css';

export function ShippingBanner() {
  const { cart, loading } = useCart();
  const [promotionAmount, setPromotionAmount] = useState(0);

  const currencyCode = cart?.cost?.subtotalAmount?.currencyCode ?? 'USD';
  const subTotal = tryParseMoney(cart?.cost?.subtotalAmount?.amount) ?? 0;

  const channelMetafields = cart?.channelMetafields;

  useEffect(() => {
    channelMetafields?.forEach((field: any) => {
      if (field.key === 'free-shipping') {
        setPromotionAmount(field.value);
      }
    });
  }, [channelMetafields]);

  return (
    <div className="cart-shipping-banner">
      <div className="cart-shipping-banner-content">
        {
          loading ? (
            <ShippingBannerMessagePlaceholder />
          ) : (
            <ShippingBannerMessage promotionAmount={promotionAmount} subTotal={subTotal} currencyCode={currencyCode} />
          )
        }
      </div>
      <div className="cart-shipping-banner-progress">
        <div
          className="cart-shipping-banner-progress-bar"
          style={{
            width: `${(promotionAmount && subTotal > promotionAmount) ? `100` : (subTotal / promotionAmount) * 100}%`
          }}
        ></div>
      </div>
    </div>
  )
}

function ShippingBannerMessage({ promotionAmount, subTotal, currencyCode }: { promotionAmount: number, subTotal: number, currencyCode: string | undefined }) {
  return (
    <>
      <TruckIcon />
      {
        subTotal > promotionAmount ? (
          <span>You qualify for <strong>FREE</strong> shipping on this order!</span>
        ) : (
          <span>
            You are {currencyCode === 'USD' && '$'}{(Math.floor(Number(promotionAmount - subTotal) * 100) / 100).toFixed(2)} {currencyCode !== 'USD' && currencyCode} away from <strong className="uppercase">FREE</strong> shipping!
          </span>
        )
      }
    </>
  );
}

function ShippingBannerMessagePlaceholder() {
  return (
    <span style={{ lineHeight: "24px" }}>&nbsp;</span>
  );
}