'use client';
import { Builder } from '@builder.io/react';
import '@builder.io/widgets/dist/lib/builder-widgets-async';
import dynamic from 'next/dynamic';
// General
import { Carousel, carouselOptions } from 'components/global/carousel';
import { CQLSection } from './components/builder/components/cql-section';
import { cqlSectionOptions } from './components/builder/components/cql-section.config';
import { CQLText } from './components/builder/components/cql-text';
import { builderTextOptions, cqlTextOptions } from './components/builder/components/cql-text.config';
import { withTimer, withTimerContext } from './components/builder/hooks/with-timer';
import { AddToCartCTA } from './components/global/add-to-cart-cta';
import { BackToTop } from './components/global/back-to-top';
import { SocialProof } from "./components/global/social-proof";
import { Accordion } from './components/layout/accordion';
import { Tabs } from './components/layout/tabs';
//Header
import { HeaderAccount } from './components/header/header-account';
import { HeaderCart } from './components/header/header-cart';
import { HeaderWishlist } from './components/header/header-wishlist';
import { MegaNav } from './components/header/mega-nav';
import { MobileNav } from './components/header/mobile-nav';
// Product
import { Breadcrumbs } from 'components/global/breadcrumbs';
import { PLPBreadcrumbs } from 'components/global/plp-breadcrumbs';
import { Autocomplete } from 'components/searchspring/Autocomplete/Autocomplete';
import { ProductGridWrap } from 'components/searchspring/Grid/ProductGrid';
import ProductCount from 'components/searchspring/Grid/ResultsCount';
import { Sidebar } from 'components/searchspring/Grid/Sidebar';
import { MegaMenuRecsWrap } from 'components/searchspring/Megamenu/megaMenuRecs';
import { Recommendations } from 'components/searchspring/Recs/Recs';
import { SortBy } from 'components/searchspring/SortBy/SortBy';
import { ProductBanner } from './components/product/banner';
import { BuyBox } from './components/product/buy-box';
import { ProductDescription } from './components/product/description';
import { ProductInventory } from './components/product/inventory';
import { MediaCarousel } from './components/product/media-carousel';
import { OptionSelector } from './components/product/option-selector';
import { ProductPrice } from './components/product/price';
import { ShippingDelivery } from './components/product/shipping-delivery';
import { ShippingRestriction } from './components/product/shipping-restriction';
import { ProductSKU } from './components/product/sku';
import { ProductSpecs } from './components/product/specs';
import { ProductTitle } from './components/product/title';
// Global Product Card
import { Chat } from 'components/global/snap-chat';
import { AddWishlist } from 'components/login/add-to-wishlist';
import { Mulberry } from 'components/product/mulberry';
import { ContentRecsWrap } from 'components/searchspring/ContentRecs/contentRecs';
import { ContentRecsStackedWrap } from 'components/searchspring/ContentRecs/contentRecsStacked';
import { SearchspringWrap } from 'components/searchspring/searchspring-wrapper';
import { VideoEmbed } from 'components/video/video-block';
import { ShippingBanner } from './components/cart/shipping-banner';


// Register this component for use in the Visual Editor
Builder.registerComponent(VideoEmbed, {
  name: 'CQL Video',
  friendlyName: 'CQL Video',
  inputs: [
    {
      name: 'url',
      type: 'url',
      defaultValue: 'Your link',
      required: true,
      onChange: (options: Map<string, any>) => {
        const url = options.get('url');
        
        if (url) {
          options.set('content', 'Loading...');
          const apiKey = "ae0e60e78201a3f2b0de4b"
          const autoPlayUrl = `${url}?autoplay=1`;
          return fetch(`https://iframe.ly/api/iframely?url=${autoPlayUrl}&api_key=${apiKey}`)
            .then(res => res.json())
            .then(data => {
              if (options.get('url') === url) {
                if (data.links.player) {
                  data.links.player.forEach((player: any) => {
                    if (player.href.includes('autoplay')) {
                      options.set('contentAutoplay', player.html);
                    } else if (!player.href.includes('autoplay')) {
                      options.set('content', player.html);
                    } else {
                      options.set('content', 'Invalid url, please try another');
                    }
                  })
                }
              }
            })
            .catch(err => {
              options.set(
                'content',
                'There was an error embedding this URL, please try again or another URL'
              );
            });
        } else {
          options.delete('content');
        }
      }
    },
    {
      name: 'content',
      type: 'html',
      defaultValue: `<div style="padding: 20px; text-align: center">(Choose an embed URL)<div>`,
      hideFromUI: true,
    },
    {
      name: 'contentAutoplay',
      type: 'html',
      defaultValue: `<div style="padding: 20px; text-align: center">(Choose an embed URL)<div>`,
      hideFromUI: true,
    },
    {
      name: 'posterImage',
      friendlyName: 'Poster Image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      defaultValue: 'https://cdn.builder.io/api/v1/image/assets%2F1adbd5971dac4273891f273603729777%2F150cebebb11f471995d81c043686e357',
      required: false,
    },
    {
      name: 'posterImageVisibility',
      friendlyName: 'Hide Poster Image',
      type: 'boolean',
      required: false,
    },
    {
      name: 'posterImageAltText',
      friendlyName: 'Poster Image Alt Text',
      type: 'text',
      required: false,
    },
  ],
}
)

Builder.registerComponent(SearchspringWrap, {
  name: 'Searchspring',
  friendlyName: 'Search & Grid',
  inputs: [
    {
      name: 'tileBanner',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileBurst',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileSpecs',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileTagline',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileRatings',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tilePromo',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileATC',
      type: 'boolean',
      defaultValue: true,
    },
  ]
}
)

Builder.registerComponent(Sidebar, {
    name: 'Sidebar',
    friendlyName: 'Searchspring Sidebar'
  }
)

Builder.registerComponent(ProductCount, {
    name: 'Products Count',
    friendlyName: 'Searchspring Count'
  }
)

Builder.registerComponent(ProductGridWrap, {
    name: 'ProductGrid',
    friendlyName: 'Searchspring Grid',
    inputs: [
      {
        name: 'tileBanner',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileBurst',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileSpecs',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileTagline',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileRatings',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tilePromo',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileATC',
        type: 'boolean',
        defaultValue: true,
      },
    ]
  }
)

Builder.registerComponent(Autocomplete, {
    name: 'Autocomplete',
  }
)

Builder.registerComponent(Recommendations, {
    name: 'Recommendations',
    inputs: [
      {
        name: 'profile',
        type: 'string',
      },
      {
        name: 'tileBanner',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileBurst',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileSpecs',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileTagline',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileRatings',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tilePromo',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'tileATC',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'desktopSettings',
        type: 'object',
        defaultValue: {
          count: 4,
          per: 4,
        },
        subFields: [
          {
            name: 'count',
            friendlyName: 'Slides to Show',
            type: 'number',
            defaultValue: 4
          },
          {
            name: 'per',
            friendlyName: 'Slides to Scroll',
            type: 'number',
            defaultValue: 4
          },
        ]
      },
      {
        name: 'tabletSettings',
        type: 'object',
        defaultValue: {
          count: 2,
          per: 2,
        },
        subFields: [
          {
            name: 'count',
            friendlyName: 'Slides to Show',
            type: 'number',
            defaultValue: 4
          },
          {
            name: 'per',
            friendlyName: 'Slides to Scroll',
            type: 'number',
            defaultValue: 4
          },
        ]
      },
      {
        name: 'mobileSettings',
        type: 'object',
        defaultValue: {
          count: 1,
          per: 1,
        },
        subFields: [
          {
            name: 'count',
            friendlyName: 'Slides to Show',
            type: 'number',
            defaultValue: 4
          },
          {
            name: 'per',
            friendlyName: 'Slides to Scroll',
            type: 'number',
            defaultValue: 4
          },
        ]
      },
      {
        name: 'navTheme',
        friendlyName: 'Nav Theme',
        type: 'text',
        enum: [
          'light',
          'dark'
        ],
        defaultValue: 'dark'
      },
      {
        name: 'showNav',
        friendlyName: 'Show Navigation',
        type: 'boolean',
        defaultValue: true
      },
      {
        name: 'dtSwipe',
        friendlyName: 'Desktop Swipe',
        type: 'boolean',
        defaultValue: false
      },
      {
        name: 'autoPlay',
        friendlyName: 'AutoPlay',
        type: 'boolean',
        defaultValue: false
      },
      {
        name: 'speed',
        friendlyName: 'Speed (ms)',
        type: 'number',
        defaultValue: 500
      },
      {
        name: 'initialSlide',
        friendlyName: 'Index of first slide',
        type: 'number',
        defaultValue: 1
      },
    ]
  }
)

Builder.registerComponent(MegaMenuRecsWrap, {
  name: 'Megamenu Recs',
  inputs: [
    {
      name: 'profile',
      type: 'string',
    },
    {
      name: 'maxTiles',
      type: 'number',
      defaultValue: 4,
    },
    {
      name: 'tileBanner',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileBurst',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileSpecs',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'tileTagline',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'tileRatings',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tilePromo',
      type: 'boolean',
      defaultValue: false,
    },
  ]
}
)

Builder.registerComponent(ContentRecsWrap, {
  name: 'Content Recs',
  friendlyName: 'Searchspring Content Recs',
  inputs: [
    {
      name: 'profile',
      type: 'string',
    },
    {
      name: 'maxTiles',
      type: 'number',
      defaultValue: 3,
    },
    {
      name: 'tileType',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileDate',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileDescription',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileTopics',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'desktopSettings',
      type: 'object',
      defaultValue: {
        count: 4,
        per: 4,
      },
      subFields: [
        {
          name: 'count',
          friendlyName: 'Slides to Show',
          type: 'number',
          defaultValue: 4
        },
        {
          name: 'per',
          friendlyName: 'Slides to Scroll',
          type: 'number',
          defaultValue: 4
        },
      ]
    },
    {
      name: 'tabletSettings',
      type: 'object',
      defaultValue: {
        count: 2,
        per: 2,
      },
      subFields: [
        {
          name: 'count',
          friendlyName: 'Slides to Show',
          type: 'number',
          defaultValue: 4
        },
        {
          name: 'per',
          friendlyName: 'Slides to Scroll',
          type: 'number',
          defaultValue: 4
        },
      ]
    },
    {
      name: 'mobileSettings',
      type: 'object',
      defaultValue: {
        count: 1,
        per: 1,
      },
      subFields: [
        {
          name: 'count',
          friendlyName: 'Slides to Show',
          type: 'number',
          defaultValue: 4
        },
        {
          name: 'per',
          friendlyName: 'Slides to Scroll',
          type: 'number',
          defaultValue: 4
        },
      ]
    },
    {
      name: 'navTheme',
      friendlyName: 'Nav Theme',
      type: 'text',
      enum: [
        'light',
        'dark'
      ],
      defaultValue: 'dark'
    },
    {
      name: 'showNav',
      friendlyName: 'Show Navigation',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'dtSwipe',
      friendlyName: 'Desktop Swipe',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'autoPlay',
      friendlyName: 'AutoPlay',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'speed',
      friendlyName: 'Speed (ms)',
      type: 'number',
      defaultValue: 500
    },
    {
      name: 'initialSlide',
      friendlyName: 'Index of first slide',
      type: 'number',
      defaultValue: 1
    },
  ]
}
)

Builder.registerComponent(ContentRecsStackedWrap, {
  name: 'Content Recs Stacked',
  inputs: [
    {
      name: 'profile',
      type: 'string',
    },
    {
      name: 'tileType',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileDate',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileDescription',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'tileTopics',
      type: 'boolean',
      defaultValue: true,
    }
  ]
}
)

Builder.registerComponent(SortBy, {
  name: 'SortBy',
  friendlyName: 'Searchspring SortBy'
})

// ------------------
// General Components
// ------------------
Builder.registerComponent(withTimerContext(CQLSection), cqlSectionOptions);
Builder.registerComponent(withTimer(CQLText), cqlTextOptions);
Builder.registerComponent(withTimer(CQLText), builderTextOptions);

Builder.registerComponent(BackToTop, {
    name: 'Back to Top',
    noWrap: true,
    models: ['sticky-utility-menu'],
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/inpicture.svg',
    description: 'Global button for users to return to the top of the page',
    inputs: [
      {
        name: 'buttonPosition',
        friendlyName: 'Desktop Position',
        type: 'string',
        enum: [
          'Right',
          'Left'
        ],
        defaultValue: 'Right',
      },
    ],
});

Builder.registerComponent(SocialProof, {
  name: 'Social Proof Popup',
  noWrap: true,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/inpicture.svg',
  description: 'Global component used to show previous customer\'s purchases',
  inputs: [
    {
      name: 'offerId',
      friendlyName: 'Offer ID of product you want displayed',
      type: 'number',
    },
  ],
});

Builder.registerComponent(Chat, {
  name: 'SnapEngage Chat',
  noWrap: true,
  models: ['sticky-utility-menu'],
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/inpicture.svg',
  description: 'Global button BC chat',
  inputs: [
    {
      name: 'buttonPosition',
      friendlyName: 'Desktop Position',
      type: 'string',
      enum: [
        'Right',
        'Left'
      ],
      defaultValue: 'Right',
    },
  ],
});

Builder.registerComponent(Carousel, carouselOptions);

Builder.registerComponent(Tabs, {
  name: 'Tabs',
  noWrap: true,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/tab.svg',
  inputs: [
    {
      name: 'tabs',
      type: 'list',
      subFields: [
        {
          name: 'label',
          type: 'text',
          defaultValue: 'New tab',
        },
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [],
        },
      ],
      defaultValue: [
        {
          label: 'Tab 1',
          content: [],
        },
      ],
    },
    {
      name: 'tabsStyle',
      type: 'string',
      enum: ['Pill','Block'],
      defaultValue: 'Pill',
    },
  ],
});
Builder.registerComponent(Accordion, {
  name: 'Accordion',
  noWrap: true,
  image: 'https://cdn.builder.io/api/v1/image/assets%2FagZ9n5CUKRfbL9t6CaJOyVSK4Es2%2Ffab6c1fd3fe542408cbdec078bca7f35',
  override: true,
  inputs: [
    {
      name: 'folds',
      type: 'list',
      subFields: [
        {
          name: 'label',
          type: 'text',
          defaultValue: 'New fold',
        },
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [],
        },
      ],
      defaultValue: [
        {
          label: 'Fold 1',
          content: [],
        },
      ],
    },
    {
      name: 'accordionStyle',
      type: 'string',
      enum: ['Plus','Hybrid'],
      defaultValue: 'Plus',
    },
  ],
});


// ------------------
// Header Components
// ------------------
Builder.registerComponent(MegaNav,
  {
    name: 'Mega Nav',
    noWrap: true,
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/menu-boxed.svg',
    description: 'Mega Menu Navigation Container',
    inputs: [
      {
        name: 'nav',
        type: 'list',
        subFields: [
          {
            name: 'label',
            type: 'text',
            defaultValue: 'New Nav Item',
          },
          {
            name: 'link',
            type: 'text',
            defaultValue: '',
          },
          {
            name: 'textColorOverride',
            type: 'color',
            defaultValue: '',
          },
          {
            name: 'contentBackgroundColor',
            type: 'color',
            defaultValue: '#FFF',
          },
          {
            name: 'content',
            type: 'uiBlocks',
            defaultValue: [],
          },
        ],
        defaultValue: [
          {
            label: 'Nav 1',
            content: [],
          },
        ],
      },
      {
        name: 'sensitivity',
        type: 'number',
        defaultValue: 6,
        advanced: true,
        helperText: 'If the mouse travels fewer than this number of pixels between polling intervals, then the onMouseOver callback will be called. With the minimum sensitivity threshold of 1, the mouse must not move between polling intervals. With higher sensitivity thresholds you are more likely to receive a false positive.',
      },
      {
        name: 'interval',
        type: 'number',
        defaultValue: 100,
        advanced: true,
        helperText: 'The number of milliseconds hoverIntent waits between reading/comparing mouse coordinates. When the user`s mouse first enters the element its coordinates are recorded. The soonest the onMouseOut callback can be called is after a single polling interval. Setting the polling interval higher will increase the delay before the first possible onMouseOver call, but also increases the time to the next point of comparison.',
      },
      {
        name: 'timeout',
        type: 'number',
        defaultValue: 0,
        advanced: true,
        helperText: 'A simple delay, in milliseconds, before the onMouseOut callback is fired. If the user mouses back over the element before the timeout has expired the onMouseOut callback will not be called (nor will the onMouseOver callback be called). This is primarily to protect against sloppy/human mousing trajectories that temporarily (and unintentionally) take the user off of the target element... giving them time to return.',
      }
    ],
  }
);
Builder.registerComponent(MobileNav,
    {
      name: 'Mobile Nav',
      noWrap: true,
      image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/menu.svg',
      description: 'Mobile Menu Navigation Container',
      inputs: [
          {
            name: 'homeContent',
            type: 'uiBlocks',
            defaultValue: [],
          },
          {
            name: 'nav',
            type: 'list',
            subFields: [
              {
                name: 'label',
                type: 'text',
                defaultValue: 'New Nav Item',
              },
              {
                name: 'textColorOverride',
                type: 'color',
                defaultValue: '',
              },
              {
                name: 'contentBackgroundColor',
                type: 'color',
                defaultValue: '#FFF',
              },
              {
                name: 'content',
                type: 'uiBlocks',
                defaultValue: [],
              },
            ],
            defaultValue: [
              {
                label: 'Nav 1',
                content: [],
              },
            ],
          },
        ],
    }
  );
Builder.registerComponent(HeaderCart,
  {
    name: 'Cart Icon',
    noWrap: true,
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/shopping-cart.svg',
    description: 'Header Cart Icon'
  }
);
Builder.registerComponent(HeaderAccount,
  {
    name: 'Account Icon',
    noWrap: true,
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/user.svg',
    description: 'Header User Icon',
    inputs: [
        {
          name: 'showLabel',
          type: 'boolean',
          defaultValue: false,
        }
    ]
  }
);

Builder.registerComponent(HeaderWishlist,
  {
    name: 'Wishlist Icon',
    noWrap: true,
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/heart.svg',
    description: 'Header Heart Icon',
    inputs: [
        {
          name: 'showLabel',
          type: 'boolean',
          defaultValue: false,
        }
    ]
  }
);

Builder.register('insertMenu', {
    name: 'Header Components',
    items: [
      { name: 'Mega Nav' },
      { name: 'Mobile Nav' },
      { name: 'Cart Icon' },
      { name: 'Account Icon' },
      { name: 'Wishlist Icon' },
      { name: 'Autocomplete' },
      { name: 'Megamenu Recs'}
    ],
    priority: 1,
 });

// ------------------
// Product Components
// ------------------
Builder.register('insertMenu', {
  name: 'PDP Components',
  items: [
    { name: 'PDP Add to Cart' },
    { name: 'Banner' },
    { name: 'PDP Breadcrumbs'},
    { name: 'Description' },
    { name: 'Inventory' },
    { name: 'Media Carousel' },
    { name: 'Options' },
    { name: 'Price' },
    { name: 'Ratings' },
    { name: 'Reviews' },
    { name: 'Shipping Delivery' },
    { name: 'Shipping Restriction' },
    { name: 'SKU' },
    { name: 'Specs' },
    { name: 'Title' },
    { name: 'Wishlist' },
    { name: 'Mulberry' },
  ],
  priority: 2,
});

Builder.register('insertMenu', {
  name: 'PLP Components',
  items: [
    { name: 'PLP Breadcrumbs' },
    { name: 'Searchspring' },
    { name: 'Sidebar' },
    { name: 'Products Count' },
    { name: 'ProductGrid' },
    { name: 'SortBy' }
  ],
  priority: 3,
});

Builder.registerComponent(PLPBreadcrumbs, {
  name: 'PLP Breadcrumbs',
});

Builder.registerComponent(AddWishlist, {
  models: ['product-detail-page'],
  name: 'Wishlist',
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/heart.svg',
  description: 'Add to Wishlist for Product Detail Page',
  defaults: {
    bindings: {
      'component.options.product': 'state.product'
    }
  }
});

Builder.registerComponent(Breadcrumbs, {
  models: ['product-detail-page'],
  name: 'PDP Breadcrumbs',
  defaults: {
    bindings: {
      'component.options.foundCat': 'state.foundCat',
      'component.options.notFoundCat': 'state.notFoundCat'
    }
  }
});

Builder.registerComponent(ProductBanner,
  {
    models: ['product-detail-page'],
    name: 'Banner',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/awards.svg',
    description:
      'Product banner for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(Mulberry,
  {
    models: ['product-detail-page'],
    name: 'Mulberry',
    noWrap: true,
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/awards.svg',
    description:
      'Mulberry for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(BuyBox,
  {
    models: ['product-detail-page'],
    name: 'PDP Add to Cart',
    image: 'https://cdn.builder.io/api/v1/image/assets/IsxPKMo2gPRRKeakUztj1D6uqed2/81a15681c3e74df09677dfc57a615b13',
    description: 'Component with CTA & quantity selector for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    },
    inputs: [
      {
        name: 'showQuantitySelector',
        friendlyName: 'Show Quantity Selector',
        type: 'boolean',
        defaultValue: true
      },
      {
        name: 'showStickyBar',
        friendlyName: 'Show Sticky Bar',
        type: 'boolean',
        defaultValue: true
      }
    ]
  }
);

Builder.registerComponent(ProductDescription,
  {
    models: ['product-detail-page'],
    name: 'Description',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description: 'Product description for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(MediaCarousel,
  {
    models: ['product-detail-page'],
    name: 'Media Carousel',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/image.svg',
    description: 'Product media carousel for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(ProductInventory,
  {
    models: ['product-detail-page'],
    name: 'Inventory',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description:
      'Product inventory for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(OptionSelector,
  {
    models: ['product-detail-page'],
    name: 'Options',
    noWrap: true,
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/options.svg',
    description: 'Option selector for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(ProductPrice,
  {
    models: ['product-detail-page'],
    name: 'Price',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/dollar.svg',
    description: 'Price for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(ShippingRestriction,
  {
    models: ['product-detail-page'],
    name: 'Shipping Restriction',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description: 'Product shipping restriction for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(ShippingDelivery,
  {
    models: ['product-detail-page'],
    name: 'Shipping Delivery',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description: 'Product shipping delivery for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(ProductSKU,
  {
    models: ['product-detail-page'],
    name: 'SKU',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description: 'Product SKU for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(ProductSpecs,
  {
    models: ['product-detail-page'],
    name: 'Specs',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description: 'Product specs for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(ProductTitle,
  {
    models: ['product-detail-page'],
    name: 'Title',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description: 'Product title for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);


Builder.registerComponent(dynamic(() => import('./components/product/ratings').then((m) => m.ProductRatings), {
  ssr: false
}),
  {
    models: ['product-detail-page'],
    name: 'Ratings',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/smile.svg',
    description: 'Ratings for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

Builder.registerComponent(dynamic(() => import('./components/product/reviews').then((m) => m.ProductReviews), {
  ssr: false
}),
  {
    models: ['product-detail-page'],
    name: 'Reviews',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/comment.svg',
    description: 'Reviews for Product Detail Page',
    defaults: {
      bindings: {
        'component.options.product': 'state.product'
      }
    }
  }
);

// -------------------------
// Global Product Components
// -------------------------
Builder.registerComponent(AddToCartCTA,
  {
    name: 'addToCartCTA',
    friendlyName: 'General Add to Cart',
    image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/format-text.svg',
    description: 'Add to Cart CTA for global use in Product Frame Symbol',
    inputs: [
      {
        name: 'setQuantity',
        friendlyName: 'Quantity',
        type: 'text',
        defaultValue: 1
      },
      {
        name: 'variantSku',
        friendlyName: 'Variant SKU',
        type: 'text',
        defaultValue: ''
      },
      {
        name: 'buttonColor',
        friendlyName: 'Button Color',
        type: 'string',
        enum: [
          'Primary',
          'Primary Inverted',
          'Secondary',
          'Secondary Inverted'
        ],
        defaultValue: 'Primary'
      },
      {
        name: 'buttonText',
        friendlyName: 'Button Text',
        type: 'text',
        defaultValue: 'Add to Cart'
      },
      {
        name: 'buttonFontSize',
        friendlyName: 'Button Text Size',
        type: 'number'
      }
    ]
  }
);

// ------------------
// Cart Components
// ------------------
Builder.register('insertMenu', {
  name: 'Cart Components',
  items: [
    { name: 'Shipping Banner' }
  ],
  priority: 4,
});

Builder.registerComponent(ShippingBanner,
  {
    name: 'Shipping Banner',
    image: 'https://unpkg.com/browse/css.gg@2.0.0/icons/svg/math-plus.svg',
    description: 'Shipping banner for Cart'
  }
);
