import { VercelProduct as Product } from 'lib/bigcommerce/types';
import './shipping-delivery.css';

export function ShippingDelivery({ product }: { product: Product }) {
  return (
    <div>
      {product.productMetafields.map((field: any, i) => (
        field.key === 'shipping-delivery-information' 
          ? <div className={`shipping-delivery-text`} dangerouslySetInnerHTML={{ __html: field.value }} key={i} /> 
          : null
      ))}
    </div>
  );
}
