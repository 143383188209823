'use client';

import { BuilderBlocks } from '@builder.io/react';
import { BuilderBlock } from '@builder.io/react/dist/types/src/components/builder-block.component';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ReactNode, useEffect, useState } from 'react';

import { withSuspense } from 'components/with-suspense';
import './tabs.css';

export interface BuilderSectionProps {
  content?: ReactNode;
  model?: string;
  data?: any;
  attributes: {
    style: any,
    className: string, 
    'builder-id': string,
    id: string
  }
}

function TabsComponent(props: {
  builderBlock: BuilderBlock, 
  tabs: Array<BuilderSectionProps>, 
  tabsStyle: string,
  attributes: {
    style: any,
    className: string, 
    'builder-id': string,
    id: string
  },
  contentType?: string,
  productCount?: number,
  articleCount?: number
}) {
  const [activeTab, setActiveTab] = useState(0);
  const tabsStyle = props.tabsStyle.toLowerCase();
  const tabsLength = props.tabs.length;
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const [productsCount, setProductsCount] = useState<number>(props.productCount ?? 0);
  const [articlesCount, setArticlesCount] = useState<number>(props.articleCount ?? 0);
  const view = searchParams?.get('view');
  
  useEffect(() => {
    if (pathname === '/search') {
      const handleLoadCount = (event: CustomEvent<{ productCount: number, articleCount: number }>) => {
        const productCount = event.detail.productCount;
        const articleCount = event.detail.articleCount;
        setProductsCount(productCount);
        setArticlesCount(articleCount);
    
        if (productCount === 0 && articleCount > 0) {
          setActiveTab(1);
          updateParams('articles');
        } else if (articleCount === 0 && productCount > 0) {
          setActiveTab(0);
          updateParams('products');
        }
      };
    
      const handleResultsUpdate = (event: CustomEvent<{ newCount: number, contentType: string }>) => {
        const newCount = event.detail.newCount;
        const contentType = event.detail.contentType;
        if (contentType === 'Products') {
          setProductsCount(newCount);
          if (newCount === 0 && articlesCount > 0) {
            setActiveTab(1);
            updateParams('articles');
          }
        } else if (contentType === 'Articles') {
          setArticlesCount(newCount);
          if (newCount === 0 && productsCount > 0) {
            setActiveTab(0);
            updateParams('products');
          }
        }
      };
    
      const updateParams = (view: string) => {
        const newParams = new URLSearchParams(searchParams?.toString());
        newParams.set('view', view);
        router.push(`${pathname}?${newParams.toString()}`);
      };
    
      window.addEventListener('resultsCountUpdated', handleResultsUpdate as any);
      window.addEventListener('onLoadCount', handleLoadCount as any);
    
      if (view === 'articles') {
        setActiveTab(1);
        updateParams('articles');
      }
    
      return () => {
        window.removeEventListener('resultsCountUpdated', handleResultsUpdate as any);
        window.removeEventListener('onLoadCount', handleLoadCount as any);
      };
    }
  }, [articlesCount, productsCount, searchParams, pathname, router, view]);
  
  return (
    <div style={props.attributes.style} className={props.attributes.className} builder-id={props.attributes['builder-id']} id={props.attributes.id}>
      <div className={`builder-tabs builder-tabs-${tabsStyle}`}>
        <div className={`builder-tabs-nav builder-tabs-nav-${tabsLength}${tabsLength > 4 ? ' builder-tabs-nav-extended' : ''}`}>
          {props.tabs?.map((item: any, index: number) => {
            const isDisabled = pathname === '/search' && ((index === 0 && productsCount === 0) || (index === 1 && articlesCount === 0));
            return (
              <button
                key={index}
                className={`builder-tab${activeTab === index ? ' builder-tab-active' : ''} ${isDisabled ? 'builder-tab-disabled': ''}`}
                onClick={() => {
                  if (!isDisabled) {
                    setActiveTab(index);
                    if (pathname === '/search') {
                      const newParams = new URLSearchParams(searchParams?.toString());
                      const keysToDelete = [];
                      for (const key of newParams.keys()) {
                        if (key.startsWith('filter.')) {
                          keysToDelete.push(key);
                        }
                      }
                      keysToDelete.forEach((key) => newParams.delete(key));
                      newParams.set('view', index === 0 ? 'products' : 'articles');
                      router.push(`${pathname}?${newParams.toString()}`);
                    } else {
                      setActiveTab(index);
                    }
                  }
                }}
                disabled={isDisabled}
              >
                <span>
                  {item.label} {pathname === '/search' && (index === 0 ? `(${productsCount})` : `(${articlesCount})`)}
                </span>
              </button>
            );
          })}
        </div>
        <div className="builder-tabs-content">
          {props.tabs?.length && (
            <BuilderBlocks
              parentElementId={props.builderBlock.id}
              dataPath={`component.options.tabs.${activeTab}.content`}
              blocks={props?.tabs[activeTab]?.content}
            />
          )}
        </div>
      </div>
    </div>
  );  
}

const TabsSkeleton = (
  <div>
    <div className='builder-tabs builder-tabs-pill' >
      <div className='builder-tabs-nav builder-tabs-nav-4'>
        <div className='builder-tab animate-pulse h-10' ></div>
        <div className='builder-tab animate-pulse h-10' ></div>
        <div className='builder-tab animate-pulse h-10' ></div>
        <div className='builder-tab animate-pulse h-10' ></div>
      </div>
      <div className="builder-tabs-content animate-pulse h-96 bg-neutral-400 dark:bg-neutral-700">
        <div></div>
      </div>
    </div>
  </div>
);

export const Tabs = withSuspense(TabsComponent, TabsSkeleton);
