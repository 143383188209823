'use client';

import { builder } from '@builder.io/react';
import { withSuspense } from 'components/with-suspense';
import Link from 'next/link';
import { RecommendationsCarousel, RecommendationsSkeleton } from 'propel-react-components';
import { AddToCartComponentInterface, LinkComponentInterface } from 'propel-shared-utility';
import { AddToCartCTA } from '../add-to-cart-cta';
import getRecommendationsPropsStrategy from './recsBuilderStrategy';

const AddToCartComponent: AddToCartComponentInterface = (props) => {
    return (
        <AddToCartCTA {...props} />
    );
};

const LinkComponent: LinkComponentInterface = ({ href, className, children, onClick }) => {
    return (
        <Link href={href ?? '#'} className={className} onClick={onClick}>
            {children}
        </Link>
    );
};

const RecommendationsComponent = getRecommendationsPropsStrategy(RecommendationsCarousel, builder.editingMode, AddToCartComponent, LinkComponent)


export const Recommendations = withSuspense(RecommendationsComponent, RecommendationsSkeleton);


