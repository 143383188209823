'use client';

import { BuilderElement, BuilderStore } from '@builder.io/react';
import * as blotout from 'components/analytics/blotout';
import * as gtm from 'components/analytics/gtm';
import { addItem } from 'components/cart/actions';
import useCart from 'components/hooks/use-cart';
import LoadingDots from 'components/loading-dots';
import { useStorefrontDispatch } from 'components/providers/storefront-provider';
import { Product, ProductVariant } from 'lib/bigcommerce/types';
import { CSSProperties, FormEvent, useEffect, useState, useTransition } from 'react';
import Modal from './modal';

export function SubmitButton({
  availableForSale,
  isInStock,
  selectedQuantity,
  selectedVariantId,
  buttonColor,
  buttonText,
  buttonFontSize,
  notifyMe,
  pending,
  productHandle
}: {
  availableForSale: boolean;
  isInStock: boolean;
  selectedQuantity?: number;
  selectedVariantId: string | undefined;
  buttonColor: string;
  buttonText: string;
  buttonFontSize?: number;
  notifyMe: boolean;
  pending: boolean;
  productHandle: string;
}) {
  let buttonStyle = {};
  if (buttonFontSize && buttonFontSize > 0) {
    buttonStyle = {
      'fontSize': `${buttonFontSize}px`
    }
  }

  if (!selectedVariantId) {
    return (
      <a
        aria-label="Please select an option"
        aria-disabled
        className={getClassName(buttonColor)}
        href={productHandle}
        style={buttonStyle}
      >
        View Options
      </a>
    );
  }

  const isAvailable = availableForSale && isInStock;
  const showNotifyMe = availableForSale && notifyMe === true && !isInStock;
  const showOutOfStock = !availableForSale || (notifyMe === false && !isInStock)

  return (
    <>
      {showNotifyMe && <NotifyMeModal buttonFontSize={buttonFontSize} />}
      {showOutOfStock && <OutOfStockButton buttonStyle={buttonStyle} />}
      {isAvailable && <AddToCartButton
        buttonColor={buttonColor}
        buttonStyle={buttonStyle}
        buttonText={buttonText}
        pending={pending}
      />}
    </>
  );
}

function AddToCartButton({
  buttonColor,
  buttonStyle,
  buttonText = "Add to cart",
  pending
}: {
  buttonColor: string;
  buttonStyle: CSSProperties | undefined;
  buttonText?: string;
  pending: boolean;
}) {
  return (
    <button
      aria-label="Add to cart"
      className={getClassName(buttonColor)}
      style={buttonStyle}
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
    >
      {buttonText}
      {pending && <LoadingDots className="loading-dots-dark" />}
    </button>
  );
}

function getClassName(buttonColor: string) {
  if (buttonColor === 'Primary')
    return 'cta-button cta-button-atc';
  if (buttonColor === 'Primary Inverted')
    return 'cta-button cta-button-inverted'
  if (buttonColor === 'Secondary')
    return 'cta-button cta-button-secondary'
  if (buttonColor === 'Secondary Inverted')
    return 'cta-button cta-button-secondary-inverted'
  if (buttonColor === 'ATC Tile')
    return 'tile__atc'
}

function OutOfStockButton({
  buttonStyle,
}: {
  buttonStyle: CSSProperties | undefined;
}) {
  return (
    <button
      aria-disabled
      className="cta-button cta-button-atc"
      disabled
      style={buttonStyle}
    >
      Out Of Stock
    </button>
  );
}

function NotifyMeModal({
  buttonFontSize
}: {
  buttonFontSize: number | undefined;
}) {
  return (
    <Modal
      buttonClasses="cta-button cta-button-atc"
      buttonFontSize={buttonFontSize}
      buttonText="Notify Me"
      preventDefault={true}
    >
      Placement for Klaviyo content
    </Modal>
  );
}

export function AddToCartCTA({
  builderState,
  apiProduct,
  setQuantity,
  variantSku,
  buttonColor,
  buttonText,
  buttonFontSize
}: {
  builderState?: BuilderStore;
  builderBlock?: BuilderElement;
  apiProduct: Product;
  setQuantity: number;
  variantSku: string;
  buttonColor: string;
  buttonText: string;
  buttonFontSize: number | undefined;
}) {
  const { mutate } = useCart();
  const [message, setMessage] = useState<undefined | string>(undefined);
  const [isPending, startTransition] = useTransition();
  const dispatch = useStorefrontDispatch();

  if (!apiProduct && builderState?.state?.apiProduct) {
    apiProduct = builderState.state.apiProduct;
  }

  useEffect(() => {
    if(apiProduct){
      gtm.viewItemList({
        product: apiProduct,
        itemListId: 'related_products',
        itemListName: 'Related Products'
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!apiProduct) {
    return <></>;
  }
  const defaultVariantId = apiProduct.variants.length === 1 ? apiProduct.variants[0]?.id : undefined;
  const defaultProductId = apiProduct.variants.length === 1 ? apiProduct.variants[0]?.parentId : undefined;
  const variant = apiProduct.variants.find((variant: ProductVariant) =>
    variant.sku === variantSku
  );
  const selectedVariantId = variant?.id || defaultVariantId;
  const selectedProductId = variant?.parentId || defaultProductId;
  const selectedQuantity = setQuantity;
  const inStock = variant?.inventory.isInStock || false;

  // Set product-level notifyMe boolean
  let notifyMe = false;
  apiProduct && apiProduct.productMetafields.forEach((field: any) => {
    field.key === 'notify-me' && field.value === 'true' ? notifyMe = true : null;
    return;
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    startTransition(async () => {
      setMessage(undefined);
      const result = await addItem(null, { selectedProductId, selectedVariantId, selectedQuantity });
      if (result.isSuccess) {
        setMessage('Success')
        if (result.value) {
          const item = result.value.lines.find(l => l.merchandise.id === selectedVariantId)
          if (item) {
            gtm.addToCart({
              cart: result.value,
              lineItem: item,
              quantity: selectedQuantity,
              itemListId: 'related_products',
              itemListName: 'Related Products'
            });
            blotout.addToCart({
              cart: result.value,
              lineItem: item,
              quantity: selectedQuantity
            });
          }
        }
        mutate(result);
        dispatch({ type: 'OPEN_CART_CONFIRM', currentVariant: selectedVariantId });
      }
      else {
        console.log(result.error)
        setMessage(result.error)
      }
    });
  };

  return (
    <div className="add-to-cart-box">
      <form onSubmit={onSubmit}>
        <SubmitButton
          availableForSale={true}
          isInStock={inStock}
          selectedVariantId={selectedVariantId}
          buttonColor={buttonColor}
          buttonText={buttonText}
          buttonFontSize={buttonFontSize}
          notifyMe={notifyMe}
          pending={isPending}
          productHandle={apiProduct.handle}
        />
        <p aria-live="polite" className="sr-only" role="status">
          {message}
        </p>
      </form>
    </div>
  );
}
