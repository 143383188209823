'use client';

import { builder } from '@builder.io/react';
import { withSuspense } from 'components/with-suspense';
import Link from 'next/link';
import { ContentRecsCarosuel } from 'propel-react-components';
import { LinkComponentInterface } from 'propel-shared-utility';
import getContentRecsStrategy from './contentRecsStrategy';

const LinkComponent: LinkComponentInterface = ({ href, className, children }) => {
    return (
        <Link href={href ?? '#'} className={className}>
            {children}
        </Link>
    );
};

const ContentRecsSkeleton = (
    <div className="megamenu__recs">
        <div className="megamenu__recs-tile megamenu__recs-tile--4 animate-pulse"></div>
        <div className="megamenu__recs-tile megamenu__recs-tile--4 animate-pulse"></div>
        <div className="megamenu__recs-tile megamenu__recs-tile--4 animate-pulse"></div>
    </div>
);

export const ContentRecsWrap = withSuspense(getContentRecsStrategy(ContentRecsCarosuel, builder.editingMode, LinkComponent), ContentRecsSkeleton)
