import {
  BeaconEventDataLayer,
  BrowserSearchspringClient,
  SearchResponseModel,
  createSearchspringAutocompleteAdapter,
} from 'int-searchspring-api';
import {type SuggestResponseModel} from 'int-searchspring-api/src/generated/search/models/SuggestResponseModel';
import {
  type BeaconConfig,
  type SearchspringAPIContract,
  type SearchspringState,
} from 'int-searchspring-api/src/types';
import {
  ContentRecommendation,
  type AutocompleteDataFetcher,
  type Recommendation,
} from 'propel-shared-utility';
import {useCallback, useEffect, useRef, useState} from 'react';

interface TrendingItem {
  searchQuery: string;
  popularity: number;
}

export const useSearchspringClient = (state: SearchspringState) => {
  const searchspringClient = useRef<BrowserSearchspringClient>(
    new BrowserSearchspringClient(state),
  );

  useEffect(() => {
    searchspringClient.current?.updateState(state);
  }, [state, searchspringClient]);

  return searchspringClient.current;
};

export const useBeaconEventDataLayer = (
  beaconConfig: BeaconConfig,
  apiWrapper: Pick<SearchspringAPIContract, 'beaconPOST'>,
  recs: Recommendation[] | ContentRecommendation[],
) => {
  const beaconDataLayer = useRef<BeaconEventDataLayer>(
    BeaconEventDataLayer.create(beaconConfig, apiWrapper),
  ).current;

  useEffect(() => {
    if (!beaconDataLayer.initialized) {
      beaconDataLayer.initialize();
    }
    beaconDataLayer.updateOptions(beaconConfig, apiWrapper);

    return () => {
      beaconDataLayer.cleanUp();
    };
  }, [apiWrapper, beaconConfig, beaconDataLayer]);

  useEffect(() => {
    if (recs) {
      beaconDataLayer.createRenderEvent(recs);
    }
  }, [beaconDataLayer, recs]);

  return {
    setCarouselDataProps: beaconDataLayer.setCarouselDataProps,
    setRecommendationDataProps: beaconDataLayer.setRecommendationDataProps,
    setContentRecommendationDataProps: beaconDataLayer.setContentRecommendationDataProps
  };
};

export const useSearchspringSuggestionsFetcher = (
  searchspringClient?: BrowserSearchspringClient,
): AutocompleteDataFetcher<{
  suggestions: SuggestResponseModel | undefined;
  autocomplete: SearchResponseModel | undefined;
  trending: TrendingItem[] | undefined
}> => {
  const [data, setData] = useState<{
    suggestions: SuggestResponseModel | undefined;
    autocomplete: SearchResponseModel | undefined;
    trending: TrendingItem[] | undefined;
  }>({suggestions: undefined, autocomplete: undefined, trending: undefined});

  const load = useCallback(
    async (q: string) => {
      if (searchspringClient) {
        const suggestionsRequest = await searchspringClient.suggestionsGET(q);
        const autocompleteRequest = await searchspringClient.autocompleteGET(q);
        const trendingRequest = await searchspringClient.trendingGET();
        
        const suggestions = suggestionsRequest.body;
        const autocomplete = autocompleteRequest.body;
        const trending = trendingRequest.body;
      
        setData({suggestions, autocomplete, trending});
      }
    },
    [searchspringClient],
  );

  return {load, data};
};

export const useSearchspringAutocompleteAdapter = () =>
  useRef(
    createSearchspringAutocompleteAdapter([
      'trending',
      'suggestions',
      'alternatives',
      'products',
      'didYouMean',
    ]),
  );
