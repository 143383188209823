import { v4 as uuid4 } from 'uuid';
import { type OpenAPIConfig } from '../generated/search';
import {
  type CartLines,
  type SearchspringCookies,
  type SearchspringState,
} from '../types';
import { type Translator } from './QueryStringTranslator';

export const updateSearchStateQueryParameters = (
  stateIn: SearchspringState,
  translator: Translator,
  url: string,
): SearchspringState => {
  const translated = translator.deserialize(url);
  const stateOut = stateIn;
  stateOut.q = translated.q;
  stateOut.filter = translated.filter;
  stateOut.bgfilter = stateIn.bgfilter;
  stateOut.sort = translated.sort;
  stateOut.page = translated.page;
  stateOut.landingPage = translated.landingPage;
  stateOut.tag = translated.tag;
    return stateOut;
};

export const getQueryFromUrl = (url: string) => {
  return new URL(url).searchParams.get('q');
};

//logic code
export const getStandardCookieValues = (
  userId: string | undefined,
  sessionId: string | undefined,
  isuid: string | undefined,
) => {
  let updatedUserId = userId;
  let updatedIsuid = isuid;
  if (!userId || !isuid) {
    const shared = uuid4();
    updatedUserId = shared;
    updatedIsuid = shared;
  }
  return {
    sessionId: sessionId || uuid4(),
    userId: updatedUserId as string,
    isuid: updatedIsuid as string,
  };
};

// logic code 
export const getNewPageLoadId = (
  currentPath: string,
  currentPageLoadIdCookieValue?: {
    path: string;
    identifier: string;
  },
) => {
  let pageLoadId = currentPageLoadIdCookieValue;
  if (pageLoadId?.path && pageLoadId?.identifier) {
    if (currentPath !== pageLoadId.path) {
      pageLoadId = {
        path: currentPath,
        identifier: uuid4(),
      };
    }
  } else {
    pageLoadId = {
      path: currentPath,
      identifier: uuid4(),
    };
  }
  return pageLoadId;
};

// logic code
export const getNewShopperValue = (
  shopperId: string,
  isLoggedIn: boolean,
  shopperIdCookieValue: string | undefined,
) => {
  if (isLoggedIn) {
    if (shopperIdCookieValue && shopperIdCookieValue === shopperId){
      return undefined;
    }
    return shopperId;
  }

  return undefined;
};

export const getPreflightCookieDelta = (
  shopperId: string | undefined,
  cart: string | undefined,
  lastViewed: string | undefined,
  preflightCookieValue: string | undefined,
) => {
  const hash = `${shopperId}${cart}${lastViewed}`;
  return {deltaExits: hash === preflightCookieValue, hash};
};

// logic code
export const getNewLastViewedValue = (
  productId_PDP: string | undefined,
  currentLastViewedCookieValue: string | undefined,
) => {
  if (productId_PDP) {
    const updatedViewedProducts = updateViewedProductsCsv(
      productId_PDP,
      currentLastViewedCookieValue || '',
    );
    return updatedViewedProducts;
  }
  return currentLastViewedCookieValue;
};

//logic code
export const getNewCartValue = (
  cartLines: CartLines | undefined,
  currentCartCookieValue: string | undefined,
) => {
  if (cartLines) {
    const updatedCart = updateCartCsv(cartLines, currentCartCookieValue || '');
    return updatedCart;
  }
  return currentCartCookieValue;
};

// logic code
export const updateViewedProductsCsv = (
  sku: string,
  lastViewedProductsCSV: string,
) => {
  const MAX_VIEWED_COUNT = 20;
  const lastViewedProducts = lastViewedProductsCSV
    ? lastViewedProductsCSV.split(',')
    : '';

  const uniqueCartItems = Array.from(new Set([...lastViewedProducts, sku])).map(
    (item) => item.trim(),
  );
  const updatedViewedProducts = uniqueCartItems
    .slice(0, MAX_VIEWED_COUNT)
    .join(',');
  return updatedViewedProducts;
};

export const updateCartCsv = (
  userCartList: CartLines,
  cartCookieValue: string,
) => {
  const cartCookieList = cartCookieValue.split(',');

  const cartCookieMap: Map<string, boolean> = cartCookieList.reduce(
    (prev: any, curr) => {
      prev.set(curr, true);
      return prev;
    },
    new Map<string, boolean>(),
  );

  const userCartMap: Map<string, boolean> = userCartList.reduce(
    (prev: any, curr) => {
      prev.set(curr.merchandise.id, true);
      return prev;
    },
    new Map<string, boolean>(),
  );

  const cartCookieMatchesUserCart = cartCookieList.every((id) =>
    userCartMap.has(id),
  );
  const userCartMatchesCartCookie = userCartList.every((lineItem) =>
    cartCookieMap.has(lineItem.merchandise.id),
  );
  const countMatches = cartCookieList.length === userCartList.length;

  if (cartCookieMatchesUserCart && userCartMatchesCartCookie && countMatches) {
    return undefined;
  }

  return userCartList.map((lineItem) => lineItem.merchandise.id).join(',');
};

const tryAppendToHeader = (headers: Headers, value?: string) => {
  if (value) {
    headers.append('Set-Cookie', value);
  }
};

export const updateResponseCookies = async (
  headers: Headers,
  searchspringCookies: SearchspringCookies,
  state: SearchspringState,
) => {
  const {
    ssSessionIdNamespace,
    ssUserId,
    pageLoadId,
    ss_isuid,
    ssCartProducts,
    ssShopperId,
    ssViewedProducts,
  } = searchspringCookies;

  const ssUserId_serialized = await ssUserId.serialize(state.userId);
  tryAppendToHeader(headers, ssUserId_serialized);
  const isuid_serialized = await ss_isuid.serialize(state.isuid);
  tryAppendToHeader(headers, isuid_serialized);
  const pageLoadId_serialized = await pageLoadId.serialize(state.pageLoadId);
  tryAppendToHeader(headers, pageLoadId_serialized);
  const ssSessionIdNamespace_serialized = await ssSessionIdNamespace.serialize(
    state.sessionId,
  );
  tryAppendToHeader(headers, ssSessionIdNamespace_serialized);

  if (state.shopper) {
    const shopper_serialized = await ssShopperId.serialize(state.shopper);
    tryAppendToHeader(headers, shopper_serialized);
  }
  if (state.cart) {
    const cart_serialized = await ssCartProducts.serialize(state.cart);
    tryAppendToHeader(headers, cart_serialized);
  }
  if (state.lastViewed) {
    const lastViewed_serialized = await ssViewedProducts.serialize(
      state.lastViewed,
    );
    tryAppendToHeader(headers, lastViewed_serialized);
  }

};

export const createOpenAPIConfigurations = (siteId: string) => {
  const openAPIConfig: OpenAPIConfig = {
    BASE: `https://${siteId}.a.searchspring.io`,
    VERSION: '1.0.0',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'include',
    TOKEN: undefined,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: undefined,
    ENCODE_PATH: undefined,
  };

  const beaconConfig: OpenAPIConfig = {
    BASE: 'https://beacon.searchspring.io',
    VERSION: '1.0.0',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'include',
    TOKEN: undefined,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: undefined,
    ENCODE_PATH: undefined,
  };

  return {
    beaconConfig,
    openAPIConfig,
  };
};


