import {
  type BeaconConfig,
  type SearchspringState
} from 'int-searchspring-api/src/types';
import {
  AddToCartComponentInterface,
  LinkComponentInterface,
  type Recommendation,
} from 'propel-shared-utility';

import { SearchspringService } from 'components/searchspring/searchspringService';
import { type RecommendationsProps } from 'propel-react-components/src/components/Recommendations/RecommendationsCarousel';
import React, { useEffect, useState } from 'react';
import {
  useBeaconEventDataLayer,
  useSearchspringClient,
} from '../../hooks/ssClientHooks';
import { getBeaconConfig, getSearchspringState } from '../search-spring-state';

type RecommendationsStrategyParams = {
  tilePromo: boolean;
  tileBurst: boolean;
  tileBanner: boolean;
  tileTagline: boolean;
  tileSpecs: boolean;
  tileRatings: boolean;
  tileATC: boolean;
  recommendations?: Recommendation[];
  beaconConfig?: BeaconConfig;
  searchspringState?: SearchspringState;
  profile: string;
  showNav: boolean;
  navTheme: string;
  desktopSettings: {
    count: number,
    per: number,
  },
  tabletSettings: {
    count: number,
    per: number,
  },
  mobileSettings: {
    count: number,
    per: number,
  },
  builderBlock: {
    id: string
  },
  speed: number;
  autoPlay: boolean;
  dtSwipe: boolean;
  initialSlide: number;
};

export default function getRecommendationsPropsStrategy(
  Component: React.FC<RecommendationsProps>,
  builderEditorMode: boolean,
  AddToCartComponent: AddToCartComponentInterface,
  LinkComponent: LinkComponentInterface,
) {
  
  const RecommendationsPropsStrategy = (props: RecommendationsStrategyParams) => {
      const tileOptions = {
        banner: props.tileBanner,
        burst: props.tileBurst,
        promo: props.tilePromo,
        specs: props.tileSpecs,
        ratings: props.tileRatings,
        tagline: props.tileTagline,
        addToCart: props.tileATC,
      };

      const carouselOptions = {
        showNav: props.showNav,
        navTheme: props.navTheme,
        desktopSettings: props.desktopSettings,
        tabletSettings: props.tabletSettings,
        mobileSettings: props.mobileSettings,
        builderBlock: props.builderBlock,
        speed: props.speed,
        autoPlay: props.autoPlay,
        dtSwipe: props.dtSwipe,
        initialSlide: props.initialSlide
      }

      const state = getSearchspringState({ overrides: { profile: props.profile }})
      const searchspringClient: any = useSearchspringClient(state);
      const [recsData, setRecsData] = useState<any | null>(null);
      const [error, setError] = useState<any | null>(null);

      useEffect(() => {
        const searchService = new SearchspringService(state);
        const fetchRecsData = async () => {
          try {
            const data = await searchService.recommendationsGET(state);
            setRecsData(data);
          } catch (error) {
            setError(error);
          }
        };
        fetchRecsData();   
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [setRecsData, setError]);

      const beaconConfig = getBeaconConfig({});
      const { setRecommendationDataProps, setCarouselDataProps } =
        useBeaconEventDataLayer(
          beaconConfig,
          searchspringClient,
          props.recommendations || [],
        );

      return (
        <Component
          recommendations={recsData?.body?.at(0)?.results || []}
          setRecommendationDataProps={setRecommendationDataProps}
          setCarouselDataProps={setCarouselDataProps}
          tileOptions={tileOptions}
          carouselOptions={carouselOptions}
          AddToCartComponent={AddToCartComponent}
          LinkComponent={LinkComponent}
           />
      );

  };

  RecommendationsPropsStrategy.displayName = 'RecommendationsPropsStrategy';

  return RecommendationsPropsStrategy;
}
