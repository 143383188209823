import {type GenericAPIResult} from '../generated/search/core/ApiResult';
import {
  type BeaconDTO,
  type SearchspringAPIContract,
  type SearchspringState,
} from '../types';
import { SearchspringAPIWrapper } from './apiClientBase';
import {
  type SearchResponseModel,
  SearchspringAPIService,
} from '../generated/search';
import {createOpenAPIConfigurations} from '../lib/stateMutations';
import {type SuggestResponseModel} from '../generated/search/models/SuggestResponseModel';

export class BrowserSearchspringClient {
  #state: SearchspringState;
  #apiWrapper: SearchspringAPIContract;

  constructor(state: SearchspringState) {
    this.#state = state;
    const {openAPIConfig, beaconConfig} = createOpenAPIConfigurations(
      state.siteId,
    );
    this.#apiWrapper = new SearchspringAPIWrapper(
      new SearchspringAPIService(openAPIConfig, beaconConfig),
    );

    //bindings
    this.beaconPOST = this.beaconPOST.bind(this);
    this.suggestionsGET = this.suggestionsGET.bind(this);
    this.autocompleteGET = this.autocompleteGET.bind(this);
    this.trendingGET = this.trendingGET.bind(this);
  }

  public updateState(state: SearchspringState) {
    this.#state = {
      ...this.#state,
      ...state,
    };
  }

  public beaconPOST(
    dto: BeaconDTO,
  ): Promise<GenericAPIResult<{success: boolean} | undefined>> {
    return this.#apiWrapper.beaconPOST(dto);
  }


  public trendingGET(
  ): Promise<GenericAPIResult<any | undefined>> {
    
    return this.#apiWrapper.trendingGET(this.#state);
  }

  public suggestionsGET(
    q: string,
  ): Promise<GenericAPIResult<SuggestResponseModel | undefined>> {
    this.#state.q = q;
    return this.#apiWrapper.suggestionsGET(this.#state);
  }

  public autocompleteGET(
    q: string,
  ): Promise<GenericAPIResult<SearchResponseModel | undefined>> {
    this.#state.q = q;
    return this.#apiWrapper.autocompleteGET(this.#state);
  }
}
