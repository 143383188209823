import React, { PropsWithChildren, createContext, useContext } from "react";
import { type AutocompleteLinkComponentInterface } from 'propel-shared-utility';

type WrappedComponentContext = {
    LinkComponent: AutocompleteLinkComponentInterface;
}

const context = createContext({} as WrappedComponentContext);
export const useWrappedComponentContext = () => useContext(context);

export const WrappedComponentContextProvider: React.FC<PropsWithChildren & WrappedComponentContext> = ({LinkComponent, children}: WrappedComponentContext & PropsWithChildren) => {
    return (
        <context.Provider value={{ LinkComponent }}>
            {children}
        </context.Provider>
    )
}